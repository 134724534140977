/**
 *
 * SimpleVideoPage
 *
 */

import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { injectIntl, intlShape } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose, bindActionCreators } from 'redux';
import classnames from 'classnames';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import VideoWrap from 'components/VideoWrap';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import AppPreloader from 'components/AppPreloader';
import FavoriteFolderBtns from 'components/FavoriteFolderBtns';
import VideoBackNotePopup from 'components/VideoBackNotePopup/Loadable';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LockIcon from '@material-ui/icons/Lock';
import { getParams } from 'utils/getParams';
import ConfirmPaidPopup from 'components/ConfirmPaidPopup/Loadable';
import ReactGA from 'googleAnalytics';
import * as actions from './actions';
import * as appActions from '../App/actions';
import makeSelectSimpleVideoPage from './selectors';
import reducer from './reducer';
import appReducer from '../App/reducer';
import saga from './saga';
import appSaga from '../App/saga';
import messages from './messages';
import styles from './styles';
import { makeSelectGlobal } from '../App/selectors';
import { defineAppTitle, CURRENT_VERTICAL } from '../../verticalConfigs';
// import { PRICING_PAGE } from '../../utils/constants';
import { SmartLink } from '../../components/SmartLink';
import { mixpanelStorage, setExerciseViewInfo, setExerciseViewTime } from '../../localStorage';
import mixpanel from '../../mixpanel';

/* eslint-disable react/prefer-stateless-function */
export class SimpleVideoPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      video: null,
      updateFlag: true,
      correct_videos: [],
      mistake_videos: [],
      preparatory_exercises: [],
      openDialog: false,
      openMusclesBox: false,
      linkToSubMuscle: null,
      targetId: null,
      type: 'correct_videos',
      key: 0,
      section: null,
      isConfirmPaidPopup: false,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      getVideoById,
      getMenuItemForVideo,
      match: {
        params: { videoId, workout_item_id },
      },
    } = this.props;

    const [id, section] = getParams(videoId);
    getVideoById(id, section, workout_item_id);
    getMenuItemForVideo('exercise_id', id, section);
    ReactGA.event('1oldview_item', { item_id: id });
    this.setExerciseTracingInfo();

    if (_.includes(videoId, '@')) {
      this.setState({ section: videoId.split('@')[1] });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      simpleVideoPage,
      simpleVideoPage: { data },
      match: { params },
    } = nextProps;

    if (simpleVideoPage.loaded && this.state.updateFlag) {
      const {
        // eslint-disable-next-line camelcase
        exercise: {
          correct_videos,
          mistake_videos,
          muscle_ids,
          preparatory_exercises,
          name,
          is_paid,
          general_exercise_id,
        },
      } = data;

      let dataForBack = JSON.parse(localStorage.getItem('backSimpleVideoAsana'));
      let currentVideo = _.first(correct_videos);
      if (dataForBack && dataForBack.isBack && dataForBack.video) {
        currentVideo = dataForBack.video;
        dataForBack = null;
        localStorage.setItem('backSimpleVideoAsana', JSON.stringify(dataForBack));
      }
      // eslint-disable-next-line react/no-did-update-set-state
      const preparatoryVideos = preparatory_exercises || [];
      this.setState(
        {
          correct_videos,
          mistake_videos,
          preparatory_exercises: preparatoryVideos,
          video: currentVideo,
          muscle_ids,
          updateFlag: typeof this.props.resizePlanExerciseVideo === 'boolean',
        },
        () => {
          mixpanelStorage.setItem('exerciseId', general_exercise_id);
          mixpanelStorage.setItem('exerciseName', name);
          mixpanelStorage.setItem('locked', is_paid);
          this.setExerciseTracingInfo();
        },
      );
    }
    if (params.videoId !== this.props.match.params.videoId) {
      const [videoId, section] = getParams(params.videoId);
      this.props.getVideoById(videoId, section, params.workout_item_id);
      this.setState({ updateFlag: true });
    }
  }

  componentWillUnmount() {
    mixpanel.sendExerciseViewTime();
  }

  setExerciseTracingInfo = () => {
    setExerciseViewInfo({
      ...mixpanelStorage.getItem('entryPoint'),
      ...(mixpanelStorage.getCleanItem('entryPoint') === 'Theory' ? mixpanelStorage.getItem('theorySubChapter') : {}),
      ...(mixpanelStorage.getCleanItem('entryPoint') === 'Theory'
        ? mixpanelStorage.getItem('theorySubSubChapter')
        : {}),
      ...mixpanelStorage.getItem('exerciseId'),
      ...mixpanelStorage.getItem('exerciseName'),
      ...mixpanelStorage.getItem('locked'),
    });
  };

  handleClick = (val, stateVal) => {
    const obj = { [val]: true };
    if (stateVal) obj[val] = false;
    this.setState(obj);
  };

  renderVideoButtons = (data, classes, type) =>
    data?.map((el, i) => {
      const isActiveButton = this.state.video.id === el.id && this.state.type === type;
      const videoButtonClasses = classnames(classes.bullet, {
        [classes.bulletActive]: isActiveButton,
      });

      return (
        <button
          type="button"
          // eslint-disable-next-line react/no-array-index-key
          key={`buttons_${i}`}
          className={videoButtonClasses}
          onClick={() => {
            this.setState({ video: el, key: i, type });
            if (type === 'correct_videos') {
              setExerciseViewTime('videosViewed', i);
            }
            if (type === 'mistake_videos') {
              setExerciseViewTime('commonMistakesViewed', i);
            }
          }}
        >
          {i + 1}
        </button>
      );
    });

  handleOpenDialog = (linkToSubMuscle, isPaid, targetId) => {
    const { global } = this.props;
    if (localStorage.getItem('subMuscleDialogLock') !== 'true' && !isPaid) {
      this.setState({ openDialog: true, linkToSubMuscle, targetId });
    }
    if (
      global.user.data !== null &&
      global.user.data.is_paid &&
      localStorage.getItem('subMuscleDialogLock') !== 'true'
    ) {
      this.setState({ openDialog: true, linkToSubMuscle, targetId });
    }
  };

  checkSquareColor = key => {
    let color;
    if (key === 'Target') color = 'red';
    else if (key === 'Synergist') color = '#f2589e';
    else if (key === 'Stabilizer') color = '#c0c6aa';
    else if (key === 'Lengthening') color = '#C6FA01';
    return color;
  };

  renderCorrectLanguageString = key => {
    const {
      intl: { formatMessage },
    } = this.props;
    let str;
    if (key === 'Target') str = formatMessage(messages.labelTarget);
    else if (key === 'Synergist') str = formatMessage(messages.labelSynergist);
    else if (key === 'Stabilizer') str = formatMessage(messages.labelStabilizer);
    else if (key === 'Lengthening') str = formatMessage(messages.labelLengthening);
    return str;
  };

  renderSubmuscles() {
    // eslint-disable-next-line camelcase
    const { muscle_ids } = this.state;
    const {
      classes,
      global: { submuscles },
      global,
    } = this.props;
    return _.map(muscle_ids, (categorieObject, key) => {
      if (!categorieObject.length) return false;
      return (
        <div className={classes.subMusclesBox} key={key}>
          <p className={classes.subMuscleLabel}>
            <span className={classes.squareCup} style={{ background: this.checkSquareColor(_.startCase(key)) }} />
            {this.renderCorrectLanguageString(_.startCase(key))}
          </p>
          {_.map(categorieObject, (categoryId, ind) => {
            const findedMuscle = _.find(submuscles?.data?.submuscles, { id: categoryId });
            if (!findedMuscle) return false;
            return (
              <button
                type="button"
                key={`muscleId_${ind}`}
                className={classes.subMuscle}
                onClick={() => {
                  mixpanelStorage.setItem('entryPoint', 'Exercise');
                  this.handleOpenDialog(`/submuscle/${findedMuscle.id}`, findedMuscle.is_paid, findedMuscle.id);
                }}
              >
                {(() => {
                  if (global.user.data !== null && global.user.data.is_paid) {
                    if (localStorage.getItem('subMuscleDialogLock') === 'true') {
                      return (
                        <Link
                          to={`/submuscle/${findedMuscle.id}`}
                          onClick={() => {
                            mixpanelStorage.setItem('entryPoint', 'Exercise');
                            this.fetchAddExerciseToLocalWebStorage(findedMuscle.id);
                            ReactGA.event('1oldview_item', { item_id: findedMuscle.id });
                          }}
                        >
                          {findedMuscle.name}
                        </Link>
                      );
                    }
                    return <p style={{ margin: 0 }}>{findedMuscle.name}</p>;
                  }
                  if (localStorage.getItem('subMuscleDialogLock') === 'true') {
                    if (findedMuscle.is_paid) {
                      return (
                        <div className={classes.lockIconWrap}>
                          <SmartLink to={`/submuscle/${findedMuscle.id}`}>{findedMuscle.name}</SmartLink>
                          <LockIcon className={classes.lockIcon} />
                        </div>
                      );
                    }
                    return (
                      <Link
                        to={`/submuscle/${findedMuscle.id}`}
                        onClick={() => {
                          mixpanelStorage.setItem('entryPoint', 'Exercise');
                          this.fetchAddExerciseToLocalWebStorage(findedMuscle.id);
                          ReactGA.event('1oldview_item', { item_id: findedMuscle.id });
                        }}
                      >
                        {findedMuscle.name}
                      </Link>
                    );
                  }
                  if (findedMuscle.is_paid) {
                    return (
                      <div className={classes.lockIconWrap}>
                        <SmartLink to={`/submuscle/${findedMuscle.id}`}>{findedMuscle.name}</SmartLink>
                        <LockIcon className={classes.lockIcon} />
                      </div>
                    );
                  }

                  return <p style={{ margin: 0 }}>{findedMuscle.name}</p>;
                })()}
              </button>
            );
          })}
        </div>
      );
    });
  }

  // eslint-disable-next-line consistent-return
  renderArrows() {
    // eslint-disable-next-line camelcase
    const { correct_videos, mistake_videos, preparatory_exercises, type, key } = this.state;
    const { classes } = this.props;
    const coorectLength = correct_videos?.length;
    const mistakeLength = mistake_videos?.length;
    const preparatoryLength = preparatory_exercises?.length;
    const prevButtonClasses = classnames(classes.prevBtn, classes.arrowBtn);
    const nextButtonClasses = classnames(classes.nextBtn, classes.arrowBtn);

    if (type === 'correct_videos') {
      return (
        <>
          {key > 0 && (
            <button type="button" onClick={this.prevVideo} className={prevButtonClasses}>
              <ArrowBackIosIcon />
            </button>
          )}
          {coorectLength - 1 > key && (
            <button type="button" onClick={this.nextVideo} className={nextButtonClasses}>
              <ArrowForwardIosIcon />
            </button>
          )}
        </>
      );
    }
    if (type === 'mistake_videos') {
      return (
        <>
          {key > 0 && (
            <button type="button" onClick={this.prevVideo} className={prevButtonClasses}>
              <ArrowBackIosIcon />
            </button>
          )}
          {mistakeLength - 1 > key && (
            <button type="button" onClick={this.nextVideo} className={nextButtonClasses}>
              <ArrowForwardIosIcon />
            </button>
          )}
        </>
      );
    }
    if (type === 'preparatory_exercises') {
      return (
        <>
          {key > 0 && (
            <button type="button" onClick={this.prevVideo} className={prevButtonClasses}>
              <ArrowBackIosIcon />
            </button>
          )}
          {preparatoryLength - 1 > key && (
            <button type="button" onClick={this.nextVideo} className={nextButtonClasses}>
              <ArrowForwardIosIcon />
            </button>
          )}
        </>
      );
    }
  }

  nextVideo = () => {
    // eslint-disable-next-line camelcase
    const { correct_videos, mistake_videos, preparatory_exercises, type, key } = this.state;
    if (type === 'correct_videos') {
      this.setState({ video: correct_videos[key + 1], key: key + 1 });
      setExerciseViewTime('videosViewed', key + 1);
    }
    if (type === 'mistake_videos') {
      this.setState({ video: mistake_videos[key + 1], key: key + 1 });
      setExerciseViewTime('commonMistakesViewed', key + 1);
    }
    if (type === 'preparatory_exercises') this.setState({ video: preparatory_exercises[key + 1], key: key + 1 });
  };

  prevVideo = () => {
    // eslint-disable-next-line camelcase
    const { correct_videos, mistake_videos, preparatory_exercises, type, key } = this.state;
    if (type === 'correct_videos') {
      this.setState({ video: correct_videos[key - 1], key: key - 1 });
      setExerciseViewTime('videosViewed', key - 1);
    }
    if (type === 'mistake_videos') {
      this.setState({ video: mistake_videos[key - 1], key: key - 1 });
      setExerciseViewTime('commonMistakesViewed', key - 1);
    }
    if (type === 'preparatory_exercises') this.setState({ video: preparatory_exercises[key - 1], key: key - 1 });
  };

  handleCloseDialog = () => this.setState({ openDialog: false });

  handleChangeLocalWebStorage = () => {
    if (localStorage.getItem('subMuscleDialogLock') !== 'true') {
      localStorage.setItem('subMuscleDialogLock', true);
    } else {
      localStorage.setItem('subMuscleDialogLock', false);
    }
  };

  fetchAddExerciseToLocalWebStorage = id => {
    const {
      match: {
        params: { videoId },
      },
      simpleVideoPage: {
        data: { exercise },
      },
    } = this.props;
    const { targetId } = this.state;
    const obj = { exerciseName: exercise.name, backUrl: `/exercise/${videoId}`, targetId: id || targetId };
    localStorage.setItem('backExerciseData', JSON.stringify(obj));
  };

  fetchCheckSource = video => {
    const type = video.asset_url.split('.');
    let res = true;
    if (type[type.length - 1] !== 'mp4') res = false;
    return res;
  };

  gotoFullAsana = () => {
    this.setState({ updateFlag: true });
    const section = this.state.section ? `@${this.state.section}` : '';
    const dataForBack = { video: this.state.video, isBack: false };
    localStorage.setItem('backSimpleVideoAsana', JSON.stringify(dataForBack));

    if (this.state.video.exercise_id && this.state.video.exercise_id !== '') {
      mixpanelStorage.setItem('entryPoint', 'Alternative Exercises');
      mixpanelStorage.setItem('exerciseId', this.state.video.exercise_id);
      mixpanelStorage.setItem('exerciseName', this.state.video.name);
      mixpanelStorage.setItem('locked', this.state.video.is_paid);
      // mixpanel.exerciseClick();
    }
    this.props.history.push(`/exercise/${this.state.video.exercise_id}${section}`);
  };

  handleGoBack = () => {
    const { match, handleBackToWorkout, history } = this.props;
    const backFlag = match?.params?.backFlag;

    if (typeof backFlag === 'boolean' && !backFlag) {
      return this.props.closePopup();
    }
    if (!handleBackToWorkout) {
      const dataForBack = JSON.parse(localStorage.getItem('backSimpleVideoAsana'));
      if (dataForBack && !dataForBack.isBack) {
        dataForBack.isBack = true;
        localStorage.setItem('backSimpleVideoAsana', JSON.stringify(dataForBack));
      }
      history.goBack();
      return;
    }
    handleBackToWorkout();
  };

  onConfirmPaidPopup = () => this.setState({ isConfirmPaidPopup: true });

  cancelConfirmPaidPopup = () => this.setState({ isConfirmPaidPopup: false });

  render() {
    const {
      simpleVideoPage,
      classes,
      match,
      intl: { formatMessage },
      global: { menuItem, user },
      planExerciseFlag,
      resizePlanExerciseVideo,
    } = this.props;
    const {
      video,
      correct_videos,
      mistake_videos,
      preparatory_exercises,
      openDialog,
      linkToSubMuscle,
      muscle_ids,
      section,
      openMusclesBox,
      type,
    } = this.state;

    setExerciseViewTime('videos', correct_videos?.length || 0);
    setExerciseViewTime('commonMistakesVideos', mistake_videos?.length || 0);

    const videoIsPaid = video && video.is_paid;
    const isLock = (!user.data && videoIsPaid) || (user.data && !user.data.is_paid && videoIsPaid);
    const isNotFoundVideo = simpleVideoPage.loaded && !video;
    const prepExercisesMessage =
      ['yoga', 'posture'].indexOf(CURRENT_VERTICAL) >= 0 ? messages.preparatory : messages.alternativeExercises;
    const goToFullAsanaMessage =
      ['yoga', 'posture'].indexOf(CURRENT_VERTICAL) >= 0 ? messages.goToFullAsana : messages.goToFullExercise;
    const rightBlockStyle =
      !planExerciseFlag || resizePlanExerciseVideo ? { maxWidth: 376 } : { width: '38%', paddingTop: 24 };

    return (
      <div className={classes.wrap} style={planExerciseFlag ? { padding: '50px 0 0' } : {}}>
        <Helmet>
          <title>{defineAppTitle()}</title>
          <meta name="description" content={formatMessage(messages.metaDescription)} />
        </Helmet>
        {simpleVideoPage.loading && <AppPreloader />}
        {isNotFoundVideo && <h1>{formatMessage(messages.videoIsNotFound)}</h1>}
        {video && simpleVideoPage.loaded && (
          <>
            {!planExerciseFlag ? (
              <div className={classes.exerciseName}>
                <button type="button" className={classes.backButton} onClick={this.handleGoBack}>
                  {formatMessage(messages.back)}
                </button>
                {menuItem && menuItem.data
                  ? `${menuItem.data.string.split('--')[0]} / ${simpleVideoPage.data.exercise.name}`
                  : null}
              </div>
            ) : null}
            <div className={classes.mainContentBox}>
              <h2>{simpleVideoPage.data.exercise.name}</h2>
              <div className={classes.bulletsAndMissingLanguageWrapper}>
                <div
                  className={classes.bulletsWrap}
                  style={planExerciseFlag ? { maxWidth: resizePlanExerciseVideo ? 'calc(100% - 450px)' : '93%' } : {}}
                >
                  {correct_videos?.length !== 0 && (
                    <div className={classes.bulletsBox}>
                      <span className={classes.bulletsLabel}>{formatMessage(messages.correct)}</span>
                      {this.renderVideoButtons(correct_videos, classes, 'correct_videos')}
                    </div>
                  )}
                  {mistake_videos?.length !== 0 && (
                    <div className={classes.bulletsBox}>
                      <span className={classes.bulletsLabel}>
                        {CURRENT_VERTICAL === 'yoga'
                          ? formatMessage(messages.problems)
                          : formatMessage(messages.mistake)}
                      </span>
                      {this.renderVideoButtons(mistake_videos, classes, 'mistake_videos')}
                    </div>
                  )}
                  {preparatory_exercises?.length !== 0 && (
                    <div className={classes.bulletsBox}>
                      <span className={classnames(classes.bulletsLabel)}>{formatMessage(prepExercisesMessage)}</span>
                      {this.renderVideoButtons(preparatory_exercises, classes, 'preparatory_exercises')}
                    </div>
                  )}
                  {_.size(_.filter(muscle_ids, item => _.size(item))) !== 0 && (
                    <>
                      <button
                        type="button"
                        onClick={() => {
                          setExerciseViewTime('musclesClicked', true);
                          this.setState(state => ({ openMusclesBox: !state.openMusclesBox }));
                        }}
                        className={openMusclesBox ? `${classes.labelActive} ${classes.label}` : classes.label}
                      >
                        {formatMessage(messages.muscles)}
                        <ArrowDropDownIcon
                          fontSize="large"
                          style={openMusclesBox ? { transform: 'rotate(180deg)' } : {}}
                        />
                      </button>
                      <div
                        className={classes.muscleBox}
                        style={openMusclesBox ? { maxHeight: 400, paddingBottom: 12 } : {}}
                      >
                        {this.renderSubmuscles()}
                      </div>
                    </>
                  )}
                </div>
                {video.description_is_en ? (
                  <div
                    className={classes.missingLanguage}
                    style={rightBlockStyle.maxWidth ? { minWidth: rightBlockStyle.maxWidth } : rightBlockStyle}
                  >
                    {formatMessage(messages.missingLangDescription)}
                  </div>
                ) : null}
              </div>
              <div className={classes.videoBox}>
                <div
                  className={classes.videoContainer}
                  style={planExerciseFlag ? { maxWidth: resizePlanExerciseVideo ? 'calc(100% - 450px)' : '54%' } : {}}
                >
                  {this.renderArrows()}
                  {!planExerciseFlag ? (
                    <FavoriteFolderBtns
                      id={match.params.videoId}
                      video={video}
                      assetId={video ? video.id : null}
                      chapter="exercise"
                      section={section}
                    />
                  ) : null}
                  {this.fetchCheckSource(video) ? (
                    <VideoWrap video={video} audio />
                  ) : (
                    <div className={classes.posterWrap} style={{ width: '100%' }}>
                      <img src={video.asset_url} alt="poster" onContextMenu={e => e.preventDefault()} />
                    </div>
                  )}
                </div>
                <div style={rightBlockStyle}>
                  {type === 'preparatory_exercises' && video.exercise_id && (
                    <button
                      className={classes.goToFullAsanaBtn}
                      onClick={isLock ? this.onConfirmPaidPopup : this.gotoFullAsana}
                      type="button"
                    >
                      {formatMessage(goToFullAsanaMessage)}
                    </button>
                  )}
                  {_.map(video.description, (dscrBox, index) => (
                    <div key={index} style={{ marginBottom: 20 }}>
                      {_.map(dscrBox, (dscr, ind) => (
                        <p key={ind} className={classes.dscr}>
                          {dscr.length > 1 ? dscr : '‎ '}
                        </p>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
        <ConfirmPaidPopup
          open={this.state.isConfirmPaidPopup}
          onCancel={this.cancelConfirmPaidPopup}
          onConfirm={() => this.setState({ updateFlag: true })}
        />
        <VideoBackNotePopup
          open={openDialog}
          handleClose={this.handleCloseDialog}
          linkToSubMuscle={linkToSubMuscle}
          fetchAddExerciseToLocalWebStorage={this.fetchAddExerciseToLocalWebStorage}
        />
      </div>
    );
  }
}

SimpleVideoPage.propTypes = {
  classes: PropTypes.object,
  global: PropTypes.object,
  getVideoById: PropTypes.func,
  getMenuItemForVideo: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  simpleVideoPage: PropTypes.object,
  planExerciseFlag: PropTypes.bool,
  handleBackToWorkout: PropTypes.func,
  resizePlanExerciseVideo: PropTypes.bool,
  intl: intlShape.isRequired,
};

const mapStateToProps = createStructuredSelector({
  simpleVideoPage: makeSelectSimpleVideoPage(),
  global: makeSelectGlobal(),
});

function mapDispatchToProps(dispatch) {
  return {
    getVideoById: bindActionCreators(actions.getDetailedVideo, dispatch),
    getMenuItemForVideo: bindActionCreators(appActions.getMenuItemForVideo, dispatch),
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'simpleVideoPage', reducer });
const withSaga = injectSaga({ key: 'simpleVideoPage', saga });
const withAppReducer = injectReducer({ key: 'global', reducer: appReducer });
const withAppSaga = injectSaga({ key: 'global', saga: appSaga });

const SimpleVideoPageStyled = withStyles(styles)(injectIntl(SimpleVideoPage));

export default compose(
  withReducer,
  withSaga,
  withAppReducer,
  withAppSaga,
  withConnect,
)(SimpleVideoPageStyled);
