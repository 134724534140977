import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    width: 100%;
	  min-height: 100vh;
  }
  
  a {
    text-decoration: none;
  }
  
  img {
    display: block;  
  }
  
  * {
    outline: none;
    font-family: 'Assistant', sans-serif;
    color: #d7e5ff;
  }
  
  button {
    cursor: pointer;
  }
  
  #app {
	  min-height: 100vh;
	  padding-top: 60px;
	  background: #1a212e;
  }
  
  .video-js.mm-video-js {
  	width: 100%;
  }
  
  .drag-exercise {
  	position: fixed !important;
  	z-index: 1000 !important;
  };

  .highlight {
    background-color: #fab923;

  }
`;

export default GlobalStyle;
