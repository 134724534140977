import React from 'react';

export const mmIcons = {
  facebookIcon: (
    <svg fill="#bec2c6" viewBox="0 0 24 24" style={{ width: 28 }}>
      <path d="M17.525,9H14V7c0-1.032,0.084-1.682,1.563-1.682h1.868v-3.18C16.522,2.044,15.608,1.998,14.693,2 C11.98,2,10,3.657,10,6.699V9H7v4l3-0.001V22h4v-9.003l3.066-0.001L17.525,9z" />
    </svg>
  ),
  newPopular: (
    <svg x="0px" y="0px" viewBox="0 0 612 612" style={{ enableBackground: 'new 0 0 612 612', width: 20 }}>
      <g>
        <path
          d="M608.1,333.3l-45-71.4c-3.6-5.7-4.8-12.5-3.4-19l17.9-82.4c2.8-12.8-4.6-25.7-17.2-29.7l-80.3-25.8
            c-6.4-2-11.7-6.5-14.8-12.4L426,17.9c-6.1-11.6-20.1-16.7-32.3-11.7l-78.1,31.9c-6.2,2.5-13.1,2.5-19.3,0L218.3,6.2
            c-12.2-5-26.1,0.1-32.3,11.7l-39.3,74.6c-3.1,5.9-8.4,10.4-14.8,12.4l-80.3,25.8c-12.5,4-19.9,16.9-17.2,29.7l17.8,82.4
            c1.4,6.5,0.2,13.4-3.4,19l-45,71.4c-7,11.1-4.4,25.7,6,33.8l66.7,51.7c5.3,4.1,8.8,10.1,9.7,16.7L97.6,519
            c1.8,13,13.2,22.6,26.3,22.1l84.3-3.3c6.7-0.3,13.2,2.1,18.2,6.6l62.5,56.7c9.7,8.8,24.6,8.8,34.3,0l62.5-56.7
            c4.9-4.5,11.5-6.9,18.2-6.6l84.3,3.3c13.1,0.5,24.5-9,26.3-22.1l11.4-83.6c0.9-6.6,4.4-12.6,9.7-16.7l66.7-51.7
            C612.5,359,615.1,344.4,608.1,333.3z M235.2,407.9l-74.4-52l30.6,70.9l-20.4,8.8l-46.9-108.7l21.4-9.2l75.8,53.4l-31.3-72.6
            l20.4-8.8l46.9,108.7L235.2,407.9z M280.5,388.4l-46.9-108.7l80.6-34.8l7.9,18.4l-58.7,25.3l10.4,24.1l54.6-23.6l7.9,18.3
            L281.7,331l12.8,29.6l60.7-26.2l7.9,18.3L280.5,388.4z M464.2,309.1l-56.7-71.9l13.5,90.6L397.2,338l-72.9-97.5l22.5-9.7l48.6,67.6
            l-12.4-83.3l26.1-11.3l51.8,67.7l-16.1-83.1l22.1-9.5L487.5,299L464.2,309.1z"
        />
      </g>
    </svg>
  ),
  plansIcon: (
    <svg width="15.603" height="19.254" viewBox="0 0 15.603 19.254">
      <g id="plan-group" transform="translate(-3.5 -1.5)">
        <path d="M13.127 2h-7.3A1.825 1.825 0 0 0 4 3.825v14.6a1.825 1.825 0 0 0 1.825 1.825h10.953a1.825 1.825 0 0 0 1.822-1.821V7.476z" />
        <path d="M14 2v5.476h5.476" transform="translate(-.873)" />
        <path d="M8 0L0 0" transform="translate(8.442 12.201)" />
        <path d="M8 0L0 0" transform="translate(8.442 16.201)" />
        <path d="M9.825 9H8" transform="translate(-.349 -.611)" />
      </g>
    </svg>
  ),
  exercises: (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 512 315.9"
      style={{ enableBackground: 'new 0 0 512 315.9', width: 28, marginLeft: -4 }}
    >
      <g>
        <g>
          <path
            d="M450.7,193.4c-8.1,0-15.9,1.6-23,4.5l16,7.3c21.4,9.8,30.9,35.1,21.1,56.5c-9.7,21.3-35,31-56.5,21.1l-15.1-6.9
            c8.6,23.4,31.1,40,57.5,40c33.8,0,61.3-27.4,61.3-61.3S484.6,193.4,450.7,193.4z"
          />
        </g>
        <g>
          <circle cx="412.5" cy="47.3" r="47.3" />
        </g>
        <g>
          <path
            d="M435.1,224.2c-7.2-3.3-61.3-27.9-68.5-31.2l-13.1-81.8l14.9,33.9c4-6.1,5.2-14,2.6-21.4l-16.2-45.2
            c-4.5-12.6-18.4-19.1-30.9-14.6c-10.9,3.9-172.1,61.8-180.6,64.8l0,0c-10.3,4.5-16.3,15.8-13.7,27.1l26.1,112.5l-132,0.1
            C10.6,268.4,0,279.1,0,292.2c0,13.1,10.6,23.7,23.7,23.7c0,0,0,0,0,0l161.8-0.2c15.3,0,26.5-14.2,23.1-29.1l-16.9-73l115.5-41.5
            L311,121l14.4,90.3c1.2,7.2,5.8,13.3,12.5,16.4l79.1,36.1c10.1,4.6,22.1,0.9,27.8-8.8C451.5,243.7,446.8,229.5,435.1,224.2z"
          />
        </g>
      </g>
    </svg>
  ),
  muscular: (
    <svg x="0px" y="0px" viewBox="0 0 45.4 50.5" style={{ enableBackground: 'new 0 0 45.4 50.5', width: 19 }}>
      <g>
        <path
          d="M29.4,40.5c2.8,3.5,6.7,6.9,10.1,8.1l1,1.8h4.9V27.8c-1.8-3.1-5.1-5.1-8.9-5.1c-3.7,0-6.9,1.9-8.8,4.8
          c-2.2-1.6-5.2-2.6-8.6-2.6c-0.8,0-1.6,0.1-2.3,0.2c3-5.1,4.7-13.1,6.3-15.5c1.6-2.4,6.6,1.3,9.2-0.5c2.7-1.9,1.3-7.6-1.2-8.6
          c-2.5-1-6.2-0.4-9.6,1.8c-1.2,0.8-1.4,1.5-1.7,2.5c-0.1,0-0.3,0.1-0.4,0.1C16.2,6.2,8,14.2,4.9,19.2c-1.9,3.1-3.3,6.2-4,9
          C0.5,28.6,0,29.1,0,29.7C0.2,36.8,18.4,44.8,29.4,40.5z"
        />
      </g>
    </svg>
  ),
  skeletal: (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 99.1 197.7"
      style={{ enableBackground: 'new 0 0 99.1 197.7', width: 16, marginLeft: 2 }}
    >
      <g>
        <path
          d="M0,109.4c0.4-1.1,0.8-2.2,1.2-3.2c1-3,2.7-5.1,5.5-5.8c0.1-0.9,0-1.6,0.2-2.3c0.7-2,1.6-3.8,2.2-5.8
          c1.2-3.9,3.2-7.4,5.1-10.9c1.1-2,2.3-4,2.1-6.4c0-0.1,0-0.3,0.1-0.5c0.7-1.1,1.3-2.2,2-3.2c1.8-2.4,2.4-5.2,3.1-8
          c1-4.4,2.1-8.9,3-13.3c0.4-2,0.8-4,0.8-6c0-2.4,0-4.7,3.1-5.5c-1.2-1.4-0.1-1.8,0.8-2.4c2.4-1.6,4.9-1.6,7.6-1.1
          c2.1,0.4,4.3,0.7,6.4,1c0.5,0.1,1.2-0.3,1.6-0.6c0.8-0.7,1.5-1.4,1.7-2.6c0.1-0.6,0.7-1.2,1.1-1.9c-0.4-0.1-0.9-0.2-1.5-0.3
          c0.6-0.8,1-1.5,1.6-2.4c-0.5,0-0.9,0-1.6,0c0.6-0.9,1-1.6,1.5-2.3c0.3,0.2,0.6,0.3,0.8,0.5c0.8,0.7,1.6,0.8,2.4,0
          c0.6-0.6,0.9-0.4,1.3,0.2c0.3,0.6,0.8,1,1.3,1.7c-0.7,0-1.2,0-1.8,0c0.5,0.8,1,1.4,1.6,2.3c-0.7,0.1-1.2,0.2-1.6,0.3
          c0.9,1.4,1.8,2.8,2.7,4.2c0.5,0.7,1.1,0.9,2,0.8c2.8-0.5,5.6-0.9,8.4-1.3c2.3-0.3,4.3,0.6,6.2,1.7c1.2,0.7,0.5,1.4-0.2,2.1
          c0.2,0.1,0.4,0.2,0.6,0.3c2.1,0.6,2.8,1.4,2.7,3.5c-0.3,3.3,0.5,6.5,1.3,9.7c1,4.2,1.9,8.4,2.7,12.6c0.5,2.7,1.7,5,3.3,7.2
          c0.8,1.1,1.1,2.7,1.5,4c0.4,1.2,0.5,2.5,1.1,3.5c3.4,5.9,6.1,12.1,8.4,18.5c0.2,0.7,0.1,1.5,0.2,2.3c2.6,0.5,4.2,2.5,5.2,5.1
          c0.5,1.3,0.9,2.7,1.4,4.1c0,0.2,0,0.5,0,0.7c-0.8,0.4-0.9-0.4-1.1-0.8c-0.8-1.6-1.6-3.3-2.5-4.9c-0.3-0.6-0.9-1.2-1.4-1.7
          c-0.1,0.1-0.3,0.1-0.4,0.2c0.2,0.9,0.2,2,0.6,2.8c1.1,1.9,1.4,3.9,1.7,5.9c0.2,1.3,0.5,2.6,0.7,3.9c0.1,0.3,0,0.6-0.2,1
          c-0.9-4.2-2.8-8.1-3.9-12.2c-0.2,0.1-0.3,0.1-0.5,0.2c0.9,4.4,1.8,8.7,2.8,13.1c-0.2,0-0.3,0.1-0.5,0.1c-1.9-3.8-2.2-8-3.6-12
          c-0.2,4,1.2,7.9,1.4,11.9c-0.2,0-0.3,0.1-0.5,0.1c-0.4-1.7-0.9-3.4-1.3-5.1c-0.5-2.6-1-5.2-1.4-7.8c-0.1-0.4,0-0.9,0.3-1.2
          c0.7-0.7,0.3-1.3-0.2-1.8c-4.7-4.1-6.5-9.9-8.5-15.5c-0.7-1.9-1.3-3.8-2-5.6c-0.2-0.5-0.4-1-0.8-1.3c-1.3-1.2-2.1-3.1-4-3.7
          c-0.2-0.1-0.5-0.8-0.3-1c1.5-2.4,1.3-5,0.7-7.5c-1.6-7.3-3.3-14.6-5-21.8c-0.1-0.4-0.3-0.8-0.4-1.2c-0.6,0.3-1.2,0.4-1.5,0.8
          c-0.5,0.5-1,1.1-1.1,1.7c-0.3,1.1-0.3,2.3-0.5,3.4c0,0.3-0.3,0.5-0.4,0.8c-0.3-0.2-0.7-0.3-0.8-0.5c-0.3-0.6-0.6-1.3-0.7-2
          c-0.4-2.2-1.2-4.2-2.6-5.9c0.4,1.3,0.9,2.5,1.1,3.8c0.7,3.2,1.3,6.4,2,9.6c0.8,3.7,0.2,7.3-0.5,10.9c-0.2,1-0.2,2.1-0.3,3.1
          c-0.1,1-0.4,1.9-1.5,2.3c-1.1,0.4-1.8-0.4-2.4-1.1c-0.3-0.3-0.6-0.7-0.8-1.1c-1.9-3.5-5.1-4.9-8.7-5.1c-3.8-0.2-7.3,1.1-9.4,4.8
          c-0.5,0.8-1.2,1.5-1.9,2.1C38,72.2,37,72,36.6,71c-0.5-1.1-0.8-2.2-1-3.4c-0.3-1.6-0.7-3.3-0.7-4.9c-0.1-2.3,0.1-4.6,0.3-6.9
          c0.2-1.8,0.5-3.5,0.9-5.2c0.5-2.4,1.1-4.7,1.7-7.1c0.1-0.3,0.2-0.6,0.2-0.9c-1.3,1.6-2.1,3.5-2.3,5.6c-0.1,1.1-0.4,2.1-1.9,2.6
          c-0.2-1.2-0.4-2.4-0.5-3.6c0-1.3-0.3-2.4-1.8-2.7c-0.3-0.1-0.5-0.3-0.9-0.6c-0.2,0.4-0.4,0.7-0.5,1.1c-2.1,7.6-4.2,15.3-5.2,23.1
          c-0.3,2.2-0.5,4.4,0.9,6.4c0.4,0.6,0,1-0.6,1.2c-0.9,0.3-1.8,0.6-1.9,1.7c0,0.1-0.2,0.3-0.3,0.3c-1.7,1.1-2.4,2.8-3,4.6
          c-1.9,6-4,12-7.6,17.3c-0.5,0.7-1.3,1.3-2.1,1.8c-0.9,0.5-0.6,1.1-0.5,1.8c0.1,0.7,0.3,1.4,0.2,2c-0.6,3.5-1.4,7-2.1,10.5
          c-0.1,0.5-0.3,0.9-0.4,1.4c-0.1,0-0.3-0.1-0.4-0.1c0.6-4,1.2-7.9,1.8-11.9c-0.1,0-0.3-0.1-0.4-0.1c-0.3,0.6-0.6,1.2-0.8,1.9
          c-0.4,1.7-0.6,3.5-1.1,5.1c-0.5,1.9-1.1,3.7-1.7,5.5c-0.2-0.1-0.4-0.1-0.6-0.2c0.9-4.4,1.9-8.8,2.8-13.2c-0.1,0-0.3-0.1-0.4-0.1
          c-1.6,4-3.1,8-4.1,12.3c-0.3-2.4,0.6-4.6,1.1-6.9c0.5-2.3,1.2-4.5,1.9-6.7c-0.1-0.1-0.2-0.2-0.4-0.3c-0.5,0.5-1.1,1-1.5,1.6
          c-0.9,1.7-1.7,3.5-2.6,5.3c-0.3,0.6-0.6,1-1.3,0.9C0,110.1,0,109.8,0,109.4z M45.7,53.7c-0.5,0.3-0.9,0.7-1.4,1
          c0,0.1,0.1,0.2,0.1,0.2c0.8-0.2,1.7-0.3,2.5-0.5c0-0.2,0-0.3,0.1-0.5C46.6,53.9,46.1,53.8,45.7,53.7c0.2-0.1,0.5-0.3,0.6-0.5
          c0.2-0.4,0.3-0.9,0.5-1.4c-0.4-0.1-0.9-0.2-1.3-0.1c-1.8,0.4-3.5,0.9-5.5,1.5C42,54.4,43.8,54.2,45.7,53.7z M11.7,97.6
          c2.7-4.8,4.4-9.9,5-15.4C13.9,86.9,11.8,91.9,11.7,97.6z M43.2,65.1c1.7-0.6,3.7-0.3,4.5-2.4c0.1-0.1,0.3-0.3,0.5-0.3
          c0.9-0.1,1.8-0.3,2.7-0.2c0.3,0,0.6,0.8,0.9,1.2c0,0.1,0.1,0.1,0.2,0.1c1.3,0.6,2.5,1.2,3.8,1.8c0.1-0.1,0.2-0.2,0.2-0.3
          c-1.4-1.8-2.8-3.5-4.2-5.3c-0.1,0.1-0.2,0.1-0.3,0.2c0,0.5,0,0.9,0,1.4c-1.4,0-2.7,0-4.1,0c0-0.5,0-1,0-1.8
          C46,61.7,44.6,63.4,43.2,65.1z M82.7,82.1c-0.3,3.1,3.1,13.3,5.1,15.4C87.9,93.9,85.3,86,82.7,82.1z M39.2,57
          c2.6,2.4,6.6,1.7,8.3-1.3C46.3,54.6,42.1,55.2,39.2,57z M51.7,55.8c2.2,3.1,6.1,3.7,8.2,1.3C57.7,55.2,52.9,54.5,51.7,55.8z
           M56.2,45.3c0-0.1-0.1-0.2-0.1-0.3c-1.1-0.2-2.2-0.3-3.3-0.5c2.1-0.7,4.4,0.8,6.4-1.1c-2.9-1.1-5.4-1.1-7.5,1.5
          c0.5,0.7,1.1,1.4,1.6,2.2C54.3,46.4,55.2,45.8,56.2,45.3z M59.6,65.2c0.1-0.2,0.2-0.4,0.2-0.6c-0.8-0.7-1.6-1.5-2.5-1.9
          c-2.3-1-3.9-2.7-5.1-4.9c-0.3-0.5-0.6-1-0.9-1.5c-0.3,0.6-0.5,1.2-0.2,1.5c1.7,2.4,3.3,4.9,5.9,6.5C57.8,64.8,58.7,64.9,59.6,65.2
          z M39.4,64.5c0.1,0.1,0.1,0.3,0.2,0.4c1.3,0.4,2.4,0,3.3-1c1.6-1.7,3.2-3.4,4.6-5.2c0.5-0.6,0.6-1.5,0.9-2.2
          c-0.1,0-0.2-0.1-0.4-0.1c-1.2,1.6-2.2,3.4-3.6,4.7C43,62.4,41.1,63.4,39.4,64.5z M38.6,46.9c1.8,1.8,4.4,2,6.9,0.5
          c-0.8-0.4-1.7-0.7-2.5-1.2C41.3,45.3,40,45.5,38.6,46.9z M60.5,46.7c-2.5-2.4-4.5,0.1-6.7,0.8c1.2,0.4,2.5,0.6,3.7,0.9
          C59.1,48.6,59.8,48.2,60.5,46.7z M59.3,53.2c-2.4-0.7-4.6-1.2-6.9-1.9C52.7,54,56.7,55.1,59.3,53.2z M52.3,40.7
          c2.5,1,4.2,0.6,5.4-1C55.1,38.2,53.2,38.5,52.3,40.7z M47.1,40.8c-0.9-2.3-3.2-2.7-5.4-1C42.3,41.2,44.3,41.5,47.1,40.8z
           M40.2,43.6c2.1,1.5,4.3,0.5,6.6,0.6C45.6,42.2,42.3,42,40.2,43.6z M60.4,61.5c-1.9-1.7-4.1-2.3-6.6-2.7
          C55.4,61.2,58.1,62.3,60.4,61.5z M61.3,51c-2.4-1.2-4.1-1.1-6.1,0.3c1.2,0.3,2.4,0.6,3.6,0.9C60.2,52.6,60.7,52.4,61.3,51z
           M57.5,49.2c-2-0.4-3.7-1.2-5.7-1.3C53.1,50.9,54.9,51.3,57.5,49.2z M47,47.7c-1.7,0.5-3.4,1-5.1,1.5c1.7,1.3,3.3,1.7,4.4,0.9
          C47,49.6,47.5,48.9,47,47.7z M44.2,51.2c-0.9-0.3-1.7-0.5-2.6-0.8c-1.6-0.5-2.5-0.3-3.7,1.2C40.1,53.3,42.1,51.5,44.2,51.2z
           M45.5,58.7c-2.6,0.4-4.7,1.2-6.7,2.7C41.3,62.2,43.9,61.2,45.5,58.7z M66.2,36c-1.8,0.3-3.4,0.6-5,0.8c0.7,1.5,1.6,1.7,2.9,1.6
          C65.4,38.3,65.8,37.5,66.2,36z M33.1,36c0.8,2.3,0.8,2.3,2.8,2.4c1.1,0,1.8-0.6,2.1-1.7C36.3,36.5,34.7,36.3,33.1,36z M38,61
          c1-0.5,2-1,3.1-1.5c-0.8-0.4-1.3-0.7-1.9-1c-1.5-0.8-1.5-0.8-2.6,0.7C37.1,59.8,37.5,60.4,38,61z M58.3,59.5
          c1.1,0.5,2.1,1,3.1,1.5c0.5-0.6,0.9-1.2,1.4-1.8c-0.5-0.5-0.9-0.9-1.4-1.4c-0.9,0.5-1.8,0.9-2.6,1.4
          C58.7,59.2,58.5,59.3,58.3,59.5z M58,66.1c1.9,2.2,2.6,2.5,4.1,1.5C61.6,66,61.3,65.8,58,66.1z M46.1,47c0.6-0.9,1.2-1.6,1.8-2.6
          c-1.7,0.2-3.2,0.4-4.6,0.6c0,0.1,0,0.2-0.1,0.3C44.1,45.8,45,46.4,46.1,47z M41.3,66.1c-3.3-0.3-3.5-0.2-4,1.5
          C38.9,68.5,39.7,68.2,41.3,66.1z M58.7,55.1c2.5,1.4,2.5,1.4,3.3-0.1C60.8,53.6,60.2,53.6,58.7,55.1z M40.7,55.1
          c-1.9-1.4-2.3-1.4-3.4,0C38.4,56.4,37.7,56.6,40.7,55.1z M59.2,63c1.6,1.9,2.6,2,3.4,0.5c-0.3-0.4-0.6-0.7-0.9-1.1
          c-0.5,0.2-0.8,0.3-1.2,0.4C60.1,62.9,59.7,62.9,59.2,63z M36.7,63.7c1.3,1.2,2,1.1,3.2-0.6C37.8,62.3,37.6,62.3,36.7,63.7z M48,31
          c1.1,1.7,2.3,1.7,3.2,0C50.2,31,49.2,31,48,31z M45,41.7c1.9,1.3,2.4,1.2,3.1-0.3C46.9,41.5,45.9,41.6,45,41.7z M54.4,42.2
          c0-0.1,0-0.3,0-0.4c-0.8-0.1-1.5-0.3-2.3-0.3c-0.2,0-0.4,0.3-0.6,0.4c0.2,0.2,0.4,0.7,0.5,0.7C52.8,42.4,53.6,42.3,54.4,42.2z
           M37.5,47.5c-0.1,1-0.2,1.8-0.3,2.7c0.7-0.4,1.3-0.7,2.1-1C38.7,48.6,38.2,48.2,37.5,47.5z M60,49.2c0.8,0.4,1.4,0.6,2.1,1
          c-0.1-0.9-0.2-1.6-0.4-2.5C61.1,48.2,60.6,48.6,60,49.2z M40.4,40.2c-0.4,0.7-0.7,1.3-1.2,2.2c1-0.3,1.7-0.5,2.5-0.8
          C41.2,41.1,40.8,40.7,40.4,40.2z M37.7,70.8c0.2-0.7,0.4-1.3,0.5-1.9c-0.5,0-0.9-0.1-1.5-0.2C36.8,69.7,36.7,70.5,37.7,70.8z
           M60.1,42.4c-0.5-0.9-0.8-1.5-1.2-2.2c-0.4,0.5-0.8,0.9-1.3,1.4C58.5,41.9,59.2,42.1,60.1,42.4z M62.4,68.7
          c-0.5,0.1-1,0.2-1.3,0.3c0.1,0.7,0.3,1.3,0.4,1.9C62.5,70.6,62.6,69.8,62.4,68.7z M39.1,39.8c0.1,0.1,0.2,0.2,0.3,0.2
          c0.6-0.5,1.1-1,1.6-1.6c0.1-0.1,0.1-0.4,0.2-0.6c-0.2,0-0.6-0.1-0.7,0C40,38.5,39.5,39.2,39.1,39.8z M55.2,55
          c-1.4-1.4-2.2-1.6-3-0.6C53.2,54.6,54.1,54.8,55.2,55z M48.2,28.7c1.4,1.2,2.1,1.1,2.8-0.2C50.1,28.6,49.3,28.6,48.2,28.7z M63,54
          c-0.2-0.8-0.4-1.3-0.6-2.1c-0.4,0.5-0.7,0.9-1,1.2C61.9,53.4,62.3,53.6,63,54z M57.9,38c0.7,0.7,1.2,1.2,1.9,2
          C59.4,38.9,59.5,37.8,57.9,38z M52.1,34.6c0.2,1.8,0.5,2,1.6,1.3C53.2,35.5,52.7,35.1,52.1,34.6z"
        />
        <path
          d="M51.1,0c2.1,0.6,4.1,1.5,5.5,3.4c1.9,2.4,2.6,5.1,1.9,8.2c-0.2,0.8,0,1.7-0.1,2.5c-0.1,0.5-0.2,1.3-0.6,1.5
          c-1.4,1-1.8,2.4-2.2,4c-0.3,1.3-0.8,2.6-1.5,3.7c-0.3,0.6-1.1,0.8-1.8,1.3c0.1,0.2,0.4,0.6,0.7,1.2c-2.4,0-4.6,0-7,0
          c0.3-0.5,0.5-0.9,0.7-1.3c-1.8-0.9-2.9-2.4-3.2-4.4c-0.2-1.8-0.7-3.3-2.2-4.5c-0.5-0.4-0.6-1.3-0.7-2c-0.2-1-0.3-2-0.4-3.1
          c-0.1-0.8-0.2-1.5,0-2.3c0.7-3.3,2.6-5.8,5.5-7.4c0.8-0.4,1.7-0.5,2.6-0.8C49.4,0,50.2,0,51.1,0z M56,16.3
          c-0.7-0.2-1.4-0.7-1.9-0.5c-0.8,0.3-0.9,1.1-0.7,1.9c0.2,0.8-0.1,1.3-1,1.3c-1.6,0-3.2,0.1-4.8,0.1c-1.8,0-1.8-0.1-1.9-1.9
          c0-0.4-0.2-0.9-0.4-1.2c-0.2-0.2-0.7-0.4-0.9-0.3c-0.4,0.2-0.8,0.5-0.9,0.9c-0.3,1.4,1.9,4,3.2,4.1c1.8,0,3.6,0.1,5.4,0
          c0.6,0,1.4-0.5,1.8-0.9C54.8,18.7,55.3,17.6,56,16.3z M52.9,9c-0.7,0.1-1.8-0.1-1.8,1.3c0,1.4,1.1,2.4,2.8,2.4
          c1,0,1.6-0.3,1.6-1.4C55.4,9.7,54.7,9,52.9,9z M46.2,9c-1.5,0-2.3,0.7-2.3,2.1c0,0.9,0.8,1.9,1.6,1.9c1.3,0,2.8-1.3,2.7-2.5
          C48.2,9.5,47.5,9,46.2,9z M50,12.8c0.1,1.1-0.2,2,1,2.4C51.5,14,50.7,13.6,50,12.8z M49.2,13.2c-0.1,0-0.3-0.1-0.4-0.1
          c-0.2,0.6-0.5,1.2-0.8,2.1C49.5,14.8,49.3,14,49.2,13.2z"
        />
        <path
          d="M38.9,99.6c-0.5,0-0.9,0-1.3,0.1c-0.7,0.2-1,0.8-0.5,1.4c0.8,0.8,0.5,1.6,0.2,2.5c-0.4,1.2-0.7,2.4-0.5,3.6
          c1.4,9.3,3,18.6,4.5,27.9c0.3,1.9,1.1,3.5,2.3,4.9c1.2,1.5,1.3,2.9,0.3,4.6c-0.1,0.2-0.2,0.6-0.1,0.9c0.3,1.1-0.1,2.1-0.7,2.9
          c-0.9,1.1-1.2,2.3-1.3,3.7c-0.5,8.8-0.3,17.7,0.3,26.5c0.1,1.4,0.4,2.9,0.5,4.3c0.1,1.1-0.1,2.3-0.2,3.6c1,0.1,1.1,0.9,0.9,2
          c0,0.3,0,0.6-0.1,0.8c-0.1,1-0.3,2.1-1.3,2.3c-0.6,0.2-1.4-0.3-2.3-0.5c-0.7,0.6-1,1.5-0.7,2.7c0.1,0.4-0.2,0.9-0.5,1.3
          c-0.3,0.5-0.6,0.9-0.9,1.4c-0.5,0.8-1.1,1.3-2.3,1.2c0.8-2.2,1.5-4.2,2.3-6.3c-1.1,0.4-1.4,1.2-1.8,2.1c-0.7,1.5-1.1,3.4-3.5,3.9
          c0.7-1,1.2-1.8,1.9-2.9c-1.5,0.5-2,2.3-3.6,1.8c0.2-0.2,0.4-0.5,0.7-0.8c-0.6,0.1-1.1,0.1-1.6,0.1c-0.1-0.1-0.1-0.2-0.2-0.3
          c0.3-0.2,0.6-0.3,1-0.5c-0.4-0.2-0.7-0.3-1-0.4c0.7-0.5,1.3-0.9,1.8-1.4c0.4-0.4,0.7-0.9,1.1-1.4c0.1-0.2,0.2-0.4,0.3-0.6
          c1.1-1.3,2.2-2.7,3.3-4c0.1-0.2,0.3-0.4,0.3-0.6c0.1-1.3,0.2-2.6,0.3-3.9c0.1-3.9,0.3-7.8,0.1-11.6c-0.3-6.1-1.1-12.2-0.8-18.3
          c0-0.9-0.3-1.8-0.6-2.6c-0.3-0.9-0.4-1.8,0.4-2.5c0.2-0.1,0.3-0.5,0.2-0.7c-0.1-1-0.2-1.9-0.4-2.8c-0.3-1.4-0.2-2.8,0.4-4.2
          c0.4-1,0.4-2.3,0.4-3.4c0.3-5.2-0.8-10.2-1.7-15.3c-0.7-4.4-1.1-8.9-1.7-13.3c-0.2-1.2-0.4-2.6-1-3.6c-1.2-2-1-4-0.1-6
          c0.3-0.5,1.3-1,1.9-1c1-0.1,1.6-0.4,1.8-1.4c0.2-1,0.7-1.6,1.7-1.6c-0.4-0.9-0.7-1.8-1.2-2.7c-0.4-0.7-1-1.5-1.6-2
          c-1.5-1.3-2-2.7-1.2-4.6c1.1-2.6,3.2-4,5.7-5c1.3-0.5,4.8,1,5.5,2.1c0.9,1.5,1.8,3,2.7,4.5c1,1.8,4.6,1.9,5.7,0.1
          c0.9-1.4,1.6-2.8,2.5-4.3c1.3-2.3,5.3-3.3,7.5-1.8c1.3,0.9,2.5,2,3.5,3.2c1.3,1.5,1.1,3.2,0,4.7c-1.3,1.7-2.4,3.4-3.8,5.3
          c1.5,0.4,2.1,1.2,2.3,2.5c0,0.3,0.8,0.7,1.1,0.7c2.2-0.3,2.7,0.9,2.9,2.6c0.2,1.8-0.1,3.4-1,5.1c-0.6,1.3-0.7,2.8-0.7,4.3
          c-0.2,6.1-1.8,11.9-2.6,17.9c-0.5,3.7-0.4,7.5-0.5,11.2c0,0.9,0.3,1.7,0.6,2.6c0.7,2-0.3,3.8-0.3,5.7c0,0.3,0.1,0.8,0.3,1
          c0.8,0.8,0.6,1.6,0.3,2.5c-0.3,0.9-0.5,1.9-0.6,2.8c-0.2,4.8-0.4,9.6-0.5,14.4c-0.1,5-0.2,9.9-0.3,14.9c0,1.5,0,3.1,0.4,4.5
          c0.4,1.2,1.2,2.3,2.1,3.3c1.4,1.6,2.9,3.1,4.3,4.7c-0.1,0-0.3,0.1-0.8,0.2c0.5,0.3,0.8,0.5,1.1,0.7c0,0.1-0.1,0.2-0.1,0.2
          c-0.5,0-1-0.1-1.6-0.1c0.3,0.3,0.5,0.5,0.7,0.8c-1.8,0.5-2.3-1.2-3.6-1.7c0.6,0.9,1.1,1.7,1.8,2.8c-1-0.3-1.8-0.5-2.3-1.5
          c-0.6-1.5-1.5-2.8-2.5-4.2c-0.4,2.2,1.2,3.9,1.7,5.9c-1,0.5-1.7,0-2.2-0.7c-0.5-0.6-0.9-1.3-1.3-2c-0.2-0.3-0.3-0.8-0.2-1.1
          c0.4-1.1-0.1-1.8-0.9-2.6c-0.3,0.2-0.5,0.3-0.8,0.4c-1.3,0.5-2,0.1-2.4-1.3c-0.2-0.6-0.3-1.2-0.4-1.8c-0.1-1-0.1-1.9,1.1-2.1
          c-0.9-4,0.4-7.9,0.4-11.8c0-6.7,0.3-13.4,0.3-20.1c0-2.3-0.2-4.7-1.9-6.6c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-1.8-0.8-3.4-0.9-5.1
          c-0.1-0.6,0.2-1.4,0.6-1.9c1.8-1.8,2.4-4.2,2.9-6.6c1.2-7,2.4-14,3.5-21c0.3-2.1,0.6-4.3,0.7-6.4c0-1.1-0.5-2.2-0.6-3.3
          c-0.1-0.7,0-1.4,0.3-2c0.2-0.5,0.9-0.9,0.1-1.4c-0.7-0.5-1.4-0.4-1.8,0.4c-0.3,0.5-0.4,1.1-0.4,1.6c-0.3,3.1-2.6,4.7-5.4,3.4
          c-1.6-0.7-3-1.9-4.3-3.2c-1.1-1.1-0.5-3,0.9-3.7c1.5-0.7,3-1.4,4.4-2.1c1.5-0.8,2-2.2,1.2-3.4c-0.2-0.3-0.7-0.5-0.9-0.5
          c-1.3,0.3-2.6,0.8-3,2.3c-0.4,1.5-1.3,2-2.8,1.8c-0.4,0-0.8,0-1.2,0c-1.4,0.1-2.1-0.5-2.6-1.7c-0.4-0.9-1.1-1.7-1.8-2.2
          c-0.4-0.3-1.6-0.3-1.8,0c-0.4,0.6-0.6,1.5-0.4,2.2c0.2,0.7,0.9,1.3,1.6,1.7c1.4,0.8,2.9,1.5,4.4,2.1c0.9,0.4,1.1,0.8,1,1.9
          c-0.5,3.2-2.8,4.3-5.3,5.1c-2.4,0.8-4.3-0.7-4.6-3.3C39.2,101.3,39.1,100.5,38.9,99.6z M62,149.1c-0.1,0-0.2,0-0.4,0
          c-0.3,9.4-1.2,18.8-0.9,28.2c0.1,0,0.3,0,0.4,0C61.4,167.9,61.7,158.5,62,149.1z M38.2,176.9c0.1,0,0.3,0,0.4,0
          c0.3-9.5-0.7-18.9-1-28.4c-0.1,0-0.2,0-0.3,0C37.6,158,37.9,167.4,38.2,176.9z M55.7,99c-0.7,0.4-1.7,0.7-2,1.3
          c-0.4,0.7,1.1,2.1,2,2.1c1,0,1.7-0.8,1.7-1.9C57.3,99.5,56.7,99.2,55.7,99z M46,100c-1-0.3-1.7-0.6-2.5-0.7
          c-1.2-0.2-1.8,0.4-1.5,1.6c0.1,0.6,0.5,1.3,1,1.5c0.5,0.2,1.3-0.2,1.8-0.5C45.2,101.4,45.5,100.8,46,100z"
        />
        <path
          d="M60.2,73.6c-1.9-1.3-3.8-2.6-5.7-3.9c-3.2,2-6.4,2.1-9.7,0c-1.8,1.3-3.7,2.6-5.6,3.9c-0.1-0.1-0.2-0.2-0.3-0.2
          c0.4-0.5,0.8-1.2,1.3-1.6c1.7-1.3,3.5-2.5,5.3-3.8c0.7-0.5,1.2-1.2,1.9-1.8c0.3-0.3,0.8-0.5,1.2-0.6c0.7-0.1,1.4,0,2,0
          c0.7,0,1.3,0,1.4,0.9c0,0.3,0.5,0.6,0.8,0.8c1.9,1.3,3.9,2.6,5.8,4c0.7,0.5,1.3,1.3,2,2C60.4,73.3,60.3,73.4,60.2,73.6z
           M53.5,69.2c-2.1-2.1-6.3-2.1-7.6,0c1.4-0.3,2.6-0.7,3.9-0.7C51,68.5,52.2,69,53.5,69.2z"
        />
        <path
          d="M49.6,80.2c1.4,0.1,2.7,0.1,4.1,0.3c0.4,0,0.7,0.4,1.1,0.6c-0.3,0.3-0.4,0.8-0.8,1c-0.7,0.4-1.4,0.7-2.1,1.1
          c-1.7,0.8-3.4,0.8-5-0.3c-0.4-0.3-0.9-0.3-1.3-0.6c-0.4-0.3-0.7-0.7-1-1c0.4-0.3,0.9-0.8,1.3-0.8c1.2-0.1,2.5,0,3.7,0
          C49.6,80.4,49.6,80.3,49.6,80.2z"
        />
        <path d="M54.3,77.3c-1.9,0.6-2.9,3-5.4,2.4c-0.2,0-0.4,0-0.5,0c-1.3-0.8-2.6-1.6-4.2-2.6C47.9,75.8,51.1,75.5,54.3,77.3z" />
        <path
          d="M52.2,72.9c0.6,0,1.2,0,1.7,0c0.2,0,0.5,0.3,0.5,0.5c0,0.1-0.2,0.5-0.3,0.6c-1.5,0.6-2.9,1.2-4.4,1.8
          c-0.2,0.1-0.5,0.1-0.7,0.1c-1.4-0.7-2.8-1.5-4.1-2.2c0-0.1,0-0.2,0.1-0.3c0.4-0.1,0.9-0.3,1.3-0.3c0.5,0,0.9,0,1.2-0.7
          c0.2-0.6,4.2-0.4,4.6,0.1C52.1,72.5,52.1,72.7,52.2,72.9z"
        />
        <path
          d="M49.6,84c0.4,0,0.7,0,1.1,0c0.6,0.1,1.6-0.5,1.6,0.9c0,1.6-0.5,2.3-1.8,2.3c-0.6,0-1.3,0-1.9,0c-0.7,0-1.2-0.4-1.4-1.1
          C46.7,84.1,46.8,83.9,49.6,84z"
        />
        <path
          d="M90.1,115.8c-0.3-0.5-0.6-1-0.8-1.5c-0.9-3.3-1.9-6.7-1.7-10.2c0-0.4-0.3-0.9-0.5-1.3c-0.1-0.3-0.2-0.7-0.2-1.1
          c0-0.1,0.6-0.3,0.8-0.2c0.3,0.2,0.8,0.5,0.8,0.8c0.2,0.8,0.2,1.6,0.2,2.4c0,3.7,0.5,7.4,1.7,10.9
          C90.3,115.7,90.2,115.8,90.1,115.8z"
        />
        <path
          d="M8.9,115.6c0.4-1.8,0.9-3.5,1.1-5.3c0.3-2.4,0.4-4.8,0.6-7.1c0-0.4,0-0.8,0.2-1.2c0.1-0.3,0.5-0.5,0.8-0.5
          c0.2,0,0.7,0.1,0.8,0.4c0.1,0.3,0,0.6-0.1,0.9c-0.2,0.8-0.7,1.6-0.7,2.4c0.2,2.2-0.2,4.3-0.9,6.3c-0.3,0.8-0.4,1.7-0.6,2.5
          c-0.2,0.6-0.5,1.2-0.7,1.8C9.2,115.7,9.1,115.7,8.9,115.6z"
        />
      </g>
    </svg>
  ),
  theory: (
    <svg x="0px" y="0px" viewBox="0 0 491.3 512" style={{ enableBackground: 'new 0 0 491.3 512', width: 22 }}>
      <g>
        <path
          d="M223.7,512c-9.8-3.2-13-11.1-14.7-20.1c-0.7-3.9-2.3-6-6.4-7.5c-9-3.2-14.4-10.1-15.1-19.8c-0.4-6-0.4-12-0.1-18
          c0.5-8.2,6.1-13.5,14.4-13.6c29.1-0.1,58.3-0.1,87.4,0c8.6,0,14.4,5.9,14.6,14.4c0.2,5.3,0.2,10.7,0,16
          c-0.4,10.2-6.6,18.5-16.4,21.5c-2.8,0.9-4.1,2.3-4.7,5c-0.4,1.8-1,3.5-1.3,5.3c-1.5,8.1-5.9,13.9-13.8,16.8
          C253,512,238.3,512,223.7,512z"
        />
        <path
          d="M249.7,0c7.9,3.9,10.6,10.3,10.3,19.1c-0.5,15.1,0,30.3-0.3,45.5c-0.1,7.2-5.5,12.8-12.3,13.5c-7.1,0.7-13.5-3.4-15.3-10.1
          c-0.5-2-0.6-4.3-0.6-6.4c0-14.2,0.4-28.3-0.2-42.5c-0.3-8.8,2.3-15.2,10.3-19.1C244.3,0,247,0,249.7,0z"
        />
        <path
          d="M245.3,413.8c-12.2,0-24.3,0-36.5,0c-13.5-0.1-20.9-6.5-23.3-19.8c-4.1-22.7-13.7-42.7-29.6-59.5
          c-24.7-26-36.9-56.8-36.3-92.8c1-57.5,44.6-109.3,101.2-119.7c69.3-12.8,133.7,29.3,148.1,98c8.6,41.1-1.4,78.3-29.5,109.5
          c-17.5,19.5-30,40.9-34.2,66.9c-1.7,10.7-10.1,17.1-21.4,17.2C271,413.9,258.2,413.8,245.3,413.8z M154.7,244.1c0-0.6,0,0.1,0,0.8
          c0,8.9,5.5,15.1,13.6,15.4c8.3,0.3,14-5.2,14.7-14.2c0.1-1.8,0.2-3.7,0.4-5.5c2.1-20.6,12.1-36.3,29.4-47.5
          c9.8-6.3,20.6-9,32.2-9.4c9.4-0.3,15-6.3,14.8-14.8c-0.3-8.2-6.7-13.8-15.9-13.7c-3,0-6,0.4-9,0.7
          C191.3,160.3,155.6,199,154.7,244.1z"
        />
        <path
          d="M38.9,259.9c-7.8,0-15.6,0.1-23.5,0c-9-0.1-15.4-6-15.4-14.2c0-7.9,6.1-14,14.9-14.2c16.1-0.2,32.3-0.2,48.4,0
          c8.8,0.1,14.9,6.2,14.9,14.2c0,8.2-6.3,14.1-15.4,14.2C54.9,260,46.9,259.9,38.9,259.9z"
        />
        <path
          d="M452.2,259.9c-8,0-16,0.1-24,0c-8.8-0.1-14.9-5.9-15.1-13.9c-0.2-8,5.8-14.3,14.6-14.4c16.3-0.2,32.6-0.2,48.9,0
          c8.7,0.1,14.9,6.5,14.7,14.4c-0.2,8-6.4,13.9-15.1,14C468.2,260,460.2,259.9,452.2,259.9z"
        />
        <path
          d="M83.8,423.7c-7.6-0.5-11.9-2.9-14.5-8c-2.5-5.1-2.1-10.1,1.3-14.6c1.8-2.4,4-4.4,6.1-6.6c9.9-9.9,19.7-19.8,29.7-29.6
          c6.5-6.4,15.1-6.7,20.9-0.8c5.9,6,5.6,14.3-0.9,20.9c-11.2,11.4-22.4,22.8-34,33.8C89.7,421.4,85.7,422.7,83.8,423.7z"
        />
        <path
          d="M409.1,67.1c6.5,1,10.9,3.9,13.3,9.4c2.5,5.8,1.2,11.2-3.2,15.6c-10.5,10.7-21.2,21.2-31.8,31.8c-1.4,1.4-2.8,2.9-4.3,4.1
          c-5.7,4.6-14,4.2-19.1-0.9c-5.3-5.2-6-13.5-0.7-19c12.1-12.6,24.5-24.9,37.1-37.1C402.6,69.1,406.2,68.4,409.1,67.1z"
        />
        <path
          d="M375.9,359.8c2,1.1,5.9,2.4,8.6,5c11.5,11.1,22.7,22.5,33.9,33.9c6.5,6.6,6.6,15,0.7,20.9c-5.8,5.7-14.4,5.4-20.9-1.1
          c-11.3-11.3-22.6-22.6-33.9-33.9c-4.5-4.6-6-10-3.3-16C363.5,363,368,360.3,375.9,359.8z"
        />
        <path
          d="M82.4,66.8c3.4,1.9,7.3,3.1,10,5.7c11.7,11.2,23,22.7,34.3,34.2c6.2,6.3,6.3,14.7,0.5,20.5c-5.5,5.6-14.5,5.5-20.6-0.6
          c-11.5-11.3-22.9-22.8-34.3-34.2c-4.5-4.5-6.1-10-3.5-16C71.3,70.9,75.6,68,82.4,66.8z"
        />
      </g>
    </svg>
  ),
  allExercises: (
    <svg x="0px" y="0px" viewBox="0 0 275.6 156.3" style={{ enableBackground: 'new 0 0 275.6 156.3', width: 23 }}>
      <g>
        <path
          d="M13.6,91.7h17V119c0,8.7,7.1,15.8,15.8,15.8c2.7,0,5.3-0.8,7.5-2v7.7c0,8.7,7.1,15.8,15.8,15.8c8.7,0,15.8-7.1,15.8-15.8
          V91.7h100.6v48.8c0,8.7,7.1,15.8,15.8,15.8c8.7,0,15.8-7.1,15.8-15.8v-7.7c2.2,1.2,4.8,2,7.5,2c8.7,0,15.8-7.1,15.8-15.8V91.7h21
          c7.5,0,13.6-6.1,13.6-13.6c0-7.5-6.1-13.6-13.6-13.6h-21V37.3c0-8.7-7.1-15.8-15.8-15.8c-2.7,0-5.3,0.8-7.5,2v-7.7
          c0-8.7-7.1-15.8-15.8-15.8c-8.7,0-15.8,7.1-15.8,15.8v48.8H85.5V15.8C85.5,7.1,78.4,0,69.7,0C61,0,53.9,7.1,53.9,15.8v7.7
          c-2.2-1.2-4.8-2-7.5-2c-8.7,0-15.8,7.1-15.8,15.8v27.3h-17C6.1,64.6,0,70.7,0,78.1C0,85.6,6.1,91.7,13.6,91.7z"
        />
      </g>
    </svg>
  ),
  byModel: (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 86.8 206.3"
      style={{ enableBackground: 'new 0 0 86.8 206.3', width: 14, marginLeft: 3 }}
    >
      <g>
        <path
          d="M44.5,118c-0.3,3.6,2.1,22.5,3.4,30c0.6,3.5,2.2,9.3,1.6,12.3c-0.8,4.2-1.1,9.7-0.6,12.9c0.3,1.9,1.2,10.9-0.1,14.2
          c-0.7,1.7-1.9,10.5-1.9,10.5c-3.3,8.3-1.4,7.9-1.4,7.9c1,1.2,2.8,0.1,2.8,0.1c1.3,0.8,2.2-0.2,2.2-0.2c1.1,0.9,2.5-0.1,2.5-0.1
          c1.4,0.7,2.8-0.6,2.8-0.6c0.8,0.4,1-0.1,1-0.1c2.5-0.2-1.4-8.1-1.4-8.1c-0.9-7.1,0.9-11,0.9-11c6-17.8,6.3-22.5,3.9-29.2
          c-0.7-1.9-0.8-2.7-0.5-3.5c0.7-1.9,0.2-9.7,1.1-12.8c1.7-6,3.4-21.1,4.2-28.2c1.2-9.5-4.1-22.3-4.1-22.3
          c-1.2-5.2,0.5-23.7,0.5-23.7c2.4,3.7,2.3,10.2,2.3,10.2c-0.4,6.9,5.5,17.3,5.5,17.3c2.8,4.3,3.9,8.4,3.9,8.7
          c0,1.2-0.3,4.3-0.3,4.3l0.1,2.6c0,0.7,0.4,3,0.4,4.1c-0.4,6.9,0.6,5.6,0.6,5.6c0.9,0,1.9-5.5,1.9-5.5c0,1.4-0.3,5.7,0.4,7.3
          c0.9,1.9,1.6-0.3,1.6-0.8c0.2-8.7,0.8-6.4,0.8-6.4c0.5,7.1,1.1,8.7,2.3,8.1c0.9-0.4,0.1-8.5,0.1-8.5c1.5,4.8,2.6,5.6,2.6,5.6
          c2.4,1.7,0.9-3,0.6-3.9c-1.8-4.9-1.8-6.6-1.8-6.6c2.2,4.4,3.9,4.3,3.9,4.3c2.2-0.7-1.9-7-4.3-10c-1.2-1.5-2.8-3.6-3.2-4.8
          c-0.7-2.1-1.3-8.7-1.3-8.7c-0.2-7.8-2.2-11.2-2.2-11.2c-3.3-5.3-3.9-15.1-3.9-15.1l-0.1-16.6c-1.2-11.3-9.5-11.4-9.5-11.4
          c-8.5-1.3-9.6-4-9.6-4c-1.8-2.6-0.8-7.5-0.8-7.5c1.5-1.2,2.1-4.4,2.1-4.4c2.5-1.9,2.3-4.7,1.2-4.6c-0.9,0-0.7-0.7-0.7-0.7
          C55.3,0.6,44.2,0,44.2,0h-1.7c0,0-11.1,0.6-9.5,13.1c0,0,0.2,0.8-0.7,0.7c-1.1,0-1.2,2.7,1.2,4.6c0,0,0.6,3.2,2.1,4.4
          c0,0,1,4.9-0.8,7.5c0,0-1.2,2.7-9.6,4c0,0-8.4,0.1-9.5,11.4l-0.2,16.6c0,0-0.6,9.9-3.9,15.1c0,0-1.9,3.4-2.1,11.2
          c0,0-0.6,6.6-1.3,8.7c-0.5,1.2-2,3.3-3.2,4.8c-2.4,3-6.5,9.2-4.3,10c0,0,1.7,0.2,3.9-4.3c0,0,0,1.7-1.8,6.6
          c-0.3,0.9-1.8,5.6,0.6,3.9c0,0,1.1-0.8,2.6-5.6c0,0-0.8,8.1,0.1,8.5c1.1,0.6,1.8-1,2.3-8.1c0,0,0.5-2.3,0.8,6.4
          c0,0.5,0.7,2.7,1.6,0.8c0.8-1.6,0.4-5.9,0.4-7.3c0,0,1,5.5,1.9,5.5c0,0,1.1,1.3,0.6-5.6c-0.1-1.1,0.3-3.4,0.4-4.1l0.1-2.6
          c0,0-0.3-3-0.3-4.3c0-0.3,1.1-4.4,3.9-8.7c0,0,5.9-10.5,5.5-17.3c0,0-0.1-6.5,2.3-10.2c0,0,1.7,18.5,0.5,23.7
          c0,0-5.3,12.8-4.1,22.3c0.9,7.1,2.5,22.2,4.2,28.2c0.9,3.1,0.4,10.9,1.1,12.8c0.3,0.8,0.2,1.6-0.5,3.5c-2.4,6.7-2.1,11.4,3.9,29.2
          c0,0,1.8,3.9,0.9,11c0,0-3.8,7.9-1.4,8.1c0,0,0.2,0.5,1,0.1c0,0,1.3,1.4,2.8,0.6c0,0,1.3,1.1,2.5,0.1c0,0,0.9,1,2.2,0.2
          c0,0,1.7,1.2,2.8-0.1c0,0,1.8,0.4-1.4-7.9c0,0-1.3-8.8-1.9-10.5c-1.3-3.3-0.4-12.3-0.1-14.2c0.5-3.2,0.2-8.7-0.6-12.9
          c-0.6-3,1-8.8,1.6-12.3c1.2-7.4,3.7-26.4,3.4-30l1,0.4C44,118.3,44.5,118,44.5,118z"
        />
      </g>
    </svg>
  ),
  abcCore: (
    <svg x="0px" y="0px" viewBox="0 0 87.7 92.6" style={{ enableBackground: 'new 0 0 86.8 206.3', width: 22 }}>
      <g>
        <path
          d="M16.2,50.9c0,0.8,0.3,2.4,0.2,4.1c-0.3,4.5-3.5,6.2-7.4,3.9C-1,53.2-1.6,51.4,2.1,40.3c0.7-1.9,1.4-4.3,1-6.1
		C-0.9,19,8.4,10.7,18.9,6C30,1,41.6-1.5,53.1,1c10,2.2,20.8,4.5,28.3,13c4.4,5,5.2,10.8,4.5,17.3c-0.4,3.3,0.3,6.7,0.6,10
		c0.1,1.3,0.7,2.6,0.9,3.9c1.4,7.2-3.5,14.5-10.6,14.9c-1.5,0.1-4.1-3.4-4.6-5.6c-0.7-3.3,0.1-6.9-0.1-10.4
		c-0.1-1.1-1.3-2.2-2.1-3.3c-0.6,1-1.7,2-1.8,3.1c-0.8,11.6-1.5,23.2-2,34.8c-0.3,6.6-7.1,13.7-13.8,13.9c-5.3,0.1-10.7,0.1-16,0
		c-7.4-0.1-14-7.1-14.3-14.4c-0.5-11.4-1.2-22.9-2-34.3c-0.1-1.1-1.2-2.1-1.8-3.1c-0.7,1.1-1.8,2.1-2.1,3.3
		C15.9,46,16.2,48,16.2,50.9z"
        />
        <g>
          <g>
            <path
              className="st0"
              d="M56.6,67.7h-8.2c-1.1,0-2.1-0.9-2.1-2.1v-5c0-1.1,0.9-2.1,2.1-2.1h8.2c1.1,0,2.1,0.9,2.1,2.1v5
				C58.7,66.7,57.8,67.7,56.6,67.7z"
            />
            <path
              className="st0"
              d="M53.4,76.2h-5.2c-1,0-1.8-0.8-1.8-1.8v-3.1c0-1,0.8-1.8,1.8-1.8h5.2c1,0,1.8,0.8,1.8,1.8v3.1
				C55.2,75.4,54.4,76.2,53.4,76.2z"
            />
            <path
              className="st0"
              d="M58,56.5h-8.5c-1.7,0-3.1-1.4-3.1-3.1v-4.7c0-1.7,1.4-3.1,3.1-3.1H58c1.7,0,3.1,1.4,3.1,3.1v4.7
				C61,55.1,59.7,56.5,58,56.5z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M42.1,67.7h-8.2c-1.1,0-2.1-0.9-2.1-2.1v-5c0-1.1,0.9-2.1,2.1-2.1h8.2c1.1,0,2.1,0.9,2.1,2.1v5
				C44.1,66.7,43.2,67.7,42.1,67.7z"
            />
            <path
              className="st0"
              d="M42.3,76.2h-5.2c-1,0-1.8-0.8-1.8-1.8v-3.1c0-1,0.8-1.8,1.8-1.8h5.2c1,0,1.8,0.8,1.8,1.8v3.1
				C44.1,75.4,43.3,76.2,42.3,76.2z"
            />
            <path
              className="st0"
              d="M41.1,56.5h-8.5c-1.7,0-3.1-1.4-3.1-3.1v-4.7c0-1.7,1.4-3.1,3.1-3.1h8.5c1.7,0,3.1,1.4,3.1,3.1v4.7
				C44.1,55.1,42.7,56.5,41.1,56.5z"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  headNeck: (
    <svg x="0px" y="0px" viewBox="0 0 33.1 42.1" style={{ enableBackground: 'new 0 0 33.1 42.1', width: 20 }}>
      <g>
        <path
          d="M33.1,34.3C24.5,45.1,7.7,44.3,0,34.3c1.6-0.5,3.1-0.9,4.7-1.5c4.5-1.6,4.8-5.4,4.1-9.6c-1.7-1.6-1.5-4.2-3.2-5.8
          c-0.5-0.5-0.7-1.4-0.8-2.2C4.6,14.8,5,14,5.2,14c1.1-0.2,0.9-0.9,0.9-1.6c0-1.3-0.1-2.6,0.1-3.8c0.9-5.4,5.7-9,11.2-8.5
          c5.7,0.5,9.6,4.6,9.7,10.4c0,0.6,0,1.2-0.1,1.8c0,0.8-0.4,1.6,0.9,1.8c0.2,0,0.5,0.6,0.4,0.9c-0.2,0.9-0.6,1.9-1,2.8
          c-0.4,0.9-0.9,1.7-1.4,2.6c-0.3,0.6-0.5,1.4-0.9,1.9c-2.4,3.7-1,8.8,3.1,10.5C29.7,33.3,31.4,33.7,33.1,34.3z"
        />
      </g>
    </svg>
  ),
  bodyWeight: (
    <svg x="0px" y="0px" viewBox="0 0 314.8 163.1" style={{ enableBackground: 'new 0 0 314.8 163.1', width: 24 }}>
      <path
        d="M311,12c-5.5-9-14-13.6-21.3-11.5c-9.5,2.8-17.8,7.3-21.8,17c-2.2,5.4-5.9,6.5-11.6,6.6c-17.7,0.3-35.5,1.5-50.7,12.3
        c-9.5,6.8-19.7,7.2-30.5,4.9c-3.1-0.7-6.3-1-9.4-1.7c-13.6-2.8-26.2-0.4-38.6,6.2c-12.2,6.5-24.9,12.1-37.5,17.8
        c-2.5,1.1-5.7,0.7-8.5,1.1c-12.7,1.8-25.9,1-37.6,7.7c-1.5-0.2-3.1-0.5-4.7-1.1c-5.7-2.2-9.1,0-10.5,3.9c-4.9-1-6.7-1.1-13.4-2.6
        C3.2,69.9-0.5,79.1,3.3,91.2c0.6,2,0.8,4.6,0,6.4c-5.8,13.9-4.4,19.8,7.7,29c7.6,5.9,13.9,5.5,13.4-4.1c-0.5-8.6,0.3-14.8,2.1-19.4
        c0.9,3.5,4,6.4,9.7,10c7,4.4,14.5,3.9,12.3-3.1c-3-9.3-0.7-15.3,4.2-19.1c3.2-0.1,6.5-0.1,9.8-0.3c11.5-0.7,23.3-1.7,34.7-0.3
        c27.6,3.4,65,0.9,91.9-6.7c4.3-1.2,9-0.7,14.6-1c-0.7,3.8-1.2,6.2-1.5,8.7c-2.3,19.4,3.5,35.2,4.8,57.3c2.1,4.7,6.3,10.3,10.7,11.6
        c9.4,2.7,12.7,5,21.1-0.3c1.4-0.9,1-3.1,1.6-4.6c-6.7-3.4-5-2.6-11.7-5.9c-2.7-1.4-4.7-4.7-4.9-6.5c-1.3-7.8,0.3-21,0.6-28.9
        c0.2-6.3,1.8-12.9,2.2-19.2c0.9-13.2-1.9-6,11.6-13.2c3.1,16.3-1,28.8,2.2,44.2c0.5,2.4,6.4,13.7,8.3,14.5c9.6,3.9,27,11.5,29.7,1.7
        c1.5-5.6-9.3-6.2-14.3-8.1c-7-2.7-9.9-7.3-8.9-14.7c1.9-14.6,3.1-29.3,5.5-43.8c0.9-5.5,4-11,7.4-15.4c1.7-2.2,6.6-2.2,10.1-2.4
        c2.6-0.1,5.9,2.4,7.8,1.5c5.7-2.9,12.5-4.2,15.2-11.8c1.8-5,5.7-9.4,9.3-13.5C316,27.3,316.4,20.9,311,12z"
      />
    </svg>
  ),
  functionalTraining: (
    <svg x="0px" y="0px" viewBox="0 0 399.6 508.5" style={{ enableBackground: 'new 0 0 399.6 508.5', width: 22 }}>
      <g>
        <path d="M199.8,174.8c28,0,50.8-22.8,50.8-50.8s-22.8-50.8-50.8-50.8c-28,0-50.8,22.8-50.8,50.8C149,152,171.8,174.8,199.8,174.8z" />
        <path
          d="M399.6,48.6c0-6.2-5-11.3-11.2-11.4V24.3c0-8.6-7-15.6-15.6-15.6c-2.4,0-4.7,0.6-6.8,1.6C362.7,4.1,356.2,0,348.8,0
          c-10.8,0-19.6,8.8-19.6,19.6v17.6h-10.6c-2.7-8.6-10.7-14.8-20.2-14.8c-9.5,0-17.5,6.2-20.2,14.8h-157
          c-2.7-8.6-10.7-14.8-20.2-14.8c-9.5,0-17.5,6.2-20.2,14.8H70.4V19.6C70.4,8.8,61.6,0,50.8,0C43.4,0,37,4.1,33.6,10.2
          c-2.1-1-4.3-1.6-6.8-1.6c-8.6,0-15.6,7-15.6,15.6v12.9C5,37.3,0,42.3,0,48.6S5,59.9,11.2,60v12.9c0,8.6,7,15.6,15.6,15.6
          c2.4,0,4.7-0.6,6.8-1.6c3.3,6.1,9.8,10.2,17.2,10.2c10.8,0,19.6-8.8,19.6-19.6V60H80v83.7c0,10.4,5,22.9,12.2,30.4l46.1,48.3v260.3
          c0,14.2,11.5,25.7,25.7,25.7c14.2,0,25.7-11.5,25.7-25.7V354.5h20.3v128.1c0,14.2,11.5,25.7,25.7,25.7s25.7-11.5,25.7-25.7
          l0.1-260.2l46.1-48.3c7.2-7.5,12.2-20,12.2-30.4V60h9.6v17.6c0,10.8,8.8,19.6,19.6,19.6c7.4,0,13.9-4.1,17.2-10.2
          c2.1,1,4.3,1.6,6.8,1.6c8.6,0,15.6-7,15.6-15.6V60C394.6,59.9,399.6,54.8,399.6,48.6z M277.3,143.3c-0.1,0.5-0.5,1.5-0.8,2
          l-40,41.9h-73.5l-40-41.9c-0.3-0.5-0.7-1.5-0.8-2V60h155L277.3,143.3L277.3,143.3z"
        />
      </g>
    </svg>
  ),
  kettlebell: (
    <svg x="0px" y="0px" viewBox="0 0 419.7 512" style={{ enableBackground: 'new 0 0 419.7 512', width: 19 }}>
      <g>
        <path
          d="M319.1,169.2l23.7-45.2c13.8-26.2,12.8-57.8-2.5-83.1C325.1,15.5,297.6,0,268,0H151.7c-29.6,0-57.1,15.5-72.4,40.9
          S63,97.7,76.8,124l23.7,45.2C40.3,206.1,0,272.5,0,348.2c0,51.3,18.7,100.6,52.6,139c13.9,15.8,34,24.8,55.1,24.8H312
          c21.1,0,41.1-9,55.1-24.8c33.9-38.4,52.6-87.7,52.6-139C419.7,272.5,379.4,206.1,319.1,169.2z M275.8,98.4l-23.3,44.4
          c-13.8-2.9-28-4.4-42.6-4.4s-28.8,1.5-42.6,4.4l-23.3-44.4c-3-5.7-2.8-12.6,0.5-18.1c3.3-5.5,9.3-8.9,15.8-8.9h99.1
          c6.5,0,12.5,3.4,15.8,8.9C278.6,85.8,278.8,92.7,275.8,98.4z"
        />
      </g>
    </svg>
  ),
  straps: (
    <svg x="0px" y="0px" viewBox="0 0 77.3 90.4" style={{ enableBackground: 'new 0 0 77.3 90.4', width: 20 }}>
      <g>
        <g>
          <path
            d="M17.1,77c3.2,3.8,6.4,7.7,9.8,11.7c0.9,1.1,2.3,1.7,3.7,1.7h5.5c1.3,0,2.6-0.6,3.5-1.5C49.8,78.1,61,69,74.2,62.6
            c2.7-1.3,4-3.7,2.4-6.7c-1.6-3-4.2-3.5-7.2-2c-3.5,1.6-7.1,2.9-10.4,4.7c-7.2,4-14.2,8.4-21.5,12.7c-4-5-8.3-10.3-12.5-15.5
            c8.1-3.6,15.7-6.9,23.3-10.3c3.2-1.4,6.3-3.3,4.4-7.5c-1.9-4-5.1-3.1-8.5-1.7c-10.7,4.2-21.3,8.3-32.1,12
            c-9.6,3.3-11.3,8.3-5,16.1C10.4,68.7,13.7,72.9,17.1,77z"
          />
          <path d="M19.9,37.3c0.1,5.5-4.5,10.2-10,10.2c-5.3,0-9.8-4.4-9.9-9.7c-0.1-5.5,4.4-10.2,10-10.2C15.3,27.6,19.8,32,19.9,37.3z" />
        </g>
        <path
          d="M76.9,1.8l-2-1.6c-0.1-0.1-0.3-0.1-0.4,0.1L63.3,14.2c-0.1,0-0.2,0.1-0.3,0.1L40.8,26.1c-0.2,0.1-0.4,0.2-0.5,0.4
          c-2.2,2.2-2.9,5.5-1.6,8.6c0.6,1.4,1.6,2.5,2.8,3.4l11.2,7.9c0.8,0.6,1.8,0.9,2.7,0.9c0.7,0,1.3-0.1,2-0.4c1.5-0.7,2.5-2.1,2.7-3.7
          l5.8-26.6c0-0.1,0-0.3,0-0.4l11-13.8C77,2.1,77,1.9,76.9,1.8z M56.2,42.3c0,0.1,0,0.2,0,0.2c0,0.4-0.3,0.5-0.4,0.6
          c-0.1,0.1-0.4,0.1-0.7-0.1l-11.2-8c-0.6-0.5-1.2-1-1.5-1.8c-0.5-1.3-0.3-2.8,0.6-3.8c0,0,0.1-0.1,0.1-0.1l17.5-9.3
          c0.2-0.1,0.5,0.1,0.5,0.3L56.2,42.3z"
        />
      </g>
    </svg>
  ),
  stretching: (
    <svg x="0px" y="0px" viewBox="0 0 45.7 61.9" style={{ enableBackground: 'new 0 0 45.7 61.9', width: 20 }}>
      <g>
        <path
          d="M42,0c-9.9,0.9-17.2,5.8-21.5,13c-2.9,3.6-8.6,14.5-10.6,20.3c-3.8,7.1-7.1,15.6-9.6,23.2
          c-1.6,4.7,5.5,7.7,7.1,3c1.9-5.7,4.2-11.9,6.9-17.6c2.6,5.8,5.2,11.6,8.1,17.4c2.2,4.4,8.8,0.5,6.6-3.9
          c-2.9-5.9-5.6-11.9-8.3-17.9c0-0.1,0.1-0.2,0.1-0.3c0.4,0.1,0.9,0.2,1.5,0.1c4.1-0.8,8.2-1.5,12.3-2.3c1.8-0.3,2.4-2.2,2.1-3.8
          c-0.8-4.6-3.4-7.8-6.7-10.8c-0.5-1.6-2.1-3.1-3.9-4.5c3.6-5.9,9.6-9.1,17.3-9.7C47.2,5.7,45.9-0.3,42,0z M27.1,26.1
          c1,1.1,1.9,2.2,2.5,3.4c-2,0.4-4,0.7-5.9,1.1C24.6,29.1,25.8,27.6,27.1,26.1z"
        />
        <circle cx="35.6" cy="15.6" r="5.3" />
      </g>
    </svg>
  ),
  chest: (
    <svg x="0px" y="0px" viewBox="0 0 87.6 92.3" style={{ enableBackground: 'new 0 0 87.6 92.3', width: 22 }}>
      <g>
        <path
          d="M44,0.6c11.4-2.5,20.9,3.6,30.5,8c9.8,4.6,13,13.2,10.9,23.8c-0.4,2.3,0.2,4.8,0.5,7.1c0.2,1.3,0.8,2.5,1.1,3.8
          c2.1,9-1,14.1-9.9,16.5c-2.5,0.7-5-2.2-5.4-5.7c-0.2-2.6-0.9-5.2-1.4-7.8c-4.1,1.2-2.2,4.7-2.4,7.1c-0.9,8.1-1.5,16.2-1.8,24.3
          c-0.3,7.3-7,14.2-14.4,14.4c-4.3,0.1-8.7,0-13,0c-10.5,0-16.4-6.2-16.7-16.6c-0.3-8.8-1.2-17.6-2-26.3c-0.1-1-1.3-2-1.9-2.9
          c-0.7,1-1.8,1.9-1.9,3c-1.6,12.9-1.5,12.9-12.7,6.1c-2.7-1.6-3.9-3.4-3.3-6.4c0.3-1.5,0.3-3,0.7-4.4c1.8-5.5,2.3-11,1.4-16.9
          c-1.3-8.7,3.4-15.5,11-19C22.9,4.3,32.4-1.8,44,0.6z M44.1,38c0.3,0.4,0.7,0.7,0.9,1c4,6.3,8.4,8.3,15,7c6.6-1.3,9.8-4.7,11.1-12.3
          c0.4-2.5,0.8-4.9,0.8-7.4c0.2-7-3.6-11-10.4-9.8c-3.8,0.7-7.5,3.3-10.9,5.5c-4.8,3.1-8.6,3.2-13.3,0c-2.7-1.9-5.4-4-8.5-5.1
          c-7.4-2.6-13.1,1.7-12.8,9.4c0.1,3.6,0.8,7.3,1.6,10.8c1,4.7,4.3,7.3,8.8,8.5C34,47.6,38.1,45.9,44.1,38z"
        />
        <path
          className="st0"
          d="M44.1,38c-6,8-10.1,9.7-17.6,7.7c-4.6-1.2-7.8-3.9-8.8-8.5c-0.8-3.5-1.4-7.2-1.6-10.8
          c-0.3-7.7,5.4-12,12.8-9.4c3,1.1,5.8,3.2,8.5,5.1c4.7,3.2,8.5,3.1,13.3,0c3.4-2.2,7.1-4.8,10.9-5.5c6.8-1.2,10.6,2.7,10.4,9.8
          c-0.1,2.5-0.4,5-0.8,7.4c-1.3,7.6-4.5,11-11.1,12.3c-6.6,1.3-11-0.8-15-7C44.8,38.6,44.5,38.3,44.1,38z"
        />
      </g>
    </svg>
  ),
  arms: (
    <svg x="0px" y="0px" viewBox="0 0 45.4 50.5" style={{ enableBackground: 'new 0 0 45.4 50.5', width: 20 }}>
      <g>
        <path
          d="M29.4,40.5c2.8,3.5,6.7,6.9,10.1,8.1l1,1.8h4.9V27.8c-1.8-3.1-5.1-5.1-8.9-5.1c-3.7,0-6.9,1.9-8.8,4.8
          c-2.2-1.6-5.2-2.6-8.6-2.6c-0.8,0-1.6,0.1-2.3,0.2c3-5.1,4.7-13.1,6.3-15.5c1.6-2.4,6.6,1.3,9.2-0.5c2.7-1.9,1.3-7.6-1.2-8.6
          c-2.5-1-6.2-0.4-9.6,1.8c-1.2,0.8-1.4,1.5-1.7,2.5c-0.1,0-0.3,0.1-0.4,0.1C16.2,6.2,8,14.2,4.9,19.2c-1.9,3.1-3.3,6.2-4,9
          C0.5,28.6,0,29.1,0,29.7C0.2,36.8,18.4,44.8,29.4,40.5z"
        />
      </g>
    </svg>
  ),
  torso: (
    <svg x="0px" y="0px" viewBox="0 0 87.7 92.6" style={{ enableBackground: 'new 0 0 87.7 92.6', width: 20 }}>
      <g>
        <path
          d="M43.9,0.9c11.8-2.6,21.1,3.5,30.5,7.8c10.1,4.6,13.2,13.2,11.1,24.1c-0.4,2.1,0.1,4.5,0.5,6.7c0.2,1.6,1,3.2,1.3,4.8
          c1.8,8.3-1.9,14-10.2,15.9c-2.5,0.6-5.1-2.4-5.1-5.9c0-4.5,0.2-9,0.1-13.5c0-1.3-0.8-2.7-1.3-4c-1.1,0.8-2.4,1.4-3.2,2.5
          c-5.8,7.9-16.8,7-21.2-1.8c-0.5-1.1-1.6-1.9-2.4-2.8c-0.8,1-1.8,1.8-2.3,2.9c-4.4,8.7-15.3,9.5-21.2,1.6c-0.7-1-1.8-1.6-2.8-2.4
          c-0.6,1.2-1.6,2.4-1.6,3.7c-0.1,4.5,0.3,9,0,13.5c-0.2,2.1-1.4,4.4-2.9,5.8c-0.6,0.6-3.3-0.3-4.7-1.1C-1.1,53-1.5,51.6,2,40.8
          c0.7-2.3,1.5-4.9,1-7.1C0.7,20.5,4.8,11.8,17.3,7.1C25.9,3.8,34.1-2.2,43.9,0.9z"
        />
        <path
          d="M68.2,45.7c0,1.9,0.1,3.7,0,5.5c-0.6,9.8-1.3,19.5-2,29.3c-0.4,5.3-7.9,11.9-13.6,12c-5.5,0.1-11,0.1-16.5,0
          c-6.8-0.2-13.5-7.3-13.9-13.9c-0.4-8.4-1.2-16.9-1.8-25.3c-0.2-2.5,0-5,0-7.5c13.9,4.1,15.8,3.8,23.8-3.2
          C52.6,49.6,55.2,50,68.2,45.7z"
        />
      </g>
    </svg>
  ),
  lowerArm: (
    <svg x="0px" y="0px" viewBox="0 0 136.5 191.4" style={{ enableBackground: 'new 0 0 136.5 191.4', width: 20 }}>
      <path
        d="M130.2,48.2c-3.6-10.1-5.9-15.8-3.8-25.3C130.2,6.2,105.8-3.3,91.2,1s-23,21.3-17.6,37.6c2.9,8.6,5.8,16,8.2,19.8
        c0,0,8.4-1.6,9.9-9.6c0.2-0.9,1.7,0.1,1.9,1c1.5,6-11.1,12-11.1,12c-0.6,0.8-0.6,0.8-1.2,1.7l-0.9,0.7c0,0-18.3,32.6-26,42.9
        c-12,16.1-46.7,50-46.7,50c-1.3,1.8-7.9,16.6-7.7,18.8l2.4,13.5l22,2c4.5,0.4,8.2-3.4,7.6-7.9c-0.9-7.1-2.1-8.9-0.3-11.1
        c3.4-4.2,44.3-28.6,55.7-37.3c25.7-19.8,38.9-39.6,38.9-39.6C139.8,80,138.7,59,130.2,48.2z M114.3,90.6c0,0-6.5,8.6-5.6,6.9
        c0.8-1.8,13.8-25.2,14.4-26.3s3.7-9.8,0.9-11.5c-2.9-1.7-6.6,1.5-6.6,1.5l-0.4-3.7c0,0,5.1-2.9,9-0.9c3.9,1.9,4.1,10.4,1.3,15.7
        S114.3,90.6,114.3,90.6z"
      />
    </svg>
  ),
  upperArm: (
    <svg x="0px" y="0px" viewBox="0 0 98.3 164.3" style={{ enableBackground: 'new 0 0 98.3 164.34', width: 18 }}>
      <path
        d="M79.4,1.2C63.7-3.9,45.9,8.1,39.4,27.9c-1.2,3.7-1.9,7.4-2.2,11l4.2,30.9l-0.6,1.9c0,0-4-14.2-4.7-18.6c0-0.1,0-0.2,0-0.3
      c-4.3,6.5-7,13.1-9.8,21.8c-5.2,16-5.9,31.6-2.8,42.7l0,0l5.8-3c0.4-0.2,0.8,0,1,0.3c0.2,0.4,0,0.8-0.3,1L0.5,130.7
      c-0.1,0.1-0.3,0.1-0.5,0.1l18,33.5l37.7-20.2l-0.2-0.4c1.3-1,2.5-2.2,3.6-3.6c5.9-6.9,7.2-18.1,5.7-23.4c5.1-6.9,9.6-15.7,12.8-25.6
      c2.7-8.4,4.2-15.8,4.5-23.3c-0.9,0.3-4.1,1.2-5.7,1.5c-1.9,0.3-5.7,2.8-5.7,2.8l-5.1,3.6c0,0,0,0,3.8-4.6c3.5-4.2,15.5-7.8,16.8-7.8
      c6-4.9,6.9-8.5,9.7-17.1C102.4,26.4,95,6.2,79.4,1.2z"
      />
    </svg>
  ),
  upperLegPelvis: (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 43.3 66"
      style={{ enableBackground: 'new 0 0 43.3 66', width: 16, marginLeft: 2 }}
    >
      <g>
        <path
          d="M38.7,0H4.6C4.5,0.8,4.4,1.5,4.3,2c0,0-5.3,12.8-4.1,22.3C1,31.4,2.7,46.6,4.4,52.6c0.9,3.1,0.4,10.9,1.1,12.8
          c0.1,0.2,0.1,0.4,0.1,0.6h10.4c0.4-2,0.9-4,1.1-5.6c1.2-7.4,3.7-26.4,3.4-30l1,0.4c0.7,0,1.2-0.4,1.2-0.4
          c-0.3,3.6,2.1,22.5,3.4,30c0.3,1.6,0.7,3.6,1.1,5.6h10.4c0-0.2,0.1-0.4,0.1-0.6c0.7-1.9,0.2-9.7,1.1-12.8c1.7-6,3.4-21.1,4.2-28.2
          C44.3,14.8,39,2,39,2C38.9,1.5,38.8,0.8,38.7,0z"
        />
      </g>
    </svg>
  ),
  lowerLegFoot: (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 43.4 107.9"
      style={{ enableBackground: 'new 0 0 43.4 107.9', width: 14, marginLeft: 3 }}
    >
      <g>
        <path
          d="M1.4,0c-1,4.1-1.7,9-1.2,13.2c0.9,7.1,2.5,22.2,4.2,28.2c0.9,3.1,0.4,10.9,1.1,12.8C5.8,55,5.7,55.8,5,57.7
          c-2.4,6.7-2.1,11.4,3.9,29.2c0,0,1.8,3.9,0.9,11c0,0-3.8,7.9-1.4,8.1c0,0,0.2,0.5,1,0.1c0,0,1.3,1.4,2.8,0.6c0,0,1.3,1.1,2.5,0.1
          c0,0,0.9,1,2.2,0.2c0,0,1.7,1.2,2.8-0.1c0,0,1.8,0.4-1.4-7.9c0,0-1.3-8.8-1.9-10.5c-1.3-3.3-0.4-12.3-0.1-14.2
          c0.5-3.2,0.2-8.7-0.6-12.9c-0.6-3,1-8.8,1.6-12.3c1.2-7.4,3.7-26.4,3.4-30l1,0.4c0.5,0.3,1,0,1,0c-0.3,3.6,2.1,22.5,3.4,30
          c0.6,3.5,2.2,9.3,1.6,12.3c-0.8,4.2-1.1,9.7-0.6,12.9c0.3,1.9,1.2,10.9-0.1,14.2c-0.7,1.7-1.9,10.5-1.9,10.5
          c-3.3,8.3-1.4,7.9-1.4,7.9c1,1.2,2.8,0.1,2.8,0.1c1.3,0.8,2.2-0.2,2.2-0.2c1.1,0.9,2.5-0.1,2.5-0.1c1.4,0.7,2.8-0.6,2.8-0.6
          c0.8,0.4,1-0.1,1-0.1c2.5-0.2-1.4-8.1-1.4-8.1c-0.9-7.1,0.9-11,0.9-11c6-17.8,6.3-22.5,3.9-29.2c-0.7-1.9-0.8-2.7-0.5-3.5
          c0.7-1.9,0.2-9.7,1.1-12.8c1.7-6,3.4-21.1,4.2-28.2c0.6-4.4-0.3-9.4-1.3-13.6H1.4z"
        />
      </g>
    </svg>
  ),
  rotate: (
    <svg x="0px" y="0px" viewBox="0 0 300.3 189.2" style={{ enableBackground: 'new 0 0 300.3 189.2', width: 34 }}>
      <g>
        <path
          d="M154.4,0c-33.7-0.1-66.9,3.1-98.8,14.3c-8.9,3.1-17.4,7.4-25.7,11.9c-4.6,2.5-8.7,6.2-12.3,10.1C11.4,43,11,50,17.5,56.2
          c6.3,6,14,10.4,21.3,15.4c0.8,0.6,2.6,0.3,3.7-0.1c40.6-16.6,83.2-20.1,126.4-18.2c30.3,1.4,59.9,6.4,88.1,18
          c1.3,0.5,3.5,0.7,4.6,0c6.8-4.4,13.9-8.6,19.9-14c7.5-6.8,7.7-15.2,0.1-21.7c-7.3-6.3-15.6-12-24.3-16.1
          C232.6,8,206.1,3.6,179.3,1.3C171,0.6,162.7,0.4,154.4,0z"
        />
        <path
          d="M146.9,129.3c23.7,19.9,47,39.4,71.3,59.9c0-7,0-12.8,0-18.7c0-12.3,0-12.2,11.7-15.2c17.8-4.5,35.1-10.4,50.4-20.9
          c9.5-6.6,17.5-14.5,19.4-26.7c0.1-0.8,0.4-1.6,0.4-2.4c0-18.7,0-37.3-0.2-56.1c-4.8,18.5-19.2,27.7-34.9,34.5
          c-13.8,6-28.6,9.7-43,14.4c-1,0.3-2.3,0.2-3.8,0.2c0-9.4,0-18.5,0-28.9C194.1,89.8,170.7,109.4,146.9,129.3z"
        />
        <path
          d="M0.1,52.2c0,18.3-0.3,36.6,0.1,54.9c0.3,10.7,7.2,18,15,24.2c14.7,11.7,32.1,17.9,50,22.9c4.3,1.2,8.7,2.1,13.5,3.3
          c0-19.7,0-39.1,0-58.7c-8.6-2.5-17.8-4.2-26.3-7.8c-11.9-5-23.6-10.6-34.7-17.2C9.6,69,3.5,61.5,0.1,52.2z"
        />
      </g>
    </svg>
  ),
  addLayer: (
    <svg x="0px" y="0px" viewBox="0 0 71.1 72.7" style={{ enableBackground: 'new 0 0 71.1 72.7', width: 34 }}>
      <g>
        <g>
          <path d="M1.4,42l34.1-20.4L61.6,36L27,56.2L1.4,42z" />
          <path d="M0,49.1l3.2-2.8l23.9,13.5l32.5-19.5l3.2,2.9L27.1,65L0,49.1z" />
          <path d="M0,56.9l3.2-2.8l23.9,13.5l32.5-19.5l3.2,2.9L27.1,72.7L0,56.9z" />
        </g>
        <polygon
          points="71.1,12.1 58.9,12.1 58.9,0 54.6,0 54.6,12.1 42.5,12.1 42.5,16.5 54.6,16.5 54.6,28.6 58.9,28.6 58.9,16.5
          71.1,16.5"
        />
      </g>
    </svg>
  ),
  removeLayer: (
    <svg x="0px" y="0px" viewBox="0 0 71.1 52.8" style={{ enableBackground: 'new 0 0 71.1 52.8', width: 34 }}>
      <g>
        <g>
          <path d="M1.4,29.8L35.5,9.4l26.1,14.4L27,44.1L1.4,29.8z" />
          <path d="M0,37l3.2-2.8l23.9,13.5l32.5-19.5l3.2,2.9L27.1,52.8L0,37z" />
        </g>
        <rect x="42.5" width="28.6" height="4.3" />
      </g>
    </svg>
  ),
  asanas: (
    <svg x="0px" y="0px" viewBox="0 0 88.97 97.48" style={{ enableBackground: 'new 0 0 88.97 97.48', width: 22 }}>
      <g>
        <path d="M41.34,25.71c-8.29-3-11.94-10.13-9-17.57a13,13,0,1,1,24,10.15,12.4,12.4,0,0,1-8.8,7.41A6,6,0,0,1,41.34,25.71Z" />
        <path d="M41.34,25.71h6.24l0,1.8H41.38Z" />
        <path d="M87.92,75.65c-1.71-2.86-4.49-4.71-2.26-8.74.84-1.51-1.2-3.19-3.12-3.83-3.47-1.15-6.86-2.54-10.38-3.48-2.92-.78-4.32-2.24-4.82-5.33-1-6.55-2.76-13-3.79-19.54-.74-4.72-3-6.6-7.75-6.21-2.76.22-5.67.58-8.27-1a4.78,4.78,0,0,0-6.15,0c-2.27,1.39-4.79,1.09-7.27,1.09-7.34,0-7.38.05-8.86,7.08-.61,2.92-1.67,5.83-1.74,8.77C23.25,54.91,18.57,61.3,7.8,62.55a4.7,4.7,0,0,0-.94.31c-3.68,1.37-5.21,3.65-2.69,7.2.72,1,.91,1.25,0,2.24C-2.22,78.92-1.3,83,7.25,86.45c7.24,2.92,14.73,5.33,21.69,8.8,3.66,1.82,6.84,2.32,10.53,1.2,1-1.26,1.89-2.6,3-3.75,1.58-1.64,3.18-2.53,3.86.76,1.32,3.24,4.06,4.77,7.09,3.67C64,93.31,74.55,89.26,84.93,84.88,88.88,83.22,90.05,79.22,87.92,75.65Zm-52.1-2.87c0,1.61,0,3.07-2.29,2.69C25.69,74.22,17.85,73,10,71.8l-.06-1c4.57-1.51,9.08-3.17,13.7-4.48,3.32-.94,4.91-2.93,5.5-6.23,1.19-6.7,2.57-13.37,3.94-20,.21-1,0-2.72,1.65-2.51s1,1.79,1,2.82C35.83,51.19,35.8,62,35.82,72.78Zm43.21-1q-12,1.88-24,3.72C53,75.8,53.1,74.3,53.1,73q0-16.44,0-32.88c0-.9-.42-2.24.86-2.5,1.78-.36,1.59,1.33,1.8,2.33,1.31,6.34,2.8,12.66,3.7,19.06.63,4.43,2.76,6.71,7,7.8s8.38,2.73,12.57,4.12C79,71.18,79,71.47,79,71.76Z" />
      </g>
    </svg>
  ),
  book: (
    <svg x="0px" y="0px" viewBox="0 0 97.62 72.1" style={{ enableBackground: 'new 0 0 97.62 72.1', width: 23 }}>
      <g>
        <path d="M93.48,5c-.23-.23-.43-.59-.72-.67C79.21.35,65.55-1.79,51.6,2.1c-1.56.44-2.85,1.38-4.7.69-13.3-5-26.62-2.4-39.73.7-2.68.63-7,2.45-7,6.41C-.09,28.53,0,47.16,0,65.79,0,67.67.29,69,2.65,69.06c14.31.64,28.8-1.81,42.91,2.55C51.84,73.55,57.32,69.1,63.42,69c11.3-.14,22.62.57,33.91-.39q.06-25.15.1-50.33C97.43,13.49,98.91,8.19,93.48,5ZM24.27,62.61,29,61h0l6.55,0c.9.21,1.89.28,2.33,1.6Zm22.21-4c-11.63-3.67-22.93-2.19-34.17.31-2.63.58-3.15-.37-3.14-2.72q.09-22.66,0-45.32c0-1.93.53-3.17,2.58-3.51,9.84-1.64,19.66-3.23,29.66-1.26,3.52.7,5.26,2.15,5.18,6.29C46.31,27.66,46.48,42.93,46.48,58.61Zm25.1,4H58.74c.75-1.18,2-1.3,3.13-1.65h0l5.74-.06,4.06,1.17ZM84.89,59c-11.25-2.43-22.56-4-34.32-.25,0-16.39,0-32.32,0-48.24,0-3.49,2.66-3.86,5-4.42,8.1-2,16.2-.86,24.26.31,8.51,1.22,8.49,1.32,8.49,9.9,0,13.12,0,26.24,0,39.36C88.37,58.17,88.23,59.67,84.89,59Z" />
      </g>
    </svg>
  ),
  postural: (
    <svg viewBox="0 0 28.49 102.5" style={{ enableBackground: 'new 0 0 28.49 102.5', width: 9, marginLeft: 5 }}>
      <g>
        <path d="M28.4,24.93a12,12,0,0,0-5.5-8.56,29.86,29.86,0,0,1-2.53-1.67,3.93,3.93,0,0,1-1.46-2.19,1,1,0,0,1,.41-1.15c.75-.52,1.58-.9,2.35-1.39a4,4,0,0,0,1.8-2.15c.87-2.71.25-5.87-3.17-7.1A11.18,11.18,0,0,0,10,2,4.63,4.63,0,0,0,7.86,5c-.08.36-.21.71-.28,1.07A1.24,1.24,0,0,1,6.87,7a7.16,7.16,0,0,1-.78.4c-.53.19-.62.5-.45,1,.33,1,.59,2,.87,3a.68.68,0,0,1-.12.76c-.51.44-.51.86.06,1.2a3.69,3.69,0,0,0,1.29.5c.83.15,1.67.13,2.5.21a.61.61,0,0,1,.54.26A7.46,7.46,0,0,1,12,17.34a9.81,9.81,0,0,1-.66,4.8,18.38,18.38,0,0,0-1,7.24c.06.75,0,1.52,0,2.27a5.38,5.38,0,0,1-1,3L7.83,36.91a11.3,11.3,0,0,0-2,7.75c.17,1.8.36,3.6.49,5.4A37.24,37.24,0,0,1,6,56.12a84.76,84.76,0,0,0-.1,11.27,17.82,17.82,0,0,0,1.52,7.43A36.18,36.18,0,0,1,10.06,82a29.9,29.9,0,0,1,1.35,9A8.36,8.36,0,0,1,5.77,99c-1.38.54-2.82.88-4.21,1.38a3.79,3.79,0,0,0-1.36.75c-.31.28-.24.48.1.64a4.8,4.8,0,0,0,1.22.38,37.64,37.64,0,0,0,6.87.36v-.15c1.31,0,2.61.05,3.91,0a42.61,42.61,0,0,0,4.36-.43.76.76,0,0,0,.77-.79,7.66,7.66,0,0,0-.26-2.43,9.51,9.51,0,0,1,0-4.5c.33-1.28.62-2.58,1.07-3.81a16.6,16.6,0,0,0-1-13.61c-.59-1.2-1.15-2.42-1.72-3.63a5,5,0,0,1-.34-3.52q1.63-6.28,3.26-12.57c.61-2.37,1.4-4.6,3.49-6.15a3.92,3.92,0,0,0,1.37-2.45,5.52,5.52,0,0,0-.84-4.34,5.65,5.65,0,0,1-.07-6.57,27.25,27.25,0,0,1,2-2.81,27.16,27.16,0,0,0,3.06-4.54A8.76,8.76,0,0,0,28.4,24.93Zm-7.06,2.44A20,20,0,0,1,19.63,33c-.71,1.58-1.53,3.1-2.33,4.63a10.55,10.55,0,0,0-1.46,5.62c0,.19,0,.39.05.58.13.84.13.85,1,.77.27,0,.38,0,.4.32s.07.38-.26.42c-.71.09-1.42.21-2.13.34-.25,0-.41,0-.43-.28s0-.44.31-.46.57.07.51-.28a9.72,9.72,0,0,1,.36-5.09,21,21,0,0,1,1.47-3.16,32.07,32.07,0,0,0,3.24-7.71,10.46,10.46,0,0,0-2.24-9.65,11.91,11.91,0,0,1-2.78-4.8,4.26,4.26,0,0,1,.44-3.48c.08-.12.14-.46.4-.21s.46.26.23.61a3.87,3.87,0,0,0-.13,3.58,11.2,11.2,0,0,0,2.23,3.6A11.11,11.11,0,0,1,21.34,27.37Z" />
      </g>
    </svg>
  ),
  therapeutic: (
    <svg x="0px" y="0px" viewBox="0 0 82.68 76.3" style={{ enableBackground: 'new 0 0 82.68 76.3', width: 21 }}>
      <g>
        <path d="M49.82,66.82a6.69,6.69,0,0,0,.85-1.95,4.55,4.55,0,0,0-1.17-4.53,5.41,5.41,0,0,0-2.6-1.23A17.75,17.75,0,0,0,43,58.85c-2.76,0-5.52-.07-8.27-.11-1.8,0-3.6,0-5.39-.08-.72,0-1.44,0-2.17,0a7.4,7.4,0,0,0,1,.12c1.18.08,2.35.26,3.53.34.74.06,1.49.12,2.23.22,1.2.15,2.43.15,3.63.38.41.09.84.05,1.27.1,1.09.13,2.2.24,3.3.35l2,.17a3.93,3.93,0,0,1,2.73.95,2.72,2.72,0,0,1,.82,2,4.7,4.7,0,0,1-.16,1.58,2.91,2.91,0,0,1-2.39,1.94,8.7,8.7,0,0,1-1.69.08H22.34A6.42,6.42,0,0,1,16.05,62a6.1,6.1,0,0,1,1.18-5.5,6.64,6.64,0,0,1,4-2.43,1.7,1.7,0,0,1,.39,0H41.37a.79.79,0,0,0,.63-.26c1.27-1.37,2.55-2.72,3.82-4.09.55-.6,1.1-1.2,1.64-1.82a.39.39,0,0,1,.55-.1,27.44,27.44,0,0,0,5,1.93,4.09,4.09,0,0,0,2.63-.18,5.47,5.47,0,0,0,3-2.62,4.68,4.68,0,0,0,.25-3.19,6.43,6.43,0,0,0-1.15-2.4c.09-.13.22-.07.32-.07,4.32,0,8.64,0,13,0a4.23,4.23,0,0,1,3.85,2.18,3.27,3.27,0,0,1-.21,3.76A4.12,4.12,0,0,1,72,49a9.19,9.19,0,0,1-1.57.09c-1.19,0-2.37.19-3.56.22-.84,0-1.69.09-2.54.15-1.35.1-2.7.17-4.06.27-.81.06-1.63.08-2.45.15-.44,0-.53.39-.72.64-1.52,2-3,4.09-4.53,6.14,0,.07-.08.17-.13.26,1.1.12,2.19.15,3.28.2s1.94.11,2.92.17,1.89.08,2.83.16c1.16.1,2.32.12,3.48.18s2.06.13,3.1.18,1.8.1,2.7.16c1.11.08,2.21.11,3.31.18s2.06.1,3.09.17a7.37,7.37,0,0,1,3.46.68,3.86,3.86,0,0,1,2.06,3.31,4.23,4.23,0,0,1-2.17,3.94,3.55,3.55,0,0,1-1.93.55H50Z" />
        <path d="M40.07,39.07a6.67,6.67,0,0,0,.5,3.47,3.11,3.11,0,0,0,1,1.11c1.26.86,2.5,1.74,3.76,2.6.17.11.22.15.06.32-.88,1-1.76,1.9-2.65,2.83l-1.26,1.33A.65.65,0,0,1,41,51q-2.88,0-5.76,0c-.26,0-.36-.05-.36-.35,0-8.4,0-16.8,0-25.2a6.19,6.19,0,0,1,1.51-4,6.71,6.71,0,0,1,3.51-2.18,3.51,3.51,0,0,1,.92-.1H54.47a6.22,6.22,0,0,1,3.73,1.34,15.94,15.94,0,0,1,2.56,2.76c.52.6,1,1.23,1.52,1.85s1.22,1.49,1.84,2.24,1,1.21,1.55,1.83c.76.92,1.48,1.87,2.27,2.76s1.28,1.53,1.92,2.29c.92,1.09,1.81,2.21,2.72,3.31a2.65,2.65,0,0,1,.55.73c-.14.09-.28,0-.42,0H65.28a.8.8,0,0,1-.73-.35,21.42,21.42,0,0,0-1.48-1.68c-1.22-1.36-2.43-2.73-3.65-4.08-.67-.75-1.35-1.48-2.09-2.28v1.06c0,2.32,0,4.64,0,7,0,.29-.06.38-.37.37-1.23,0-2.46,0-3.69,0a.86.86,0,0,1-.41-.08c-1.41-.7-2.82-1.38-4.24-2.07a1.47,1.47,0,0,0-.34-.15c-.88-.23-1-1.06-1.33-1.71-.68-1.43-1.41-2.84-2.09-4.27-.59-1.24-1.21-2.48-1.85-3.79-.1.4.11.67.18.95.22,1,.53,1.92.75,2.89s.58,2.09.87,3.14c.23.84.43,1.68.66,2.52a.89.89,0,0,0,.47.52c2.14,1.27,4.26,2.56,6.41,3.81,1,.59,2.06,1.14,3,1.82a2.92,2.92,0,0,1-.21,5.09A3.62,3.62,0,0,1,51,46.68c-1.56-1-3.18-1.94-4.78-2.91-1.38-.84-2.78-1.64-4.12-2.54A6.65,6.65,0,0,1,40.07,39.07Z" />
        <path d="M41.35,76.29H.51c-.39,0-.49-.09-.48-.48q0-3.06,0-6.1a3.22,3.22,0,0,1,2.61-3,6.67,6.67,0,0,1,1.26-.08c2.43,0,4.87,0,7.3,0a3.41,3.41,0,0,1,2.59,1.07c.6.65,1.26,1.25,1.87,1.89a.9.9,0,0,0,.74.31H82.07c.6,0,.6,0,.6.6,0,1.78,0,3.55,0,5.33,0,.36-.08.44-.44.44Z" />
        <path d="M46.06,16a8,8,0,1,1,.61-16,7.77,7.77,0,0,1,5.11,2.33A7.6,7.6,0,0,1,54.13,8,8,8,0,0,1,46.06,16Z" />
        <path d="M8,63.66A8,8,0,0,1,0,54.85a7.65,7.65,0,0,1,2.6-5.16A7.67,7.67,0,0,1,8,47.63,7.82,7.82,0,0,1,14,50.32,7.71,7.71,0,0,1,16,55.67,8.05,8.05,0,0,1,8,63.66Z" />
      </g>
    </svg>
  ),
  facebookLogin: (
    <svg x="0px" y="0px" viewBox="0 0 1024 1018" style={{ width: 22, margin: '-2px 6px 0 0' }}>
      <defs>
        <polygon id="path-1" points="0.06 0.04 1024 0.04 1024 1017.78 0.06 1017.78" />
      </defs>
      <g id="Page-1" stroke="none">
        <path
          d="M1024,512 C1024,229.23 794.77,0 512,0 C229.23,0 0,229.23 0,512 C0,767.554 187.231,979.37 432,1017.78 L432,660 L302,660 L302,512 L432,512 L432,399.2 C432,270.88 508.438,200 625.39,200 C681.407,200 740,210 740,210 L740,336 L675.438,336 C611.835,336 592,375.467 592,415.957 L592,512 L734,512 L711.3,660 L592,660 L592,1017.78 C836.769,979.37 1024,767.554 1024,512"
          id="Fill-1"
          fill="#FFFFFE"
          mask="url(#mask-2)"
        />
      </g>
    </svg>
  ),
  googleLogin: (
    <svg x="0px" y="0px" viewBox="0 0 18 18" style={{ width: 22, margin: '-2px 6px 0 0' }}>
      <g id="Google-Button" stroke="none" fill="none">
        <path
          d="M17.64,9.20454545 C17.64,8.56636364 17.5827273,7.95272727 17.4763636,7.36363636 L9,7.36363636 L9,10.845 L13.8436364,10.845 C13.635,11.97 13.0009091,12.9231818 12.0477273,13.5613636 L12.0477273,15.8195455 L14.9563636,15.8195455 C16.6581818,14.2527273 17.64,11.9454545 17.64,9.20454545 L17.64,9.20454545 Z"
          id="Shape"
          fill="#4285F4"
        />
        <path
          d="M9,18 C11.43,18 13.4672727,17.1940909 14.9563636,15.8195455 L12.0477273,13.5613636 C11.2418182,14.1013636 10.2109091,14.4204545 9,14.4204545 C6.65590909,14.4204545 4.67181818,12.8372727 3.96409091,10.71 L0.957272727,10.71 L0.957272727,13.0418182 C2.43818182,15.9831818 5.48181818,18 9,18 L9,18 Z"
          id="Shape"
          fill="#34A853"
        />
        <path
          d="M3.96409091,10.71 C3.78409091,10.17 3.68181818,9.59318182 3.68181818,9 C3.68181818,8.40681818 3.78409091,7.83 3.96409091,7.29 L3.96409091,4.95818182 L0.957272727,4.95818182 C0.347727273,6.17318182 0,7.54772727 0,9 C0,10.4522727 0.347727273,11.8268182 0.957272727,13.0418182 L3.96409091,10.71 L3.96409091,10.71 Z"
          id="Shape"
          fill="#FBBC05"
        />
        <path
          d="M9,3.57954545 C10.3213636,3.57954545 11.5077273,4.03363636 12.4404545,4.92545455 L15.0218182,2.34409091 C13.4631818,0.891818182 11.4259091,0 9,0 C5.48181818,0 2.43818182,2.01681818 0.957272727,4.95818182 L3.96409091,7.29 C4.67181818,5.16272727 6.65590909,3.57954545 9,3.57954545 L9,3.57954545 Z"
          id="Shape"
          fill="#EA4335"
        />
        <polygon id="Shape" points="0 0 18 0 18 18 0 18" />
      </g>
    </svg>
  ),
  captionOn: (
    <svg width="32px" height="22px" viewBox="0 0 32 22">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M25.1163529,0.465279808 L25.606593,0.495782697 C28.6760756,0.687759132 31.1033917,3.16955654 31.2271991,6.24254368 C31.2936909,7.89291981 31.3269369,9.52379347 31.3269369,11.1351647 C31.3269369,12.1448359 31.313884,13.1545071 31.2877781,14.1641784 L31.2388346,15.6786854 C31.1176821,18.8024913 28.6183013,21.3098188 25.4948959,21.4408873 C21.6080065,21.6039941 18.1282658,21.6855474 15.055674,21.6855474 C12.7595173,21.6855474 10.4045606,21.640003 7.99080405,21.5489142 L6.53549401,21.4887975 C3.40552253,21.348425 0.910500146,18.8234002 0.807549048,15.6919745 C0.757483617,14.1691526 0.732450902,12.6352449 0.732450902,11.0902512 C0.732450902,10.0084964 0.744722812,8.9267416 0.769266633,7.84498682 L0.815282632,6.22235453 C0.919729874,3.15338645 3.3244712,0.658598569 6.38752886,0.441453203 C10.3792912,0.158470381 13.2097661,0.011536992 14.8789533,0.000653037283 L15.0736816,1.38555833e-13 C16.756676,1.38555833e-13 20.104233,0.155093269 25.1163529,0.465279808 Z M23.5841392,17.7593128 L8.29567747,17.7593128 L8.29567747,19.1495624 L23.5841392,19.1495624 L23.5841392,17.7593128 Z M27.5317982,14.8366859 L4.34801851,14.8366859 L4.34801851,16.2269356 L27.5317982,16.2269356 L27.5317982,14.8366859 Z"
          fill="#FFFFFF"
        />
      </g>
    </svg>
  ),
  filterIcon: (
    <svg width="29px" height="20px" viewBox="0 0 17 11">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ic_filter" transform="translate(0.400000, 0.000000)" fill="#FFFFFF">
          <rect id="Rectangle" x="0" y="1.13242749e-14" width="16.5" height="2" />
          <rect id="Rectangle" x="2.7" y="4.5" width="11" height="2" />
          <rect id="Rectangle" x="6.2" y="9" width="4" height="2" />
        </g>
      </g>
    </svg>
  ),
  doubleArrow: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20">
      <path d="M 4.4648438 4.0507812 L 3.0351562 5.4492188 L 11.998047 14.597656 L 20.962891 5.4980469 L 19.537109 4.0957031 L 12.001953 11.744141 L 4.4648438 4.0507812 z M 19.544922 9.9160156 L 11.994141 17.423828 L 4.4492188 10.035156 L 3.0507812 11.464844 L 12.005859 20.234375 L 20.955078 11.333984 L 19.544922 9.9160156 z" />
    </svg>
  ),
  info: (
    <svg focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
      <path d="M20 424.229h20V279.771H20c-11.046 0-20-8.954-20-20V212c0-11.046 8.954-20 20-20h112c11.046 0 20 8.954 20 20v212.229h20c11.046 0 20 8.954 20 20V492c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20v-47.771c0-11.046 8.954-20 20-20zM96 0C56.235 0 24 32.235 24 72s32.235 72 72 72 72-32.235 72-72S135.764 0 96 0z" />
    </svg>
  ),
  myofascial: (
    <svg width="28" height="24" viewBox="0 0 360.000000 360.000000" style={{ marginLeft: -3 }}>
      <g transform="translate(0.000000,360.000000) scale(0.100000,-0.100000)" stroke="none">
        <path
          d="M1253 2926 c-145 -34 -272 -123 -334 -238 l-34 -63 -3 -253 c-3 -222
-5 -253 -19 -248 -79 31 -170 11 -226 -48 -36 -39 -562 -924 -613 -1031 -50
-105 -12 -224 89 -278 l42 -22 645 0 c698 0 696 0 815 55 118 56 205 144 247
249 21 56 22 60 19 584 -2 290 -1 527 2 527 2 0 285 -156 628 -346 835 -463
757 -424 835 -424 76 0 126 18 172 63 56 53 76 102 76 182 0 84 -23 135 -83
186 -33 28 -1686 952 -1846 1033 -143 71 -297 98 -412 72z m-370 -1508 l5
-248 -150 0 c-82 0 -148 4 -146 9 4 12 283 489 284 487 1 0 4 -112 7 -248z"
        />
        <path
          d="M2312 1736 c-136 -34 -284 -148 -349 -271 -127 -242 -58 -545 159
-698 79 -55 144 -82 232 -98 283 -49 561 142 621 426 8 39 15 88 15 110 0 196
-127 398 -304 485 -121 60 -250 75 -374 46z"
        />
      </g>
    </svg>
  ),
  posturalTests: (
    <svg width="26" height="26" viewBox="0 0 920.000000 920.000000" style={{ marginLeft: -3 }}>
      <g transform="translate(0.000000,920.000000) scale(0.100000,-0.100000)" stroke="none">
        <path
          d="M4383 9191 c-241 -39 -458 -180 -591 -384 -328 -502 -50 -1178 539
-1312 93 -21 272 -21 364 0 222 52 410 180 534 365 100 149 142 291 143 475 0
135 -15 216 -63 331 -104 249 -310 431 -572 504 -81 23 -270 34 -354 21z"
        />
        <path
          d="M4875 7133 c-428 -38 -835 -200 -1165 -463 -116 -92 -301 -285 -387
-400 -268 -364 -407 -764 -420 -1211 -11 -373 51 -680 201 -994 294 -615 834
-1044 1496 -1189 471 -103 973 -39 1409 180 80 40 146 72 146 71 0 -1 11 -24
25 -52 19 -39 232 -258 975 -1002 l950 -952 85 -40 c132 -61 240 -74 373 -46
196 41 366 198 428 395 27 85 29 232 5 315 -27 92 -71 169 -135 237 -33 35
-457 461 -944 947 -782 781 -949 941 -986 941 -24 0 -18 25 28 112 109 205
200 486 232 718 22 157 17 470 -10 629 -40 236 -102 425 -208 634 -294 579
-854 1006 -1483 1131 -202 40 -437 55 -615 39z m395 -487 c563 -75 1035 -416
1293 -932 75 -149 137 -366 156 -546 44 -398 -54 -792 -277 -1123 -245 -362
-620 -613 -1058 -707 -96 -20 -135 -23 -334 -23 -196 1 -239 4 -330 23 -750
163 -1293 784 -1338 1531 -38 624 242 1181 763 1524 323 212 735 305 1125 253z"
        />
        <path
          d="M4825 6305 c-290 -53 -539 -186 -740 -396 -185 -194 -298 -413 -351
-684 -24 -121 -24 -377 0 -490 80 -373 279 -672 581 -870 152 -100 325 -170
498 -201 139 -26 372 -24 507 5 188 40 352 112 508 221 l72 51 0 1044 0 1044
-98 65 c-239 159 -466 227 -755 225 -84 0 -183 -7 -222 -14z"
        />
        <path
          d="M1081 7074 c-79 -21 -148 -62 -211 -124 -93 -93 -140 -208 -140 -340
1 -227 169 -427 395 -469 39 -7 331 -11 886 -11 l827 0 38 68 c110 198 303
445 470 600 75 71 254 213 344 274 24 16 -48 17 -1265 17 -1096 0 -1298 -3
-1344 -15z"
        />
        <path
          d="M6461 7039 c130 -90 252 -195 385 -332 145 -150 246 -280 350 -454
l71 -118 274 -3 c157 -1 304 2 344 8 397 60 552 545 261 817 -35 33 -84 70
-110 82 -104 51 -99 51 -900 51 l-750 0 75 -51z"
        />
        <path
          d="M3122 1939 l3 -1464 22 -65 c89 -260 328 -425 588 -406 94 7 153 23
238 68 84 44 193 154 234 238 66 132 63 76 63 1246 l0 1061 -90 32 c-136 49
-371 169 -505 259 -159 107 -294 222 -438 372 l-118 124 3 -1465z"
        />
        <path
          d="M5840 2618 c-103 -35 -285 -78 -425 -100 -150 -23 -477 -31 -603 -15
l-63 8 3 -1018 3 -1018 28 -78 c36 -103 79 -168 160 -244 226 -211 572 -202
792 21 45 46 81 94 102 137 66 134 63 75 61 1255 l-3 1071 -55 -19z"
        />
      </g>
    </svg>
  ),
};

export const itemsGroupIcons = {
  bigGroupIcon: val => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
      <g opacity={val === 'small' ? '0.45' : '1'} transform="translate(-7 -.159)">
        <path d="M0 0H5V5H0z" fill="#fff" transform="translate(17 .159)" />
        <path d="M0 0H5V5H0z" fill="#fff" transform="translate(7 .159)" />
        <g transform="translate(7 10.159)">
          <path d="M0 0H5V5H0z" fill="#fff" transform="translate(10)" />
          <path d="M0 0H5V5H0z" fill="#fff" />
        </g>
      </g>
    </svg>
  ),
  smallGroupIcon: val => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
      <g opacity={val === 'big' ? '0.45' : '1'} transform="translate(0 -.159)">
        <g>
          <path d="M0 0H3V3H0z" fill="#fff" transform="translate(13 .159)" />
          <path d="M0 0H3V3H0z" fill="#fff" transform="translate(13 6.159)" />
          <path d="M0 0H3V3H0z" fill="#fff" transform="translate(13 12.159)" />
        </g>
        <g>
          <path d="M0 0H3V3H0z" fill="#fff" transform="translate(7 .159)" />
          <path d="M0 0H3V3H0z" fill="#fff" transform="translate(7 6.159)" />
          <path d="M0 0H3V3H0z" fill="#fff" transform="translate(7 12.159)" />
        </g>
        <g>
          <path d="M0 0H3V3H0z" fill="#fff" transform="translate(0 .159)" />
          <path d="M0 0H3V3H0z" fill="#fff" transform="translate(0 6.159)" />
          <path d="M0 0H3V3H0z" fill="#fff" transform="translate(0 12.159)" />
        </g>
      </g>
    </svg>
  ),
};

export const sortingIcons = {
  none: (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1026 7.03609L12.5801 8.74984H11.2476L13.4838 2.08484H15.0263L17.2526 8.74984H15.8526L15.3288 7.03609H13.1026ZM15.0651 6.05484L14.2501 3.35859H14.1913L13.3763 6.05484H15.0651Z"
        fill="#737881"
      />
      <path
        d="M16.6998 17.4998H11.7848V16.6361L15.0086 11.9861V11.9186H11.8723V10.8348H16.6036V11.6986L13.3948 16.3486V16.4161H16.7011V17.4998H16.6998ZM6.12481 3.12482C6.12481 2.95906 6.05896 2.80009 5.94175 2.68288C5.82454 2.56567 5.66557 2.49982 5.49981 2.49982C5.33405 2.49982 5.17508 2.56567 5.05787 2.68288C4.94066 2.80009 4.87481 2.95906 4.87481 3.12482V15.3661L3.44231 13.9323C3.3842 13.8742 3.31522 13.8281 3.23929 13.7967C3.16337 13.7652 3.08199 13.749 2.99981 13.749C2.91763 13.749 2.83626 13.7652 2.76033 13.7967C2.68441 13.8281 2.61542 13.8742 2.55731 13.9323C2.4992 13.9904 2.45311 14.0594 2.42166 14.1353C2.39021 14.2113 2.37402 14.2926 2.37402 14.3748C2.37402 14.457 2.39021 14.5384 2.42166 14.6143C2.45311 14.6902 2.4992 14.7592 2.55731 14.8173L5.05731 17.3161L5.06606 17.3248C5.18341 17.4392 5.34122 17.5027 5.50511 17.5013C5.669 17.4998 5.8257 17.4337 5.94106 17.3173L8.44106 14.8173C8.55842 14.7001 8.62442 14.5411 8.62453 14.3753C8.62465 14.2094 8.55888 14.0503 8.44169 13.9329C8.3245 13.8156 8.16548 13.7496 7.99963 13.7495C7.83378 13.7494 7.67467 13.8151 7.55731 13.9323L6.12481 15.3661V3.12482Z"
        fill="#737881"
      />
    </svg>
  ),
  asc: (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6338 7.27846L12.1113 8.99221H10.7788L13.0151 2.32721H14.5576L16.7838 8.99221H15.3838L14.8601 7.27846H12.6338ZM14.5963 6.29721L13.7813 3.60096H13.7226L12.9076 6.29721H14.5963Z"
        fill="#FFA733"
      />
      <path
        d="M16.2311 17.7422H11.3161V16.8784L14.5398 12.2284V12.1609H11.4036V11.0772H16.1348V11.9409L12.9261 16.5909V16.6584H16.2323V17.7422H16.2311ZM5.65606 3.36719C5.65606 3.20143 5.59021 3.04246 5.473 2.92525C5.35579 2.80804 5.19682 2.74219 5.03106 2.74219C4.8653 2.74219 4.70633 2.80804 4.58912 2.92525C4.47191 3.04246 4.40606 3.20143 4.40606 3.36719V15.6084L2.97356 14.1747C2.91545 14.1166 2.84647 14.0705 2.77054 14.039C2.69462 14.0076 2.61324 13.9914 2.53106 13.9914C2.44888 13.9914 2.36751 14.0076 2.29158 14.039C2.21566 14.0705 2.14667 14.1166 2.08856 14.1747C2.03045 14.2328 1.98436 14.3018 1.95291 14.3777C1.92146 14.4536 1.90527 14.535 1.90527 14.6172C1.90527 14.6994 1.92146 14.7807 1.95291 14.8567C1.98436 14.9326 2.03045 15.0016 2.08856 15.0597L4.58856 17.5584L4.59731 17.5672C4.71466 17.6816 4.87247 17.745 5.03636 17.7436C5.20025 17.7422 5.35695 17.6761 5.47231 17.5597L7.97231 15.0597C8.08967 14.9425 8.15567 14.7835 8.15578 14.6176C8.1559 14.4518 8.09013 14.2927 7.97294 14.1753C7.85575 14.058 7.69673 13.992 7.53088 13.9918C7.36503 13.9917 7.20592 14.0575 7.08856 14.1747L5.65606 15.6084V3.36719Z"
        fill="#FFA733"
      />
    </svg>
  ),
  desc: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2002 8.75002H11.2852V7.88627L14.5089 3.23627V3.16877H11.3727V2.08502H16.1039V2.94877L12.8952 7.59877V7.66627H16.2014V8.75002H16.2002Z"
        fill="#FFA733"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6021 15.7863L12.0796 17.5H10.7471L12.9833 10.835H14.5258L16.7521 17.5H15.3521L14.8283 15.7863H12.6021ZM14.5646 14.805L13.7496 12.11H13.6908L12.8758 14.805H14.5646Z"
        fill="#FFA733"
      />
      <path
        d="M5.62481 16.875C5.62481 17.0407 5.55896 17.1997 5.44175 17.3169C5.32454 17.4341 5.16557 17.5 4.99981 17.5C4.83405 17.5 4.67508 17.4341 4.55787 17.3169C4.44066 17.1997 4.37481 17.0407 4.37481 16.875V4.63372L2.94231 6.06747C2.8842 6.12558 2.81522 6.17168 2.73929 6.20313C2.66337 6.23458 2.58199 6.25076 2.49981 6.25076C2.41763 6.25076 2.33626 6.23458 2.26033 6.20313C2.18441 6.17168 2.11542 6.12558 2.05731 6.06747C1.9992 6.00936 1.95311 5.94038 1.92166 5.86445C1.89021 5.78853 1.87402 5.70715 1.87402 5.62497C1.87402 5.54279 1.89021 5.46142 1.92166 5.38549C1.95311 5.30957 1.9992 5.24058 2.05731 5.18247L4.55731 2.68372L4.56606 2.67497C4.68356 2.56085 4.84131 2.49764 5.0051 2.49905C5.1689 2.50045 5.32554 2.56636 5.44106 2.68247L7.94106 5.18247C7.99917 5.2405 8.04528 5.30941 8.07676 5.38526C8.10824 5.4611 8.12448 5.54241 8.12454 5.62453C8.12459 5.70665 8.10848 5.78798 8.0771 5.86388C8.04573 5.93977 7.99972 6.00874 7.94169 6.06685C7.88366 6.12496 7.81475 6.17107 7.73891 6.20255C7.66306 6.23403 7.58175 6.25026 7.49963 6.25032C7.41751 6.25038 7.33618 6.23426 7.26029 6.20289C7.18439 6.17152 7.11542 6.1255 7.05731 6.06747L5.62481 4.63372V16.875Z"
        fill="#FFA733"
      />
    </svg>
  ),
};

export const workoutExercisesHeaderIcons = {
  addText: (boxClassName, topIconClassName, bottomIconClassName) => (
    <div className={boxClassName}>
      <svg xmlns="http://www.w3.org/2000/svg" className={topIconClassName} viewBox="0 0 11.321 11.321">
        <path
          fill="none"
          stroke="#273342"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.808 2.6a1.472 1.472 0 0 1 2.082 2.085l-7.027 7.027L2 12.493l.781-2.863z"
          transform="translate(-1.5 -1.672)"
        />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" className={bottomIconClassName} viewBox="0 0 19.826 10.349">
        <g transform="translate(.5 .5)">
          <path d="M18.826 0L0 0" transform="translate(0 4.642)" />
          <path d="M18.826 0L0 0" transform="translate(0 9.349)" />
          <path d="M13.45 9H8" transform="translate(-7.936 -9)" />
        </g>
      </svg>
    </div>
  ),
  addRest: className => (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 23 23">
      <g transform="translate(-.209 -.45)">
        <circle cx="11" cy="11" r="11" transform="translate(.709 .95)" />
        <g transform="translate(1.26 1.223)">
          <path d="M0 9.413L0 0" transform="translate(7.949 6.227)" />
          <path d="M0 9.413L0 0" transform="translate(12.342 6.227)" />
        </g>
      </g>
    </svg>
  ),
  circuit: className => (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 21.568 21.735">
      <g fill="none" stroke="#273342" transform="translate(0 .168)">
        <circle cx="10.784" cy="10.784" r="10.784" stroke="none" />
        <circle cx="10.784" cy="10.784" r="10.284" />
      </g>
      <circle cx="2.353" cy="2.353" r="2.353" fill="#273342" transform="rotate(45 8.825 21.305)" />
    </svg>
  ),
  superset: className => (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 22.958 25.489">
      <path
        fill="none"
        stroke="#273342"
        d="M67.123 1474.561c-.1-.264-.069-.151-.251-.367-.32-1.292-.254-2.771-1.172-4.237-1.475-2.353-5.378-6.148-6.857-6.733a1.682 1.682 0 0 0-.2-.06 1.187 1.187 0 0 0-.747-.938c-1.515-.711-3.269-1.518-4.233-1.122-.55.228-.985-.223-1.162.307-.112.333-.714.97-.586 1.7a2.916 2.916 0 0 0 .892 1.416c.075.215-.578-.152-.194.652s3.793-.614 4.484.24c.76 1.134.9 4.9 2.3 7.312-.24-.031-1.665-2.149-4.134-1.553a4.368 4.368 0 0 0-3.126 2.7c-2.371-2.21-6.283-.681-6.817 1.461l.015 10.169 2.41-.042.911-1.616c1.586-.61 2.834-3.226 4.136-4.9a23.35 23.35 0 0 0 5.516-.359 49.7 49.7 0 0 0 5.693-1.55c2.438-.889 3.741-.787 3.122-2.48z"
        transform="translate(-6.5 .471) translate(7) translate(-45.312 -1461)"
      />
    </svg>
  ),
  delete: className => (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 20.832 23.035">
      <g transform="translate(.5 .5)">
        <path d="M3 6h19.832" transform="translate(-3 -1.593)" />
        <path
          d="M20.425 6.407v15.424a2.2 2.2 0 0 1-2.2 2.2H7.2a2.2 2.2 0 0 1-2.2-2.2V6.407m3.305 0V4.2a2.2 2.2 0 0 1 2.2-2.2h4.407a2.2 2.2 0 0 1 2.2 2.2v2.2"
          transform="translate(-2.796 -2)"
        />
        <path d="M0 0L0 7.347" transform="translate(6.858 9.184)" />
        <path d="M0 0L0 7.347" transform="translate(12.368 9.184)" />
      </g>
    </svg>
  ),
  edit: (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.213" height="16.373" viewBox="0 0 16.213 16.373">
      <path
        fill="none"
        stroke="#525b6d"
        strokeLinecap="round"
        d="M13.347 2.8a2.14 2.14 0 1 1 3.026 3.026L6.161 16.037 2 17.172l1.135-4.161z"
        transform="translate(-1.287 -1.511)"
      />
    </svg>
  ),
};

export const planIcons = {
  maxScreen: (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.208" height="33.208" viewBox="0 0 33.208 33.208">
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3px"
        d="M11.391 3H6.356A3.356 3.356 0 0 0 3 6.356v5.035m30.208 0V6.356A3.356 3.356 0 0 0 29.852 3h-5.035m0 30.208h5.035a3.356 3.356 0 0 0 3.356-3.356v-5.035M3 24.817v5.035a3.356 3.356 0 0 0 3.356 3.356h5.035"
        transform="translate(-1.5 -1.5)"
      />
    </svg>
  ),
  deletePlan: className => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.832" height="23.035" viewBox="0 0 20.832 23.035">
      <g transform="translate(.5 .5)">
        <path d="M3 6h19.832" className={className} transform="translate(-3 -1.593)" />
        <path
          d="M20.425 6.407v15.424a2.2 2.2 0 0 1-2.2 2.2H7.2a2.2 2.2 0 0 1-2.2-2.2V6.407m3.305 0V4.2a2.2 2.2 0 0 1 2.2-2.2h4.407a2.2 2.2 0 0 1 2.2 2.2v2.2"
          className={className}
          transform="translate(-2.796 -2)"
        />
        <path d="M0 0L0 7.347" className={className} transform="translate(6.858 9.184)" />
        <path d="M0 0L0 7.347" className={className} transform="translate(12.368 9.184)" />
      </g>
    </svg>
  ),
  editWorkout: className => (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.035" height="23.035" viewBox="0 0 23.035 23.035">
      <g transform="translate(.5 .5)">
        <path
          d="M11.856 4H4.19A2.19 2.19 0 0 0 2 6.19v15.331a2.19 2.19 0 0 0 2.19 2.19h15.331a2.19 2.19 0 0 0 2.19-2.19v-7.665"
          className={className}
          transform="translate(-2 -1.677)"
        />
        <path
          d="M19.5 2.559a2.323 2.323 0 0 1 3.285 3.285l-10.4 10.4L8 17.343l1.1-4.38z"
          className={className}
          transform="translate(-1.429 -1.879)"
        />
      </g>
    </svg>
  ),
};

export const smallTrashBasketIcon = className => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16.556" viewBox="0 0 15 16.556">
    <g transform="translate(.5 .5)">
      <path d="M3 6h14" className={className} transform="translate(-3 -2.889)" />
      <path
        d="M16.15 5.111V16a1.575 1.575 0 0 1-1.593 1.556H6.593A1.575 1.575 0 0 1 5 16V5.111m2.389 0V3.556A1.575 1.575 0 0 1 8.982 2h3.186a1.575 1.575 0 0 1 1.593 1.556v1.555"
        className={className}
        transform="translate(-3.575 -2)"
      />
      <path d="M0 0L0 4" className={className} transform="translate(5.355 7.065)" />
      <path d="M0 0L0 4" className={className} transform="translate(8.63 7.065)" />
    </g>
  </svg>
);
