/**
 *
 * Asynchronously loads the component for VideoPlayer
 *
 */

import React from 'react';
import loadable from 'utils/loadable';
import AppPreloader from 'components/AppPreloader';

// eslint-disable-next-line import/no-cycle
export default loadable(() => import('./index'), {
  fallback: <AppPreloader />,
});
