/*
 *
 * SubMuscle actions
 *
 */

import * as ActionTypes from './constants';

export function getDetailSubmuscle(muscleId) {
  return {
    type: ActionTypes.GET_SUBMUSCLE_DETAILS,
    muscleId,
  };
}
