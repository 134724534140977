import React from 'react';
import StackTrace from 'stacktrace-js';
import axios from 'utils/axiosConfig';

const buttonStyle = {
  color: '#000',
  border: '2px solid #fab923',
  display: 'inline-block', // inline-block
  padding: '0 20px',
  minWidth: 100,
  background: '#fab923',
  textAlign: 'center',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  fontWeight: 600,
  lineHeight: 2,
  whiteSpace: 'nowrap',
  borderRadius: 20,
};
const buttonStyleHover = {
  color: '#fab923',
  background: '#0000',
};

const errorsToReload = [
  // 'ChunkLoadError',
  'NotFoundError',
  'Minified React error',
  'The node to be removed is not a child of this node',
  "Cannot create a property 'day' on",
  'is not a function',
  'The operation is insecure',
  'Loading CSS chunk',
  "Cannot read property 'body_part' of undefined",
  "Cannot read properties of undefined (reading 'layers')",
  "Cannot read properties of null (reading 'exercises')",
  "Cannot read properties of null (reading 'replace')",
  "null is not an object (evaluating 'i.paid_status.type.replace')",
];

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      stack: null,
      user: null,
      buttonHover: false,
      reportStatus: 'nothing',
      language: 'en',
    };
  }

  componentDidMount() {
    if (this.props.withClicks && !window.clickErrorInstalled) {
      window.clickErrorInstalled = true;
      document.querySelector('body').addEventListener('click', e => {
        const isClickNotInErrorArea = !e.target.closest('#main-simple-bar');
        if (isClickNotInErrorArea && this.state.error !== null) {
          this.setState({ error: null, errorInfo: null, stack: null, user: null });
        }
      });
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.user && nextProps.user.data) {
      this.setState({ user: nextProps.user.data });
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);

    StackTrace.fromError(error).then(result => {
      this.setState({ stack: result.slice(0, 10) });
    });
    errorsToReload.forEach(errorString => {
      if (error.toString().match(errorString)) {
        document.location.reload();
      }
    });
    this.setState({ error, errorInfo, language: localStorage.getItem('language') || 'en' });
  }

  toggleButtonHover = () => {
    const { buttonHover } = this.state;
    this.setState({ buttonHover: !buttonHover });
  };

  handleSendReport = () => {
    const { stack, user, language } = this.state;
    const payload = {
      error: this.state.error.toString(),
      stack,
      userId: user ? user.id : 'Guest',
      userAgent: `${window.navigator.userAgent} (${language})`,
      // eslint-disable-next-line no-restricted-globals
      url: location.href,
      date: new Date().toLocaleString('ru-RU', { timeZone: 'Europe/Kiev' }),
    };
    this.setState({ reportStatus: 'Sending...' });
    axios.post('errorreport', { payload }).then(() => {
      this.setState({ reportStatus: 'Sent!' });
    });
  };

  render() {
    const { stack, user, buttonHover, reportStatus, language } = this.state;
    const isChuckError = this.state.error && !!this.state.error.toString().match(/chunk/i);
    if (this.state.errorInfo) {
      return (
        <div style={{ marginLeft: 16, zIndex: 99999 }}>
          <h1>Something went wrong.</h1>
          <p>
            <b>User ID:</b> {user ? user.id : 'Guest'}
          </p>
          <p>
            {/* eslint-disable-next-line no-restricted-globals */}
            <b>User Agent:</b> {window.navigator.userAgent} ({language})
          </p>
          <p>
            {/* eslint-disable-next-line no-restricted-globals */}
            <b>URL:</b> {location.href}
          </p>
          <p>
            <b>Date:</b> {new Date().toLocaleString()}
          </p>
          <h4 style={{ marginTop: 36 }}>
            {isChuckError
              ? `Dear user, we have noticed a connection problem on your end, please refresh your network connection, switch network or just try again later`
              : this.state.error.toString()}
          </h4>
          {stack && !isChuckError
            ? stack.map(stackFrame => (
                <p>
                  at {stackFrame.functionName} (<b>{stackFrame.fileName}</b> {stackFrame.lineNumber}:
                  {stackFrame.columnNumber})
                </p>
              ))
            : null}
          {isChuckError ? (
            <button
              type="button"
              style={buttonHover ? { ...buttonStyle, ...buttonStyleHover } : buttonStyle}
              onMouseEnter={this.toggleButtonHover}
              onMouseLeave={this.toggleButtonHover}
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload
            </button>
          ) : (
            <button
              type="button"
              disabled={isChuckError || reportStatus !== 'nothing'}
              style={buttonHover ? { ...buttonStyle, ...buttonStyleHover } : buttonStyle}
              onMouseEnter={this.toggleButtonHover}
              onMouseLeave={this.toggleButtonHover}
              onClick={this.handleSendReport}
            >
              {reportStatus === 'nothing' ? 'Send Error Report' : reportStatus}
            </button>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
