/**
 *
 * AppPreloader
 * -----------------
 * Note: For the container HTML, in which this component should be located,
 * it is necessary to specify the 'position' property with the value 'relative' in the styles
 *
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';

function AppPreloader({ classes, fullWidth }) {
  return (
    <div className={classes.wrap} style={fullWidth ? { width: '100%', left: 0 } : {}}>
      <div className={classes.box}>
        <CircularProgress size={140} thickness={1.6} classes={{ root: classes.root }} />
      </div>
    </div>
  );
}

AppPreloader.propTypes = {
  classes: PropTypes.object,
  fullWidth: PropTypes.bool,
};

const AppPreloaderStyled = withStyles(styles)(AppPreloader);

export default AppPreloaderStyled;
