/**
 *
 * ChangeForgetPassDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { intlShape, injectIntl } from 'react-intl';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Dialog from '@material-ui/core/Dialog';
import Input from '@material-ui/core/FilledInput';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import AppPreloader from '../AppPreloader';
import { fetchValidateEmail } from '../../utils/regExps';
import messages from './messages';
import authRegMessages from '../../containers/AuthReg/messages';
import styles from './styles';
import { makeSelectGlobal } from '../../containers/App/selectors';
import * as appActions from '../../containers/App/actions';
import * as authRegActions from '../../containers/AuthReg/actions';
import makeSelectAuthReg from '../../containers/AuthReg/selectors';

/* eslint-disable react/prefer-stateless-function */
export class ChangeForgetPassDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      resetCode: '',
      pass: '',
      confPass: '',
      showPass: false,
      showConfPass: false,
      submitFlag: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.global.openChangeForgetPassDialog) {
      this.setState({
        email: '',
        resetCode: '',
        pass: '',
        confPass: '',
        showPass: false,
        showConfPass: false,
        submitFlag: false,
      });
    }
  }

  render() {
    const {
      global: { openChangeForgetPassDialog, user },
      intl: { formatMessage },
      closeChangeForgetPassDialog,
      classes,
      handleAddEmail,
      handleReset,
      authReg,
    } = this.props;
    const { email, resetCode, pass, confPass, showPass, showConfPass, submitFlag } = this.state;
    return (
      <Dialog
        open={openChangeForgetPassDialog}
        onClose={closeChangeForgetPassDialog}
        classes={{ paper: classes.dialogPaper }}
      >
        {(authReg.loadingReset || authReg.loadingResetPass) && <AppPreloader fullWidth />}
        <h3 className={classes.dialogTitle}>{formatMessage(messages.title)}</h3>
        {!authReg.loadedReset && !authReg.dataResetPass && !user.data && (
          <Input
            name="email"
            error={submitFlag && (email === '' || !fetchValidateEmail(email))}
            autoComplete="off"
            placeholder={formatMessage(authRegMessages.formEmail)}
            onChange={e => this.setState({ email: e.target.value.trim() })}
            classes={{ root: classes.root, input: classes.input }}
          />
        )}
        {authReg.loadedReset && !authReg.dataResetPass && authReg.dataReset && (
          <>
            <p className={classes.noticeDscr}>{authReg.dataReset.message}</p>
            <Input
              name="resetCode"
              error={submitFlag && resetCode === ''}
              autoComplete="off"
              placeholder={formatMessage(messages.resetCode)}
              onChange={e => this.setState({ resetCode: e.target.value.trim() })}
              classes={{ root: classes.root, input: classes.input }}
            />
            <Input
              name="pass"
              error={submitFlag && pass === ''}
              autoComplete="new-password"
              type={showPass ? 'text' : 'password'}
              placeholder={formatMessage(messages.newPass)}
              onChange={e => this.setState({ pass: e.target.value.trim() })}
              classes={{ root: classes.root, input: classes.input }}
              endAdornment={
                <InputAdornment position="end" classes={{ root: classes.rootInputAdornment }}>
                  <IconButton
                    onClick={() => this.setState(state => ({ showPass: !state.showPass }))}
                    className={classes.iconButton}
                  >
                    {showPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Input
              name="confPass"
              autoComplete="off"
              error={submitFlag && (confPass === '' || pass !== confPass)}
              type={showConfPass ? 'text' : 'password'}
              placeholder={formatMessage(messages.confirmNewPass)}
              onChange={e => this.setState({ confPass: e.target.value.trim() })}
              classes={{ root: classes.root, input: classes.input }}
              endAdornment={
                <InputAdornment position="end" classes={{ root: classes.rootInputAdornment }}>
                  <IconButton
                    onClick={() => this.setState(state => ({ showConfPass: !state.showConfPass }))}
                    className={classes.iconButton}
                  >
                    {showConfPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </>
        )}
        {authReg.dataResetPass && <p className={classes.noticeDscr}>{authReg.dataResetPass.message}</p>}
        {!authReg.dataResetPass && !authReg.loadingReset && (
          <button
            type="button"
            className={classes.submitBtn}
            onClick={() => {
              this.setState({ submitFlag: true });
              if (!authReg.loadedReset && email !== '' && fetchValidateEmail(email)) {
                handleAddEmail(email);
                this.setState({ submitFlag: false });
              } else if (
                authReg.loadedReset &&
                resetCode !== '' &&
                pass !== '' &&
                confPass !== '' &&
                pass === confPass
              ) {
                if (email !== '') handleReset({ email, resetCode, pass, confPass });
                else handleReset({ email: user.data.email, resetCode, pass, confPass });
                this.setState({ submitFlag: false });
              }
            }}
          >
            {formatMessage(messages.submitBtn)}
          </button>
        )}
      </Dialog>
    );
  }
}

ChangeForgetPassDialog.propTypes = {
  closeChangeForgetPassDialog: PropTypes.func,
  handleAddEmail: PropTypes.func,
  handleReset: PropTypes.func,
  intl: intlShape.isRequired,
  global: PropTypes.object,
  authReg: PropTypes.object,
  classes: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  global: makeSelectGlobal(),
  authReg: makeSelectAuthReg(),
});

function mapDispatchToProps(dispatch) {
  return {
    closeChangeForgetPassDialog: bindActionCreators(appActions.closeChangeForgetPassDialog, dispatch),
    handleAddEmail: bindActionCreators(authRegActions.handleAddEmail, dispatch),
    handleReset: bindActionCreators(authRegActions.handleReset, dispatch),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const ChangeForgetPassDialogIntl = injectIntl(ChangeForgetPassDialog);
const ChangeForgetPassDialogStyled = withStyles(styles)(ChangeForgetPassDialogIntl);

export default compose(withConnect)(ChangeForgetPassDialogStyled);
