/**
 *
 * ConfirmSmartLinkPopup
 *
 */

import React, { memo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SmartLink from 'components/SmartLink/Loadable';
import PlansDialog from 'components/PlansDialog/Loadable';
import styles from './styles';

const ConfirmSmartLinkPopup = ({
  open,
  classes,
  onCancel,
  onConfirm = () => {},
  cancelName,
  confirmName,
  title = '',
  children,
  to,
  disabled = false,
  closeIcon = false,
}) => {
  const cancelClasses = classnames(classes.btn, classes.cancelPopupBtn);
  const confirmBtnClasses = classnames(classes.btn, { [classes.btnDisabled]: disabled });

  return (
    <PlansDialog open={open} handleClose={onCancel} title={title} closeIcon={closeIcon}>
      <div className={classes.body}>
        {children}
        <div className={classes.btnBox}>
          <button type="button" onClick={onCancel} className={cancelClasses}>
            {cancelName}
          </button>
          {to ? (
            <SmartLink to={to} onClick={onConfirm} className={confirmBtnClasses}>
              {confirmName}
            </SmartLink>
          ) : (
            <button type="button" onClick={onConfirm} className={confirmBtnClasses}>
              {confirmName}
            </button>
          )}
        </div>
      </div>
    </PlansDialog>
  );
};

ConfirmSmartLinkPopup.propTypes = {
  closeIcon: PropTypes.bool,
  open: PropTypes.bool,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  cancelName: PropTypes.string,
  confirmName: PropTypes.string,
  to: PropTypes.string,
  classes: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

const ConfirmSmartLinkPopupStyled = withStyles(styles)(ConfirmSmartLinkPopup);

export default memo(ConfirmSmartLinkPopupStyled);
