/*
 * MyFolders Messages
 *
 * This contains all the text for the MyFolders container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MyFolders';

export default defineMessages({
  metaTitle: {
    id: `${scope}.metaTitle`,
    defaultMessage: 'My Folders',
  },
  metaDescription: {
    id: `${scope}.metaDescription`,
    defaultMessage: 'Description of My Folders',
  },
  addFolder: {
    id: `${scope}.addFolder`,
    defaultMessage: 'Add new folder',
  },
  add: {
    id: `${scope}.Add`,
    defaultMessage: 'Add',
  },
  renameTitle: {
    id: `${scope}.renameTitle`,
    defaultMessage: 'Rename this folder',
  },
  rename: {
    id: `${scope}.rename`,
    defaultMessage: 'Rename folder',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete',
  },
  deleteTitle: {
    id: `${scope}.deleteTitle`,
    defaultMessage: 'Are you sure you want to delete this folder?',
  },
  error: {
    id: `${scope}.error`,
    defaultMessage: 'Error receiving data',
  },
  create_first_folder: {
    id: `${scope}.create_first_folder`,
    defaultMessage: 'Create your first folder',
  },
  arrange_your_favorite_videos: {
    id: `${scope}.arrange_your_favorite_videos`,
    defaultMessage: 'Arrange your favorite videos/exercises in your own customized folders',
  },
  create_new_folder: {
    id: `${scope}.create_new_folder`,
    defaultMessage: 'Create a new folder',
  },
  done: {
    id: `${scope}.done`,
    defaultMessage: 'Done',
  },
  deleteFolder: {
    id: `${scope}.deleteFolder`,
    defaultMessage: 'Delete folder',
  },
  deleteFolderName: {
    id: `${scope}.deleteFolderName`,
    defaultMessage: 'Are you sure you want to delete "{name}"?',
  },
});
