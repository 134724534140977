/*
 *
 * SimpleVideoPage actions
 *
 */

import * as ActionTypes from './constants';

export function getDetailedVideo(id, section, workout_item_id) {
  return {
    type: ActionTypes.GET_VIDEO_BY_ID,
    id,
    section,
    workout_item_id,
  };
}

export function clearDetailedVideo() {
  return {
    type: ActionTypes.CLEAR_VIDEO_BY_ID,
  };
}
