/*
 *
 * MyFolders actions
 *
 */

import * as ActionTypes from './constants';

export function cleanErrorAction() {
  return {
    type: ActionTypes.CLEAN_ERROR,
  };
}
export function getAllFolders() {
  return {
    type: ActionTypes.GET_ALL_FOLDERS,
  };
}
export function addFolder(title, chapter, section, itemId, assetId) {
  return {
    type: ActionTypes.ADD_NEW_FOLDER,
    title,
    chapter,
    section,
    itemId,
    assetId,
  };
}
// eslint-disable-next-line camelcase
export function addToFolder({ id, chapter, item_id, asset_id, section }) {
  return {
    type: ActionTypes.ADD_TO_FOLDER,
    id,
    chapter,
    item_id,
    asset_id,
    section,
  };
}
export function deleteFolder(id) {
  return {
    type: ActionTypes.DELETE_FOLDER,
    id,
  };
}
export function renameFolder(id, title) {
  return {
    type: ActionTypes.RENAME_FOLDER,
    id,
    title,
  };
}
