/*
 * FavoriteFolderBtns Messages
 *
 * This contains all the text for the FavoriteFolderBtns container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.FavoriteFolderBtns';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the FavoriteFolderBtns container!',
  },
  lockPopupDescription: {
    id: `${scope}.lockPopupDescription`,
    defaultMessage: 'Dear guest, in order to add videos to Favorites or Folders, you must Sign-Up first.',
  },
  lockSharePopupDescription: {
    id: `${scope}.lockSharePopupDescription`,
    defaultMessage: 'Dear user, sharing videos is only available for registered users.',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Add to folder or create new folder',
  },
  placeholder: {
    id: `${scope}.placeholder`,
    defaultMessage: 'Write new folder name',
  },
  noticeMsg: {
    id: `${scope}.noticeMsg`,
    defaultMessage: 'This video was already saved in this folder',
  },
  errorAddFolder: {
    id: `${scope}.errorAddFolder`,
    defaultMessage: 'An error occured while creating a folder',
  },
  shareTitleButton: {
    id: `${scope}.shareTitleButton`,
    defaultMessage: 'Copy Header to Clipboard',
  },
  shareTitleCopied: {
    id: `${scope}.shareTitleCopied`,
    defaultMessage: 'Header successfully copied!',
  },
  shareDescriptionButton: {
    id: `${scope}.shareDescriptionButton`,
    defaultMessage: 'Copy text to Clipboard',
  },
  shareDescriptionCopied: {
    id: `${scope}.shareDescriptionCopied`,
    defaultMessage: 'Text successfully copied!',
  },
  shareTitle: {
    id: `${scope}.shareTitle`,
    defaultMessage: 'A friend shared a great Muscle & Motion video with you!',
  },
  shareDescription: {
    id: `${scope}.shareDescription`,
    defaultMessage: 'Hey, here is a link for a great video from {appName}. Check it out:\n{deeplink}',
  },
});
