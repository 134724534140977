/*
 * AuthRegComponent Messages
 *
 * This contains all the text for the AuthRegComponent component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.AuthRegComponent';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the AuthRegComponent component!',
  },
  newHere: {
    id: `${scope}.newHere`,
    defaultMessage: 'New here',
  },
  forgotBtn: {
    id: `${scope}.forgotBtn`,
    defaultMessage: 'Change/Forgot Password?',
  },
  haveAccount: {
    id: `${scope}.haveAccount`,
    defaultMessage: 'Have an account',
  },
  startButton: {
    id: `${scope}.startButton`,
    defaultMessage: 'Sign up for free',
  },
  startDailyYogaButton: {
    id: `${scope}.startDailyYogaButton`,
    defaultMessage: 'Create your account',
  },
  loginButton: {
    id: `${scope}.loginButton`,
    defaultMessage: 'Login',
  },
  guestButton: {
    id: `${scope}.guestButton`,
    defaultMessage: 'Enter as a guest',
  },
  facebookButton: {
    id: `${scope}.facebookButton`,
    defaultMessage: 'Continue with Facebook',
  },
  googleButton: {
    id: `${scope}.googleButton`,
    defaultMessage: 'Continue With Google',
  },
});
