/**
 *
 * ConfirmPopup
 *
 */

import React, { memo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import PlansDialog from 'components/PlansDialog/Loadable';
import styles from './styles';

const ConfirmPopup = ({
  open,
  classes,
  dialogRootClass,
  btnBoxClass,
  cancelClass,
  onCancel,
  onConfirm,
  cancelName,
  confirmName,
  title,
  children,
  disabled = false,
  closeIcon = false,
}) => {
  const cancelClasses = classnames(classes.btn, classes.cancelPopupBtn, { [cancelClass]: cancelClass });
  const confirmBtnClasses = classnames(classes.btn, { [classes.btnDisabled]: disabled });
  const btnBoxClasses = classnames(classes.btnBox, { [btnBoxClass]: btnBoxClass });

  return (
    <PlansDialog open={open} handleClose={onCancel} title={title} closeIcon={closeIcon} className={dialogRootClass}>
      <div className={classes.body}>
        {children}
        <div className={btnBoxClasses}>
          <button type="button" onClick={onCancel} className={cancelClasses}>
            {cancelName}
          </button>
          <button type="button" onClick={onConfirm} disabled={disabled} className={confirmBtnClasses}>
            {confirmName}
          </button>
        </div>
      </div>
    </PlansDialog>
  );
};

ConfirmPopup.propTypes = {
  closeIcon: PropTypes.bool,
  open: PropTypes.bool,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  cancelName: PropTypes.string,
  confirmName: PropTypes.string,
  classes: PropTypes.object,
  dialogRootClass: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

const ConfirmPopupStyled = withStyles(styles)(ConfirmPopup);

export default memo(ConfirmPopupStyled);
