export default theme => ({
  text: {
    ...theme.confirmPopup.notice,
  },
  foldersBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 40,
    maxHeight: 345,
    ...theme.scrollbar,
  },
  folderItemBtn: {
    display: 'flex',
    border: 'none',
    background: 'none',
    margin: '0 0 22px 53px',
    marginBottom: 22,
    marginLeft: 53,
    fontSize: 20,
    textAlign: 'left',
  },
  folderItemText: {
    marginLeft: 10,
  },
  noticeDialogWrap: {
    position: 'fixed',
    fontSize: 18,
    padding: `${theme.spacing(2)}px ${theme.spacing(5.5)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.MMColors.deepMain,
    boxShadow: theme.shadows[5],
  },
  closeNoticeBtn: {
    position: 'absolute',
    top: theme.spacing(),
    right: theme.spacing(1.5),
    padding: 0,
    fontSize: 18,
    border: 'none',
    background: 'none',
  },
});
