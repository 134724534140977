/*
 * SubMuscle Messages
 *
 * This contains all the text for the SubMuscle container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.SubMuscle';

export default defineMessages({
  metaTitle: {
    id: `${scope}.metaTitle`,
    defaultMessage: 'Sub Muscle',
  },
  metaDescription: {
    id: `${scope}.metaDescription`,
    defaultMessage: 'Description of Sub Muscle',
  },
  strength: {
    id: `${scope}.strength`,
    defaultMessage: 'Strength',
  },
  stretch: {
    id: `${scope}.stretch`,
    defaultMessage: 'Stretch',
  },
  moreInfo: {
    id: `${scope}.moreInfo`,
    defaultMessage: 'More info',
  },
  originInsertion: {
    id: `${scope}.originInsertion`,
    defaultMessage: 'Origin & Insertion',
  },
  chooseMuscle: {
    id: `${scope}.chooseMuscle`,
    defaultMessage: 'Choose muscle:',
  },
  chooseAction: {
    id: `${scope}.chooseAction`,
    defaultMessage: 'Choose action:',
  },
  noDataMsg: {
    id: `${scope}.noDataMsg`,
    defaultMessage:
      'Sorry, seems we do not have the requested information, but we are always improving and enriching our data base.',
  },
  origin: {
    id: `${scope}.origin`,
    defaultMessage: 'Origin',
  },
  insertion: {
    id: `${scope}.insertion`,
    defaultMessage: 'Insertion',
  },
});
