export default theme => ({
  dialogPaper: {
    background: theme.palette.MMColors.main,
    padding: theme.spacing(2.5),
  },
  dialogTitle: {
    margin: `0 0 ${theme.spacing()}px`,
    fontSize: 24,
    color: theme.palette.MMColors.fox,
    textTransform: 'uppercase',
  },
  noticeDscr: {
    fontSize: 20,
  },
  root: {
    margin: `${theme.spacing()}px 0`,
    backgroundColor: '#525b6d !important',
    borderRadius: 0,
    'label + &': {
      marginTop: theme.spacing(2.5),
    },
  },
  input: {
    border: 'none',
    fontSize: 16,
    minWidth: 400,
    position: 'relative',
    zIndex: 2,
    color: '#d7e5ff',
    padding: '12px 12px',
  },
  submitBtn: {
    margin: `${theme.spacing(2)}px auto 0`,
    textTransform: 'uppercase',
    display: 'inline-block',
    padding: `0 ${theme.spacing(2.5)}px`,
    color: '#000000',
    fontWeight: 600,
    ...theme.action.button(theme.palette.MMColors.fox, theme.transitions.easing.easeInOut),
  },
  iconButton: {
    zIndex: 3,
  },
  rootInputAdornment: {
    marginLeft: -63,
  },
});
