/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from 'immer';
import { CURRENT_VERTICAL } from 'verticalConfigs';
import * as ActionTypes from './constants';
import prepareFirebaseValuesWithTranlsation from './prepareFirebaseValuesWithTranlsation';

export const initialState = {
  openChangeForgetPassDialog: false,
  vertical: CURRENT_VERTICAL || 'strength',
  language: 'en',
  user: { loaded: false, loading: false, data: null, error: null },
  notification: { open: false, text: '', variant: '' },
  submuscles: { loading: false, loaded: false, data: [], error: null },
  search: { loading: false, loaded: false, data: {}, error: null, value: null },
  menuItem: { loaded: false, loading: false, data: null, error: null },
  subscriptionsUrls: { loaded: false, loading: false, data: null, error: null },
  pricing: { loaded: false, loading: false, data: null, error: null },
  firebaseDB: { loaded: false, loading: false, data: null, enData: null, error: null },
  webUpgradeTitle: { loaded: false, loading: false, data: null, error: null },
  isAsideMenuHidden: false,
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.HANDLE_CHANGE_FORGET_PASS:
        draft.openChangeForgetPassDialog = true;
        break;
      case ActionTypes.CLOSE_CHANGE_FORGET_PASS:
        draft.openChangeForgetPassDialog = false;
        break;
      case ActionTypes.GET_USER_DATA:
        draft.user.loading = true;
        break;
      case ActionTypes.GET_USER_DATA_SUCCESS:
        draft.user.loading = false;
        draft.user.loaded = true;
        draft.user.data = action.data;
        break;
      case ActionTypes.GET_USER_DATA_FAIL:
        draft.user.loading = false;
        draft.user.data = null;
        draft.user.error = action.error;
        break;
      case ActionTypes.GET_FIREBASE:
        draft.firebaseDB.loading = true;
        break;
      case ActionTypes.GET_FIREBASE_SUCCESS:
        draft.firebaseDB.loading = false;
        draft.firebaseDB.loaded = true;
        draft.firebaseDB.data = prepareFirebaseValuesWithTranlsation(action.data);
        draft.firebaseDB.enData = action.data;
        break;
      case ActionTypes.GET_FIREBASE_UPDATE_LANGUAGE:
        draft.firebaseDB.data = prepareFirebaseValuesWithTranlsation(draft.firebaseDB.enData);
        break;
      case ActionTypes.GET_FIREBASE_FAIL:
        draft.firebaseDB.loading = false;
        draft.firebaseDB.data = null;
        draft.firebaseDB.enData = null;
        draft.firebaseDB.error = action.error;
        break;
      case ActionTypes.GET_SUBMUSCLES:
        draft.submuscles.loading = true;
        break;
      case ActionTypes.GET_SUBMUSCLES_SUCCESS:
        draft.submuscles.loading = false;
        draft.submuscles.loaded = true;
        draft.submuscles.data = action.data;
        break;
      case ActionTypes.GET_SUBMUSCLES_FAIL:
        draft.submuscles.loading = false;
        draft.submuscles.data = null;
        draft.submuscles.error = action.error;
        break;
      case ActionTypes.USER_DATA_CLEAN:
        draft.user.loading = false;
        draft.user.data = null;
        break;
      case ActionTypes.OPEN_NOTIFICATION:
        draft.notification.open = true;
        draft.notification.text = action.text;
        draft.notification.variant = 'success';
        break;
      case ActionTypes.OPEN_MAIN_NOTIFICATION:
        draft.notification.open = true;
        draft.notification.text = action.text;
        draft.notification.variant = 'main';
        break;
      case ActionTypes.OPEN_ERROR_NOTIFICATION:
        draft.notification.open = true;
        draft.notification.text = action.text;
        draft.notification.variant = 'error';
        break;
      case ActionTypes.CLOSE_NOTIFICATION:
        draft.notification.open = false;
        break;
      case ActionTypes.GET_SEARCH:
        draft.search.loading = true;
        draft.search.error = null;
        break;
      case ActionTypes.GET_SEARCH_SUCCESS:
        draft.search.loading = false;
        draft.search.data = action.data;
        draft.search.value = action.value;
        break;
      case ActionTypes.GET_SEARCH_FAIL:
        draft.search.loading = false;
        draft.search.data = {};
        draft.search.error = action.error;
        break;
      case ActionTypes.CLEAR_SEARCH:
        draft.search.data = {};
        draft.search.value = null;
        break;
      case ActionTypes.GET_MENU_ITEM_FOR_VIDEO:
        draft.menuItem.loading = true;
        draft.menuItem.loaded = false;
        draft.menuItem.data = null;
        break;
      case ActionTypes.GET_MENU_ITEM_FOR_VIDEO_SUCCESS:
        draft.menuItem.loading = false;
        draft.menuItem.loaded = true;
        draft.menuItem.data = action.data;
        break;
      case ActionTypes.GET_MENU_ITEM_FOR_VIDEO_FAIL:
        draft.menuItem.loading = false;
        draft.menuItem.loaded = false;
        draft.menuItem.data = null;
        draft.menuItem.error = action.error;
        break;
      case ActionTypes.SET_ASIDE_MENU_HIDDEN:
        draft.isAsideMenuHidden = action.value;
    }
  });

export default appReducer;
