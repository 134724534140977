/*
 *
 * SubMuscle constants
 *
 */

export const GET_SUBMUSCLE_DETAILS = 'app/SubMuscle/GET_SUBMUSCLE_DETAILS';
export const GET_SUBMUSCLE_DETAILS_SUCCESS = 'app/SubMuscle/GET_SUBMUSCLE_DETAILS_SUCCESS';
export const GET_SUBMUSCLE_DETAILS_FAIL = 'app/SubMuscle/GET_SUBMUSCLE_DETAILS_FAIL';

export const ASSET_ID_STRETCHING = 'stretching';
export const ASSET_ID_STRENGTHENING = 'strengthening';
export const ASSET_ID_ORIGIN = 'origin';
