/*
 *
 * Theory actions
 *
 */

import * as ActionTypes from './constants';

export function getTheoryCategoriesAction() {
  return {
    type: ActionTypes.GET_THEORY_CATEGORIES,
  };
}
export function getAsanasCategoriesAction() {
  return {
    type: ActionTypes.GET_ASANAS_CATEGORIES,
  };
}
export function getMyofascialCategoryAction() {
  return {
    type: ActionTypes.GET_MYOFASCIAL_CATEGORY,
  };
}
export function getPosturalTestsAction() {
  return {
    type: ActionTypes.GET_POSTURAL_TESTS,
  };
}
export function getPosturalCategoriesAction() {
  return {
    type: ActionTypes.GET_POSTURAL_CATEGORIES,
  };
}
export function getTherapeuticVideos() {
  return {
    type: ActionTypes.GET_TERAPETIC_CATEGORIES,
  };
}
export function getTheorySubCategoriesAction(subId, section) {
  return {
    type: ActionTypes.GET_THEORY_SUBCATEGORIES,
    subId,
    section,
  };
}
export function getPosturalDisordersCategories() {
  return {
    type: ActionTypes.GET_POSTURAL_DISORDERS_CATEGORIES,
  };
}
export function getPostureYogaTheoryCategories() {
  return {
    type: ActionTypes.GET_POSTURE_YOGA_THEORY_CATEGORIES,
  };
}
