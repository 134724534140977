/*
 * AuthReg Messages
 *
 * This contains all the text for the AuthReg container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.AuthReg';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the AuthReg container!',
  },
  metaTitle: {
    id: `${scope}.metaTitle`,
    defaultMessage: 'Login / Registration',
  },
  metaDescription: {
    id: `${scope}.metaDescription`,
    defaultMessage: 'Description of AuthReg',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: "Hey you, don't be a stranger!",
  },
  subtitle: {
    id: `${scope}.subtitle`,
    defaultMessage: 'Sign up to get access to all of our unique content and expand your knowledge',
  },
  dailyYogaSubtitle: {
    id: `${scope}.dailyYogaSubtitle`,
    defaultMessage: 'Create Your M&M Yoga Account',
  },
  termConditions: {
    id: `${scope}.termConditions`,
    defaultMessage: 'I accept the Terms & Conditions',
  },
  formName: {
    id: `${scope}.formName`,
    defaultMessage: 'Name',
  },
  formEmail: {
    id: `${scope}.formEmail`,
    defaultMessage: 'Email',
  },
  formPhone: {
    id: `${scope}.formPhone`,
    defaultMessage: 'Phone',
  },
  formPassword: {
    id: `${scope}.formPassword`,
    defaultMessage: 'Password',
  },
  formConfirmPassword: {
    id: `${scope}.formConfirmPassword`,
    defaultMessage: 'Confirm password',
  },
  formCode: {
    id: `${scope}.formCode`,
    defaultMessage: 'Enter the code here!',
  },
  dailyYogaSuccessTitle: {
    id: `${scope}.dailyYogaSuccessTitle`,
    defaultMessage: 'Hi! You can now login with the email and password you entered.',
  },
  dailyYogaSuccessSubtitle: {
    id: `${scope}.dailyYogaSuccessSubtitle`,
    defaultMessage:
      'The available assets for your use are in the email you originally received or on our website under the',
  },
  dailyYogaSuccessLink: {
    id: `${scope}.dailyYogaSuccessLink`,
    defaultMessage: 'Yoga Login Page',
  },
  emptyField: {
    id: `${scope}.emptyField`,
    defaultMessage: 'Please enter %field%, then try to create your account again',
  },
  passwordsDoesntMatch: {
    id: `${scope}.passwordsDoesntMatch`,
    defaultMessage: 'The passwords do not match, please check and try again',
  },
});
