import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the authReg state domain
 */

const selectAuthRegDomain = state => state.authReg || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by AuthReg
 * Default selector used by AuthReg
 */

const makeSelectAuthReg = () =>
  createSelector(
    selectAuthRegDomain,
    substate => substate,
  );

export default makeSelectAuthReg;
export { selectAuthRegDomain };
