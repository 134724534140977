/*
 * Azlist Messages
 *
 * This contains all the text for the Azlist container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Azlist';

export default defineMessages({
  metaTitle: {
    id: `${scope}.metaTitle`,
    defaultMessage: 'Azlist',
  },
  metaDescription: {
    id: `${scope}.metaDescription`,
    defaultMessage: 'Description of Azlist',
  },
  all: {
    id: `${scope}.all`,
    defaultMessage: 'All',
  },
  azList: {
    id: `${scope}.azList`,
    defaultMessage: 'A-Z list',
  },
  mmAZList: {
    id: `${scope}.mmAZList`,
    defaultMessage: 'Muscle & Motion A-Z list',
  },
});
