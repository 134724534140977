/*
 *
 * MyFolders reducer
 *
 */
import produce from 'immer';
import * as ActionTypes from './constants';

export const initialState = {
  folders: {
    loaded: false,
    loading: false,
    data: [],
    error: null,
  },
  loading: false,
  loaded: true,
};

/* eslint-disable default-case, no-param-reassign */
const myFoldersReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.CLEAN_ERROR:
        draft.folders.error = null;
        break;
      case ActionTypes.GET_ALL_FOLDERS:
        draft.folders.loading = true;
        draft.folders.loaded = false;
        break;
      case ActionTypes.GET_ALL_FOLDERS_SUCCESS:
        draft.folders.loading = false;
        draft.folders.loaded = true;
        draft.folders.data = action.data;
        break;
      case ActionTypes.GET_ALL_FOLDERS_FAIL:
        draft.folders.loading = false;
        draft.folders.loaded = false;
        draft.folders.error = action.data;
        break;
      case ActionTypes.ADD_TO_FOLDER:
        draft.added = false;
        draft.loading = true;
        draft.loaded = false;
        break;
      case ActionTypes.ADD_TO_FOLDER_SUCCESS:
        draft.loading = false;
        draft.loaded = true;
        draft.added = true;
        break;
      case ActionTypes.ADD_TO_FOLDER_FAIL:
        draft.added = false;
        draft.loading = false;
        draft.loaded = false;
        draft.error = action.data;
        break;
      case ActionTypes.ADD_NEW_FOLDER:
        draft.loading = true;
        draft.loaded = false;
        draft.data = state.data;
        break;
      case ActionTypes.ADD_NEW_FOLDER_SUCCESS:
        draft.loading = false;
        draft.loaded = true;
        draft.data = action.data;
        break;
      case ActionTypes.ADD_NEW_FOLDER_FAIL:
        draft.loading = false;
        draft.loaded = false;
        draft.error = action.data;
        break;
      case ActionTypes.DELETE_FOLDER:
        draft.loading = false;
        draft.loaded = true;
        break;
      case ActionTypes.DELETE_FOLDER_SUCCESS:
        draft.loading = false;
        draft.loaded = false;
        draft.error = action.data;
        break;
      case ActionTypes.DELETE_FOLDER_FAIL:
        draft.loading = true;
        draft.loaded = false;
        break;
    }
  });

export default myFoldersReducer;
