const LOCAL_STRENGTH_PORT = 'http://localhost:3000';
const LOCAL_POSTURE_PORT = 'http://localhost:3010';
const LOCAL_YOGA_PORT = 'http://localhost:3020';
const LOCAL_ANATOMY_PORT = 'http://localhost:3030';

const QA_STRENGTH_DOMAIN = 'https://qa.strength.muscleandmotion.com';
const QA_POSTURE_DOMAIN = 'https://qa.posture.muscleandmotion.com';
const QA_YOGA_DOMAIN = 'https://qa.yoga.muscleandmotion.com';
const QA_ANATOMY_DOMAIN = 'https://qa.anatomy.muscleandmotion.com';

const PROD_STRENGTH_DOMAIN = 'https://app.strength.muscleandmotion.com';
const PROD_POSTURE_DOMAIN = 'https://app.posture.muscleandmotion.com';
const PROD_YOGA_DOMAIN = 'https://app.yoga.muscleandmotion.com';
const PROD_ANATOMY_DOMAIN = 'https://app.anatomy.muscleandmotion.com';

const QA_STRENGTH_DOMAIN_ENCRYPTED = 'https%3A%2F%2Fqa.strength.muscleandmotion.com';
const QA_POSTURE_DOMAIN_ENCRYPTED = 'https%3A%2F%2Fqa.posture.muscleandmotion.com';
const QA_YOGA_DOMAIN_ENCRYPTED = 'https%3A%2F%2Fqa.yoga.muscleandmotion.com';
const QA_ANATOMY_DOMAIN_ENCRYPTED = 'https%3A%2F%2Fqa.anatomy.muscleandmotion.com';

const PROD_STRENGTH_DOMAIN_ENCRYPTED = 'https%3A%2F%2Fapp.strength.muscleandmotion.com';
const PROD_POSTURE_DOMAIN_ENCRYPTED = 'https%3A%2F%2Fapp.posture.muscleandmotion.com';
const PROD_YOGA_DOMAIN_ENCRYPTED = 'https%3A%2F%2Fapp.yoga.muscleandmotion.com';
const PROD_ANATOMY_DOMAIN_ENCRYPTED = 'https%3A%2F%2Fapp.anatomy.muscleandmotion.com';

const PROD_STRENGTH_BLUESNAP_KEY = 'QVBJXzE1MTExODI2NjM5NzIxMjI5NDU4MDc1OmpSczI0dXR6TG1OemNEUw==';
const PROD_POSTURE_BLUESNAP_KEY = 'QVBJXzE1NjIwNzkwNzg1MzAxMTA5NTU4MTc6Z3BFV3hQS0RpQTkzZTQ5';
const PROD_YOGA_BLUESNAP_KEY = 'QVBJXzE1NjIwNzkwNzg1MzAxMTA5NTU4MTc6Z3BFV3hQS0RpQTkzZTQ5';
const PROD_ANATOMY_BLUESNAP_KEY = 'QVBJXzE1MTExODI2NjM5NzIxMjI5NDU4MDc1OmpSczI0dXR6TG1OemNEUw==';

const QA_STRENGTH_BLUESNAP_KEY = 'QVBJXzE1NjIxMzc5MTA5NzE4NzAyNjc0NDpDd3pFQmc4RUplaGlLSmg=';
const QA_POSTURE_BLUESNAP_KEY = 'QVBJXzE1NjIxMzc5MTA5NzE4NzAyNjc0NDpDd3pFQmc4RUplaGlLSmg=';
const QA_YOGA_BLUESNAP_KEY = 'QVBJXzE1NjIxMzc5MTA5NzE4NzAyNjc0NDpDd3pFQmc4RUplaGlLSmg=';
const QA_ANATOMY_BLUESNAP_KEY = 'QVBJXzE1NjIxMzc5MTA5NzE4NzAyNjc0NDpDd3pFQmc4RUplaGlLSmg=';

const GOOGLE_LOGIN_STRENGTH = '624522472295-l28ami89jhfj194486v0q0mflv0fipd2.apps.googleusercontent.com';
const GOOGLE_LOGIN_YOGA = '901235019960-sapm739bs4u0cc7rif7n63vneoivbc49.apps.googleusercontent.com';
const GOOGLE_LOGIN_POSTURE = '283698943242-jrnv6m113ob1976bhi9pnjglnuum5h24.apps.googleusercontent.com';
const GOOGLE_LOGIN_ANATOMY = '627601699529-1kqaevl32ejagku5ce3ajvskhcaa2rbk.apps.googleusercontent.com';

const FACEBOOK_LOGIN_STRENGTH = '349647728935067';
const FACEBOOK_LOGIN_YOGA = '310529032980404';
const FACEBOOK_LOGIN_POSTURE = '2308368369392214';
const FACEBOOK_LOGIN_ANATOMY = '1790301654555702';

const QA_OR_PROD_STRENGTH_DOMAIN = process.env.REACT_APP_SERVER === 'qa' ? QA_STRENGTH_DOMAIN : PROD_STRENGTH_DOMAIN;

export const STRENGTH_DOMAIN =
  process.env.REACT_APP_SERVER === 'development' ? LOCAL_STRENGTH_PORT : QA_OR_PROD_STRENGTH_DOMAIN;

const QA_OR_PROD_POSTORE_DOMAIN = process.env.REACT_APP_SERVER === 'qa' ? QA_POSTURE_DOMAIN : PROD_POSTURE_DOMAIN;

export const POSTURE_DOMAIN =
  process.env.REACT_APP_SERVER === 'development' ? LOCAL_POSTURE_PORT : QA_OR_PROD_POSTORE_DOMAIN;

const QA_OR_PROD_YOGA_DOMAIN = process.env.REACT_APP_SERVER === 'qa' ? QA_YOGA_DOMAIN : PROD_YOGA_DOMAIN;

export const YOGA_DOMAIN = process.env.REACT_APP_SERVER === 'development' ? LOCAL_YOGA_PORT : QA_OR_PROD_YOGA_DOMAIN;

const QA_OR_PROD_ANATOMY_DOMAIN = process.env.REACT_APP_SERVER === 'qa' ? QA_ANATOMY_DOMAIN : PROD_ANATOMY_DOMAIN;

export const ANATOMY_DOMAIN =
  process.env.REACT_APP_SERVER === 'development' ? LOCAL_ANATOMY_PORT : QA_OR_PROD_ANATOMY_DOMAIN;

export const STRENGTH_DOMAIN_ENCRYPTED =
  process.env.REACT_APP_SERVER === 'development' ? QA_STRENGTH_DOMAIN_ENCRYPTED : PROD_STRENGTH_DOMAIN_ENCRYPTED;
export const POSTURE_DOMAIN_ENCRYPTED =
  process.env.REACT_APP_SERVER === 'development' ? QA_POSTURE_DOMAIN_ENCRYPTED : PROD_POSTURE_DOMAIN_ENCRYPTED;
export const YOGA_DOMAIN_ENCRYPTED =
  process.env.REACT_APP_SERVER === 'development' ? QA_YOGA_DOMAIN_ENCRYPTED : PROD_YOGA_DOMAIN_ENCRYPTED;
export const ANATOMY_DOMAIN_ENCRYPTED =
  process.env.REACT_APP_SERVER === 'development' ? QA_ANATOMY_DOMAIN_ENCRYPTED : PROD_ANATOMY_DOMAIN_ENCRYPTED;

export const IS_SALE_NOW = false;

export const FASTSPRING_SCRIPT = {
  src: 'https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.5/fastspring-builder.min.js',
  id: 'fsc-api',
  dataStorefront: `muscleandmotion${
    process.env.REACT_APP_ENV === 'production' ? '' : '.test'
  }.onfastspring.com/popup-muscleandmotion`,
};

const STRENGTH_FIREBASE = {
  apiKey: 'AIzaSyAZRYSmB9idC3F-VP3YWlpk1w92LXWKSjA',
  authDomain: 'strength-training-20317.firebaseapp.com',
  databaseURL: 'https://strength-training-20317.firebaseio.com',
  projectId: 'strength-training-20317',
  storageBucket: 'strength-training-20317.appspot.com',
  messagingSenderId: '624522472295',
  appId: '1:624522472295:web:02ddc11b92d0c0f2599dc2',
  measurementId: 'G-ZCSE5L01QW',
};
const YOGA_FIREBASE = {
  apiKey: 'AIzaSyAQF8ZDvVVrt13GPjl3DxbcivwySXIM61g',
  authDomain: 'yoga-683d5.firebaseapp.com',
  databaseURL: 'https://yoga-683d5.firebaseio.com',
  projectId: 'yoga-683d5',
  storageBucket: 'yoga-683d5.appspot.com',
  messagingSenderId: '901235019960',
  appId: '1:901235019960:web:933b45065302612696244d',
  measurementId: 'G-LCWFWXS0B7',
};
const POSTURE_FIREBASE = {
  apiKey: 'AIzaSyBW4cmhThrlYTrkUNcN6eftqhDjTGT20_k',
  authDomain: 'posture-2bdca.firebaseapp.com',
  databaseURL: 'https://posture-2bdca.firebaseio.com',
  projectId: 'posture-2bdca',
  storageBucket: 'posture-2bdca.appspot.com',
  messagingSenderId: '283698943242',
  appId: '1:283698943242:web:84c092ac8547bdec1fd6f3',
  measurementId: 'G-9BSHCQTSX8',
};
const ANATOMY_FIREBASE = {
  apiKey: 'AIzaSyD3YJ3yd5I5U6zdhvcjxo2Bnc8D0_PvOLE',
  authDomain: 'anatomy-dad39.firebaseapp.com',
  databaseURL: 'https://anatomy-dad39.firebaseio.com',
  projectId: 'anatomy-dad39',
  storageBucket: 'anatomy-dad39.appspot.com',
  messagingSenderId: '627601699529',
  appId: '1:627601699529:web:576cceaf1c0c0eb51d1318',
  measurementId: 'G-LJGE6Z4C8R',
};
const COMMON_FACEBOOK = {
  cookie: true,
  xfbml: true,
  version: 'v7.0',
};

const STRENGTH_FACEBOOK = {
  ...COMMON_FACEBOOK,
  appId: '349647728935067',
};

const POSTURE_FACEBOOK = {
  ...COMMON_FACEBOOK,
  appId: '2308368369392214',
};

const YOGA_FACEBOOK = {
  ...COMMON_FACEBOOK,
  appId: '310529032980404',
};

const ANATOMY_FACEBOOK = {
  ...COMMON_FACEBOOK,
  appId: '1790301654555702',
};

const STRENGTH_ADJUST = {
  appToken: 'cvorpj6mlgcg',
  firstLaunch: '8sd77i',
  launch: 's1znsf',
  signUpEmail: '7zkgch',
  signUpApple: '5pd7fd',
  signUpFacebook: 'sumgka',
  signUpGoogle: 'd861js',
  loginGuest: 'ul4osc',
  loginEmail: 'spjzl4',
  loginApple: 'l665ns',
  loginFacebook: '11cfga',
  loginGoogle: 'joleec',
  pricingFromMenu: 'l98mhb',
  pricingFromLock: 'uc7fx4',
  pricingFromBannerPopupHeader: 'uye297',
};

const ANATOMY_ADJUST = {
  appToken: 'vlbc1ktattds',
  firstLaunch: '2z868v',
  launch: 'uq3dw5',
  signUpEmail: 'h2jkmm',
  signUpApple: '6eopzk',
  signUpFacebook: 'vw5qkz',
  signUpGoogle: 'nnhrui',
  loginGuest: 'ybe5qw',
  loginEmail: 'orny7f',
  loginApple: 'tnnzl0',
  loginFacebook: 'cymdgk',
  loginGoogle: 'mh88ft',
  pricingFromMenu: 'rtoslv',
  pricingFromLock: 't5qxgw',
  pricingFromBannerPopupHeader: 'p93qwr',
};

const POSTURE_ADJUST = {
  appToken: 't7giu114mtc0',
  firstLaunch: '6ohsc2',
  launch: 'px2h3t',
  signUpEmail: '1on0y4',
  signUpApple: 'l27o31',
  signUpFacebook: 'dajw02',
  signUpGoogle: 'qwa9bp',
  loginGuest: 'wxzzxc',
  loginEmail: 'fwscpw',
  loginApple: '66wfe2',
  loginFacebook: '7ce5eo',
  loginGoogle: '4bolju',
  pricingFromMenu: 'hm7g8s',
  pricingFromLock: 'z9rwil',
  pricingFromBannerPopupHeader: 'e1bpr1',
};

const YOGA_ADJUST = {
  appToken: 'td5beamqmwhs',
  firstLaunch: 'olut3c',
  launch: 'epfknm',
  signUpEmail: 'jpad75',
  signUpApple: 'stldn6',
  signUpFacebook: 'j4b7t1',
  signUpGoogle: 'fcghcc',
  loginGuest: 'liz587',
  loginEmail: '564gb0',
  loginApple: 'kmeeud',
  loginFacebook: 'c91vqv',
  loginGoogle: 'o7q3lo',
  pricingFromMenu: 'he8e91',
  pricingFromLock: 'us9x13',
  pricingFromBannerPopupHeader: '4wejie',
};

const mixpanelTokens = {
  strength_qa: '43c0225d4818bd2dd4ce1008febc4a08',
  posture_qa: '',
  yoga_qa: '',
  anatomy_qa: '',
  strength_prod: 'd4fe4b010b534ef276d52711ebdc20a9',
  posture_prod: '',
  yoga_prod: '',
  anatomy_prod: '',
};

export const getMixpanelToken = () => {
  return mixpanelTokens[`${CURRENT_VERTICAL}_${process.env.REACT_APP_ENV === 'production' ? 'prod' : 'qa'}`];
};

export const CURRENT_VERTICAL =
  ['strength', 'yoga', 'posture', 'anatomy'].find(vertical => window.location.host.includes(vertical)) ||
  process.env.REACT_APP_VERTICAL;

const STRENGTH_GA_MEASURMENT_ID = STRENGTH_FIREBASE.measurementId;
const POSTURE_GA_MEASURMENT_ID = POSTURE_FIREBASE.measurementId;
const YOGA_GA_MEASURMENT_ID = YOGA_FIREBASE.measurementId;
const ANATOMY_GA_MEASURMENT_ID = ANATOMY_FIREBASE.measurementId;

export const defineGAMeasurmentId = () => {
  let gaId;
  switch (CURRENT_VERTICAL) {
    case 'yoga':
      gaId = YOGA_GA_MEASURMENT_ID;
      break;
    case 'posture':
      gaId = POSTURE_GA_MEASURMENT_ID;
      break;
    case 'anatomy':
      gaId = ANATOMY_GA_MEASURMENT_ID;
      break;
    default:
      // Strength vertical
      gaId = STRENGTH_GA_MEASURMENT_ID;
  }
  return gaId;
};

export const defineAppName = () => {
  let vertical;
  switch (CURRENT_VERTICAL) {
    case 'yoga':
      vertical = 'Yoga';
      break;
    case 'posture':
      vertical = 'Posture';
      break;
    case 'anatomy':
      vertical = 'Anatomy';
      break;
    default:
      // Strength vertical
      vertical = 'Strength Training';
  }

  return vertical;
};

export const defineAppTitle = () => {
  const title = 'by Muscle&Motion';

  return `${defineAppName()} ${title}`;
};

export const defineFirebaseConfigs = () => {
  switch (CURRENT_VERTICAL) {
    case 'yoga':
      return YOGA_FIREBASE;
    case 'posture':
      return POSTURE_FIREBASE;
    case 'anatomy':
      return ANATOMY_FIREBASE;
    default:
      // Strength vertical
      return STRENGTH_FIREBASE;
  }
};

export const defineAdjustConfigs = () => {
  switch (CURRENT_VERTICAL) {
    case 'yoga':
      return YOGA_ADJUST;
    case 'posture':
      return POSTURE_ADJUST;
    case 'anatomy':
      return ANATOMY_ADJUST;
    default:
      // Strength vertical
      return STRENGTH_ADJUST;
  }
};

export const defineFireBaseQAorPRODObject = () => {
  if (process.env.REACT_APP_ENV !== 'production') {
    return 'qa';
  }
  return 'prod';
};

export const defineFacebookConfigs = () => {
  switch (CURRENT_VERTICAL) {
    case 'yoga':
      return YOGA_FACEBOOK;
    case 'posture':
      return POSTURE_FACEBOOK;
    case 'anatomy':
      return ANATOMY_FACEBOOK;
    default:
      // Strength vertical
      return STRENGTH_FACEBOOK;
  }
};
export const defineDomainUrl = () => {
  switch (CURRENT_VERTICAL) {
    case 'yoga':
      return YOGA_DOMAIN;
    case 'posture':
      return POSTURE_DOMAIN;
    case 'anatomy':
      return ANATOMY_DOMAIN;
    default:
      // Strength vertical
      return STRENGTH_DOMAIN;
  }
};
export const defineEncryptedDomainUrl = () => {
  switch (CURRENT_VERTICAL) {
    case 'yoga':
      return YOGA_DOMAIN_ENCRYPTED;
    case 'posture':
      return POSTURE_DOMAIN_ENCRYPTED;
    case 'anatomy':
      return ANATOMY_DOMAIN_ENCRYPTED;
    default:
      // Strength vertical
      return STRENGTH_DOMAIN_ENCRYPTED;
  }
};

export const defineGoogleLoginConfigs = () => {
  switch (CURRENT_VERTICAL) {
    case 'yoga':
      return GOOGLE_LOGIN_YOGA;
    case 'posture':
      return GOOGLE_LOGIN_POSTURE;
    case 'anatomy':
      return GOOGLE_LOGIN_ANATOMY;
    default:
      // Strength vertical
      return GOOGLE_LOGIN_STRENGTH;
  }
};
export const defineFacebookLoginConfigs = () => {
  switch (CURRENT_VERTICAL) {
    case 'yoga':
      return FACEBOOK_LOGIN_YOGA;
    case 'posture':
      return FACEBOOK_LOGIN_POSTURE;
    case 'anatomy':
      return FACEBOOK_LOGIN_ANATOMY;
    default:
      // Strength vertical
      return FACEBOOK_LOGIN_STRENGTH;
  }
};

export const bluesnapAuthorizationString = () => {
  switch (CURRENT_VERTICAL) {
    case 'yoga':
      return process.env.REACT_APP_SERVER === 'development' ? QA_YOGA_BLUESNAP_KEY : PROD_YOGA_BLUESNAP_KEY;
    case 'posture':
      return process.env.REACT_APP_SERVER === 'development' ? QA_POSTURE_BLUESNAP_KEY : PROD_POSTURE_BLUESNAP_KEY;
    case 'anatomy':
      return process.env.REACT_APP_SERVER === 'development' ? QA_ANATOMY_BLUESNAP_KEY : PROD_ANATOMY_BLUESNAP_KEY;
    default:
      // Strength vertical
      return process.env.REACT_APP_SERVER === 'development' ? QA_STRENGTH_BLUESNAP_KEY : PROD_STRENGTH_BLUESNAP_KEY;
  }
};

export const storeLinks = {
  strength: {
    googleplay: 'https://play.google.com/store/apps/details?id=air.com.musclemotion.strength.mobile&hl=en',
    appstore: 'https://itunes.apple.com/il/app/muscle-motion-strength/id1302056349?mt=8',
  },
  posture: {
    googleplay: 'https://play.google.com/store/apps/details?id=air.com.musclemotion.posture&hl=en',
    appstore: 'https://apps.apple.com/am/app/muscle-and-motion-posture/id1199500066?mt=8',
  },
  yoga: {
    googleplay: 'https://play.google.com/store/apps/details?id=air.com.musclemotion.yoga&hl=en',
    appstore: 'https://apps.apple.com/il/app/yoga-by-muscle-motion/id1228855553?mt=8',
  },
  anatomy: {
    googleplay: 'https://play.google.com/store/apps/details?id=air.com.musclemotion.anatomy&hl=en',
    appstore: 'https://itunes.apple.com/gb/app/muscle-and-motion-anatomy/id1149322730?mt=8',
  },
};
