/*
 *
 * AuthReg constants
 *
 */

export const LOGIN_SOCIAL = 'app/AuthReg/LOGIN_SOCIAL';
export const LOGIN_SOCIAL_SUCCESS = 'app/AuthReg/LOGIN_SOCIAL_SUCCESS';
export const LOGIN_SOCIAL_FAIL = 'app/AuthReg/LOGIN_SOCIAL_FAIL';

export const LOGIN_USER = 'app/AuthReg/LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'app/AuthReg/LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAIL = 'app/AuthReg/LOGIN_USER_FAIL';

export const LOGOUT_USER = 'app/AuthReg/LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'app/AuthReg/LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAIL = 'app/AuthReg/LOGOUT_USER_FAIL';

export const REGISTRATION = 'app/AuthReg/REGISTRATION';
export const REGISTRATION_SUCCESS = 'app/AuthReg/REGISTRATION_SUCCESS';
export const REGISTRATION_FAIL = 'app/AuthReg/REGISTRATION_FAIL';

export const FORGET_PASS_EMAIL = 'app/AuthReg/FORGET_PASS_EMAIL';
export const FORGET_PASS_EMAIL_SUCCESS = 'app/AuthReg/FORGET_PASS_EMAIL_SUCCESS';
export const FORGET_PASS_EMAIL_FAIL = 'app/AuthReg/FORGET_PASS_EMAIL_FAIL';

export const RESET_PASS = 'app/AuthReg/RESET_PASS';
export const RESET_PASS_SUCCESS = 'app/AuthReg/RESET_PASS_SUCCESS';
export const RESET_PASS_FAIL = 'app/AuthReg/RESET_PASS_FAIL';

export const CLEAR_CHANGE_FORGET_PASS = 'app/AuthReg/CLEAR_CHANGE_FORGET_PASS';
