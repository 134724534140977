/*
 *
 * SimpleVideoPage reducer
 *
 */
import produce from 'immer';
import * as ActionTypes from './constants';

export const initialState = {
  data: {},
  loaded: false,
  loading: false,
  error: null,
};

/* eslint-disable default-case, no-param-reassign */
const simpleVideoPageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.GET_VIDEO_BY_ID:
        draft.loading = true;
        draft.loaded = false;
        break;
      case ActionTypes.GET_VIDEO_BY_ID_SUCCESS:
        draft.loading = false;
        draft.loaded = true;
        draft.data = action.data;
        break;
      case ActionTypes.GET_VIDEO_BY_ID_FAIL:
        draft.loading = false;
        draft.loaded = false;
        draft.data = null;
        draft.error = action.error;
        break;
      case ActionTypes.CLEAR_VIDEO_BY_ID:
        draft.loading = false;
        draft.loaded = false;
        draft.data = {};
        break;
    }
  });

export default simpleVideoPageReducer;
