/**
 *
 * Notification
 *
 */

import React, { memo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import messages from './messages';
import styles from './styles';

function MainSnackbarContentWrapper({ classes, message, handleCloseNotification, intl: { formatMessage } }) {
  return (
    <SnackbarContent
      className={classes.mainContent}
      message={
        <div className={classes.msgBox}>
          <div className={classes.checkIcon} />
          <p className={classes.msg}>{message}</p>
        </div>
      }
      action={[
        <button key="close" type="button" className={classes.dismissBtn} onClick={() => handleCloseNotification()}>
          {formatMessage(messages.dismiss)}
        </button>,
      ]}
    />
  );
}

MainSnackbarContentWrapper.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object,
  message: PropTypes.string,
  handleCloseNotification: PropTypes.func,
};

const MainSnackbarContentWrapperIntl = injectIntl(MainSnackbarContentWrapper);
const MainSnackbarContentWrapperStyled = withStyles(styles)(MainSnackbarContentWrapperIntl);

function Notification({ variant, isOpen, textMsg, handleCloseNotification, classes }) {
  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{
        vertical: variant === 'main' ? 'bottom' : 'top',
        horizontal: variant === 'main' ? 'center' : 'right',
      }}
      autoHideDuration={4000}
      onClose={() => handleCloseNotification()}
    >
      {variant === 'main' ? (
        <MainSnackbarContentWrapperStyled message={textMsg} handleCloseNotification={handleCloseNotification} />
      ) : (
        <SnackbarContent className={classNames(classes[variant], classes.content)} message={textMsg} />
      )}
    </Snackbar>
  );
}

Notification.propTypes = {
  classes: PropTypes.object,
  variant: PropTypes.any,
  handleCloseNotification: PropTypes.func,
  isOpen: PropTypes.bool,
  textMsg: PropTypes.string,
};

const NotificationStyled = withStyles(styles)(Notification);

export default memo(NotificationStyled);
