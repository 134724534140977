/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import * as ActionTypes from './constants';

export function closeChangeForgetPassDialog() {
  return {
    type: ActionTypes.CLOSE_CHANGE_FORGET_PASS,
  };
}
export function getUserData() {
  return {
    type: ActionTypes.GET_USER_DATA,
  };
}

export function getFirebaseUpdateLanguage() {
  return {
    type: ActionTypes.GET_FIREBASE_UPDATE_LANGUAGE,
  };
}

export function getFirebase(dispatch) {
  return {
    type: ActionTypes.GET_FIREBASE,
    dispatch,
  };
}

export function getSubMuscles() {
  return {
    type: ActionTypes.GET_SUBMUSCLES,
  };
}

export function userChangeLocale(lang) {
  return {
    type: ActionTypes.USER_CHANGE_LOCALE,
    lang,
  };
}

export function userUpdate(fields) {
  return {
    type: ActionTypes.USER_UPDATE,
    fields,
  };
}

export function closeNotification() {
  return {
    type: ActionTypes.CLOSE_NOTIFICATION,
  };
}

export function openSuccessNotification(text) {
  return {
    type: ActionTypes.OPEN_NOTIFICATION,
    text,
  };
}

export function openMainNotification(text) {
  return {
    type: ActionTypes.OPEN_MAIN_NOTIFICATION,
    text,
  };
}

export function openErrorNotification(text) {
  return {
    type: ActionTypes.OPEN_ERROR_NOTIFICATION,
    text,
  };
}

export function getSearch(text) {
  return {
    type: ActionTypes.GET_SEARCH,
    text,
  };
}

export function clearSearch() {
  return {
    type: ActionTypes.CLEAR_SEARCH,
  };
}

export function getMenuItemForVideo(idName, id, section) {
  return {
    type: ActionTypes.GET_MENU_ITEM_FOR_VIDEO,
    idName,
    id,
    section,
  };
}
export function setAsideMenuHidden(value) {
  return {
    type: ActionTypes.SET_ASIDE_MENU_HIDDEN,
    value,
  };
}
