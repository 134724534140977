import { takeLatest, put } from 'redux-saga/effects';
import * as ActionTypes from './constants';
import axios from '../../utils/axiosConfig';
import { OPEN_MAIN_NOTIFICATION } from '../App/constants';

export function* fetchGetAZ({ dispatch }) {
  try {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (!window.navigator.onLine && !error.response) {
          dispatch({
            type: OPEN_MAIN_NOTIFICATION,
            text:
              'Dear user, we have noticed a connection problem on your end, please refresh your network connection, switch network or just try again later',
          });
        }

        return Promise.reject(error);
      },
    );
    const response = yield axios.get('search/index');
    if (response) {
      yield put({
        type: ActionTypes.GET_AZ_SUCCESS,
        data: response.data,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_AZ_FAIL,
      data: e.response.data.message,
    });
  }
}

// All sagas to be loaded
export default function* azlistSaga() {
  yield takeLatest(ActionTypes.GET_AZ, fetchGetAZ);
}
