/*
 *
 * Theory reducer
 *
 */
import produce from 'immer';
import * as ActionTypes from './constants';

export const initialState = {
  loading: false,
  loaded: false,
};

/* eslint-disable default-case, no-param-reassign */
const theoryReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.GET_THEORY_CATEGORIES:
        draft.loading = true;
        draft.loaded = false;
        break;
      case ActionTypes.GET_THEORY_CATEGORIES_SUCCESS:
        draft.loading = false;
        draft.loaded = true;
        draft.data = action.data;
        break;
      case ActionTypes.GET_THEORY_CATEGORIES_FAIL:
        draft.loading = false;
        draft.loaded = false;
        draft.data = null;
        draft.error = action.error;
        break;
      case ActionTypes.GET_ASANAS_CATEGORIES:
        draft.loading = true;
        draft.loaded = false;
        break;
      case ActionTypes.GET_ASANAS_CATEGORIES_SUCCESS:
        draft.loading = false;
        draft.loaded = true;
        draft.asanas = action.data;
        break;
      case ActionTypes.GET_ASANAS_CATEGORIES_FAIL:
        draft.loading = false;
        draft.loaded = false;
        draft.asanas = null;
        draft.error = action.error;
        break;
      case ActionTypes.GET_MYOFASCIAL_CATEGORY:
        draft.loading = true;
        draft.loaded = false;
        draft.myofascialError = null;
        break;
      case ActionTypes.GET_MYOFASCIAL_CATEGORY_SUCCESS:
        draft.loading = false;
        draft.loaded = true;
        draft.myofascial = action.data;
        break;
      case ActionTypes.GET_MYOFASCIAL_CATEGORYS_FAIL:
        draft.loading = false;
        draft.loaded = false;
        draft.myofascial = null;
        draft.myofascialError = action.error;
        break;
      case ActionTypes.GET_POSTURAL_TESTS:
        draft.loading = true;
        draft.loaded = false;
        draft.posturalTests = null;
        draft.posturalTestsError = null;
        break;
      case ActionTypes.GET_POSTURAL_TESTS_SUCCESS:
        draft.loading = false;
        draft.loaded = true;
        draft.posturalTests = action.data;
        break;
      case ActionTypes.GET_POSTURAL_TESTS_FAIL:
        draft.loading = false;
        draft.loaded = false;
        draft.posturalTests = null;
        draft.posturalTestsError = action.error;
        break;
      case ActionTypes.GET_TERAPETIC_CATEGORIES:
        draft.loading = true;
        draft.therapeutics = null;
        break;
      case ActionTypes.GET_TERAPETIC_CATEGORIES_SUCCESS:
        draft.loading = false;
        draft.loaded = true;
        draft.therapeutics = action.data;
        break;
      case ActionTypes.GET_TERAPETIC_CATEGORIES_FAIL:
        draft.loading = false;
        draft.loaded = false;
        draft.therapeutics = null;
        draft.error = action.error;
        break;
      case ActionTypes.GET_THEORY_SUBCATEGORIES:
        draft.loadingSub = true;
        break;
      case ActionTypes.GET_THEORY_SUBCATEGORIES_SUCCESS:
        draft.loadingSub = false;
        draft.dataSub = action.data;
        break;
      case ActionTypes.GET_THEORY_SUBCATEGORIES_FAIL:
        draft.loadingSub = false;
        draft.dataSub = null;
        draft.errorSub = action.error;
        break;
      case ActionTypes.GET_POSTURAL_CATEGORIES:
        draft.loading = true;
        break;
      case ActionTypes.GET_POSTURAL_CATEGORIES_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        break;
      case ActionTypes.GET_POSTURAL_CATEGORIES_FAIL:
        draft.loading = false;
        draft.data = null;
        draft.error = action.error;
        break;
      case ActionTypes.GET_POSTURAL_DISORDERS_CATEGORIES:
        draft.loading = true;
        break;
      case ActionTypes.GET_POSTURAL_DISORDERS_CATEGORIES_SUCCESS:
        draft.loading = false;
        draft.postural = action.data;
        break;
      case ActionTypes.GET_POSTURAL_DISORDERS_CATEGORIES_FAIL:
        draft.loading = false;
        draft.postural = null;
        draft.error = action.error;
        break;
      case ActionTypes.GET_POSTURE_YOGA_THEORY_CATEGORIES:
        draft.loading = true;
        break;
      case ActionTypes.GET_POSTURE_YOGA_THEORY_CATEGORIES_SUCCESS:
        draft.loading = false;
        draft.theory = action.data;
        break;
      case ActionTypes.GET_POSTURE_YOGA_THEORY_CATEGORIES_FAIL:
        draft.loading = false;
        draft.theory = null;
        draft.error = action.error;
        break;
    }
  });

export default theoryReducer;
