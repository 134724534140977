/*
 *
 * Theory constants
 *
 */

export const GET_THEORY_CATEGORIES = 'app/Theory/GET_THEORY_CATEGORIES';
export const GET_THEORY_CATEGORIES_SUCCESS = 'app/Theory/GET_THEORY_CATEGORIES_SUCCESS';
export const GET_THEORY_CATEGORIES_FAIL = 'app/Theory/GET_THEORY_CATEGORIES_FAIL';

export const GET_THEORY_SUBCATEGORIES = 'app/Theory/GET_THEORY_SUBCATEGORIES';
export const GET_THEORY_SUBCATEGORIES_SUCCESS = 'app/Theory/GET_THEORY_SUBCATEGORIES_SUCCESS';
export const GET_THEORY_SUBCATEGORIES_FAIL = 'app/Theory/GET_THEORY_SUBCATEGORIES_FAIL';

export const GET_ASANAS_CATEGORIES = 'app/Theory/GET_ASANAS_CATEGORIES';
export const GET_ASANAS_CATEGORIES_SUCCESS = 'app/Theory/GET_ASANAS_CATEGORIES_SUCCESS';
export const GET_ASANAS_CATEGORIES_FAIL = 'app/Theory/GET_ASANAS_CATEGORIES_FAIL';

export const GET_MYOFASCIAL_CATEGORY = 'app/Theory/GET_MYOFASCIAL_CATEGORY';
export const GET_MYOFASCIAL_CATEGORY_SUCCESS = 'app/Theory/GET_MYOFASCIAL_CATEGORY_SUCCESS';
export const GET_MYOFASCIAL_CATEGORYS_FAIL = 'app/Theory/GET_MYOFASCIAL_CATEGORYS_FAIL';

export const GET_POSTURAL_TESTS = 'app/Theory/GET_POSTURAL_TESTS';
export const GET_POSTURAL_TESTS_SUCCESS = 'app/Theory/GET_POSTURAL_TESTS_SUCCESS';
export const GET_POSTURAL_TESTS_FAIL = 'app/Theory/GET_POSTURAL_TESTS_FAIL';

export const GET_POSTURAL_CATEGORIES = 'app/Theory/GET_POSTURAL_CATEGORIES';
export const GET_POSTURAL_CATEGORIES_SUCCESS = 'app/Theory/GET_POSTURAL_CATEGORIES_SUCCESS';
export const GET_POSTURAL_CATEGORIES_FAIL = 'app/Theory/GET_POSTURAL_CATEGORIES_FAIL';

export const GET_POSTURAL_DISORDERS_CATEGORIES = 'app/Theory/GET_POSTURAL_DISORDERS_CATEGORIES';
export const GET_POSTURAL_DISORDERS_CATEGORIES_SUCCESS = 'app/Theory/GET_POSTURAL_DISORDERS_CATEGORIES_SUCCESS';
export const GET_POSTURAL_DISORDERS_CATEGORIES_FAIL = 'app/Theory/GET_POSTURAL_DISORDERS_CATEGORIES_FAIL';

export const GET_TERAPETIC_CATEGORIES = 'app/Theory/GET_TERAPETIC_CATEGORIES';
export const GET_TERAPETIC_CATEGORIES_SUCCESS = 'app/Theory/GET_TERAPETIC_CATEGORIES_SUCCESS';
export const GET_TERAPETIC_CATEGORIES_FAIL = 'app/Theory/GET_TERAPETIC_CATEGORIES_FAIL';

export const GET_POSTURE_YOGA_THEORY_CATEGORIES = 'app/Theory/GET_POSTURE_YOGA_THEORY_CATEGORIES';
export const GET_POSTURE_YOGA_THEORY_CATEGORIES_SUCCESS = 'app/Theory/GET_POSTURE_YOGA_THEORY_CATEGORIES_SUCCESS';
export const GET_POSTURE_YOGA_THEORY_CATEGORIES_FAIL = 'app/Theory/GET_POSTURE_YOGA_THEORY_CATEGORIES_FAIL';
