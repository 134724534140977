export default theme => ({
  actionBtnsWrap: {
    position: 'absolute',
    top: theme.spacing(),
    right: theme.spacing(1.5),
    zIndex: 10,
    display: 'flex',
  },
  actionBtn: {
    marginBottom: theme.spacing(),
    marginLeft: theme.spacing(),
    padding: 0,
    '& path': {
      color: theme.palette.MMColors.fox,
    },
  },
  dialogInput: {
    ...theme.confirmPopup.notice,
    background: 'none',
    border: 'none',
    borderBottom: `2px solid ${theme.palette.MMColors.fox}`,
    width: '100%',
    textAlign: 'center',
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
});
