// eslint-disable-next-line import/no-cycle
import ReactGA from './googleAnalytics';
// eslint-disable-next-line import/no-cycle
import mixpanel from './mixpanel';

export const MM_PLAN_REQUEST_KEY = 'mmPlanRequest';
export const MY_PLAN_REQUEST_KEY = 'myPlanRequest';

export const MUSCLES_REQUEST_KEY = 'musclesRequest';
export const BONES_REQUEST_KEY = 'bonesRequest';
export const JOINTS_REQUEST_KEY = 'jointsRequest';

const USER_ID = 'userId';
const TOKEN = 'token';

const REDIRECT_AFTER_LOGIN = 'redirect_after_login';
const DEFAULT_REDIRECT_AFTER_LOGIN = false;

const PRICING_POP_UP_TYPE_LOGIN = 'pricing_popup_after_login';
const DEFAULT_PRICING_POP_UP_TYPE_LOGIN = false;

const ENTRY_POINT_LOGIN = 'login_entryPoint';
const DEFAULT_ENTRY_POINT_LOGIN = 'onboarding';

const ENTRY_POINT = 'pricing_entryPoint';
const DEFAULT_ENTRY_POINT = 'pricingBanner';

const MODE = 'pricing_mode';
const DEFAULT_MODE = 'individual';

const PLAN = 'pricing_plan';
const DEFAULT_PLAN = 'monthly';

const UPGRAGE_FIELDS = 'pricing_upgradeFields';
const DEFAULT_UPGRAGE_FIELDS = '{"upgrade": "no"}';

const ITEM_LOCK_TYPE = 'pricing_itemLockType';
const DEFAULT_ITEM_LOCK_TYPE = '';

const ITEM_LOCK_ID = 'pricing_itemLockId';
const DEFAULT_ITEM_LOCK_ID = '';

const SUBSCRIPBER = 'user_subscriber';
const DEFAULT_SUBSCRIPBER = 'no';

const THEORY_SUB_CHAPTER = 'theorySubChapter';
const DEFAULT_THEORY_SUB_CHAPTER = '';

const THEORY_SUB_SUB_CHAPTER = 'theorySubSubChapter';
const DEFAULT_THEORY_SUB_SUB_CHAPTER = 'All';

const EXERCISE_VIEW_INFO = 'exerciseViewInfo';
const EXERCISE_VIEW_TIME = 'exerciseViewTime';
const EXERCISE_VIEW_TIME_KEYS = {
  videosViewed: 'Videos Viewed',
  videosViewedCount: 'Videos Viewed Count',
  videos: 'Videos',
  commonMistakesViewed: 'Common Mistakes Videos Viewed',
  commonMistakesViewedCount: 'Common Mistakes Videos Viewed Count',
  commonMistakesVideos: 'Common Mistakes Videos',
  musclesClicked: 'Active Muscles Clicked',
};
const DEFAULT_EXERCISE_VIEW_TIME = {
  'Videos Viewed': [1],
  'Videos Viewed Count': 1,
  Videos: 1,
  'Common Mistakes Videos Viewed': [],
  'Common Mistakes Videos': 0,
  'Common Mistakes Videos Viewed Count': 0,
  'Active Muscles Clicked': 'False',
};

const THEORY_VIEW_INFO = 'theoryViewInfo';
const THEORY_VIEW_TIME = 'theoryViewTime';
const DEFAULT_THEORY_VIEW_TIME = {
  duration: 0,
  timeMatrix: [],
};

const MUSCLE_ORIGIN_VIEW_INFO = 'muscleOriginViewInfo';
const MUSCLE_ORIGIN_VIEW_TIME = 'muscleOriginViewTime';
const DEFAULT_MUSCLE_ORIGIN_VIEW_TIME = {
  duration: 0,
  timeMatrix: [],
};

const ENTERED_AS_GUEST_BEFORE_KEY = 'mixpanel_ent_as_guest_before';
const DEFAULT_ENTERED_AS_GUEST_BEFORE = false;

const SIGNUP_METHOD_KEY = 'mixpanel_signup_method';
const DEFAULT_SIGNUP_METHOD = 'Email';

export const requestStarted = key => {
  sessionStorage.setItem(key, 'true');
};

export const requestStopped = key => {
  sessionStorage.removeItem(key);
};

export const isRequestGoing = key => {
  return sessionStorage.getItem(key) === 'true';
};

sessionStorage.removeItem(MM_PLAN_REQUEST_KEY);
sessionStorage.removeItem(MUSCLES_REQUEST_KEY);

export const setUserAuthInfo = user => {
  localStorage.setItem(TOKEN, user.token);
  localStorage.setItem(USER_ID, user.id);
  ReactGA.init();
};

export const removeUserAuthInfo = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(USER_ID);
  sessionStorage.clear();
  ReactGA.init();
  mixpanel.reset();
};

export const getUserId = () => {
  return localStorage.getItem(USER_ID) || false;
};

export const setEntryPoint = entryPoint => {
  sessionStorage.setItem(ENTRY_POINT, entryPoint || DEFAULT_ENTRY_POINT);
};

export const setMode = mode => {
  sessionStorage.setItem(MODE, mode || DEFAULT_MODE);
};

export const setSubscriber = subscriber => {
  sessionStorage.setItem(SUBSCRIPBER, subscriber ? 'yes' : 'no' || DEFAULT_SUBSCRIPBER);
};

export const setTheoryViewInfo = info => {
  localStorage.setItem(THEORY_VIEW_INFO, JSON.stringify(info));
};

export const setMuscleOriginViewInfo = info => {
  localStorage.setItem(MUSCLE_ORIGIN_VIEW_INFO, JSON.stringify(info));
};

export const setExerciseViewInfo = info => {
  localStorage.setItem(EXERCISE_VIEW_INFO, JSON.stringify(info));
};

export const getTheoryViewInfo = () => {
  const info = localStorage.getItem(THEORY_VIEW_INFO);
  return info ? JSON.parse(info) : null;
};

export const getMuscleOriginViewInfo = () => {
  const info = localStorage.getItem(MUSCLE_ORIGIN_VIEW_INFO);
  return info ? JSON.parse(info) : null;
};

export const getExerciseViewInfo = () => {
  const info = localStorage.getItem(EXERCISE_VIEW_INFO);
  return info ? JSON.parse(info) : null;
};

export const setTheoryViewTime = (time, duration) => {
  if (window.theoryViewTime === time || time === 0) return;
  window.theoryViewTime = time;
  const viewTime = getTheoryViewTime();
  if (viewTime.timeMatrix.findIndex(el => el === time) === -1) {
    viewTime.timeMatrix.push(time);
    const newViewTime = {
      timeMatrix: viewTime.timeMatrix,
      videoLength: duration,
      completionRate: Math.floor((viewTime.timeMatrix.length / duration) * 100),
    };
    localStorage.setItem(THEORY_VIEW_TIME, JSON.stringify(newViewTime));

    if (newViewTime.completionRate === 100) {
      window.theoryViewTime = 0;
      ReactGA.sendTheoryViewTime();
      mixpanel.sendTheoryViewTime();
    }
  }
};

export const setMuscleOriginViewTime = (time, duration) => {
  if (window.muscleViewTime === time || time === 0) return;
  if (time >= 0) window.muscleViewTime = time;
  const viewTime = getMuscleOriginViewTime();
  if (viewTime.timeMatrix.findIndex(el => el === time) === -1 || time === -1) {
    viewTime.timeMatrix.push(time);
    const newViewTime = {
      timeMatrix: time === -1 ? [-1] : viewTime.timeMatrix.filter(v => v !== -1),
      videoLength: duration,
      completionRate: Math.floor((viewTime.timeMatrix.filter(v => v !== -1).length / duration) * 100),
    };
    localStorage.setItem(MUSCLE_ORIGIN_VIEW_TIME, JSON.stringify(newViewTime));

    if (newViewTime.completionRate === 100) {
      window.muscleViewTime = 0;
      mixpanel.sendMuscleOriginViewTime();
    }
  }
};

export const setExerciseViewTime = (key, value) => {
  const formattedKey = EXERCISE_VIEW_TIME_KEYS[key];
  const viewTime = getExerciseViewTime();
  if (key === 'musclesClicked') {
    viewTime[formattedKey] = value ? 'True' : 'False';
  }
  if (key === 'videosViewed') {
    viewTime[formattedKey] = [...new Set([...viewTime[formattedKey], value + 1])];
    viewTime[EXERCISE_VIEW_TIME_KEYS.videosViewedCount] = viewTime[formattedKey].length;
  }
  if (key === 'commonMistakesViewed') {
    viewTime[formattedKey] = [...new Set([...viewTime[formattedKey], value + 1])];
    viewTime[EXERCISE_VIEW_TIME_KEYS.commonMistakesViewedCount] = viewTime[formattedKey].length;
  }
  if (key === 'videos' || key === 'commonMistakesVideos') {
    viewTime[formattedKey] = value;
  }

  localStorage.setItem(EXERCISE_VIEW_TIME, JSON.stringify(viewTime));
};

export const getTheoryViewTime = () => {
  const info = localStorage.getItem(THEORY_VIEW_TIME);
  return info ? JSON.parse(info) : DEFAULT_THEORY_VIEW_TIME;
};
export const getMuscleOriginViewTime = () => {
  const info = localStorage.getItem(MUSCLE_ORIGIN_VIEW_TIME);
  return info ? JSON.parse(info) : DEFAULT_MUSCLE_ORIGIN_VIEW_TIME;
};

export const getExerciseViewTime = (toSend = 'wrong') => {
  const info = localStorage.getItem(EXERCISE_VIEW_TIME);
  const infoJson = info ? JSON.parse(info) : DEFAULT_EXERCISE_VIEW_TIME;
  const videos = infoJson[EXERCISE_VIEW_TIME_KEYS.videos];
  const commonMistakesVideos = infoJson[EXERCISE_VIEW_TIME_KEYS.commonMistakesVideos];

  if (toSend === 'wrong') return infoJson;
  infoJson[EXERCISE_VIEW_TIME_KEYS.videosViewed] = infoJson[EXERCISE_VIEW_TIME_KEYS.videosViewed]
    .filter(i => i <= videos)
    .splice(0, videos);
  infoJson[EXERCISE_VIEW_TIME_KEYS.videosViewedCount] = infoJson[EXERCISE_VIEW_TIME_KEYS.videosViewed].length;

  infoJson[EXERCISE_VIEW_TIME_KEYS.commonMistakesViewed] = infoJson[EXERCISE_VIEW_TIME_KEYS.commonMistakesViewed]
    .filter(i => i <= commonMistakesVideos)
    .splice(0, commonMistakesVideos);
  infoJson[EXERCISE_VIEW_TIME_KEYS.commonMistakesViewedCount] =
    infoJson[EXERCISE_VIEW_TIME_KEYS.commonMistakesViewed].length;

  return infoJson;
};

export const removeTheoryInfoAndTime = () => {
  localStorage.removeItem(THEORY_VIEW_TIME);
  localStorage.removeItem(THEORY_VIEW_INFO);
};

export const removeMuscleOriginInfoAndTime = () => {
  localStorage.removeItem(MUSCLE_ORIGIN_VIEW_TIME);
  localStorage.removeItem(MUSCLE_ORIGIN_VIEW_INFO);
};

export const removeExerciseInfoAndTime = () => {
  localStorage.removeItem(EXERCISE_VIEW_TIME);
  localStorage.removeItem(EXERCISE_VIEW_INFO);
};

export const getSubscriber = () => {
  return sessionStorage.getItem(SUBSCRIPBER) || DEFAULT_SUBSCRIPBER;
};

export const setSubChapter = subChapter => {
  sessionStorage.setItem(THEORY_SUB_CHAPTER, subChapter || DEFAULT_THEORY_SUB_CHAPTER);
  sessionStorage.removeItem(THEORY_SUB_SUB_CHAPTER);
};

export const getSubChapter = () => {
  return sessionStorage.getItem(THEORY_SUB_CHAPTER) || DEFAULT_THEORY_SUB_CHAPTER;
};

export const setSubSubChapter = subSubChapter => {
  sessionStorage.setItem(THEORY_SUB_SUB_CHAPTER, subSubChapter || DEFAULT_THEORY_SUB_SUB_CHAPTER);
};

export const getSubSubChapter = () => {
  return sessionStorage.getItem(THEORY_SUB_SUB_CHAPTER) || DEFAULT_THEORY_SUB_SUB_CHAPTER;
};

export const setPlan = plan => {
  sessionStorage.setItem(PLAN, plan || DEFAULT_PLAN);
};

export const setItemLockType = lockType => {
  sessionStorage.setItem(ITEM_LOCK_TYPE, lockType || DEFAULT_ITEM_LOCK_TYPE);
};

export const setItemLockId = lockId => {
  sessionStorage.setItem(ITEM_LOCK_ID, lockId || DEFAULT_ITEM_LOCK_ID);
};

export const getEntryPoint = () => {
  return sessionStorage.getItem(ENTRY_POINT) || DEFAULT_ENTRY_POINT;
};

export const getMode = () => {
  return sessionStorage.getItem(MODE) || DEFAULT_MODE;
};

export const getPlan = () => {
  return sessionStorage.getItem(PLAN) || DEFAULT_PLAN;
};

export const getItemLockType = () => {
  return sessionStorage.getItem(ITEM_LOCK_TYPE) || DEFAULT_ITEM_LOCK_TYPE;
};

export const getItemLockId = () => {
  return sessionStorage.getItem(ITEM_LOCK_ID) || DEFAULT_ITEM_LOCK_ID;
};

export const setRedirectAfterLogin = (url = '') => {
  if (!url) return;
  return sessionStorage.setItem(REDIRECT_AFTER_LOGIN, url);
};

export const getRedirectAfterLogin = () => {
  return sessionStorage.getItem(REDIRECT_AFTER_LOGIN) || DEFAULT_REDIRECT_AFTER_LOGIN;
};

export const cleanRedirectAfterLogin = () => {
  return sessionStorage.removeItem(REDIRECT_AFTER_LOGIN);
};

export const setPopupTypeAfterLogin = (type = '') => {
  if (!type) return;
  return sessionStorage.setItem(PRICING_POP_UP_TYPE_LOGIN, type);
};

export const getPopupTypeAfterLogin = () => {
  return sessionStorage.getItem(PRICING_POP_UP_TYPE_LOGIN) || DEFAULT_PRICING_POP_UP_TYPE_LOGIN;
};

export const cleanPopupTypeAfterLogin = () => {
  return sessionStorage.removeItem(PRICING_POP_UP_TYPE_LOGIN);
};

export const cleanPricing = () => {
  sessionStorage.removeItem(ENTRY_POINT);
  sessionStorage.removeItem(PLAN);
  sessionStorage.removeItem(MODE);
  sessionStorage.removeItem(ITEM_LOCK_TYPE);
  sessionStorage.removeItem(ITEM_LOCK_ID);
  sessionStorage.removeItem(UPGRAGE_FIELDS);
};

export const setLoginEntryPointTemp = entryPoint => {
  sessionStorage.setItem(ENTRY_POINT_LOGIN, entryPoint || DEFAULT_ENTRY_POINT_LOGIN);
  // setTimeout(() => {
  //   sessionStorage.removeItem(ENTRY_POINT_LOGIN);
  // }, 1000);
};

export const setUpgradeFields = user => {
  const upgradeFields = user.is_paid
    ? JSON.stringify({
        upgrade: 'yes',
        currentPlan: user.paid_status.type === 'indi' ? 'individual' : user.paid_status.type,
        currentSubscription: user.paid_status.billing.replace(/3years/, '3Year'),
      })
    : DEFAULT_UPGRAGE_FIELDS;
  sessionStorage.setItem(UPGRAGE_FIELDS, upgradeFields);
};

export const getUpgradeFields = () => {
  return JSON.parse(sessionStorage.getItem(UPGRAGE_FIELDS) || DEFAULT_UPGRAGE_FIELDS);
};

export const getLoginEntryPointTemp = () => {
  return sessionStorage.getItem(ENTRY_POINT_LOGIN) || DEFAULT_ENTRY_POINT_LOGIN;
};

export const setEnteredAsGuestBefore = bool =>
  localStorage.setItem(ENTERED_AS_GUEST_BEFORE_KEY, bool || DEFAULT_ENTERED_AS_GUEST_BEFORE);

export const getEnteredAsGuestBefore = () =>
  localStorage.getItem(ENTERED_AS_GUEST_BEFORE_KEY) === 'true' ? 'True' : 'False' || DEFAULT_ENTERED_AS_GUEST_BEFORE;

export const setSignupMethod = method => localStorage.setItem(SIGNUP_METHOD_KEY, method || DEFAULT_SIGNUP_METHOD);

export const getSignupMethod = () => localStorage.getItem(SIGNUP_METHOD_KEY) || DEFAULT_SIGNUP_METHOD;

export const removeEnteredAsGuestBeforeAndSignupMethod = () => {
  localStorage.removeItem(SIGNUP_METHOD_KEY);
  localStorage.removeItem(ENTERED_AS_GUEST_BEFORE_KEY);
};

const stringBoolToStringBoolWithUppercase = bool => {
  if (typeof bool !== 'string') return bool;
  if (bool.toLowerCase() === 'true') return 'True';
  if (bool.toLowerCase() === 'false') return 'False';
  return bool;
};

export const itemLockTypeFBtoMP = {
  video: 'Video',
  exercise: 'Exercise',
  workoutPlan: 'Plan',
  joint: 'Joint',
  bone: 'Bone',
  muscle: 'Muscle',
};

export const mixpanelDefaultValues = {
  prefixMixpanel: 'mixpanel_',
  entryPoint: {
    name: 'Entry Point',
    default: 'Pricing Banner',
  },
  itemLockType: {
    name: 'Item Lock Type',
    default: '',
  },
  itemId: {
    name: 'Item Id',
    default: '',
  },
  itemName: {
    name: 'Item Name',
    default: '',
  },
  guestMode: {
    name: 'Guest Mode',
    default: false,
    funcValue: getEnteredAsGuestBefore,
  },
  plan: {
    name: 'Plan',
    default: 'Individual',
  },
  subscription: {
    name: 'Subscription',
    default: 'Monthly',
  },
  plan_cancel: {
    name: 'Plan',
    default: 'Individual',
  },
  subscription_cancel: {
    name: 'Subscription',
    default: 'Monthly',
  },
  theorySubChapter: {
    name: 'Theory Sub Chapter',
    default: '',
    funcValue: getSubChapter,
  },
  theorySubSubChapter: {
    name: 'Theory Sub Sub Chapter',
    default: '',
    funcValue: getSubSubChapter,
  },
  videoId: {
    name: 'Video ID',
    default: '',
  },
  videoName: {
    name: 'Video Name',
    default: '',
  },
  exerciseId: {
    name: 'Exercise ID',
    default: '',
  },
  exerciseName: {
    name: 'Exercise Name',
    default: '',
  },
  locked: {
    name: 'Locked',
    default: false,
  },
  firstAppOpen: {
    name: 'First App Open',
    default: false,
    funcValue: () => localStorage.getItem(`mixpanel_firstAppOpen`) || false,
  },
  appSessionDone: {
    name: 'App Session Done',
    default: false,
  },
  searchTerm: {
    name: 'Search Term',
    default: '',
  },
  searchTag: {
    name: 'Search Tag',
    default: '',
  },
  searchItemId: {
    name: 'Item Id',
    default: '',
  },
  searchItemName: {
    name: 'Item Name',
    default: '',
  },
  searchResultsCount: {
    name: 'Results Count',
    default: 0,
    type: 'int',
  },
  searchItemRanking: {
    name: 'Item Ranking',
    default: 1,
    type: 'int',
  },
  iapButtonText: {
    name: 'Pricing Banner Button Text',
    default: '',
  },
  iapPlanButtonText: {
    name: 'Button Text',
    default: '',
  },
  screenName: {
    name: 'Screen Name',
    default: '',
  },
  saveBlock: {
    name: 'Status',
    default: '',
  },
  muscleName: {
    name: 'Muscle Name',
    default: '',
  },
  muscleId: {
    name: 'Muscle ID',
    default: '',
  },
  moreInfoClicked: {
    name: 'More Info Clicked',
    default: 'False',
  },
  jointId: {
    name: 'Joint ID',
    default: 'False',
  },
  jointName: {
    name: 'Joint Name',
    default: 'False',
  },
  boneId: {
    name: 'Bone ID',
    default: '',
  },
  boneName: {
    name: 'Bone Name',
    default: '',
  },
};

export const mixpanelStorage = {
  setItem: (key, value) => {
    const property = mixpanelDefaultValues[key];
    // eslint-disable-next-line no-console
    if (!property) return console.error(`"${key}" is missing from "mixpanelDefaultValues"`);
    sessionStorage.setItem(`${mixpanelDefaultValues.prefixMixpanel}${key}`, value ?? property.default);
  },
  getItem: key => {
    const property = mixpanelDefaultValues[key];
    // eslint-disable-next-line no-console
    if (!property) return console.error(`"${key}" is missing from "mixpanelDefaultValues"`);
    const item = property.funcValue
      ? property.funcValue()
      : sessionStorage.getItem(`${mixpanelDefaultValues.prefixMixpanel}${key}`);

    if (property.type === 'int') return { [property.name]: parseInt(item, 10) };

    return { [property.name]: stringBoolToStringBoolWithUppercase(item) ?? property.default };
  },
  getCleanItem(key) {
    const item = this.getItem(key);
    if (item[Object.keys(item)[0]] === 'True' || item[Object.keys(item)[0]] === 'False') {
      return item[Object.keys(item)[0]] === 'True';
    }
    return item[Object.keys(item)[0]];
  },
};
