/*
 * NoticePopup Messages
 *
 * This contains all the text for the NoticePopup component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.NoticePopup';

export default defineMessages({
  noticeCheckbox: {
    id: `${scope}.noticeCheckbox`,
    defaultMessage: "Don't show this message again",
  },
  notice: {
    id: `${scope}.notice`,
    defaultMessage: 'Notice:',
  },
  cancelBtn: {
    id: `${scope}.cancelBtn`,
    defaultMessage: 'Cancel',
  },
  okBtn: {
    id: `${scope}.okBtn`,
    defaultMessage: 'Ok',
  },
  yesDelete: {
    id: `${scope}.yesDelete`,
    defaultMessage: 'Yes, Delete',
  },
});
