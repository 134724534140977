/* Muscle & Motion theme based on MaterialUI theme */
import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
    MMColors: {
      lightMain: '#293545',
      main: '#273342',
      ebonyClay: '#222a37',
      cloudBurst: '#203151',
      deepMain: '#1a212e',
      grey: '#9e9e9e',
      greyNurse: '#edeeec',
      corduroy: '#646868',
      catskillWhite: '#e3e8f2',
      blackCoral: '#525b6d',
      pickledBluewood: '#374c66',
      fox: '#fab923',
      selectiveYellow: '#ffb800',
      light: '#ffffff',
      dark: '#181818',
      black: '#000000',
      darkRed: '#940000',
      bitter: '#84857d',
      silver: '#cccccc',
      coral: '#c98383',
      haweksBlue: '#d7e5ff',
    },
  },
  action: {
    button: (color, transition = 'ease-out') => ({
      lineHeight: '36px',
      border: `2px solid ${color}`,
      borderRadius: 20,
      background: color,
      transition: `all 0.3s ${transition}`,
      '&:hover': {
        color,
        background: 'transparent',
      },
    }),
  },
  borders: {
    darkThin: '1px solid #181818',
    foxThin: '1px solid #fab923',
    redThin: '1px solid #940000',
    yellowThin: `1px solid #ffb800`,
    greyThin: '1px solid #cccccc',
    lightThin: '1px solid #ffffff',
    bitterThin: '1px solid #84857d72',
    yellowThick: '2px solid #ffb800',
  },
  typography: {
    fontSize: 16,
  },
  offsets: {
    layoutTopContentOffset: 190,
  },
  confirmPopup: {
    text: {
      textAlign: 'center',
      fontSize: 24,
      fontWeight: 300,
      margin: 0,
      color: '#ffffff',
    },
    notice: {
      fontSize: 20,
      fontWeight: 300,
      letterSpacing: '0.1px',
      margin: 0,
      color: '#ffffff',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      margin: 'auto',
      textAlign: 'center',
      minWidth: 407,
    },
    btnBox: {
      width: '100%',
      margin: '45px 0 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    btn: {
      wordBreak: 'break-word',
      minWidth: 180,
      minHeight: 55,
      padding: '0 10px',
      background: '#ffb800',
      color: '#181818',
      border: '1px solid #ffb800',
      borderRadius: 28,
      textTransform: 'none',
      fontSize: 24,
      fontWeight: 'normal',
      letterSpacing: 0,
      transition: 'background 0.5s, color 0.5s',
      '&:hover': {
        background: 'transparent',
        color: '#ffb800',
      },
    },
    cancelPopupBtn: {
      background: 'transparent',
      color: '#ffb800',
      '&:hover': {
        background: '#ffb800',
        color: '#181818',
      },
      '&[disabled]': {
        background: 'transparent',
        color: '#ffb800',
        opacity: 0.5,
      },
    },
  },
  buttons: {
    primeBtn: {
      wordBreak: 'break-word',
      minWidth: 220,
      minHeight: 40,
      padding: '0 10px',
      background: '#ffb800',
      color: '#181818',
      border: '1px solid #ffb800',
      borderRadius: 28,
      textTransform: 'none',
      fontSize: 22,
      fontWeight: 'normal',
      letterSpacing: 0,
      transition: 'background 0.5s, color 0.5s',
      '&:hover': {
        background: 'transparent',
        color: '#ffb800',
      },
    },
  },
  scrollbar: {
    overflow: 'auto',
    scrollbarColor: '#525b6d #203151',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 7,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#203151',
    },
    '&::-webkit-scrollbar-thumb': {
      width: 7,
      borderRadius: 6,
      backgroundColor: '#525b6d',
    },
  },
});
