/* eslint-disable no-debugger, no-console, no-restricted-globals */
import Adjust from '@adjustcom/adjust-web-sdk';
import { defineAdjustConfigs } from './verticalConfigs';

const isProd = process.env.REACT_APP_ENV === 'production';
const adjustConfig = defineAdjustConfigs();
const trackEvent = eventToken => {
  Adjust.trackEvent({
    eventToken,
  });
  if (!isProd) {
    const keyToken = Object.keys(adjustConfig).find(key => adjustConfig[key] === eventToken);
    console.log('----------------------');
    console.log(`Adjust key is: "${keyToken || eventToken}"`);
    console.log('----------------------');
  }
};

const adjustWork = {
  init: () => {
    if (window.adjustInit) return console.log('adjust already inited');
    console.log('adjust init');
    window.adjustInit = true;
    Adjust.initSdk({
      appToken: adjustConfig.appToken,
      environment: isProd ? 'production' : 'sandbox',
      logLevel: isProd ? 'error' : 'verbose',
    });
  },
  trackEvent,
  trackLaunchOrFirstLaunch: () => {
    const notFirstLaunch = !!localStorage.getItem('notFirstLaunch') || false;
    if (!notFirstLaunch) localStorage.setItem('notFirstLaunch', 'true');
    trackEvent(notFirstLaunch ? adjustConfig.launch : adjustConfig.firstLaunch);
  },
  trackLogin: method => {
    trackEvent(adjustConfig[`login${method}`]);
  },
  trackSignUp: method => {
    trackEvent(adjustConfig[`signUp${method}`]);
  },
  trackPricing: method => {
    trackEvent(adjustConfig[`pricingFrom${method}`]);
  },
};

const adjustEmptyForLocal = {
  init: () => {
    console.log('Adjust is off, local env detected');
  },
  trackEvent: () => {},
  trackLaunchOrFirstLaunch: () => {},
  trackLogin: () => {},
  trackSignUp: () => {},
  trackPricing: () => {},
};

const adjust = location.hostname === 'localhost' ? adjustEmptyForLocal : adjustWork;

export default adjust;
