/*
 *
 * AuthReg actions
 *
 */

import * as ActionTypes from './constants';
import * as AppActionTypes from '../App/constants';

export function loginSocialAction(data) {
  return {
    type: ActionTypes.LOGIN_SOCIAL,
    data,
  };
}

export function loginUserAction(email, password) {
  return {
    type: ActionTypes.LOGIN_USER,
    email,
    password,
  };
}

export function logoutUserAction() {
  return {
    type: ActionTypes.LOGOUT_USER,
  };
}

export function globalLogoutUserAction() {
  return {
    type: AppActionTypes.USER_DATA_CLEAN,
  };
}

export function registrationAction(data) {
  return {
    type: ActionTypes.REGISTRATION,
    data,
  };
}

export function handleAddEmail(value) {
  return {
    type: ActionTypes.FORGET_PASS_EMAIL,
    value,
  };
}

export function handleReset(data) {
  return {
    type: ActionTypes.RESET_PASS,
    data,
  };
}

export function callChangeForgetPass() {
  return {
    type: AppActionTypes.HANDLE_CHANGE_FORGET_PASS,
  };
}

export function clearChangeForgetPass() {
  return {
    type: ActionTypes.CLEAR_CHANGE_FORGET_PASS,
  };
}

export function errorNotification(message) {
  return {
    type: AppActionTypes.OPEN_ERROR_NOTIFICATION,
    text: message,
  };
}
