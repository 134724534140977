/*
 * ComingSoonExerciseItem Messages
 *
 * This contains all the text for the ComingSoonExerciseItem component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ComingSoonExerciseItem';

export default defineMessages({
  weAreWorking: {
    id: `${scope}.weAreWorking`,
    defaultMessage: 'We are working on it,',
  },
  comingSoon: {
    id: `${scope}.comingSoon`,
    defaultMessage: 'Coming soon',
  },
});
