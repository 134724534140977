/*
 * NewPlan Messages
 *
 * This contains all the text for the NewPlan component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.NewPlan';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the NewPlan component!',
  },
  firstTimeMsg: {
    id: `${scope}.firstTimeMsg`,
    defaultMessage:
      'We just wanted to make sure that you are familiar with the difference between a “plan” and a “workout”. By creating a plan you make a series of different workouts. A single workout is built from number of exercises.',
  },
  dontShowAgain: {
    id: `${scope}.dontShowAgain`,
    defaultMessage: "Thanks! Don't show again",
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next',
  },
  inOrderCreateNewPlan: {
    id: `${scope}.inOrderCreateNewPlan`,
    defaultMessage: 'In order to create new workout plans, you must sign-up first',
  },
  createNewPlan: {
    id: `${scope}.createNewPlan`,
    defaultMessage: 'Create new plan',
  },
  addPlanName: {
    id: `${scope}.addPlanName`,
    defaultMessage: 'Add plan name',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  createPlanFirstTimeMsg: {
    id: `${scope}.createPlanFirstTimeMsg`,
    defaultMessage: 'Creating a plan for the first time?',
  },
  authSignUpFree: {
    id: `${scope}.authSignUpFree`,
    defaultMessage: 'Sign up free',
  },
});
