import { put, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from './constants';
import axios from '../../utils/axiosConfig';
import mixpanel from '../../mixpanel';
import { mixpanelStorage } from '../../localStorage';

export function* fetchGetDefault() {
  try {
    const response = yield axios.get(`favorites`);
    if (response) {
      yield put({
        type: ActionTypes.GET_FAVORITE_LIST_SUCCESS,
        data: response.data,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_FAVORITE_LIST_FAIL,
      data: e.response.data.message,
    });
  }
}

export function* fetchAddToFavorites({ id, assetId, chapter, section, subId }) {
  const data = {
    chapter,
    item_id: subId || id,
    asset_id: assetId,
  };
  if (section) {
    data.section = section;
    data.item_id = subId.split('?')[0] || id.split('@')[0].split('?')[0];
  }
  try {
    const response = yield axios.post(`favorites`, data);
    if (response) {
      yield put({
        type: ActionTypes.ADD_TO_FAVORITE_SUCCESS,
        data: response.data,
      });
      sessionStorage.setItem('updateExerciseListFavoritesStatus', 'true');
      mixpanelStorage.setItem('saveBlock', 'Started');
      mixpanel.videoSave();
      mixpanel.exerciseSave();
    }
  } catch (e) {
    yield put({
      type: ActionTypes.ADD_TO_FAVORITE_FAIL,
      data: e.response.data.message,
    });
  }
}

export function* fetchDeleteFromFavorites({ id }) {
  try {
    const response = yield axios.delete(`favorites/${id}`);
    if (response) {
      yield put({
        type: ActionTypes.DELETE_FAVORITE_SUCCESS,
        favoriteId: id,
      });
      sessionStorage.setItem('updateExerciseListFavoritesStatus', 'true');
    }
  } catch (e) {
    yield put({
      type: ActionTypes.DELETE_FAVORITE_FAIL,
      data: e.response.data.message,
    });
  }
}

export function* fetchShareLink({ id, chapter, section }) {
  try {
    if (chapter === 'theory') chapter = 'video';
    if (chapter === 'muscular') chapter = 'muscle';
    if (chapter === 'skeletal') chapter = 'joint';
    const response = yield axios.post(`deeplink`, {
      item_id: id.replace(/@.*/, ''),
      chapter,
      section: section || '',
    });
    if (response) {
      yield put({
        type: ActionTypes.SHARE_LINK_SUCCESS,
        data: response.data.deepLink,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.SHARE_LINK_FAIL,
      data: e.response.data.message,
    });
  }
}

// All sagas to be loaded
export default function* favoriteListSaga() {
  yield takeEvery(ActionTypes.GET_FAVORITE_LIST, fetchGetDefault);
  yield takeEvery(ActionTypes.ADD_TO_FAVORITE, fetchAddToFavorites);
  yield takeEvery(ActionTypes.DELETE_FAVORITE, fetchDeleteFromFavorites);
  yield takeEvery(ActionTypes.SHARE_LINK, fetchShareLink);
}
