/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectGlobal = state => (state && state.global) || initialState;
const selectRouter = state => (state && state.router) || initialState;

const makeSelectGlobal = () =>
  createSelector(
    selectGlobal,
    globalState => globalState,
  );
const makeSelectRouter = () =>
  createSelector(
    selectRouter,
    routerState => routerState,
  );
export { selectGlobal, makeSelectGlobal, makeSelectRouter };
