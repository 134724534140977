/*
 *
 * ByModel constants
 *
 */

export const GET_BYMODEL = 'app/Exercises/GET_BYMODEL';
export const GET_BYMODEL_SUCCESS = 'app/Exercises/GET_BYMODEL_SUCCESS';
export const GET_BYMODEL_FAIL = 'app/Exercises/GET_BYMODEL_FAIL';

export const GET_BYMODEL_MUSCLES = 'app/Exercises/GET_BYMODEL_MUSCLES';
export const GET_BYMODEL_MUSCLES_SUCCESS = 'app/Exercises/GET_BYMODEL_MUSCLES_SUCCESS';
export const GET_BYMODEL_MUSCLES_FAIL = 'app/Exercises/GET_BYMODEL_MUSCLES_FAIL';

export const GET_MUSCULAR = 'app/Exercises/GET_MUSCULAR';
export const GET_MUSCULAR_SUCCESS = 'app/Exercises/GET_MUSCULAR_SUCCESS';
export const GET_MUSCULAR_FAIL = 'app/Exercises/GET_MUSCULAR_FAIL';
