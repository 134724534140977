export default theme => ({
  wrap: {
    padding: `${theme.spacing(12)}px ${theme.spacing()}px 0`,
  },
  letterBox: {
    marginBottom: theme.spacing(3.5),
  },
  letter: {
    margin: `0 0 ${theme.spacing(3.5)}px ${theme.spacing(7.5)}px`,
  },
  link: {
    display: 'inline-flex',
    margin: `${theme.spacing(0.8)}px 0 ${theme.spacing(0.8)}px ${theme.spacing(7.5)}px`,
    fontSize: 16,
    '&:hover span': {
      color: theme.palette.MMColors.fox,
    },
    '& span': {
      transition: `color 0.2s ${theme.transitions.easing.easeInOut}`,
    },
    '& svg': {
      margin: '-4px 4px -2px 0',
      fontSize: 20,
    },
  },
  linkLock: {
    marginLeft: theme.spacing(4.5),
  },
  navBox: {
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'fixed',
    padding: `${theme.spacing(1.5)}px 0`,
    zIndex: 1,
    top: 'calc(50% + 74px)',
    right: theme.spacing(3),
    maxHeight: 'calc(100vh - 124px)',
    transform: 'translateY(-50%)',
  },
  navBtn: {
    display: 'block',
    border: 'none',
    background: 'none',
    marginBottom: theme.spacing(0.5),
    cursor: 'pointer',
    '&:not(.active)': {
      transition: `all 0.2s ${theme.transitions.easing.easeInOut}`,
      '&:hover': {
        color: theme.palette.MMColors.fox,
        transform: 'scale(1.8)',
      },
    },
    '&.active': {
      color: theme.palette.MMColors.fox,
      transform: 'scale(1.8)',
    },
  },
  filterBtnsWrap: {
    position: 'fixed',
    zIndex: 10,
    top: 60,
    left: 300,
    padding: `${theme.spacing(3)}px 0 0 ${theme.spacing(7.25)}px`,
    width: 'calc(100% - 300px)',
    background: theme.palette.MMColors.deepMain,
  },
  filterBtn: {
    position: 'relative',
    zIndex: 1,
    margin: `0 ${theme.spacing(3)}px ${theme.spacing(2)}px 0`,
    padding: `${theme.spacing(0.125)}px ${theme.spacing(2)}px`,
    minWidth: 60,
    color: theme.palette.MMColors.fox,
    lineHeight: '36px',
    border: `2px solid ${theme.palette.MMColors.fox}`,
    borderRadius: 20,
    background: 'transparent',
    transition: `all 0.3s ${theme.transitions.easing.easeInOut}`,
    '&:hover': {
      color: '#000',
      background: theme.palette.MMColors.fox,
    },
    '&.active': {
      color: '#000',
      background: theme.palette.MMColors.fox,
    },
  },
});
