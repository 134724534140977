import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from './styles';
import { PRICING_PAGE } from '../../utils/constants';
import adjust from '../../adjust';
import { setEntryPoint, setItemLockId, setItemLockType, mixpanelStorage, itemLockTypeFBtoMP } from '../../localStorage';

export class SmartLink extends React.PureComponent {
  render() {
    const {
      to,
      disableTrackPricingPage,
      children,
      itemLockType,
      itemLockId,
      itemName,
      trackOnClick,
      ...rest
    } = this.props;
    let url;
    if (typeof to === 'string') url = to;
    else url = to.pathname;

    if (url.startsWith('http')) {
      return (
        <a href={url} {...rest} target="_blank">
          {children}
        </a>
      );
    }
    return (
      <Link
        to={to}
        {...rest}
        onClick={e => {
          if (rest.onClick) {
            rest.onClick(e);
          }
          if (trackOnClick) {
            trackOnClick();
          }
          if (to === PRICING_PAGE && !disableTrackPricingPage) {
            adjust.trackPricing('Lock');
            setEntryPoint('itemLock');
            mixpanelStorage.setItem('entryPoint', 'Item Lock');
            if (itemLockType) {
              mixpanelStorage.setItem('itemLockType', itemLockTypeFBtoMP[itemLockType] || itemLockType);
              setItemLockType(itemLockType);
            }
            if (itemLockId) {
              setItemLockId(itemLockId);
              mixpanelStorage.setItem('itemId', itemLockId);
            }
            if (itemName) {
              mixpanelStorage.setItem('itemName', itemName);
            }
          }
        }}
      >
        {children}
      </Link>
    );
  }
}

SmartLink.propTypes = {
  video: PropTypes.object,
};

const SmartLinkStyled = withStyles(styles)(SmartLink);
export default SmartLinkStyled;
