/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect, memo } from 'react';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import Notification from 'components/Notification';
import ChangeForgetPassDialog from 'components/ChangeForgetPassDialog';
import Header from 'components/Header/Loadable';
import * as firebase from 'firebase';
import { includes, forEach } from 'lodash';
import 'firebase/analytics';
import ReactGA from 'googleAnalytics';
import adjust from '../../adjust';
import mixpanel from '../../mixpanel';
import reducer from './reducer';
import saga from './saga';
import { makeSelectGlobal, makeSelectRouter } from './selectors';
import GlobalStyle from '../../global-styles';
import MMStylesTheme from '../../themes/MMStylesTheme';
import routes from '../../router';
import * as actions from './actions';
import logo from '../../img/logo.png';
import { defineFirebaseConfigs, CURRENT_VERTICAL } from '../../verticalConfigs';
import { facebook } from '../../facebook';
import axios from '../../utils/axiosConfig';
import * as languageActions from '../LanguageProvider/actions';
import styles from './styles';

const App = memo(
  ({
    global: { notification, user, submuscles, firebaseDB },
    fetchGetUserData,
    closeNotification,
    history,
    getSubMuscles,
    router,
    dispatch,
    classes,
    getFirebase,
    changeLocale,
  }) => {
    const key = 'global';
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga });

    const defineTitle = () => {
      let title = 'Strength Training';
      if (CURRENT_VERTICAL === 'yoga') title = 'Yoga';
      if (CURRENT_VERTICAL === 'posture') title = 'Posture';
      if (CURRENT_VERTICAL === 'anatomy') title = 'Anatomy';
      return title;
    };

    useEffect(() => {
      if (!user.data && localStorage.getItem('token')) {
        fetchGetUserData();
      }
    }, []);

    useEffect(() => {
      if (localStorage.getItem('language') && history.location.pathname === '/login') {
        localStorage.removeItem('language');
        axios.setLanguage('en');
        changeLocale('en');
      }
      if (!firebaseDB.loaded && !firebaseDB.loading) {
        getFirebase(dispatch);
      }
    }, [history.location.pathname]);

    useEffect(() => {
      adjust.init();
      mixpanel.init();
      window.trackLaunchOrFirstLaunch = adjust.trackLaunchOrFirstLaunch;
      setTimeout(() => {
        adjust.trackLaunchOrFirstLaunch();
      }, 5000);
    }, []);

    useEffect(() => {
      if (process.env.REACT_APP_SERVER === 'development') return;
      axios.get(`${window.location.origin}/version.txt`).then(res => {
        if (res.status !== 200) return;
        const actualVersion = res.data.trim();
        const currentVersionAttr = document.querySelector('[data-app-version]');
        if (!currentVersionAttr) return;
        const currentVersion = currentVersionAttr.getAttribute('data-app-version').trim();
        if (actualVersion !== currentVersion) {
          window.location.reload();
        }
      });
    }, [history.location.pathname]);

    useEffect(() => {
      if (!submuscles.loaded && !submuscles.loading) {
        getSubMuscles();
      }
    }, []);

    useEffect(() => {
      if (!firebaseDB.loaded && !firebaseDB.loading) {
        getFirebase(dispatch);
      }
    }, []);

    useEffect(() => {
      ReactGA.init();
    }, []);
    useEffect(() => {
      // eslint-disable-next-line
      const checkSenderId = history.location.search.match(/sender_id=([^&]*)/);
      if (checkSenderId && checkSenderId.length) {
        axios.setSenderId(checkSenderId[1]);
        // eslint-disable-next-line
        history.push(location.pathname);
        setTimeout(() => {
          axios.resetSenderId();
        }, 5000);
      }
    }, [history.location.search]);

    useEffect(() => {
      if (history.location.state && history.location.state.sender_id) {
        axios.setSenderId(history.location.state.sender_id);
      }
    }, [history.location.state]);

    useEffect(() => {
      const firstLocation = sessionStorage.getItem('firstLocation') || '';
      if (history.location.pathname.match(/^\/sender_id/)) return;
      if (!firstLocation) {
        sessionStorage.setItem('firstLocation', history.location.pathname);
        return;
      }
      if (firstLocation === history.location.pathname) return;
      // eslint-disable-next-line
      setTimeout(() => {
        sessionStorage.removeItem('firstLocation');
        axios.resetSenderId();
        const { sender_id, ...restState } = history.location.state || {};
        history.replace(history.location.pathname, restState);
      }, 5000);
    }, [history.location.pathname]);

    // eslint-disable-next-line camelcase
    const fetchDefinePay = ({ email, language_id, plan_id }) => {
      if (!firebase.apps.length) {
        firebase.initializeApp(defineFirebaseConfigs());
      }
      const analytics = firebase.analytics();
      analytics.logEvent('subscription_mm_paid', { email, language_id, plan_id });
      facebook.init();
      setTimeout(() => {
        facebook.logEvent('subscription_mm_paid', null, { email, language_id, plan_id });
      }, 2000);
    };
    if (history.location.search && includes(history.location.search, '?pay-plan=')) {
      const dataObj = {};
      forEach(history.location.search.split('&'), item => {
        // eslint-disable-next-line prefer-destructuring
        if (includes(item, '?pay-plan=')) dataObj.plan_id = item.split('?pay-plan=')[1];
        // eslint-disable-next-line prefer-destructuring
        else if (includes(item, 'language=')) dataObj.language_id = item.split('language=')[1];
        // eslint-disable-next-line prefer-destructuring
        else if (includes(item, 'email=')) dataObj.email = item.split('email=')[1];
      });
      fetchDefinePay(dataObj);
    }

    if (history.location.pathname === '/' && localStorage.getItem('token')) history.push('/new-popular');
    else if (
      history.location.pathname === '/' ||
      (history.location.pathname === '/login' && window.location.pathname !== '/login')
    )
      history.push('/login');

    const checkBrowser = () => {
      let val = false;
      if (
        // (navigator.userAgent.search(/Chrome/) > 0 ||
        //   navigator.userAgent.search(/Firefox/) > 0 ||
        //   navigator.userAgent.search(/Opera/) > 0) &&
        // navigator.userAgent.search(/Edge/) < 0 &&
        // navigator.userAgent.search(/MSIE/) < 0 &&
        // navigator.userAgent.search(/Trident/) < 0
        (navigator.userAgent.search(/Chrome/) > 0 ||
          navigator.userAgent.search(/Firefox/) > 0 ||
          navigator.userAgent.search(/Opera/) > 0 ||
          navigator.userAgent.search(/Safari/) > 0 ||
          navigator.userAgent.search(/Edge/) > 0) &&
        navigator.userAgent.search(/MSIE/) < 0 &&
        navigator.userAgent.search(/Trident/) < 0
      ) {
        val = true;
      }
      return val;
    };

    return (
      <MuiThemeProvider theme={MMStylesTheme}>
        <GlobalStyle />
        <Header userRole={user} history={history} router={router} />
        {checkBrowser() ? (
          <>
            {routes()}
            <Notification
              isOpen={notification.open}
              textMsg={notification.text}
              handleCloseNotification={() => closeNotification()}
              variant={notification.variant}
            />
            <ChangeForgetPassDialog />
          </>
        ) : (
          <div className={classes.warningWrap}>
            <div className={classes.container}>
              <h1 className={classes.title}>{`Thank you for reaching ${defineTitle()} by M&M!`}</h1>
              <h2 className={classes.label}>
                Our platform is optimized for Chrome and Firefox browser experience.
                <br />
                In order to have proper working system, please launch chrome or firefox
                <br />
                and use the same link address to reach the system.
                <span>
                  Thank you,
                  <br />
                  M&M Team
                </span>
              </h2>
              <img src={logo} alt="Muscle & Motion" className={classes.logo} />
            </div>
          </div>
        )}
      </MuiThemeProvider>
    );
  },
  isEqual,
);

function isEqual(prevProps, props) {
  const {
    global: { search: prevSearch },
  } = prevProps;
  const {
    history,
    global: { search },
  } = props;
  if (prevSearch.loading && !search.loading) {
    history.push('/search-result', {
      backPath: history.location.pathname !== '/search-result' ? history.location.pathname : '/new-popular',
    });
  }
}

App.propTypes = {
  global: PropTypes.object,
  history: PropTypes.object,
  router: PropTypes.object,
  classes: PropTypes.object,
  closeNotification: PropTypes.func,
  getSubMuscles: PropTypes.func,
  changeLocale: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  global: makeSelectGlobal(),
  router: makeSelectRouter(),
});

export function mapDispatchToProps(dispatch) {
  return {
    fetchGetUserData: bindActionCreators(actions.getUserData, dispatch),
    closeNotification: bindActionCreators(actions.closeNotification, dispatch),
    getSubMuscles: bindActionCreators(actions.getSubMuscles, dispatch),
    getFirebase: bindActionCreators(actions.getFirebase, dispatch),
    changeLocale: bindActionCreators(languageActions.changeLocale, dispatch),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(App));
