import { defineFireBaseQAorPRODObject } from '../../verticalConfigs';

const mergeDeep = (...objects) => {
  const isObject = obj => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach(key => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
};

const prepareFirebaseValuesWithTranlsation = firebaseDB => {
  const language = localStorage.getItem('language');
  if (defineFireBaseQAorPRODObject() === 'qa') {
    firebaseDB = firebaseDB.qa;
  }
  if (language === 'en' || !language) {
    return firebaseDB.ui;
  }
  const mergedFirebaseDB = mergeDeep(firebaseDB.ui, firebaseDB[`ui_${language}`]);
  return mergedFirebaseDB;
};

export default prepareFirebaseValuesWithTranlsation;
