export default theme => ({
  body: {
    ...theme.confirmPopup.body,
  },
  btnBox: {
    ...theme.confirmPopup.btnBox,
  },
  btn: {
    ...theme.confirmPopup.btn,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancelPopupBtn: {
    ...theme.confirmPopup.cancelPopupBtn,
  },
  btnDisabled: {
    opacity: 0.5,
  },
});
