/*
 *
 * AuthReg reducer
 *
 */
import produce from 'immer';
import * as ActionTypes from './constants';

export const initialState = {
  loading: false,
  logged: false,
  error: null,
};

/* eslint-disable default-case, no-param-reassign */
const authRegReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.CLEAR_CHANGE_FORGET_PASS:
        draft.dataReset = null;
        draft.dataResetPass = null;
        draft.loadedResetPass = false;
        draft.loadedReset = false;
        break;
      case ActionTypes.RESET_PASS:
        draft.loadingResetPass = true;
        draft.loadedResetPass = false;
        draft.dataResetPass = null;
        break;
      case ActionTypes.RESET_PASS_SUCCESS:
        draft.loadingResetPass = false;
        draft.loadedResetPass = true;
        draft.dataResetPass = action.data;
        draft.loadedReset = false;
        draft.dataReset = null;
        break;
      case ActionTypes.RESET_PASS_FAIL:
        draft.loadingResetPass = false;
        draft.loadedResetPass = false;
        draft.dataResetPass = null;
        draft.error = action.data;
        break;
      case ActionTypes.FORGET_PASS_EMAIL:
        draft.loadingReset = true;
        draft.loadedReset = false;
        draft.dataReset = null;
        break;
      case ActionTypes.FORGET_PASS_EMAIL_SUCCESS:
        draft.loadingReset = false;
        draft.loadedReset = true;
        draft.dataReset = action.data;
        break;
      case ActionTypes.FORGET_PASS_EMAIL_FAIL:
        draft.loadingReset = false;
        draft.loadedReset = false;
        draft.error = action.data;
        draft.dataReset = null;
        break;
      case ActionTypes.LOGIN_SOCIAL:
        draft.loading = true;
        draft.error = null;
        break;
      case ActionTypes.LOGIN_SOCIAL_SUCCESS:
        draft.loading = false;
        draft.logged = true;
        draft.error = null;
        break;
      case ActionTypes.LOGIN_SOCIAL_FAIL:
        draft.loading = false;
        draft.logged = false;
        draft.error = action.data;
        break;
      case ActionTypes.LOGIN_USER:
        draft.loading = true;
        draft.error = null;
        break;
      case ActionTypes.LOGIN_USER_SUCCESS:
        draft.loading = false;
        draft.logged = true;
        draft.error = null;
        break;
      case ActionTypes.LOGIN_USER_FAIL:
        draft.loading = false;
        draft.logged = false;
        draft.error = action.data;
        break;
      case ActionTypes.LOGOUT_USER:
        draft.loading = true;
        break;
      case ActionTypes.LOGOUT_USER_SUCCESS:
        draft.loading = false;
        draft.logged = false;
        break;
      case ActionTypes.LOGOUT_USER_FAIL:
        draft.loading = false;
        draft.logged = true;
        draft.error = action.data;
        break;
      case ActionTypes.REGISTRATION:
        draft.loading = true;
        break;
      case ActionTypes.REGISTRATION_SUCCESS:
        draft.loading = false;
        draft.logged = true;
        break;
      case ActionTypes.REGISTRATION_FAIL:
        draft.loading = false;
        draft.logged = false;
        draft.error = action.data;
        break;
    }
  });

export default authRegReducer;
