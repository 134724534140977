/*
 * AsideMenu Messages
 *
 * This contains all the text for the AsideMenu component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.AsideMenu';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the AsideMenu component!',
  },
  aboutUs: {
    id: `${scope}.aboutUs`,
    defaultMessage: 'About us',
  },
  termConditions: {
    id: `${scope}.termConditions`,
    defaultMessage: 'Terms & conditions',
  },
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: 'Privacy Policy',
  },
  findUs: {
    id: `${scope}.findUs`,
    defaultMessage: 'Find us also on:',
  },
  labelNewPopular: {
    id: `${scope}.labelNewPopular`,
    defaultMessage: 'New & Popular',
  },
  labelExercises: {
    id: `${scope}.labelExercises`,
    defaultMessage: 'Exercises',
  },
  subLabelExercises: {
    id: `${scope}.subLabelExercises`,
    defaultMessage: 'All Exercises',
  },
  subLabelByModel: {
    id: `${scope}.subLabelByModel`,
    defaultMessage: 'By Model',
  },
  subLabelAbsCore: {
    id: `${scope}.subLabelAbsCore`,
    defaultMessage: 'Abs & Core',
  },
  subLabelBodyweightCalisthenics: {
    id: `${scope}.subLabelBodyweightCalisthenics`,
    defaultMessage: 'Bodyweight & Calisthenics',
  },
  subLabelFunctionalTraining: {
    id: `${scope}.subLabelFunctionalTraining`,
    defaultMessage: 'Functional Training',
  },
  subLabelKettlebell: {
    id: `${scope}.subLabelKettlebell`,
    defaultMessage: 'Kettlebell',
  },
  subLabelStraps: {
    id: `${scope}.subLabelStraps`,
    defaultMessage: 'Straps',
  },
  subLabelStretching: {
    id: `${scope}.subLabelStretching`,
    defaultMessage: 'Stretching',
  },
  subLabelChest: {
    id: `${scope}.subLabelChest`,
    defaultMessage: 'Chest',
  },
  subLabelArms: {
    id: `${scope}.subLabelArms`,
    defaultMessage: 'Arms',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search for any video{path}',
  },
  searchStrength: {
    id: `${scope}.searchStrength`,
    defaultMessage: 'Search for any video/exercise',
  },
  searchPosture: {
    id: `${scope}.searchPosture`,
    defaultMessage: 'Search for any video',
  },
  searchAnatomy: {
    id: `${scope}.searchAnatomy`,
    defaultMessage: 'Search for any video/muscle',
  },
  searchYoga: {
    id: `${scope}.searchYoga`,
    defaultMessage: 'Search for any video/asana',
  },
  searchAsana: {
    id: `${scope}.searchAsana`,
    defaultMessage: 'Search for any video/asana',
  },
  searchMuscle: {
    id: `${scope}.searchMuscle`,
    defaultMessage: 'Search for any video/muscle/joint',
  },
  searchExercise: {
    id: `${scope}.searchExercise`,
    defaultMessage: 'Search for any video/exercise',
  },
  labelMuscularAnatomy: {
    id: `${scope}.labelMuscularAnatomy`,
    defaultMessage: 'Muscular Anatomy',
  },
  labelKinesiologySkeletal: {
    id: `${scope}.labelKinesiologySkeletal`,
    defaultMessage: 'Kinesiology & Skeletal',
  },
  labelTheory: {
    id: `${scope}.labelTheory`,
    defaultMessage: 'Theory',
  },
  labelAsanas: {
    id: `${scope}.labelAsanas`,
    defaultMessage: 'Asanas',
  },
  myofascialRelease: {
    id: `${scope}.myofascialRelease`,
    defaultMessage: 'Myofascial release',
  },
  generalPosturalTests: {
    id: `${scope}.generalPosturalTests`,
    defaultMessage: 'General Postural Tests',
  },
  labelTherapeuticExercises: {
    id: `${scope}.labelTherapeuticExercises`,
    defaultMessage: 'Therapeutic exercises',
  },
  labelArticles: {
    id: `${scope}.labelArticles`,
    defaultMessage: 'Articles',
  },
  labelEbook: {
    id: `${scope}.labelEbook`,
    defaultMessage: 'E-Book',
  },
  yogaAsanas: {
    id: `${scope}.yogaAsanas`,
    defaultMessage: 'Yoga asanas',
  },
  posturalDisorders: {
    id: `${scope}.posturalDisorders`,
    defaultMessage: 'Postural disorders',
  },
  az: {
    id: `${scope}.az`,
    defaultMessage: 'A-Z Index',
  },
  programs: {
    id: `${scope}.programs`,
    defaultMessage: 'Workout Plans',
  },
  plans: {
    id: `${scope}.plans`,
    defaultMessage: 'M&M Plans',
  },
  myPlans: {
    id: `${scope}.myPlans`,
    defaultMessage: 'My Plans',
  },
  clients: {
    id: `${scope}.clients`,
    defaultMessage: 'My Clients',
  },
  hideBarTitle: {
    id: `${scope}.hideBarTitle`,
    defaultMessage: 'Hide bar!',
  },
  showBarTitle: {
    id: `${scope}.showBarTitle`,
    defaultMessage: 'Show bar!',
  },
});
