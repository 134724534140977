/*
 *
 * MyFolders constants
 *
 */

export const CLEAN_ERROR = 'app/MyFolders/CLEAN_ERROR';

export const GET_ALL_FOLDERS = 'app/MyFolders/GET_ALL_FOLDERS';
export const GET_ALL_FOLDERS_SUCCESS = 'app/MyFolders/GET_ALL_FOLDERS_SUCCESS';
export const GET_ALL_FOLDERS_FAIL = 'app/MyFolders/GET_ALL_FOLDERS_FAIL';

export const ADD_NEW_FOLDER = 'app/FolderContainer/ADD_NEW_FOLDER';
export const ADD_NEW_FOLDER_SUCCESS = 'app/FolderContainer/ADD_NEW_FOLDER_SUCCESS';
export const ADD_NEW_FOLDER_FAIL = 'app/FolderContainer/ADD_NEW_FOLDER_FAIL';

export const DELETE_FOLDER = 'app/FolderContainer/DELETE_FOLDER';
export const DELETE_FOLDER_SUCCESS = 'app/FolderContainer/DELETE_FOLDER_SUCCESS';
export const DELETE_FOLDER_FAIL = 'app/FolderContainer/DELETE_FOLDER_FAIL';

export const ADD_TO_FOLDER = 'app/FolderContainer/ADD_TO_FOLDER';
export const ADD_TO_FOLDER_SUCCESS = 'app/FolderContainer/ADD_TO_FOLDER_SUCCESS';
export const ADD_TO_FOLDER_FAIL = 'app/FolderContainer/ADD_TO_FOLDER_FAIL';

export const RENAME_FOLDER = 'app/FolderContainer/RENAME_FOLDER';
export const RENAME_FOLDER_SUCCESS = 'app/FolderContainer/RENAME_FOLDER_SUCCESS';
export const RENAME_FOLDER_FAIL = 'app/FolderContainer/RENAME_FOLDER_FAIL';
