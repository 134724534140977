/**
 *
 * AzListItem
 *
 */

import React, { memo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import LockIcon from '@material-ui/icons/Lock';
import Tooltip from '@material-ui/core/Tooltip';
import lodashHas from 'lodash/has';
import comingSoonExerciseItemMessages from 'components/ComingSoonExerciseItem/messages';
import styles from './styles';
import { PRICING_PAGE } from '../../utils/constants';

function AzListItem({ intl: { formatMessage }, classes, item, link = '' }) {
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipClose = () => {
    if (openTooltip) setOpenTooltip(false);
  };

  const handleTooltipOpen = elem => {
    if (
      elem.nextElementSibling &&
      elem.nextElementSibling.offsetWidth &&
      elem.nextElementSibling.offsetWidth > elem.offsetWidth
    ) {
      setOpenTooltip(true);
    }
  };

  const isComingSoonItem = lodashHas(item, 'comingsoon') && item.comingsoon;

  return (
    <Tooltip
      title={item.name ? item.name : ''}
      PopperProps={{
        disablePortal: true,
      }}
      classes={{ tooltip: classes.tooltip }}
      placement="top"
      open={openTooltip}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    >
      <div className={link === PRICING_PAGE ? `${classes.box} ${classes.boxLock}` : classes.box}>
        {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
        <span className={classes.name} onMouseOver={e => handleTooltipOpen(e.target)} onMouseLeave={handleTooltipClose}>
          {link === PRICING_PAGE && <LockIcon />}
          {item.name}
        </span>
        {isComingSoonItem && (
          <span className={classes.comingSoonTitle}>{formatMessage(comingSoonExerciseItemMessages.comingSoon)}</span>
        )}
        <span className={classes.copyName}>
          {link === PRICING_PAGE && <LockIcon />}
          {item.name}
        </span>
      </div>
    </Tooltip>
  );
}

AzListItem.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object,
  link: PropTypes.string,
  intl: intlShape.isRequired,
};

const AzListItemIntl = injectIntl(AzListItem);

const AzListItemStyled = withStyles(styles)(AzListItemIntl);

export default memo(AzListItemStyled);
