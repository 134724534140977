import { takeLatest, put } from 'redux-saga/effects';

import * as firebase from 'firebase/app';
import mixpanel from 'mixpanel';
import axios from '../../utils/axiosConfig';
import * as ActionTypes from './constants';
import * as LanguageAction from '../LanguageProvider/constants';
import 'firebase/database';
import { defineFirebaseConfigs } from '../../verticalConfigs';
import { appVersion } from '../../utils/parameters';
import { mixpanelStorage } from '../../localStorage';

export function* fetchUserData() {
  try {
    const response = yield axios.get('users/current');

    if (response) {
      if (response.data.measuring_system) {
        localStorage.setItem('systemOfUnits', response.data.measuring_system);
      }
      yield put({
        type: ActionTypes.GET_USER_DATA_SUCCESS,
        data: response.data,
      });
      mixpanel.registerSuperProperties(response.data);
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_USER_DATA_FAIL,
      data: e.response.data.message,
    });
  }
}

export function* fetchUserUpdate({ fields }) {
  try {
    const response = yield axios.put('users/current', {
      ...fields,
      is_termsofuse: true,
      os_version: 'webApp',
      app_version: appVersion,
    });

    if (response) {
      yield put({
        type: ActionTypes.GET_USER_DATA_SUCCESS,
        data: response.data,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.USER_UPDATE_FAIL,
      data: e.response.data.message,
    });
  }
}

export function* fetchUserChangeLocale({ lang }) {
  try {
    const response = yield axios.put('users/current', {
      is_termsofuse: true,
      language_id: lang,
      os_version: 'webApp',
      app_version: appVersion,
    });

    if (response) {
      yield put({
        type: ActionTypes.GET_USER_DATA_SUCCESS,
        data: response.data,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.USER_CHANGE_LOCALE_FAIL,
      data: e.response.data.message,
    });
  }
}

export function* fetchGetSubmuscles() {
  try {
    const response = yield axios.get('muscular/submuscles?limit=-1&offset=0');

    if (response) {
      yield put({
        type: ActionTypes.GET_SUBMUSCLES_SUCCESS,
        data: response.data,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_SUBMUSCLES_FAIL,
      data: e.response.data.message,
    });
  }
}

export function* fetchChangeLocale() {
  yield put({
    type: ActionTypes.GET_SUBMUSCLES,
  });
}

export function* fetchGetSearch({ text }) {
  try {
    const response = yield axios.get(`search?search=${text}`);

    if (response) {
      yield put({
        type: ActionTypes.GET_SEARCH_SUCCESS,
        data: response.data,
        value: text,
      });

      const countResults = Object.keys(response.data).reduce((result, key) => {
        if (!Array.isArray(response.data[key])) return result;

        return result + response.data[key].length;
      }, 0);
      mixpanelStorage.setItem('searchResultsCount', countResults);
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_SEARCH_FAIL,
      data: e.response.data.message,
    });
  }
}
export function* fetchGetFirebase({ dispatch }) {
  try {
    if (!firebase.apps.length) {
      firebase.initializeApp(defineFirebaseConfigs());
    }
    const cardsRef = firebase.database().ref();
    cardsRef.on('value', snapshot => {
      dispatch({
        type: ActionTypes.GET_FIREBASE_SUCCESS,
        data: snapshot.val(),
      });
      // cardsRef.off('value');
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_FIREBASE_FAIL,
      data: e,
    });
  }
}

export function* fetchGetMenuItemForVideo({ idName, id, section }) {
  const cleanId = id.split('?')[0];
  try {
    const urlId = section ? `${idName}=${cleanId}&section=${section}` : `${idName}=${cleanId}`;
    const response = yield axios.get(`just_video_index/videocheck?${urlId}`);
    if (response) {
      yield put({
        type: ActionTypes.GET_MENU_ITEM_FOR_VIDEO_SUCCESS,
        data: response.data,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_MENU_ITEM_FOR_VIDEO_FAIL,
      data: e.response.data.message,
    });
  }
}

// All sagas to be loaded
export default function* appSaga() {
  yield takeLatest(ActionTypes.GET_USER_DATA, fetchUserData);
  yield takeLatest(ActionTypes.GET_SUBMUSCLES, fetchGetSubmuscles);
  yield takeLatest(ActionTypes.GET_SEARCH, fetchGetSearch);
  yield takeLatest(LanguageAction.CHANGE_LOCALE, fetchChangeLocale);
  yield takeLatest(ActionTypes.GET_FIREBASE, fetchGetFirebase);
  yield takeLatest(ActionTypes.GET_MENU_ITEM_FOR_VIDEO, fetchGetMenuItemForVideo);
  yield takeLatest(ActionTypes.USER_CHANGE_LOCALE, fetchUserChangeLocale);
  yield takeLatest(ActionTypes.USER_UPDATE, fetchUserUpdate);
}
