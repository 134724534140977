/*
 *
 * FavoriteList constants
 *
 */

export const SHARE_LINK = 'app/FavoriteList/SHARE_LINK';
export const SHARE_LINK_SUCCESS = 'app/FavoriteList/SHARE_LINK_SUCCESS';
export const SHARE_LINK_FAIL = 'app/FavoriteList/SHARE_LINK_FAIL';
export const SHARE_LINK_CLEAN = 'app/FavoriteList/SHARE_LINK_CLEAN';

export const ADD_TO_FAVORITE = 'app/FavoriteList/ADD_TO_FAVORITE';
export const ADD_TO_FAVORITE_SUCCESS = 'app/FavoriteList/ADD_TO_FAVORITE_SUCCESS';
export const ADD_TO_FAVORITE_FAIL = 'app/FavoriteList/ADD_TO_FAVORITE_FAIL';

export const DELETE_FAVORITE = 'app/FavoriteList/DELETE_FAVORITE';
export const DELETE_FAVORITE_SUCCESS = 'app/FavoriteList/DELETE_FAVORITE_SUCCESS';
export const DELETE_FAVORITE_FAIL = 'app/FavoriteList/DELETE_FAVORITE_FAIL';

export const GET_FAVORITE_LIST = 'app/FavoriteList/GET_FAVORITE_LIST';
export const GET_FAVORITE_LIST_SUCCESS = 'app/FavoriteList/GET_FAVORITE_LIST_SUCCESS';
export const GET_FAVORITE_LIST_FAIL = 'app/FavoriteList/GET_FAVORITE_LIST_FAIL';
