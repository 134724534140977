/*
 *
 * FavoriteList actions
 *
 */

import * as ActionTypes from './constants';

export function shareLink(id, chapter, section) {
  return {
    type: ActionTypes.SHARE_LINK,
    id,
    chapter,
    section,
  };
}
export function shareLinkClean() {
  return {
    type: ActionTypes.SHARE_LINK_CLEAN,
  };
}
export function addToFavoriteList(id, assetId, chapter, section, subId) {
  return {
    type: ActionTypes.ADD_TO_FAVORITE,
    id,
    assetId,
    chapter,
    section,
    subId,
  };
}
export function deleteFromFavoriteList(id) {
  return {
    type: ActionTypes.DELETE_FAVORITE,
    id,
  };
}
export function getFavoriteList() {
  return {
    type: ActionTypes.GET_FAVORITE_LIST,
  };
}
