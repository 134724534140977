/* eslint-disable */
import { defineFacebookConfigs } from 'verticalConfigs';

export const facebook = {
    init: function() {
        if (typeof window.fbAsyncInit === 'undefined') {
            console.log('init');
            window.fbAsyncInit = function() {
                FB.init(defineFacebookConfigs());

                FB.AppEvents.logPageView();

            };

            (function(d, s, id){
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {return;}
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk.js';
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        } else {

            console.log('already inited');
        }
  },
    logEvent: function(event, number, params) {
        console.log(FB);
        FB.AppEvents.logEvent(event, number, params);
    },
};