import { put, takeLatest } from 'redux-saga/effects';
import { cloneDeep, map } from 'lodash';
import * as ActionTypes from './constants';
import axios from '../../utils/axiosConfig';
import * as LanguageAction from '../LanguageProvider/constants';

let muscleIdSave = null;

export function* fetchGetSubmuscleById({ muscleId }) {
  muscleIdSave = muscleId;
  try {
    const response = yield axios.get(`muscular/submuscles/${muscleId}`);
    if (response) {
      const fetchSetDescriptionInLines = () => {
        const newData = cloneDeep(response.data);
        const { submuscle } = newData;
        const fetchSetVideoBlock = key => {
          submuscle.description[`${key}`] = map(submuscle.description[`${key}`].split('\n\n'), p => p.split('\n'));
        };
        if (submuscle && submuscle.description) {
          if (submuscle.description.structure) fetchSetVideoBlock('structure');
          if (submuscle.description.actions) fetchSetVideoBlock('actions');
        }
        if (submuscle && submuscle.parts.length) {
          submuscle.parts = map(submuscle.parts, part => {
            const newPart = cloneDeep(part);
            const fetchSetImgBlock = key => {
              newPart[`${key}`] = map(newPart[`${key}`].split('\n\n'), p => p.split('\n'));
            };
            if (part.origin) fetchSetImgBlock('origin');
            if (part.insertion) fetchSetImgBlock('insertion');
            return newPart;
          });
        }
        return newData;
      };
      yield put({
        type: ActionTypes.GET_SUBMUSCLE_DETAILS_SUCCESS,
        data: fetchSetDescriptionInLines(),
      });
    }
  } catch (e) {
    if (e.response.status === 404) {
      window.location.href = '/404';
    }
    yield put({
      type: ActionTypes.GET_SUBMUSCLE_DETAILS_FAIL,
      data: e.response.data.message,
    });
  }
}

export function* fetchChangeLocale() {
  if (muscleIdSave) {
    yield put({
      type: ActionTypes.GET_SUBMUSCLE_DETAILS,
      muscleId: muscleIdSave,
    });
  }
}
// All sagas to be loaded
export default function* subMuscleSaga() {
  yield takeLatest(ActionTypes.GET_SUBMUSCLE_DETAILS, fetchGetSubmuscleById);

  yield takeLatest(LanguageAction.CHANGE_LOCALE, fetchChangeLocale);
}
