import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the byModel state domain
 */

const selectByModelDomain = state => state.byModel || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ByModel
 */

const makeSelectByModel = () =>
  createSelector(
    selectByModelDomain,
    substate => substate,
  );

export default makeSelectByModel;
export { selectByModelDomain };
