import { takeLatest, put } from 'redux-saga/effects';
import * as ActionTypes from './constants';
import axios from '../../utils/axiosConfig';

export function* fetchGetTheoryCategories() {
  try {
    const response = yield axios.get('just_video_index/categories');
    if (response) {
      yield put({
        type: ActionTypes.GET_THEORY_CATEGORIES_SUCCESS,
        data: response.data.categories,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_THEORY_CATEGORIES_FAIL,
      data: e.response.data.message,
    });
  }
}
export function* fetchGetTheorySubCategories({ subId, section }) {
  try {
    const response = yield axios.get(
      `just_video_index/subcategories/${subId}${typeof section !== 'undefined' ? `?section=${section}` : ''}`,
    );
    if (response) {
      yield put({
        type: ActionTypes.GET_THEORY_SUBCATEGORIES_SUCCESS,
        data: response.data.subcategory,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_THEORY_SUBCATEGORIES_FAIL,
      data: e.response.data.message,
    });
  }
}
export function* fetchGetAsanasCategories() {
  try {
    const response = yield axios.get(`just_video_index/categories?section=asanas`);
    if (response) {
      yield put({
        type: ActionTypes.GET_ASANAS_CATEGORIES_SUCCESS,
        data: response.data.categories,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_THEORY_CATEGORIES_FAIL,
      data: e.response.data.message,
    });
  }
}
export function* fetchGetMyofascialCategoryAction() {
  try {
    const response = yield axios.get(`just_video_index/categories?section=myofascial`);
    if (response) {
      yield put({
        type: ActionTypes.GET_MYOFASCIAL_CATEGORY_SUCCESS,
        data: response.data.categories,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_MYOFASCIAL_CATEGORYS_FAIL,
      data: e.response.data.message,
    });
  }
}
export function* fetchGetPosturalTests() {
  try {
    const response = yield axios.get(`just_video_index/categories?section=postural_tests`);
    if (response) {
      yield put({
        type: ActionTypes.GET_POSTURAL_TESTS_SUCCESS,
        data: response.data.categories,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_POSTURAL_TESTS_FAIL,
      data: e.response.data.message,
    });
  }
}
export function* fetchGetTherapeuticAsanasCategories() {
  try {
    const response = yield axios.get(`just_video_index/categories?section=therapeutic_asanas`);
    if (response) {
      yield put({
        type: ActionTypes.GET_TERAPETIC_CATEGORIES_SUCCESS,
        data: response.data.categories,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_TERAPETIC_CATEGORIES_FAIL,
      data: e.response.data.message,
    });
  }
}
export function* fetchGetTheraupeticPosturalCategories() {
  try {
    const response = yield axios.get('just_video_index/categories?section=therapeutics');
    if (response) {
      yield put({
        type: ActionTypes.GET_TERAPETIC_CATEGORIES_SUCCESS,
        data: response.data.categories,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_TERAPETIC_CATEGORIES_FAIL,
      data: e.response.data.message,
    });
  }
}
export function* fetchGetPosturalDisordersCategories() {
  try {
    const response = yield axios.get('just_video_index/categories?section=postural');
    if (response) {
      yield put({
        type: ActionTypes.GET_POSTURAL_DISORDERS_CATEGORIES_SUCCESS,
        data: response.data.categories,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_POSTURAL_DISORDERS_CATEGORIES_FAIL,
      data: e.response.data.message,
    });
  }
}
export function* fetchGetPostureYogaTheoryCategories() {
  try {
    const response = yield axios.get('just_video_index/categories?section=theory');
    if (response) {
      yield put({
        type: ActionTypes.GET_POSTURE_YOGA_THEORY_CATEGORIES_SUCCESS,
        data: response.data.categories,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_POSTURE_YOGA_THEORY_CATEGORIES_FAIL,
      data: e.response.data.message,
    });
  }
}

// All sagas to be loaded
export default function* theorySaga() {
  yield takeLatest(ActionTypes.GET_THEORY_CATEGORIES, fetchGetTheoryCategories);
  yield takeLatest(ActionTypes.GET_THEORY_SUBCATEGORIES, fetchGetTheorySubCategories);
  yield takeLatest(ActionTypes.GET_ASANAS_CATEGORIES, fetchGetAsanasCategories);
  yield takeLatest(ActionTypes.GET_MYOFASCIAL_CATEGORY, fetchGetMyofascialCategoryAction);
  yield takeLatest(ActionTypes.GET_POSTURAL_TESTS, fetchGetPosturalTests);
  yield takeLatest(ActionTypes.GET_TERAPETIC_CATEGORIES, fetchGetTherapeuticAsanasCategories);
  yield takeLatest(ActionTypes.GET_POSTURAL_CATEGORIES, fetchGetTheraupeticPosturalCategories);
  yield takeLatest(ActionTypes.GET_POSTURAL_DISORDERS_CATEGORIES, fetchGetPosturalDisordersCategories);
  yield takeLatest(ActionTypes.GET_POSTURE_YOGA_THEORY_CATEGORIES, fetchGetPostureYogaTheoryCategories);
}
