import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the simpleVideoPage state domain
 */

const selectSimpleVideoPageDomain = state => state.simpleVideoPage || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by SimpleVideoPage
 */

const makeSelectSimpleVideoPage = () =>
  createSelector(
    selectSimpleVideoPageDomain,
    substate => substate,
  );

export default makeSelectSimpleVideoPage;
export { selectSimpleVideoPageDomain };
