/*
 * ChangeForgetPassDialog Messages
 *
 * This contains all the text for the ChangeForgetPassDialog container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ChangeForgetPassDialog';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Restore my password',
  },
  submitBtn: {
    id: `${scope}.submitBtn`,
    defaultMessage: 'Submit',
  },
  resetCode: {
    id: `${scope}.resetCode`,
    defaultMessage: 'Reset Code',
  },
  newPass: {
    id: `${scope}.newPass`,
    defaultMessage: 'New Password',
  },
  confirmNewPass: {
    id: `${scope}.confirmNewPass`,
    defaultMessage: 'Confirm New Password',
  },
});
