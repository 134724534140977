import logo from '../../img/logo.png';

export default theme => ({
  wrap: {
    position: 'fixed',
    top: 0,
    left: 288,
    zIndex: theme.zIndex.tooltip + 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'calc(100% - 288px)',
    height: '100%',
  },
  box: {
    padding: theme.spacing(2),
    background: `url("${logo}") 50% no-repeat`,
    backgroundSize: '70%',
  },
  root: {
    '& svg circle': {
      color: '#df0f19',
    },
  },
});
