/**
 *
 * Azlist
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';
import classnames from 'classnames';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import AppPreloader from 'components/AppPreloader';
import ComingSoonPopup from 'components/Popups/ComingSoonPopup/Loadable';
import { CURRENT_VERTICAL } from 'verticalConfigs';

import AzListItem from 'components/AzListItem';
import _ from 'lodash';
import makeSelectAzlist from './selectors';
import reducer from './reducer';
import * as actions from './actions';
import saga from './saga';
import styles from './styles';
import asideMenuMessages from '../../components/AsideMenu/messages';
import { makeSelectGlobal } from '../App/selectors';
import { PRICING_PAGE } from '../../utils/constants';
import { SmartLink } from '../../components/SmartLink';

import messages from './messages';

export class Azlist extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterKey: this.fetchGetFilterKeyFromLocalStorage(),
      showFiltersFlag: false,
      isComingsoonPopupOpen: false,
    };
    this.mainSimpleBar = null;
    this.scrollItemsTopData = null;
    this.selectedItemIndex = null;
    this.scrollBarTop = 0;
    this.scrollItemIndex = 0;
    this.selectedBtn = false;
    this.filterLabels = {};
    this.navItems = {};
  }

  componentDidMount() {
    this.fetchSetFilterLabels();
  }

  fetchGetFilterKeyFromLocalStorage = () => {
    if (localStorage.getItem('a-z_back')) {
      const { filterKey } = JSON.parse(localStorage.getItem('a-z_back'));
      return filterKey;
    }
    return null;
  };

  fetchAddCustomScrollData = () => {
    setTimeout(() => {
      // eslint-disable-next-line prefer-destructuring
      this.mainSimpleBar = document
        .getElementById('main-simple-bar')
        .getElementsByClassName('simplebar-content-wrapper')[0];
      this.mainSimpleBar.addEventListener('scroll', e => {
        if (document.getElementById('az-nav-box')) {
          const AzNavBoxChildren = document.getElementById('az-nav-box').children;
          if (!this.scrollItemsTopData) {
            this.scrollItemsTopData = _.map(
              document.getElementById('az-main-box').children,
              child => child.offsetTop - 24,
            );
          } else if (e.target.scrollTop > this.scrollBarTop) {
            this.scrollBarTop = e.target.scrollTop;
            if (
              e.target.scrollTop + 90 >= this.scrollItemsTopData[this.scrollItemIndex + 1] &&
              this.scrollItemIndex < AzNavBoxChildren.length
            ) {
              AzNavBoxChildren[this.scrollItemIndex].classList.remove('active');
              AzNavBoxChildren[this.scrollItemIndex + 1].classList.add('active');
              this.scrollItemIndex += 1;
            }
          } else {
            this.scrollBarTop = e.target.scrollTop;
            if (e.target.scrollTop + 90 <= this.scrollItemsTopData[this.scrollItemIndex] && this.scrollItemIndex > 0) {
              this.scrollItemIndex -= 1;
              AzNavBoxChildren[this.scrollItemIndex + 1].classList.remove('active');
              AzNavBoxChildren[this.scrollItemIndex].classList.add('active');
              if (this.selectedBtn) {
                AzNavBoxChildren[this.selectedItemIndex].classList.remove('active', 'selected');
                this.selectedBtn = false;
              }
            }
          }

          if (
            this.scrollItemsTopData &&
            e.target.scrollTop + 90 >= e.target.scrollHeight - this.mainSimpleBar.offsetHeight &&
            this.scrollItemsTopData[this.selectedItemIndex] > e.target.scrollTop &&
            this.selectedBtn
          ) {
            AzNavBoxChildren[this.scrollItemIndex].classList.remove('active');
          }
        }
      });
      if (localStorage.getItem('a-z_back')) {
        // eslint-disable-next-line no-unused-vars
        const { scrollTop, link } = JSON.parse(localStorage.getItem('a-z_back'));
        // this.mainSimpleBar.scrollTo(0, scrollTop);
        // change for workability for edge
        this.mainSimpleBar.scrollTop = scrollTop;
        const data = _.map(document.getElementById('az-main-box').children, child => child.offsetTop - 24);
        let flag = true;
        _.forEach(data, (topNumb, index) => {
          if (topNumb > scrollTop && flag && index !== 0) {
            this.scrollItemIndex = index - 1;
            flag = false;
          }
        });
        this.scrollBarTop = scrollTop;
        document.getElementById('az-nav-box').children[0].classList.remove('active');
        document.getElementById('az-nav-box').children[this.scrollItemIndex].classList.add('active');
        localStorage.removeItem('a-z_back');
      }
    }, 100);
  };

  fetchSetFilterLabels = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    const vertical = CURRENT_VERTICAL;
    if (vertical === 'strength') {
      this.filterLabels.exercises = formatMessage(asideMenuMessages.labelExercises);
      this.filterLabels.videos = formatMessage(asideMenuMessages.labelTheory);
    } else if (vertical === 'yoga') {
      this.filterLabels.asanas = formatMessage(asideMenuMessages.labelAsanas);
      this.filterLabels.myofascial = formatMessage(asideMenuMessages.myofascialRelease);
      this.filterLabels.therapeutic_asanas = formatMessage(asideMenuMessages.labelTherapeuticExercises);
    } else if (vertical === 'posture') {
      this.filterLabels.postural_tests = formatMessage(asideMenuMessages.generalPosturalTests);
      this.filterLabels.postural = formatMessage(asideMenuMessages.posturalDisorders);
      this.filterLabels.therapeutics = formatMessage(asideMenuMessages.labelTherapeuticExercises);
      this.filterLabels.asanas = formatMessage(asideMenuMessages.yogaAsanas);
      this.filterLabels.myofascial = formatMessage(asideMenuMessages.myofascialRelease);
    } else if (vertical === 'anatomy') {
      this.filterLabels.videos = formatMessage(asideMenuMessages.labelTheory);
    }
    this.filterLabels.anatomy = formatMessage(asideMenuMessages.labelMuscularAnatomy);
    this.filterLabels.skeletal = formatMessage(asideMenuMessages.labelKinesiologySkeletal);
  };

  fetchSetRightTitle = (chapter, section) => {
    const {
      intl: { formatMessage },
    } = this.props;
    let label;
    const vertical = CURRENT_VERTICAL;
    if (section) {
      if (section === 'asanas') {
        if (vertical === 'yoga') label = formatMessage(asideMenuMessages.labelAsanas);
        if (vertical === 'posture') label = formatMessage(asideMenuMessages.yogaAsanas);
      } else if (section === 'therapeutic_asanas') label = formatMessage(asideMenuMessages.labelTherapeuticExercises);
      else if (section === 'postural') label = formatMessage(asideMenuMessages.posturalDisorders);
      else if (section === 'myofascial') label = formatMessage(asideMenuMessages.myofascialRelease);
      else if (section === 'postural_tests') label = formatMessage(asideMenuMessages.generalPosturalTests);
      else if (section === 'therapeutics') label = formatMessage(asideMenuMessages.labelTherapeuticExercises);
    } else if (chapter === 'exercises') label = formatMessage(asideMenuMessages.labelExercises);
    else if (chapter === 'videos' && vertical !== 'yoga' && vertical !== 'posture') {
      label = formatMessage(asideMenuMessages.labelTheory);
    } else if (chapter === 'skeletal') label = formatMessage(asideMenuMessages.labelKinesiologySkeletal);
    else if (chapter === 'anatomy') label = formatMessage(asideMenuMessages.labelMuscularAnatomy);
    return label;
  };

  fetchSetLink = item => {
    const {
      global: {
        user: { data },
      },
    } = this.props;
    let link;
    const sectionOnLink = val => (item.section && item.section !== '' ? `${val}@${item.section}` : val);
    const setLink = linkKey => {
      if (data) return data.is_paid || !item.is_paid ? sectionOnLink(linkKey) : PRICING_PAGE;
      return !item.is_paid ? sectionOnLink(linkKey) : PRICING_PAGE;
    };
    if (item.exercise_id && item.exercise_id !== '') link = setLink(`/exercise/${item.exercise_id}`);
    if (item.id && item.id !== '') link = setLink(`/submuscle/${item.id}`);
    if (item.target_id && item.target_id !== '') {
      if (_.includes(item.target_id, 'bone')) link = setLink(`/bone-video/${item.target_id}`);
      if (_.includes(item.target_id, 'joint')) link = setLink(`/bones/video/${item.target_id.split('_')[2]}`);
    }
    if (item.item_id && item.item_id !== '') link = setLink(`/theory-video/${item.item_id}`);
    return link;
  };

  smoothScroll = val => {
    let c = this.mainSimpleBar.scrollTop;
    const scrollDown = () => {
      c = this.mainSimpleBar.scrollTop;
      if (val >= c + 1 && c + 1 < this.mainSimpleBar.scrollHeight - document.body.offsetHeight) {
        window.requestAnimationFrame(scrollDown);
        this.mainSimpleBar.scrollTop = c + val / 10 > val ? val : c + val / 10;
      }
    };

    const scrollUp = () => {
      c = this.mainSimpleBar.scrollTop;
      if (val <= c + 1 && c > 0) {
        window.requestAnimationFrame(scrollUp);
        this.mainSimpleBar.scrollTop = c - val / 10 < val ? val : c - val / 10;
      }
    };

    if (val > c) {
      scrollDown();
    } else if (val < c) {
      scrollUp();
    }
  };

  handleScroll = (val, index) => {
    this.selectedItemIndex = index;
    const AzNavBoxChildren = document.getElementById('az-nav-box').children;
    _.forEach(AzNavBoxChildren, btn => {
      if (btn.classList.contains('selected') || btn.classList.contains('active')) {
        btn.classList.remove('active', 'selected');
      }
    });
    document.getElementById(`${val}-btn`).classList.add('active', 'selected');
    // check supporting behavior for scroll (not suppotrted for EA and Edge)
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    if (supportsNativeSmoothScroll) {
      this.mainSimpleBar.scroll({
        top: document.getElementById(`${val}-letter`).offsetTop - 90,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      this.smoothScroll(document.getElementById(`${val}-letter`).offsetTop - 90);
    }
    this.selectedBtn = true;
  };

  fetchOrderData = letter => {
    const { global } = this.props;
    if (global.user.data && global.user.data.is_paid) return letter;
    return _.orderBy(letter, ['is_paid'], 'asc');
  };

  fetchShowFilterButtons = () => this.setState({ showFiltersFlag: true });

  fetchCheckData = letterData => {
    const { filterKey } = this.state;
    const filteredData = _.filter(letterData, item => {
      if (item.section && filterKey !== item.section) return false;
      if (!item.section && filterKey !== item.key) return false;
      return item;
    });
    return filteredData.length;
  };

  fetchClean = () => {
    // this.mainSimpleBar.scrollTo(0, 0);
    // change for workability for Edge
    this.mainSimpleBar.scrollTop = 0;
    this.navItems = {};
    this.scrollItemsTopData = null;
    this.scrollItemIndex = 0;
    const AzNavBoxChildren = document.getElementById('az-nav-box').children;
    _.forEach(AzNavBoxChildren, (btn, ind) => {
      if (btn.classList.contains('selected') || btn.classList.contains('active')) {
        btn.classList.remove('active', 'selected');
      }
      if (ind === 0) btn.classList.add('active');
    });
  };

  comingSoonPopupHandler = () => {
    this.setState(({ isComingsoonPopupOpen }) => ({ isComingsoonPopupOpen: !isComingsoonPopupOpen }));
  };

  smartLinkClickHandler = (item, filterKey) => {
    const back = {
      filterKey,
      scrollTop: this.mainSimpleBar.scrollTop,
      link: this.fetchSetLink(item),
    };
    localStorage.setItem('a-z_back', JSON.stringify(back));
  };

  render() {
    const {
      classes,
      azlist: { loading, data },
      intl: { formatMessage },
    } = this.props;
    const { filterKey, showFiltersFlag, isComingsoonPopupOpen } = this.state;
    return (
      <div className={classes.wrap}>
        <Helmet>
          <title>{formatMessage(messages.azList)}</title>
          <meta name="description" content={formatMessage(messages.mmAZList)} />
        </Helmet>
        {(loading || !data) && <AppPreloader />}
        {data && (
          <>
            {/* Filter */}
            {showFiltersFlag ? (
              <div className={classes.filterBtnsWrap}>
                <button
                  className={!filterKey ? `${classes.filterBtn} active` : classes.filterBtn}
                  type="button"
                  onClick={() => {
                    this.fetchClean();
                    this.setState({ filterKey: null });
                  }}
                >
                  {formatMessage(messages.all)}
                </button>
                {_.map(this.filterLabels, (button, key) => (
                  <button
                    className={filterKey === key ? `${classes.filterBtn} active` : classes.filterBtn}
                    key={key}
                    type="button"
                    onClick={() => {
                      this.fetchClean();
                      this.setState({ filterKey: key });
                    }}
                  >
                    {button}
                  </button>
                ))}
              </div>
            ) : null}
            {/* Content */}
            <div id="az-main-box">
              {_.map(data, (letter, index) => {
                let titleFlag = true;
                if (index === _.keys(data)[_.keys(data).length - 1]) {
                  this.fetchShowFilterButtons();
                  this.fetchAddCustomScrollData();
                }
                if (!this.fetchCheckData(letter) && filterKey) return false;
                return (
                  <div key={index} className={classes.letterBox} id={`${index}-letter`}>
                    {_.map(this.fetchOrderData(letter), (item, ind) => {
                      if (filterKey) {
                        if (item.section && filterKey !== item.section) return false;
                        if (!item.section && filterKey !== item.key) return false;
                      }
                      const renderTitle = () => {
                        if (titleFlag) {
                          titleFlag = false;
                          if (!this.navItems[index]) this.navItems[index] = true;
                          return <h3 className={classes.letter}>{index}</h3>;
                        }
                        return null;
                      };
                      const styledItemWrap = classnames(classes.link, {
                        [classes.linkLock]: this.fetchSetLink(item) === PRICING_PAGE,
                      });
                      const isComingSoonItem = _.has(item, 'comingsoon') && item.comingsoon;
                      return (
                        <div key={ind}>
                          {renderTitle()}
                          {isComingSoonItem ? (
                            <Box className={styledItemWrap} onClick={this.comingSoonPopupHandler}>
                              <AzListItem item={item} />
                              <span className={classes.listTitle}>
                                {this.fetchSetRightTitle(item.key, item.section)}
                              </span>
                            </Box>
                          ) : (
                            <SmartLink
                              to={this.fetchSetLink(item)}
                              className={styledItemWrap}
                              onClick={() => this.smartLinkClickHandler(item, filterKey)}
                            >
                              <AzListItem item={item} link={this.fetchSetLink(item)} />
                              <span className={classes.linkTitle}>
                                {this.fetchSetRightTitle(item.key, item.section)}
                              </span>
                            </SmartLink>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            {/* Right nav menu */}
            {showFiltersFlag ? (
              <div className={classes.navBox} id="az-nav-box">
                {_.map(_.keys(this.navItems), (item, index) => (
                  <button
                    key={index}
                    type="button"
                    id={`${item}-btn`}
                    className={index === 0 ? `active ${classes.navBtn}` : classes.navBtn}
                    onClick={() => this.handleScroll(item, index)}
                  >
                    {item}
                  </button>
                ))}
              </div>
            ) : null}
          </>
        )}
        {isComingsoonPopupOpen && (
          <ComingSoonPopup open={isComingsoonPopupOpen} closePopup={this.comingSoonPopupHandler} />
        )}
      </div>
    );
  }
}

Azlist.propTypes = {
  classes: PropTypes.object,
  azlist: PropTypes.object,
  intl: PropTypes.object,
  global: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  azlist: makeSelectAzlist(),
  global: makeSelectGlobal(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    getAZ: bindActionCreators(actions.getAZAction, dispatch),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'azlist', reducer });
const withSaga = injectSaga({ key: 'azlist', saga });

const AzlistIntl = injectIntl(Azlist);
const AzlistStyled = withStyles(styles)(AzlistIntl);

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(AzlistStyled);
