/**
 *
 * FavoriteFolderBtnsNoticePopup
 *
 */

import React, { memo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ConfirmSmartLinkPopup from 'components/Popups/ConfirmSmartLinkPopup';
import styles from './styles';
import messages from './messages';
import { mixpanelStorage } from '../../../localStorage';
import mixpanel from '../../../mixpanel';

function FavoriteFolderBtnsNoticePopup({
  intl: { formatMessage },
  classes,
  open,
  title,
  cancel,
  handleCloseDialog,
  handleConfirm,
  handleChangeLocalWebStorage,
  linkTo,
  description,
  shareLockPopup,
  storeName,
  linkToLabel,
  dontShowMsgCheckBox,
  linkWithFuncConfirm,
}) {
  if (open) {
    mixpanelStorage.setItem('saveBlock', 'Blocked');
    if (shareLockPopup) {
      mixpanel.videoShare();
      mixpanel.exerciseShare();
    } else {
      mixpanel.videoSave();
      mixpanel.exerciseSave();
    }
  }
  const descriptionArr = Array.isArray(description) ? description : [description];
  return (
    <ConfirmSmartLinkPopup
      open={open}
      onCancel={handleCloseDialog}
      onConfirm={linkTo && !linkWithFuncConfirm ? handleCloseDialog : handleConfirm}
      cancelName={cancel || formatMessage(messages.cancelBtn)}
      confirmName={linkToLabel || formatMessage(messages.okBtn)}
      title={title || formatMessage(messages.notice)}
      to={linkTo}
    >
      {descriptionArr.map((description, i) => (
        <p
          key={i}
          className={classes.noticeDscr}
          style={descriptionArr.length > 1 && i < descriptionArr.length - 1 ? { marginBottom: 32 } : {}}
        >
          {description}
        </p>
      ))}
      {!dontShowMsgCheckBox ? (
        <FormControlLabel
          control={<Checkbox onChange={() => handleChangeLocalWebStorage(storeName)} />}
          label={formatMessage(messages.noticeCheckbox)}
        />
      ) : null}
    </ConfirmSmartLinkPopup>
  );
}

FavoriteFolderBtnsNoticePopup.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object,
  open: PropTypes.bool,
  linkTo: PropTypes.string,
  storeName: PropTypes.string,
  linkToLabel: PropTypes.string,
  title: PropTypes.string,
  cancel: PropTypes.string,
  description: PropTypes.string,
  handleCloseDialog: PropTypes.func,
  handleChangeLocalWebStorage: PropTypes.func,
  dontShowMsgCheckBox: PropTypes.bool,
};

const FavoriteFolderBtnsNoticePopupIntl = injectIntl(FavoriteFolderBtnsNoticePopup);

const FavoriteFolderBtnsNoticePopupStyled = withStyles(styles)(FavoriteFolderBtnsNoticePopupIntl);

export default memo(FavoriteFolderBtnsNoticePopupStyled);
