import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the azlist state domain
 */

const selectAzlistDomain = state => state.azlist || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Azlist
 */

const makeSelectAzlist = () =>
  createSelector(
    selectAzlistDomain,
    substate => substate,
  );

export default makeSelectAzlist;
export { selectAzlistDomain };
