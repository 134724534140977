/**
 *
 * AddFolderPopup
 *
 */

import React, { memo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import lodashMap from 'lodash/map';

import AppPreloader from 'components/AppPreloader';
import ConfirmPopup from 'components/Popups/ConfirmPopup';
import NoticePopupMessages from 'components/NoticePopup/messages';
import myFoldersMessages from 'containers/MyFolders/messages';
import favoriteFolderBtnsMessages from 'components/FavoriteFolderBtns/messages';
import folderIcon from 'images/favorite/folder_small.svg';

import messages from './messages';
import styles from './styles';

function AddFolderPopup({
  intl: { formatMessage },
  classes,
  open,
  folders,
  addToFolder,
  onClose,
  onCreateFolder,
  onCloseNotice,
  noticeCoordinate,
  showNotice,
  loading,
}) {
  const isFolder = !!(folders && folders.length);
  const popupText = isFolder ? formatMessage(messages.chooseFolder) : formatMessage(messages.youDontHaveFolder);

  return (
    <ConfirmPopup
      title={formatMessage(messages.addToFolder)}
      open={open}
      onCancel={onClose}
      onConfirm={onCreateFolder}
      cancelName={formatMessage(NoticePopupMessages.cancelBtn)}
      confirmName={formatMessage(myFoldersMessages.create_new_folder)}
    >
      {loading && <AppPreloader />}
      <p className={classes.text}>{popupText}</p>
      {isFolder && (
        <div className={classes.foldersBox}>
          {lodashMap(folders, (folder, item) => (
            <button
              type="button"
              key={`${item}_folder`}
              className={classes.folderItemBtn}
              onClick={e => addToFolder(e, folder)}
            >
              <img src={folderIcon} alt="folder icon" />
              <span className={classes.folderItemText}>{folder.title}</span>
            </button>
          ))}
        </div>
      )}
      {showNotice && (
        <div
          className={classes.noticeDialogWrap}
          style={{ top: noticeCoordinate.mouseY - 16, left: noticeCoordinate.mouseX + 8 }}
        >
          <button type="button" className={classes.closeNoticeBtn} onClick={onCloseNotice}>
            &#10006;
          </button>
          {formatMessage(favoriteFolderBtnsMessages.noticeMsg)}
        </div>
      )}
    </ConfirmPopup>
  );
}

AddFolderPopup.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCloseNotice: PropTypes.func,
  showNotice: PropTypes.bool,
  noticeCoordinate: PropTypes.object,
  loading: PropTypes.bool,
};

const AddFolderPopupIntl = injectIntl(AddFolderPopup);

const AddFolderPopupStyled = withStyles(styles)(AddFolderPopupIntl);

export default memo(AddFolderPopupStyled);
