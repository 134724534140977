/*
 *
 * FavoriteList reducer
 *
 */
import produce from 'immer';
import _ from 'lodash';
import * as ActionTypes from './constants';

const fetchAddToFavorites = (oldData, newData) => {
  const newArr = _.cloneDeep(oldData);
  newArr.favorites.push(newData);
  return newArr;
};

const fetchRemoveItemFromFavorites = (oldData, id) => {
  const old = _.cloneDeep(oldData);
  old.favorites = _.filter(old.favorites, item => item.id !== id);
  return old;
};

export const initialState = {
  addToFavorite: {
    loading: false,
    loaded: false,
    error: null,
  },
  favoriteList: {
    loaded: false,
    loading: false,
    data: {},
    error: null,
  },
  shareLink: {
    loaded: false,
    loading: false,
    data: '',
    error: null,
  },
};

/* eslint-disable default-case, no-param-reassign */
const favoriteListReducer = (state = initialState, action) =>
  produce(state, draft => {
    const oldFavorites = state.favoriteList.data;
    switch (action.type) {
      case ActionTypes.ADD_TO_FAVORITE:
        draft.addToFavorite.loading = true;
        draft.addToFavorite.loaded = false;
        draft.addToFavorite.error = null;
        break;
      case ActionTypes.ADD_TO_FAVORITE_SUCCESS:
        draft.addToFavorite.loading = false;
        draft.addToFavorite.loaded = true;
        draft.favoriteList.data = fetchAddToFavorites(oldFavorites, action.data);
        break;
      case ActionTypes.ADD_TO_FAVORITE_FAIL:
        draft.addToFavorite.loading = false;
        draft.addToFavorite.loaded = false;
        draft.addToFavorite.error = action.message;
        break;
      case ActionTypes.DELETE_FAVORITE:
        draft.addToFavorite.deleteLoading = true;
        draft.addToFavorite.loading = true;
        draft.addToFavorite.loaded = false;
        draft.addToFavorite.error = null;
        break;
      case ActionTypes.DELETE_FAVORITE_SUCCESS:
        draft.addToFavorite.deleteLoading = false;
        draft.addToFavorite.loading = false;
        draft.addToFavorite.loaded = true;
        draft.favoriteList.data = fetchRemoveItemFromFavorites(oldFavorites, action.favoriteId);
        break;
      case ActionTypes.DELETE_FAVORITE_FAIL:
        draft.addToFavorite.deleteLoading = false;
        draft.addToFavorite.loading = false;
        draft.addToFavorite.loaded = false;
        draft.addToFavorite.error = action.data;
        break;
      case ActionTypes.GET_FAVORITE_LIST:
        draft.favoriteList.loading = true;
        draft.favoriteList.loaded = false;
        break;
      case ActionTypes.GET_FAVORITE_LIST_SUCCESS:
        draft.favoriteList.loading = false;
        draft.favoriteList.loaded = true;
        draft.favoriteList.data = action.data;
        break;
      case ActionTypes.GET_FAVORITE_LIST_FAIL:
        draft.favoriteList.loading = false;
        draft.favoriteList.loaded = false;
        draft.favoriteList.error = action.data;
        break;
      case ActionTypes.SHARE_LINK_CLEAN:
        draft.shareLink.loading = true;
        draft.shareLink.loaded = false;
        draft.shareLink.data = '';
        draft.shareLink.error = null;
        break;
      case ActionTypes.SHARE_LINK:
        draft.shareLink.loading = true;
        draft.shareLink.loaded = false;
        break;
      case ActionTypes.SHARE_LINK_SUCCESS:
        draft.shareLink.loading = false;
        draft.shareLink.loaded = true;
        draft.shareLink.data = action.data;
        break;
      case ActionTypes.SHARE_LINK_FAIL:
        draft.shareLink.loading = false;
        draft.shareLink.loaded = false;
        draft.shareLink.error = action.data;
        break;
    }
  });

export default favoriteListReducer;
