/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const GET_USER_DATA = 'app/GET_USER_DATA';
export const GET_USER_DATA_SUCCESS = 'app/GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAIL = 'app/GET_USER_DATA_FAIL';

export const USER_DATA_CLEAN = 'app/USER_DATA_CLEAN';

export const OPEN_NOTIFICATION = 'app/OPEN_NOTIFICATION';
export const OPEN_ERROR_NOTIFICATION = 'app/OPEN_ERROR_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'app/CLOSE_NOTIFICATION';
export const OPEN_MAIN_NOTIFICATION = 'app/OPEN_MAIN_NOTIFICATION';

export const GET_SUBMUSCLES = 'app/GET_SUBMUSCLES';
export const GET_SUBMUSCLES_SUCCESS = 'app/GET_SUBMUSCLES_SUCCESS';
export const GET_SUBMUSCLES_FAIL = 'app/GET_SUBMUSCLES_FAIL';

export const USER_UPDATE = 'app/USER_UPDATE';
export const USER_UPDATE_FAIL = 'app/USER_UPDATE_FAIL';

export const USER_CHANGE_LOCALE = 'app/USER_CHANGE_LOCALE';
export const USER_CHANGE_LOCALE_SUCCESS = 'app/USER_CHANGE_LOCALE_SUCCESS';
export const USER_CHANGE_LOCALE_FAIL = 'app/USER_CHANGE_LOCALE_FAIL';

export const GET_FIREBASE = 'app/GET_FIREBASE';
export const GET_FIREBASE_SUCCESS = 'app/GET_FIREBASE_SUCCESS';
export const GET_FIREBASE_FAIL = 'app/GET_FIREBASE_FAIL';
export const GET_FIREBASE_UPDATE_LANGUAGE = 'app/GET_FIREBASE_UPDATE_LANGUAGE';

export const GET_SEARCH = 'app/GET_SEARCH';
export const GET_SEARCH_SUCCESS = 'app/GET_SEARCH_SUCCESS';
export const GET_SEARCH_FAIL = 'app/GET_SEARCH_FAIL';

export const CLEAR_SEARCH = 'app/CLEAR_SEARCH';

export const HANDLE_CHANGE_FORGET_PASS = 'app/AuthReg/HANDLE_CHANGE_FORGET_PASS';
export const CLOSE_CHANGE_FORGET_PASS = 'app/AuthReg/CLOSE_CHANGE_FORGET_PASS';
export const SUCCESS_CHANGE_FORGET_PASS_EMAIL = 'app/AuthReg/SUCCESS_CHANGE_FORGET_PASS_EMAIL';

export const GET_MENU_ITEM_FOR_VIDEO = 'app/SimpleVideoPage/GET_MENU_ITEM_FOR_VIDEO';
export const GET_MENU_ITEM_FOR_VIDEO_SUCCESS = 'app/SimpleVideoPage/GET_MENU_ITEM_FOR_VIDEO_SUCCESS';
export const GET_MENU_ITEM_FOR_VIDEO_FAIL = 'app/SimpleVideoPage/GET_MENU_ITEM_FOR_VIDEO_FAIL';

export const SET_ASIDE_MENU_HIDDEN = 'SET_ASIDE_MENU_HIDDEN';
