import { takeLatest, put } from 'redux-saga/effects';
import { cloneDeep, map } from 'lodash';
import * as ActionTypes from './constants';
import axios from '../../utils/axiosConfig';

export function* fetchGetVideoById({ id, section, workout_item_id }) {
  const urlIdSection = section ? `${id}?section=${section}` : `${id}`;
  const urlId = workout_item_id ? `${urlIdSection}?workout_item_id=${workout_item_id}` : urlIdSection;
  try {
    const response = yield axios.get(`video_with_details/exercises/${urlId}`);
    if (response) {
      const fetchSetDescriptionInLines = () => {
        const newData = cloneDeep(response.data);
        const fetchSetVideoBlock = key => {
          const newVideoData = map(newData.exercise[`${key}`], item => {
            const newItem = item;
            if (item.description) {
              const dscrMain = map(item.description.split('\n\n'), p => {
                const newP = p.split('\n');
                return newP;
              });
              newItem.description = dscrMain;
            }
            return newItem;
          });
          newData.exercise[`${key}`] = newVideoData;
        };
        if (newData.exercise && newData.exercise.correct_videos && newData.exercise.correct_videos.length) {
          fetchSetVideoBlock('correct_videos');
        }
        if (newData.exercise && newData.exercise.mistake_videos && newData.exercise.mistake_videos.length) {
          fetchSetVideoBlock('mistake_videos');
        }
        if (
          newData.exercise &&
          newData.exercise.preparatory_exercises &&
          newData.exercise.preparatory_exercises.length
        ) {
          fetchSetVideoBlock('preparatory_exercises');
        }
        return newData;
      };
      yield put({
        type: ActionTypes.GET_VIDEO_BY_ID_SUCCESS,
        data: fetchSetDescriptionInLines(),
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_VIDEO_BY_ID_FAIL,
      data: e.response.data.message,
    });
  }
}

// All sagas to be loaded
export default function* simpleVideoPageSaga() {
  yield takeLatest(ActionTypes.GET_VIDEO_BY_ID, fetchGetVideoById);
}
