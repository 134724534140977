/* eslint-disable */
import axios from 'axios';
import { apiUrl } from './parameters';
import { bluesnapAuthorizationString, CURRENT_VERTICAL } from '../verticalConfigs';
import { appVersion } from './parameters';
import { cookies } from "./cookies";
import { removeUserAuthInfo } from "../localStorage";

axios.setXApiKey = token => {
  axios.defaults.headers.common['Authorization-Token'] = token;
};

axios.removeXApiKey = () => {
  axios.defaults.headers.common['Authorization-Token'] = '';
};

axios.setLanguage = language => {
  let languageVal = language;

  localStorage.setItem('language',languageVal);
  axios.defaults.headers.common['LanguageId'] = languageVal;
};

axios.defaults.baseURL = apiUrl;

let languageVal = localStorage.getItem('language') || 'en';

const commonAppHeaders = {
  "Authorization": "Bearer MuscleAndMotion2018",
  "LanguageId": languageVal,
  "VerticalId": CURRENT_VERTICAL || 'strength',
  "Content-Type": 'application/json',
  'Access-Control-Allow-Origin': '*',
  'os': 'webApp',
  'os-version': '',
  'version-number': appVersion,
  'build-number': '',
};
axios.defaults.headers.common = commonAppHeaders;

axios.resetHeadersUrl = () => {
  axios.defaults.baseURL = apiUrl;
  axios.defaults.headers.common = commonAppHeaders;
};

axios.setVertical = vertical => {
  axios.defaults.headers.common['VerticalId'] = vertical;
};
axios.resetVertical = () => {
  axios.defaults.headers.common['VerticalId'] = CURRENT_VERTICAL || 'strength';
};

axios.setMulti = () => {
  axios.defaults.headers.common['Content-Type'] = 'multipart/form-data; boundary=boundary';
};
axios.resetMulti = () => {
  axios.defaults.headers.common['Content-Type'] = 'application/json';
};

axios.setSenderId = senderId => {
  axios.defaults.headers.common['sender-id'] = senderId;
};
axios.resetSenderId = () => {
  delete axios.defaults.headers.common['sender-id'];
};

const tokenFromCookie = cookies.getTokenFromThisDomain();
if (tokenFromCookie) {
  localStorage.setItem('token', tokenFromCookie);
  cookies.deleteTokenFromThisDomain();
}

const logoutCookie = cookies.getLogoutCookieFromThisDomain();
if (logoutCookie && logoutCookie !== 'false') {
  cookies.deleteLogoutCookieFromThisDomain();
  removeUserAuthInfo();
}

const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization-Token'] = token;
}



axios.setBlueSnapCancelAutoRenewalHeadersUrl = () => {
  axios.defaults.baseURL = process.env.REACT_APP_SERVER === 'development' ? blueSnapQaUrl : blueSnapProdUrl;
  axios.defaults.headers.common = {
    "Authorization": "Basic " + bluesnapAuthorizationString(),
    "Content-Type": "application/xml",
  };
};


axios.interceptors.response.use(function (response) {
  if (window.location.pathname.match(/^\/(new-popular|login)/)) {
    return response;
  }
  if (!response.data.hasOwnProperty('webAppTokenActive')) return response;

  const currentToken = localStorage.getItem('token');
  const isTokenActive = !!response.data.webAppTokenActive;

  if (currentToken && !isTokenActive) {
    removeUserAuthInfo();
    setTimeout(() => {
      location.href = '/login';
    }, 100);
  }
  return response;
}, function (error) {
  return Promise.reject(error);
});



export default axios;
