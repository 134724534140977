import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the skeletal state domain
 */

const selectSkeletalDomain = state => state.skeletal || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Skeletal
 */

const makeSelectSkeletal = () =>
  createSelector(
    selectSkeletalDomain,
    substate => substate,
  );

export default makeSelectSkeletal;
export { selectSkeletalDomain };
