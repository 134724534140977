import MMStylesTheme from '../../themes/MMStylesTheme';
import leftBgImg from '../../img/login-bg-left.png';
import rightBgImg from '../../img/login-bg-right.png';

export default () => ({
  warningWrap: {
    textAlign: 'center',
    marginTop: 60,
    letterSpacing: 0.3,
    height: 'calc(100vh - 60px)',
    background: MMStylesTheme.palette.MMColors.deepMain,
    '&:before': {
      position: 'absolute',
      top: 0,
      left: 0,
      content: '""',
      width: '100%',
      height: '100%',
      background: `url("${leftBgImg}") 0 no-repeat`,
      backgroundSize: 'contain',
    },
    '&:after': {
      position: 'absolute',
      top: 0,
      right: 0,
      content: '""',
      width: '100%',
      height: '100%',
      background: `url("${rightBgImg}") 100% 0 no-repeat`,
      backgroundSize: 'contain',
    },
  },
  container: {
    padding: '13vh 0',
  },
  title: {
    fontWeight: 600,
    marginBottom: MMStylesTheme.spacing(6),
  },
  label: {
    display: 'inline-block',
    fontWeight: 600,
    textAlign: 'left',
    lineHeight: 1.27,
    '& span': {
      display: 'block',
      margin: `${MMStylesTheme.spacing(4)}px 0`,
      textAlign: 'center',
    },
  },
  logo: {
    width: 160,
    margin: '0 auto',
  },
});
