/* eslint-disable no-debugger, no-console, no-restricted-globals */
import mixpanel from 'mixpanel-browser';
import { defineAppName, getMixpanelToken } from './verticalConfigs';
// eslint-disable-next-line import/no-cycle
import {
  getEnteredAsGuestBefore,
  getSignupMethod,
  mixpanelStorage,
  mixpanelDefaultValues,
  getTheoryViewInfo,
  removeTheoryInfoAndTime,
  removeExerciseInfoAndTime,
  getTheoryViewTime,
  getExerciseViewInfo,
  getExerciseViewTime,
  removeMuscleOriginInfoAndTime,
  getMuscleOriginViewInfo,
  getMuscleOriginViewTime,
} from './localStorage';

const planTypes = {
  indi: 'Individual',
  pro: 'Pro',
  business: 'Business',
};

const defaultSuperProperties = { Subscriber: 'False', Guest: 'True', App: defineAppName() };

const MIXPANEL_TOKEN = getMixpanelToken();
const mixpanelWorks = {
  init: () => {
    if (window.mixpanelInited) return;
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: sessionStorage.getItem('mixpanel') === 'on' || !location.hostname.startsWith('app.'),
      persistence: 'localStorage',
      api_host: process.env.REACT_APP_ENV === 'production' ? 'https://api-eu.mixpanel.com' : 'https://api.mixpanel.com',
      ignore_dnt: true,
    });

    mixpanel.register(defaultSuperProperties);
    window.mixpanelInited = true;
  },
  register: object => {
    mixpanel.register(object);
  },
  track: (name = '', properties = {}) => {
    mixpanel.track(name, properties);
  },
  reset: () => {
    mixpanel.reset();
    setTimeout(() => {
      mixpanel.register(defaultSuperProperties);
    }, 1000);
  },
  identify: globalId => {
    mixpanel.identify(globalId);
  },
  registerSuperProperties: user => {
    mixpanel.reset();
    const properties = {
      Subscriber: user.is_paid ? 'True' : 'False',
      Guest: 'False',
      'MM User ID': user.globalId,
      'Plan Type': undefined,
      'Free Trial Status': undefined,
      'Subscription Type': undefined,
      App: defineAppName(),
    };
    if (user.is_paid) {
      // eslint-disable-next-line no-prototype-builtins
      properties['Plan Type'] = planTypes.hasOwnProperty(user.paid_status.type)
        ? planTypes[user.paid_status.type]
        : user.paid_status.type;
      properties['Free Trial Status'] = !!user.paid_status.type.match(/trial/i);
      properties['Subscription Type'] = user.paid_status.billing;
    }
    mixpanel.identify(user.globalId);
    mixpanel.register(properties);
    mixpanel.people.set({ $email: user.email });
  },
  signUpClick: () => {
    mixpanel.track('Signup Click', {
      'Signup Method': getSignupMethod(),
      'Entered As Guest Before': getEnteredAsGuestBefore(),
    });
  },
  signUpComplete: () => {
    mixpanel.track('Signup Complete', {
      'Signup Method': getSignupMethod(),
      'Entered As Guest Before': getEnteredAsGuestBefore(),
    });
  },
  loginComplete: () => {
    mixpanel.track('Login Complete', {
      'Login Method': getSignupMethod(),
    });
  },
  socialLoginOrSignUp(user) {
    if (user.is_new_registration) {
      this.signUpComplete();
    } else {
      this.loginComplete();
    }
  },
  pricingScreenView: () => {
    mixpanel.track('Pricing Screen View', {
      ...mixpanelStorage.getItem('entryPoint'),
      ...(mixpanelStorage.getCleanItem('entryPoint') === 'Item Lock' ? mixpanelStorage.getItem('itemLockType') : {}),
      ...(mixpanelStorage.getCleanItem('entryPoint') === 'Pricing Banner'
        ? mixpanelStorage.getItem('iapButtonText')
        : {}),
      ...(mixpanelStorage.getCleanItem('entryPoint') === 'Item Lock' ? mixpanelStorage.getItem('itemId') : {}),
      ...(mixpanelStorage.getCleanItem('entryPoint') === 'Item Lock' ? mixpanelStorage.getItem('itemName') : {}),
    });
  },
  iapClick: () => {
    mixpanel.track('IAP Click', {
      ...mixpanelStorage.getItem('plan'),
      ...mixpanelStorage.getItem('subscription'),
      Upgrade: 'False',
      ...mixpanelStorage.getItem('entryPoint'),
      ...(mixpanelStorage.getCleanItem('entryPoint') === 'Item Lock' ? mixpanelStorage.getItem('itemLockType') : {}),
      ...(mixpanelStorage.getCleanItem('entryPoint') === 'Item Lock' ? mixpanelStorage.getItem('itemId') : {}),
      ...(mixpanelStorage.getCleanItem('entryPoint') === 'Item Lock' ? mixpanelStorage.getItem('itemName') : {}),
      ...mixpanelStorage.getItem('iapPlanButtonText'),
    });
  },
  iapComplete: () => {
    mixpanel.track('IAP Complete', {
      ...mixpanelStorage.getItem('plan'),
      ...mixpanelStorage.getItem('subscription'),
      Upgrade: 'False',
      ...mixpanelStorage.getItem('entryPoint'),
      ...(mixpanelStorage.getCleanItem('entryPoint') === 'Item Lock' ? mixpanelStorage.getItem('itemLockType') : {}),
      ...(mixpanelStorage.getCleanItem('entryPoint') === 'Item Lock' ? mixpanelStorage.getItem('itemId') : {}),
      ...(mixpanelStorage.getCleanItem('entryPoint') === 'Item Lock' ? mixpanelStorage.getItem('itemName') : {}),
      ...mixpanelStorage.getItem('iapPlanButtonText'),
    });
  },
  iapCancel: () => {
    mixpanel.track('IAP Cancel', {
      ...mixpanelStorage.getItem('plan_cancel'),
      ...mixpanelStorage.getItem('subscription_cancel'),
    });
  },
  firstAppOpen: () => {
    const firstAppOpen = mixpanelStorage.getCleanItem('firstAppOpen');
    if (firstAppOpen) return;

    mixpanel.track('First App Open');
    localStorage.setItem('mixpanel_firstAppOpen', true);
  },
  appSession: () => {
    setTimeout(() => {
      const appSessionDone = mixpanelStorage.getCleanItem('appSessionDone');
      if (appSessionDone) return;

      mixpanel.track('App Session');
      mixpanelStorage.setItem('appSessionDone', true);
    }, 10000);
  },
  exerciseClick: () => {
    if (!mixpanelStorage.getCleanItem('exerciseName') || !mixpanelStorage.getCleanItem('exerciseId')) {
      if (process.env.NODE_ENV !== 'production') {
        return console.log('Exercise Info is missing to click');
      }
      return;
    }
    mixpanel.track('Exercise Click', {
      ...mixpanelStorage.getItem('entryPoint'),
      ...(mixpanelStorage.getCleanItem('entryPoint') === 'Theory' ? mixpanelStorage.getItem('theorySubChapter') : {}),
      ...(mixpanelStorage.getCleanItem('entryPoint') === 'Theory'
        ? mixpanelStorage.getItem('theorySubSubChapter')
        : {}),
      ...mixpanelStorage.getItem('exerciseId'),
      ...mixpanelStorage.getItem('exerciseName'),
      ...mixpanelStorage.getItem('locked'),
    });
  },
  videoClick: () => {
    if (!mixpanelStorage.getCleanItem('videoName') || !mixpanelStorage.getCleanItem('videoId')) {
      if (process.env.NODE_ENV !== 'production') {
        return console.log('Theory Info is missing to click');
      }
      return;
    }
    mixpanel.track('Video Click', {
      ...mixpanelStorage.getItem('entryPoint'),
      ...(mixpanelStorage.getCleanItem('entryPoint') === 'Theory' ? mixpanelStorage.getItem('theorySubChapter') : {}),
      ...(mixpanelStorage.getCleanItem('entryPoint') === 'Theory'
        ? mixpanelStorage.getItem('theorySubSubChapter')
        : {}),
      ...mixpanelStorage.getItem('videoId'),
      ...mixpanelStorage.getItem('videoName'),
      ...mixpanelStorage.getItem('locked'),
    });
  },
  sendTheoryViewTime: () => {
    const theoryViewInfo = getTheoryViewInfo();
    const theoryViewTime = getTheoryViewTime();
    if (
      !theoryViewInfo ||
      mixpanelStorage.getCleanItem('entryPoint') === 'Pricing Banner' ||
      !mixpanelStorage.getCleanItem('videoName') ||
      !mixpanelStorage.getCleanItem('videoId')
    ) {
      removeTheoryInfoAndTime();
      if (process.env.NODE_ENV !== 'production') {
        return console.log('Theory Video Info is missing');
      }
      return;
    }
    theoryViewTime.viewTime = theoryViewTime.timeMatrix.length;
    if (theoryViewTime.viewTime === 0) return removeTheoryInfoAndTime();

    const { timeMatrix, ...viewTimeWithoutMatrix } = theoryViewTime;
    mixpanel.track('Video View', {
      ...mixpanelStorage.getItem('entryPoint'),
      ...(mixpanelStorage.getCleanItem('entryPoint') === 'Theory' ? mixpanelStorage.getItem('theorySubChapter') : {}),
      ...(mixpanelStorage.getCleanItem('entryPoint') === 'Theory'
        ? mixpanelStorage.getItem('theorySubSubChapter')
        : {}),
      ...mixpanelStorage.getItem('videoId'),
      ...mixpanelStorage.getItem('videoName'),
      ...mixpanelStorage.getItem('locked'),
      'View Time': viewTimeWithoutMatrix.viewTime,
      'Video Length': viewTimeWithoutMatrix.videoLength,
      'Completion Rate': +(viewTimeWithoutMatrix?.completionRate / 100).toFixed(2),
    });

    removeTheoryInfoAndTime();
  },
  sendMuscleOriginViewTime: () => {
    const muscleOriginViewInfo = getMuscleOriginViewInfo();
    const muscleOriginViewTime = getMuscleOriginViewTime();
    if (!muscleOriginViewInfo) {
      removeMuscleOriginInfoAndTime();
      if (process.env.NODE_ENV !== 'production') {
        return console.log('Muscle Origin Info is missing');
      }
      return;
    }
    muscleOriginViewTime.viewTime = muscleOriginViewTime.timeMatrix.length;
    if (muscleOriginViewTime.viewTime === 0 || !muscleOriginViewTime.videoLength)
      return removeMuscleOriginInfoAndTime();

    if (!['Search', 'Model', 'Exercise', 'Joint', 'Bone'].includes(muscleOriginViewInfo['Entry Point'])) return;
    const { timeMatrix, ...viewTimeWithoutMatrix } = muscleOriginViewTime;
    if (viewTimeWithoutMatrix.videoLength === -1) {
      mixpanel.track('Muscle View', {
        ...muscleOriginViewInfo,
        'Origin Insertion Viewed': 'False',
      });
    } else {
      mixpanel.track('Muscle View', {
        ...muscleOriginViewInfo,
        'Origin Insertion Viewed': 'True',
        'Origin Insertion View Time': viewTimeWithoutMatrix.viewTime,
        'Origin Insertion Video Length': viewTimeWithoutMatrix.videoLength,
        'Origin Insertion Completion Rate': +(viewTimeWithoutMatrix?.completionRate / 100).toFixed(2),
      });
    }

    removeMuscleOriginInfoAndTime();
  },
  sendExerciseViewTime: () => {
    const exerciseViewInfo = getExerciseViewInfo();
    const exerciseViewTime = getExerciseViewTime('right');
    if (
      !exerciseViewInfo ||
      exerciseViewInfo[mixpanelDefaultValues.entryPoint.name] === 'Pricing Banner' ||
      !exerciseViewInfo[mixpanelDefaultValues.exerciseId.name] ||
      !exerciseViewInfo[mixpanelDefaultValues.exerciseName.name]
    ) {
      removeExerciseInfoAndTime();
      if (process.env.NODE_ENV !== 'production') {
        return console.log('Exercise Info is missing');
      }
      return;
    }

    mixpanel.track('Exercise View', {
      ...exerciseViewInfo,
      ...exerciseViewTime,
    });

    removeExerciseInfoAndTime();
  },
  exerciseShare: () => {
    if (!window.location.pathname.match(/\/exercise\//)) return;
    mixpanel.track('Exercise Share', {
      ...mixpanelStorage.getItem('exerciseId'),
      ...mixpanelStorage.getItem('exerciseName'),
      ...mixpanelStorage.getItem('saveBlock'),
    });
  },
  exerciseSave: (type = '') => {
    if (!window.location.pathname.match(/\/exercise\//) && type !== 'exercise') return;
    mixpanel.track('Exercise Save', {
      ...mixpanelStorage.getItem('exerciseId'),
      ...mixpanelStorage.getItem('exerciseName'),
      ...mixpanelStorage.getItem('saveBlock'),
    });
  },
  videoShare: () => {
    if (!window.location.pathname.match(/theory-video/)) return;
    mixpanel.track('Video Share', {
      ...mixpanelStorage.getItem('videoId'),
      ...mixpanelStorage.getItem('videoName'),
      ...mixpanelStorage.getItem('saveBlock'),
    });
  },
  videoSave: (type = '') => {
    if (!window.location.pathname.match(/theory-video/) && type !== 'video') return;
    mixpanel.track('Video Save', {
      ...mixpanelStorage.getItem('videoId'),
      ...mixpanelStorage.getItem('videoName'),
      ...mixpanelStorage.getItem('saveBlock'),
    });
  },
  search: () => {
    mixpanel.track('Search', {
      ...mixpanelStorage.getItem('searchTerm'),
      ...mixpanelStorage.getItem('searchTag'),
      ...mixpanelStorage.getItem('searchItemId'),
      ...mixpanelStorage.getItem('searchItemName'),
      ...mixpanelStorage.getItem('searchItemRanking'),
      ...mixpanelStorage.getItem('searchResultsCount'),
    });
  },
  muscleClick: () => {
    console.log('entryPoint', mixpanelStorage.getCleanItem('entryPoint'));
    if (!['Search', 'Model', 'Exercise', 'Joint', 'Bone'].includes(mixpanelStorage.getCleanItem('entryPoint'))) return;
    mixpanel.track('Muscle Click', {
      ...mixpanelStorage.getItem('entryPoint'),
      ...mixpanelStorage.getItem('muscleName'),
      ...mixpanelStorage.getItem('muscleId'),
      ...mixpanelStorage.getItem('locked'),
    });
  },
  boneClick: () => {
    if (!['Search', 'Model'].includes(mixpanelStorage.getCleanItem('entryPoint'))) return;
    mixpanel.track('Bone Click', {
      ...mixpanelStorage.getItem('entryPoint'),
      ...mixpanelStorage.getItem('boneName'),
      ...mixpanelStorage.getItem('boneId'),
      ...mixpanelStorage.getItem('locked'),
    });
  },
  boneView: () => {
    if (!['Search', 'Model'].includes(mixpanelStorage.getCleanItem('entryPoint'))) return;
    mixpanel.track('Bone View', {
      ...mixpanelStorage.getItem('entryPoint'),
      ...mixpanelStorage.getItem('boneName'),
      ...mixpanelStorage.getItem('boneId'),
      ...mixpanelStorage.getItem('locked'),
    });
  },
  jointClick: () => {
    if (!['Search', 'Model'].includes(mixpanelStorage.getCleanItem('entryPoint'))) return;
    mixpanel.track('Joint Click', {
      ...mixpanelStorage.getItem('entryPoint'),
      ...mixpanelStorage.getItem('jointName'),
      ...mixpanelStorage.getItem('jointId'),
      ...mixpanelStorage.getItem('locked'),
    });
  },
  jointView: () => {
    if (!['Search', 'Model'].includes(mixpanelStorage.getCleanItem('entryPoint'))) return;
    mixpanel.track('Joint View', {
      ...mixpanelStorage.getItem('entryPoint'),
      ...mixpanelStorage.getItem('jointName'),
      ...mixpanelStorage.getItem('jointId'),
      ...mixpanelStorage.getItem('locked'),
    });
  },
  screenView: () => {
    mixpanel.track('Screen View', {
      ...mixpanelStorage.getItem('screenName'),
    });
  },
  logOut: () => {
    if (sessionStorage.getItem('logoutProcess')) return;
    mixpanel.track('Log Out');
    sessionStorage.setItem('logoutProcess', 'true');
    setTimeout(() => {
      sessionStorage.removeItem('logoutProcess');
    }, 3000);
  },
};

// eslint-disable-next-line no-unused-vars
const mixpanelEmpty = {
  init: () => {
    console.log('MixPanel is off, Token is missing or local env');
  },
};

const handler = {
  get(target, prop) {
    if (prop in target && typeof target[prop] === 'function') {
      return target[prop];
    }
    return () => {};
  },
};

const mixpanelResult =
  MIXPANEL_TOKEN && process.env.REACT_APP_ENV !== 'development1' ? mixpanelWorks : new Proxy(mixpanelEmpty, handler);
export default mixpanelResult;
