/**
 *
 * PrivateRoute
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';
import { Route } from 'react-router-dom';
import classnames from 'classnames';
import AsideMenu from 'components/AsideMenu/Loadable';
import SimpleBar from 'simplebar-react';
import { bindActionCreators, compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import history from 'utils/history';
import { CURRENT_VERTICAL } from 'verticalConfigs';
import asideMenuMessages from 'components/AsideMenu/messages';
import AppPreloader from '../../components/AppPreloader';
import makeSelectAuthReg from '../AuthReg/selectors';
import { makeSelectGlobal } from '../App/selectors';
import makeSelectTheory from '../Theory/selectors';
import makeSelectByModel from '../ByModel/selectors';
import makeSelectSkeletal from '../Skeletal/selectors';
import makeSelectFavoriteList from '../FavoriteList/selectors';
import makeSelectFolderContainer from '../MyFolders/selectors';
import makeSelectAzlist from '../Azlist/selectors';
import styles from './styles';
import 'simplebar/dist/simplebar.min.css';
import { mmIcons } from '../../mmIcons';
import { getSearch, clearSearch, setAsideMenuHidden } from '../App/actions';
import * as theoryActions from '../Theory/actions';
import theorySaga from '../Theory/saga';
import theoryReducer from '../Theory/reducer';
import * as muscleActions from '../ByModel/actions';
import byModelSaga from '../ByModel/saga';
import byModelReducer from '../ByModel/reducer';
import * as skeletalAction from '../Skeletal/actions';
import skeletalSaga from '../Skeletal/saga';
import skeletalReducer from '../Skeletal/reducer';
import * as favoritesActions from '../FavoriteList/actions';
import favoritesSaga from '../FavoriteList/saga';
import favoritesReducer from '../FavoriteList/reducer';
import * as folderActions from '../MyFolders/actions';
import foldersReducer from '../MyFolders/reducer';
import foldersSaga from '../MyFolders/saga';
import * as azActions from '../Azlist/actions';
import azReducer from '../Azlist/reducer';
/* eslint-disable import/no-cycle */
import azSaga from '../Azlist/saga';
import ErrorBoundary from '../../ErrorBoundary';

/* eslint-disable react/prefer-stateless-function */
export class PrivateRoute extends React.PureComponent {
  constructor(props) {
    super(props);
    this.debounceSearch = _.debounce(this.props.getSearchAction, 500);
    this.state = {
      dataMenu: {},
      asideCollapse: false,
    };
    this.scrollableNodeRef = React.createRef();

    localStorage.removeItem('menuItemColor');
    this.renderFlag = false;
  }

  componentDidMount() {
    const { geByModelAction, geByModelMusclesAction, getMuscularAction, getSkeletalAction } = this.props;

    switch (CURRENT_VERTICAL) {
      case 'yoga':
        this.props.getAsanasCategoryAction();
        this.props.getMyofascialCategoryAction();
        this.props.getTerapeticCategoryAction();
        this.props.getPostureYogaTheoryCategories();
        break;
      case 'posture':
        this.props.getPosturalTestsAction();
        this.props.getAsanasCategoryAction();
        this.props.getMyofascialCategoryAction();
        this.props.fetchGetPosturalTherapeuticCategories();
        this.props.getPosturalDisordersCategories();
        this.props.getPostureYogaTheoryCategories();
        break;
      case 'anatomy':
        this.props.getTheoryCategoriesAction();
        break;
      default:
        this.props.getTheoryCategoriesAction();
    }

    if (localStorage.getItem('token')) {
      this.props.getMyFolders();
      this.props.getFavoriteVideosAction();
    }

    geByModelAction();
    geByModelMusclesAction();
    getMuscularAction();
    getSkeletalAction();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { theory, byModel, skeletal, location, az } = nextProps;
    // if(theory.loaded && skeletal.loaded  ){
    switch (CURRENT_VERTICAL) {
      case 'yoga':
        if (
          _.size(this.state.dataMenu) === 0 ||
          (theory.asanas && theory.myofascial && theory.therapeutics && skeletal.data && byModel.data)
        ) {
          this.setState({
            dataMenu: {
              asanaData: theory.asanas,
              myofascialData: theory.myofascial,
              therapeuticsData: theory.therapeutics,
              theory: theory.theory,
              skeletalData: skeletal.data,
              byModel: byModel.data,
            },
          });
        }
        break;
      case 'posture':
        if (
          _.size(this.state.dataMenu) === 0 ||
          (theory.postural &&
            theory.therapeutics &&
            theory.asanas &&
            theory.myofascial &&
            theory.posturalTests &&
            skeletal.data &&
            byModel.data)
        ) {
          this.setState({
            dataMenu: {
              posturalDisorders: theory.postural,
              therapeuticsData: theory.therapeutics,
              asanaData: theory.asanas,
              myofascialData: theory.myofascial,
              theory: theory.theory,
              posturalTestsData: theory.posturalTests,
              skeletalData: skeletal.data,
              byModel: byModel.data,
            },
          });
        }
        break;
      case 'anatomy':
        if (_.size(this.state.dataMenu) === 0 || (theory.data && skeletal.data && byModel.data)) {
          this.setState({
            dataMenu: {
              theoryData: theory.data,
              skeletalData: skeletal.data,
              byModel: byModel.data,
            },
          });
        }
        break;
      default:
        if (_.size(this.state.dataMenu) === 0 || (theory.data && skeletal.data && byModel.data)) {
          this.setState({
            dataMenu: {
              theoryData: theory.data,
              skeletalData: skeletal.data,
              byModel: byModel.data,
            },
          });
        }
    }
    if (
      location.pathname === '/search-result' &&
      document.getElementById('search-input') &&
      document.getElementById('search-input').value === '' &&
      localStorage.getItem('backToPageAfterSearch')
    ) {
      history.push(localStorage.getItem('backToPageAfterSearch'));
      localStorage.removeItem('backToPageAfterSearch');
    }
    if (localStorage.getItem('backFromPricing')) history.push('/search-result');

    if (!az.data && !az.loading && byModel.data && byModel.dataByModel && byModel.dataByModelMuscles) {
      this.props.getAZActionAction(this.props.dispatch);
    }
  }

  clearSearch = () => {
    const { clearSearchAction, location } = this.props;
    if (location.state && location.state.backPath) {
      clearSearchAction();
      history.push(location.state.backPath);
      if (localStorage.getItem('backToPageAfterSearch')) localStorage.removeItem('backToPageAfterSearch');
    }
  };

  collapseBottomAside = val => this.setState({ asideCollapse: val });

  hideBarHandler = () => {
    this.props.setAsideMenuHidden(!this.props.global.isAsideMenuHidden);
  };

  render() {
    const {
      classes,
      global: { user, search, menuItem, isAsideMenuHidden },
      getTheoryCategoriesAction,
      theory,
      byModel,
      skeletal,
      location,
      location: { pathname },
      component: Component,
      intl: { formatMessage },
      ...rest
    } = this.props;
    const { dataMenu, asideCollapse } = this.state;

    const mainContainerClasses = classnames(classes.main, { [classes.withSideBar]: isAsideMenuHidden });
    const collapseBtnClasses = classnames(classes.collapseMenuBtn, { [classes.hideCollapseBtn]: isAsideMenuHidden });
    window.withClicks = true;
    return (
      <div className={classes.wrap}>
        {_.size(this.state.dataMenu) ? (
          <>
            <AsideMenu
              getSearch={this.debounceSearch}
              clearSearch={this.clearSearch}
              locationPath={pathname}
              dataItems={dataMenu}
              user={user.data}
              collapseBottomAside={this.collapseBottomAside}
              globalSearch={search}
              videoHighlightingData={menuItem}
              isMenuHidden={isAsideMenuHidden}
              hideBarHandler={this.hideBarHandler}
            />
            {isAsideMenuHidden && (
              <button
                type="button"
                title={formatMessage(asideMenuMessages.showBarTitle)}
                className={collapseBtnClasses}
                onClick={this.hideBarHandler}
              >
                {mmIcons.doubleArrow}
              </button>
            )}
            <main className={mainContainerClasses}>
              <SimpleBar
                id="main-simple-bar"
                style={{ maxHeight: 'calc(100vh - 60px)', height: '100%' }}
                autoHide={false}
                scrollableNodeProps={{ ref: this.scrollableNodeRef }}
              >
                <ErrorBoundary user={user} withClicks>
                  <Route
                    {...rest}
                    render={routeProps => (
                      <Component
                        {...routeProps}
                        scrollableNodeRef={this.scrollableNodeRef}
                        asideCollapse={asideCollapse}
                      />
                    )}
                  />
                </ErrorBoundary>
              </SimpleBar>
            </main>
          </>
        ) : (
          <AppPreloader />
        )}
      </div>
    );
  }
}

PrivateRoute.propTypes = {
  classes: PropTypes.object,
  global: PropTypes.object,
  theory: PropTypes.object,
  byModel: PropTypes.object,
  az: PropTypes.object,
  location: PropTypes.object,
  skeletal: PropTypes.object,
  component: PropTypes.func,
  intl: intlShape.isRequired,
  getTheoryCategoriesAction: PropTypes.func,
  getAZActionAction: PropTypes.func,
  getAsanasCategoryAction: PropTypes.func,
  getTerapeticCategoryAction: PropTypes.func,
  getMyofascialCategoryAction: PropTypes.func,
  getPosturalTestsAction: PropTypes.func,
  geByModelAction: PropTypes.func,
  getMuscularAction: PropTypes.func,
  getSkeletalAction: PropTypes.func,
  geByModelMusclesAction: PropTypes.func,
  getPosturalDisordersCategories: PropTypes.func,
  fetchGetPosturalTherapeuticCategories: PropTypes.func,
  getPostureYogaTheoryCategories: PropTypes.func,
  getMyFolders: PropTypes.func,
  getFavoriteVideosAction: PropTypes.func,
  setMenuHiddenAction: PropTypes.func,
  getSearchAction: PropTypes.func,
  clearSearchAction: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
};

const theoryWithSaga = injectSaga({ key: 'theory', saga: theorySaga });
const theoryWithReducer = injectReducer({ key: 'theory', reducer: theoryReducer });
const muscleWithSaga = injectSaga({ key: 'byModel', saga: byModelSaga });
const muscleWithReducer = injectReducer({ key: 'byModel', reducer: byModelReducer });
const skeletalWithSaga = injectSaga({ key: 'skeletal', saga: skeletalSaga });
const skeletalWithReducer = injectReducer({ key: 'skeletal', reducer: skeletalReducer });
const favoritesWithSaga = injectSaga({ key: 'favoriteList', saga: favoritesSaga });
const favoritesWithReducer = injectReducer({ key: 'favoriteList', reducer: favoritesReducer });
const folderWithSaga = injectSaga({ key: 'myFolders', saga: foldersSaga });
const folderWithReducer = injectReducer({ key: 'myFolders', reducer: foldersReducer });
const azWithSaga = injectSaga({ key: 'azlist', saga: azSaga });
const azWithReducer = injectReducer({ key: 'azlist', reducer: azReducer });

const PrivateRouteStyled = withStyles(styles)(PrivateRoute);
const PrivateRouteIntl = injectIntl(PrivateRouteStyled);

export default compose(
  theoryWithSaga,
  theoryWithReducer,
  muscleWithSaga,
  muscleWithReducer,
  skeletalWithSaga,
  skeletalWithReducer,
  favoritesWithSaga,
  favoritesWithReducer,
  folderWithSaga,
  folderWithReducer,
  folderWithReducer,
  azWithSaga,
  azWithReducer,
  connect(
    () =>
      createStructuredSelector({
        login: makeSelectAuthReg(),
        global: makeSelectGlobal(),
        theory: makeSelectTheory(),
        az: makeSelectAzlist(),
        byModel: makeSelectByModel(),
        skeletal: makeSelectSkeletal(),
        favoriteList: makeSelectFavoriteList(),
        foldersList: makeSelectFolderContainer(),
      }),
    dispatch => ({
      getTheoryCategoriesAction: bindActionCreators(theoryActions.getTheoryCategoriesAction, dispatch),
      getAZActionAction: bindActionCreators(azActions.getAZAction, dispatch),
      getAsanasCategoryAction: bindActionCreators(theoryActions.getAsanasCategoriesAction, dispatch),
      getMyofascialCategoryAction: bindActionCreators(theoryActions.getMyofascialCategoryAction, dispatch),
      getPosturalTestsAction: bindActionCreators(theoryActions.getPosturalTestsAction, dispatch),
      getTerapeticCategoryAction: bindActionCreators(theoryActions.getTherapeuticVideos, dispatch),
      geByModelAction: bindActionCreators(muscleActions.geByModelAction, dispatch),
      geByModelMusclesAction: bindActionCreators(muscleActions.geByModelMusclesAction, dispatch),
      getMuscularAction: bindActionCreators(muscleActions.getMuscularAction, dispatch),
      getSkeletalAction: bindActionCreators(skeletalAction.getSkeletalAction, dispatch),
      fetchGetPosturalTherapeuticCategories: bindActionCreators(theoryActions.getPosturalCategoriesAction, dispatch),
      getPosturalDisordersCategories: bindActionCreators(theoryActions.getPosturalDisordersCategories, dispatch),
      getPostureYogaTheoryCategories: bindActionCreators(theoryActions.getPostureYogaTheoryCategories, dispatch),
      getMyFolders: bindActionCreators(folderActions.getAllFolders, dispatch),
      getFavoriteVideosAction: bindActionCreators(favoritesActions.getFavoriteList, dispatch),
      getSearchAction: bindActionCreators(getSearch, dispatch),
      clearSearchAction: bindActionCreators(clearSearch, dispatch),
      setAsideMenuHidden: bindActionCreators(setAsideMenuHidden, dispatch),
      dispatch,
    }),
  ),
)(PrivateRouteIntl);
