export default theme => ({
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    padding: `${theme.spacing(2.5)}px`,
  },
  videoWrap: {
    flexGrow: 1,
    margin: `${theme.spacing(4.5)}px ${theme.spacing(2.5)}px ${theme.spacing(9.5) + 62}px 0`,
    textAlign: 'center',
  },
  videoBox: {
    position: 'relative',
    display: 'inline-block',
    textAlign: 'left',
    maxWidth: 860,
    width: '100%',

    background: theme.palette.MMColors.main,
    '&:hover': {
      '& $arrowBtn': {
        opacity: 1,
      },
    },
  },
  actionCrumbsList: {
    position: 'absolute',
    top: -theme.spacing(4.5),
    left: 0,
    margin: 0,
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
  },
  actionCrumbsItem: {
    listStyle: 'none',
    fontSize: 16,
    whiteSpace: 'nowrap',
  },
  fox: {
    color: theme.palette.MMColors.fox,
  },
  bulletsWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px 0`,
  },
  bulletsBox: {
    margin: `0 ${theme.spacing(4)}px ${theme.spacing(1.5)}px 0`,
    '&:last-child': {
      marginRight: 0,
    },
  },
  bulletsLabel: {
    display: 'inline-block',
    marginRight: theme.spacing(),
    fontSize: 18,
    fontWeight: 600,
  },
  bullet: {
    border: '1px solid #d7e5ff',
    borderRadius: '50%',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    padding: 0,
    width: 22,
    height: 22,
    marginRight: theme.spacing(0.5),
    background: 'transparent',
    transition: `all 0.2s ${theme.transitions.easing.easeInOut}`,
    '&:hover': {
      borderColor: theme.palette.MMColors.fox,
      color: '#000',
      background: theme.palette.MMColors.fox,
    },
  },
  bulletActive: {
    borderColor: theme.palette.MMColors.fox,
    color: '#000',
    background: theme.palette.MMColors.fox,
  },
  actionsWrap: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2.5),
    background: theme.palette.MMColors.main,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
  },
  mmDivider: {
    width: '100%',
    margin: `${theme.spacing(0.5)}px 0`,
    borderBottom: '1px solid rgba(255, 255, 255, 0.15)',
  },
  formWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  formLabel: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px ${theme.spacing(0.5)}px 0`,
    lineHeight: 1.5,
    minWidth: 140,
    fontSize: 16,
    color: theme.palette.MMColors.fox,
    whiteSpace: 'nowrap',
  },
  focusedLabel: {
    color: `${theme.palette.MMColors.fox} !important`,
  },
  formControlLabel: {
    paddingLeft: theme.spacing(2),
    '& svg': {
      fontSize: 18,
    },
    '& > span': {
      fontSize: 16,
      padding: theme.spacing(0.5),
    },
  },
  imgPaper: {
    maxWidth: '22vw',
    minWidth: 250,
    background: theme.palette.MMColors.main,
  },
  imgDscr: {
    padding: theme.spacing(),
  },
  img: {
    width: '100%',
  },
  imgLayer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  iconButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
    '&:hover': {
      '& path': {
        color: theme.palette.MMColors.fox,
      },
    },
    '& svg': {
      fontSize: 32,
    },
    '& path': {
      transition: `color 0.2s ${theme.transitions.easing.easeInOut}`,
    },
  },
  backButton: {
    top: 0,
    left: 0,
    '&:hover': {
      '& path': {
        color: theme.palette.MMColors.fox,
      },
    },
    '& svg': {
      fontSize: 32,
    },
    '& path': {
      transition: `color 0.2s ${theme.transitions.easing.easeInOut}`,
    },
  },
  videoBtnsDialogPaper: {
    width: '100%',
  },
  infoDialogPaper: {
    padding: `0 ${theme.spacing(2)}px`,
    background: theme.palette.MMColors.main,
  },
  arrowBtn: {
    opacity: 0,
    position: 'absolute',
    top: '50%',
    padding: 0,
    zIndex: 10,
    background: 'transparent',
    border: 'none',
    transform: 'translateY(-50%)',
    transition: 'opacity 0.2s ease-out',
    '&:hover': {
      '& svg': {
        fill: theme.palette.MMColors.fox,
      },
    },
    '& svg': {
      fontSize: 50,
      transition: 'fill 0.2s ease-out',
    },
  },
  nextBtn: {
    right: theme.spacing(2),
  },
  prevBtn: {
    left: theme.spacing(2),
  },
  arrowIcon: {
    fontSize: 16,
    margin: `0 ${theme.spacing(0.5)}px`,
  },
  backLink: {
    transition: `color 0.2s ${theme.transitions.easing.easeInOut}`,
    '&:hover': {
      color: theme.palette.MMColors.fox,
    },
  },
  videoBtnsBox: {
    position: 'absolute',
    left: 0,
    top: 'calc(100% + 20px)',
    width: '100%',
  },
  btn: {
    padding: `${theme.spacing(0.125)}px ${theme.spacing(2)}px`,
    lineHeight: '36px',
    border: '2px solid #d7e5ff',
    borderRadius: 20,
    marginBottom: theme.spacing(2),
    background: 'transparent',
    transition: `all 0.3s ${theme.transitions.easing.easeInOut}`,
    '&:hover': {
      color: theme.palette.MMColors.black,
      background: theme.palette.MMColors.fox,
      borderColor: theme.palette.MMColors.fox,
    },
  },
  openDialogBtn: {
    '&:hover': {
      color: theme.palette.MMColors.black,
      background: theme.palette.MMColors.fox,
      borderColor: theme.palette.MMColors.fox,
    },
    '&:first-child': {
      color: theme.palette.MMColors.black,
      ...theme.action.button(theme.palette.MMColors.fox, theme.transitions.easing.easeInOut),
    },
    '&:not(:last-child)': {
      marginRight: theme.spacing(2.5),
    },
  },
  noDataMsg: {
    fontSize: 18,
  },
  dscrWrap: {
    marginBottom: theme.spacing(2.1),
  },
  dscrBtnWrap: {
    alignSelf: 'center',
    marginBottom: 10,
  },
  dscrBtn: {
    margin: '0 20px',
  },
  dscr: {
    margin: 0,
    fontSize: 18,
    lineHeight: '1.3em',
    letterSpacing: '0.3px',
  },
  imgDscrCircle: {
    position: 'relative',
    display: 'inline-block',
    marginBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2.1),
    '&:before': {
      position: 'absolute',
      top: '50%',
      left: 0,
      content: '""',
      width: 10,
      height: 10,
      borderRadius: '50%',
      transform: 'translateY(-50%)',
    },
    '&$insertionBg:before': {
      background: 'yellow',
    },
    '&$originBg:before': {
      background: 'blue',
    },
  },
  insertionBg: {},
  originBg: {},
});
