/* eslint-disable react/no-danger */
/**
 *
 * SubMuscle
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { injectIntl, intlShape } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose, bindActionCreators } from 'redux';
import classnames from 'classnames';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Link, Redirect } from 'react-router-dom';
import Radio from '@material-ui/core/Radio';
import AppPreloader from 'components/AppPreloader';
import FavoriteFolderBtns from 'components/FavoriteFolderBtns';
import RadioGroup from '@material-ui/core/RadioGroup';
import _ from 'lodash';
import VideoPlayer from 'components/VideoWrap/VideoPlayer/Loadable';
import Dialog from '@material-ui/core/Dialog';
import { Paper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
  mixpanelStorage,
  removeMuscleOriginInfoAndTime,
  setMuscleOriginViewInfo,
  setMuscleOriginViewTime,
} from 'localStorage';
import sanitizeHtml from 'sanitize-html';
import mixpanel from 'mixpanel';
import messages from './messages';
import subBonesMessages from '../SubBones/messages';
import makeSelectSubMuscle from './selectors';
import * as actions from './actions';
import reducer from './reducer';
import saga from './saga';
import styles from './styles';
import * as constants from './constants';
import { defineAppTitle } from '../../verticalConfigs';
import appReducer from '../App/reducer';
import appSaga from '../App/saga';
import * as appActions from '../App/actions';
import { makeSelectGlobal } from '../App/selectors';
import { PRICING_PAGE } from '../../utils/constants';

/* eslint-disable react/prefer-stateless-function */
export class SubMuscle extends React.PureComponent {
  state = {
    selectedMuscle: '0',
    selectedAction: '0',
    autoplay: false,
    imgDscrFlag: true,
    controls: true,
    videoSources: [],
    subtitlesSource: null,
    audioSource: '',
    // audio: {},
    selectedVideoPart: 0,
    heigth: 600,
    width: 860,
    openInfoDialog: false,
    openVideoDialog: false,
    stretchVideoUrl: null,
    strengthVideoUrl: null,
    selectedVideoId: null,
    noDataFlag: false,
    description: {},
    mixpanelClick: true,
    redirectToPricing: false,
    backToExercise: false,
    exerciseBackUrl: '',
    isDescriptionOldFormat: false,
  };

  UNSAFE_componentWillMount() {
    const { match, getMenuItemForVideo, getSubmuscleById } = this.props;

    if (match?.params?.muscleId) {
      const itemId = match?.params?.muscleId.split('.')[0];
      getSubmuscleById(itemId);
      getMenuItemForVideo('submuscle_id', itemId, null);
    } else {
      this.setState({ noDataFlag: true });
    }
    if (localStorage.getItem('backExerciseData')) {
      const { backUrl } = JSON.parse(localStorage.getItem('backExerciseData'));
      this.setState({ exerciseBackUrl: backUrl });
    }
  }

  componentWillUnmount() {
    const {
      subMuscle: {
        data: { submuscle },
      },
    } = this.props;
    if (this.state.redirectToPricing) {
      mixpanelStorage.setItem('entryPoint', 'Item Lock');
      mixpanelStorage.setItem('itemLockType', 'Muscle');
      mixpanelStorage.setItem('itemId', submuscle.id);
      mixpanelStorage.setItem('itemName', submuscle.name);
      removeMuscleOriginInfoAndTime();
    } else {
      mixpanel.sendMuscleOriginViewTime();
    }
  }

  setMuscleOriginViewInfoHandler() {
    setMuscleOriginViewInfo({
      ...mixpanelStorage.getItem('entryPoint'),
      ...mixpanelStorage.getItem('muscleName'),
      ...mixpanelStorage.getItem('muscleId'),
      ...mixpanelStorage.getItem('locked'),
      ...mixpanelStorage.getItem('moreInfoClicked'),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { subMuscle } = nextProps;
    const {
      subMuscle: {
        data: { submuscle },
      },
      global,
    } = nextProps;
    const { mixpanelClick } = this.state;
    const videoId = this.getVideoId();
    if (subMuscle.loaded) {
      if (
        submuscle.is_paid &&
        (global.user.loading === false &&
          global.user.loaded === false &&
          (!global.user.data || global.user.data.is_paid === false))
      ) {
        this.setState({ redirectToPricing: true });
      }
      this.setState({
        videoSources: this.filterVideoSrc(nextProps),
        selectedVideoId: this.getFilterVideoId(nextProps),
        description: this.filterDescriptions(nextProps),
        stretchVideoUrl: submuscle.stretch_video_url,
        strengthVideoUrl: submuscle.strength_video_url,
      });
      mixpanelStorage.setItem('muscleName', submuscle.name);
      mixpanelStorage.setItem('muscleId', submuscle.id);
      mixpanelStorage.setItem('locked', submuscle.is_paid ? 'True' : 'False');
      if (mixpanelClick) {
        this.setState({ mixpanelClick: false }, () => {
          mixpanel.muscleClick();
          mixpanelStorage.setItem('moreInfoClicked', 'False');
          setMuscleOriginViewTime(-1, -1);
          this.setMuscleOriginViewInfoHandler();
        });
      }
      if (videoId) {
        this.setVideoByVideoId(nextProps);
      }
    }
    // the isDescriptionOldFormat needed because since 19.03.2024 we started to use rich text editor for description on CMS side.
    // but we steel have old format of description in the database. So we need to check if the description is old format or not.
    // after editing the description on CMS side all \n will be removed so it is the only way to check if the description is old format or not.
    if (submuscle?.description?.structureRich?.includes('\n') || submuscle?.description?.actionsRich?.includes('\n')) {
      this.setState({ isDescriptionOldFormat: true });
    }
  }

  getVideoId = () => {
    const searchVideoId = window.location.search.match(/video=([^&]*)/);
    return searchVideoId?.length ? searchVideoId[1] : '';
  };

  setVideoByVideoId = props => {
    const {
      subMuscle: {
        data: { submuscle },
      },
    } = props;
    const videoId = this.getVideoId();
    submuscle.parts.forEach((part, partKey) => {
      part.actions.forEach((action, actionKey) => {
        action.videos.forEach((video, videoKey) => {
          if (videoId === video.id) {
            this.setState(
              {
                selectedMuscle: `${partKey}`,
                selectedAction: `${actionKey}`,
                selectedVideoPart: videoKey,
              },
              () => {
                this.setState(
                  {
                    selectedVideoPart: videoKey,
                    subtitlesSource: this.filterSubSrc(this.props, videoKey),
                    selectedVideoId: video.id,
                  },
                  () => {
                    this.setState({ videoSources: this.filterVideoSrc(props), imgDscrFlag: true });
                  },
                );
              },
            );
          }
        });
      });
    });
  };

  getFilterVideoId = props => {
    const { selectedAction, selectedMuscle, selectedVideoPart } = this.state;
    const {
      subMuscle: {
        data: { submuscle },
      },
    } = props;
    const allActions = submuscle.parts[parseInt(selectedMuscle, 10)].actions;
    return allActions.length ? allActions[parseInt(selectedAction, 10)].videos[parseInt(selectedVideoPart, 10)].id : '';
  };

  filterSubSrc = (props, selectedVideoPart) => {
    const { selectedAction, selectedMuscle } = this.state;
    const {
      subMuscle: {
        data: { submuscle },
      },
    } = props;
    const item =
      submuscle.parts[parseInt(selectedMuscle, 10)].actions[parseInt(selectedAction, 10)].videos[
        parseInt(selectedVideoPart, 10)
      ];
    return item.subtitles_url;
  };

  /* just return selected video from store */
  filterVideoSrc = props => {
    const { selectedAction, selectedMuscle, selectedVideoPart, subtitlesSource } = this.state;
    const {
      subMuscle: {
        data: { submuscle },
      },
    } = props;
    const allActions = submuscle.parts[parseInt(selectedMuscle, 10)].actions;
    if (allActions.length) {
      const item = allActions[parseInt(selectedAction, 10)].videos[parseInt(selectedVideoPart, 10)];
      if (subtitlesSource === null) this.setState({ subtitlesSource: item.subtitles_url || '' });
      this.setState({ audioSource: item.audio_url || '' });
    }
    return allActions.length
      ? allActions[parseInt(selectedAction, 10)].videos[parseInt(selectedVideoPart, 10)].video_url
      : '';
  };

  filterDescriptions = props => {
    const {
      subMuscle: {
        data: { submuscle },
      },
    } = props;
    return submuscle.description;
  };

  handleChangeAction = value => {
    this.setState({ selectedAction: value, selectedVideoPart: 0 }, () => {
      this.setState({
        videoSources: this.filterVideoSrc(this.props),
        description: this.filterDescriptions(this.props),
        selectedVideoId: this.getFilterVideoId(this.props),
        imgDscrFlag: true,
      });
    });
  };

  handleChangeMuscle = value => {
    this.setState({ selectedMuscle: value, selectedAction: '0', selectedVideoPart: 0 }, () => {
      this.setState({
        videoSources: this.filterVideoSrc(this.props),
        description: this.filterDescriptions(this.props),
        selectedVideoId: this.getFilterVideoId(this.props),
        imgDscrFlag: true,
      });
    });
  };

  renderSelectMuscles = () => {
    const {
      classes,
      subMuscle: {
        data: { submuscle },
      },
      intl: { formatMessage },
    } = this.props;
    const { selectedMuscle } = this.state;
    return (
      <FormControl classes={{ root: classes.formWrap }}>
        <FormLabel classes={{ root: classes.formLabel, focused: classes.focusedLabel }}>
          {formatMessage(messages.chooseMuscle)}
        </FormLabel>
        <RadioGroup onChange={e => this.handleChangeMuscle(e.target.value)} classes={{ root: classes.formWrap }}>
          {_.map(submuscle.parts, (muscle, key) => (
            <FormControlLabel
              key={`muscl_${key}`}
              value={key}
              control={<Radio />}
              checked={selectedMuscle === `${key}`}
              label={muscle.name}
              classes={{ root: classes.formControlLabel }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  };

  renderSelectAction = () => {
    const {
      classes,
      subMuscle: {
        data: { submuscle },
      },
      intl: { formatMessage },
    } = this.props;
    const { selectedMuscle, selectedAction } = this.state;
    return (
      <FormControl classes={{ root: classes.formWrap }}>
        <FormLabel classes={{ root: classes.formLabel, focused: classes.focusedLabel }}>
          {formatMessage(messages.chooseAction)}
        </FormLabel>
        <RadioGroup
          onChange={e => this.handleChangeAction(e.target.value)}
          value="0"
          classes={{ root: classes.formWrap }}
        >
          {/* eslint-disable-next-line consistent-return */}
          {_.map(submuscle.parts[parseInt(selectedMuscle, 10)].actions, (action, key) => {
            if (action.videos.length > 0 && action.videos[0].video_url !== '') {
              return (
                <FormControlLabel
                  key={`action_${key}`}
                  value={key}
                  checked={selectedAction === `${key}`}
                  control={<Radio />}
                  label={action.name}
                  classes={{ root: classes.formControlLabel }}
                />
              );
            }
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  renderSkeletalImage = () => {
    const {
      classes,
      subMuscle: {
        data: { submuscle },
      },
      intl: { formatMessage },
    } = this.props;
    const { selectedMuscle, imgDscrFlag } = this.state;
    const originData = submuscle.parts[parseInt(selectedMuscle, 10)].origin;
    const insertionData = submuscle.parts[parseInt(selectedMuscle, 10)].insertion;
    const dscrData = data =>
      _.map(data, (dscrBox, index) => (
        <div key={index} style={{ marginBottom: 22 }}>
          {_.map(dscrBox, (dscr, ind) => (
            <p key={ind} style={{ margin: 0 }}>
              {dscr}
            </p>
          ))}
        </div>
      ));
    return (
      <Paper elevation={8} className={classes.imgPaper}>
        <div style={{ position: 'relative' }}>
          {submuscle.parts[parseInt(selectedMuscle, 10)].overlay_image_url && (
            <IconButton
              onClick={() => this.setState(state => ({ imgDscrFlag: !state.imgDscrFlag }))}
              className={classes.iconButton}
            >
              {imgDscrFlag ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          )}
          <img
            className={classes.img}
            src={submuscle.parts[parseInt(selectedMuscle, 10)].image_url}
            alt=""
            onContextMenu={e => e.preventDefault()}
          />
          {submuscle.parts[parseInt(selectedMuscle, 10)].overlay_image_url && imgDscrFlag && (
            <img
              className={classes.imgLayer}
              src={submuscle.parts[parseInt(selectedMuscle, 10)].overlay_image_url}
              alt=""
              onContextMenu={e => e.preventDefault()}
            />
          )}
        </div>
        {originData.length || insertionData.length ? (
          <div className={classes.imgDscr}>
            {originData.length ? (
              <>
                <span className={`${classes.imgDscrCircle} ${classes.originBg}`}>{formatMessage(messages.origin)}</span>
                {dscrData(originData)}
              </>
            ) : null}
            {insertionData.length ? (
              <>
                <span className={`${classes.imgDscrCircle} ${classes.insertionBg}`}>
                  {formatMessage(messages.insertion)}
                </span>
                {dscrData(insertionData)}
              </>
            ) : null}
          </div>
        ) : null}
      </Paper>
    );
  };

  renderVideo = () => {
    const { autoplay, controls, videoSources, heigth, width, subtitlesSource, audioSource } = this.state;
    // Must be wrapped on <div> !!!!IMPORTANT!!!!!
    return (
      <div>
        <VideoPlayer
          track={subtitlesSource}
          autoplay={autoplay}
          controls={controls}
          sources={[{ src: videoSources, type: 'video/mp4' }]}
          audioData={{
            src: audioSource,
            type: 'audio/mp3',
          }}
          heigth={heigth}
          width={width}
          audio={!!audioSource}
          videoPause
        />
      </div>
    );
  };

  renderVideoPartsButtons = (videos, classes, formatMessage) => (
    <>
      <span className={classes.bulletsLabel}>{formatMessage(subBonesMessages.labelVideos)}</span>
      {_.map(videos, (video, key) => {
        if (video.video_url) {
          return (
            <button
              type="button"
              key={`button_${key}`}
              className={
                this.state.selectedVideoPart === key ? `${classes.bullet} ${classes.bulletActive}` : classes.bullet
              }
              onClick={() => {
                this.setState(
                  {
                    selectedVideoPart: key,
                    subtitlesSource: this.filterSubSrc(this.props, key),
                    selectedVideoId: video.id,
                  },
                  () => {
                    this.setState({ videoSources: this.filterVideoSrc(this.props), imgDscrFlag: true });
                  },
                );
              }}
            >
              {key + 1}
            </button>
          );
        }
        return false;
      })}
    </>
  );

  renderVideoDialog = () => {
    const {
      subMuscle: {
        data: { submuscle },
      },
      match: { params },
    } = this.props;
    const { autoplay, controls, heigth, width, openVideoDialog, type, stretchVideoUrl, strengthVideoUrl } = this.state;
    let video;
    let audio;
    let subtitles;
    let assetId;
    switch (type) {
      case 'strength':
        video = strengthVideoUrl;
        assetId = constants.ASSET_ID_STRENGTHENING;
        audio = submuscle.strength_audio_url;
        subtitles = submuscle.strength_subtitles_url;
        break;
      case 'stretch':
        video = stretchVideoUrl;
        assetId = constants.ASSET_ID_STRETCHING;
        audio = submuscle.stretch_audio_url;
        subtitles = submuscle.stretch_subtitles_url;
        break;
      case 'insertion':
        video = submuscle.origin.video_url;
        audio = submuscle.origin.audio_url;
        subtitles = submuscle.origin.subtitles_url;
        assetId = constants.ASSET_ID_ORIGIN;
        break;
      default:
        break;
    }
    return (
      <Dialog
        open={openVideoDialog}
        onClose={() => this.setState({ openVideoDialog: false })}
        maxWidth="xl"
        classes={{ paper: this.props.classes.videoBtnsDialogPaper }}
      >
        <FavoriteFolderBtns id={params?.muscleId} assetId={assetId} chapter="muscular" />
        <VideoPlayer
          track={subtitles || null}
          autoplay={autoplay}
          controls={controls}
          sources={[{ src: video, type: 'video/mp4' }]}
          audioData={{
            src: audio || '',
            type: 'audio/mp3',
          }}
          heigth={heigth}
          width={width}
          type="muscleOrigin"
          audio
          videoPause
        />
      </Dialog>
    );
  };

  openDialogHandler = type => {
    this.setState({ openVideoDialog: true, type }, () => {
      if (type === 'insertion') {
        this.setMuscleOriginViewInfoHandler();
      }
    });
  };

  renderInfoDialog = () => {
    const { openInfoDialog, description, isDescriptionOldFormat } = this.state;
    const {
      subMuscle,
      intl: { formatMessage },
      classes,
    } = this.props;

    const dscrData = data =>
      _.map(data, (dscrBox, index) => (
        <div key={index} style={{ marginBottom: 20 }}>
          {_.map(dscrBox, (dscr, ind) => (
            <p key={ind} className={classes.dscr}>
              {dscr}
            </p>
          ))}
        </div>
      ));
    const dialogBtnClasses = classnames(classes.dscrBtn, classes.btn);
    const isLinksExist =
      subMuscle.data?.submuscle?.stretch_video_url !== '' && subMuscle.data?.submuscle?.strength_video_url !== '';

    return (
      <Dialog
        open={openInfoDialog}
        maxWidth="lg"
        onClose={() => this.setState({ openInfoDialog: false })}
        classes={{ paper: classes.infoDialogPaper }}
      >
        {isDescriptionOldFormat && (
          <>
            <div className={classes.dscrWrap}>{dscrData(description.structure)}</div>
            <div className={classes.dscrWrap}>{dscrData(description.actions)}</div>
          </>
        )}
        {!isDescriptionOldFormat && (
          <>
            <div
              className={classes.dscrWrap}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(description.structureRich),
              }}
            />
            <div
              className={classes.dscrWrap}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(description.actionsRich),
              }}
            />
          </>
        )}
        {isLinksExist && (
          <div className={classes.dscrBtnWrap}>
            <button className={dialogBtnClasses} type="button" onClick={() => this.openDialogHandler('strength')}>
              {formatMessage(messages.strength)}
            </button>
            <button className={dialogBtnClasses} type="button" onClick={() => this.openDialogHandler('stretch')}>
              {formatMessage(messages.stretch)}
            </button>
          </div>
        )}
      </Dialog>
    );
  };

  renderArrows(videos) {
    const { classes } = this.props;
    const { selectedVideoPart } = this.state;
    return videos.length ? (
      <>
        {selectedVideoPart > 0 ? (
          <button
            type="button"
            onClick={() => {
              this.setState(
                {
                  selectedVideoPart: selectedVideoPart - 1,
                  selectedVideoId: videos[selectedVideoPart - 1].id,
                  subtitlesSource: this.filterSubSrc(this.props, selectedVideoPart - 1),
                },
                () => {
                  this.setState({ videoSources: this.filterVideoSrc(this.props), imgDscrFlag: true });
                },
              );
            }}
            className={`${classes.prevBtn} ${classes.arrowBtn}`}
          >
            <ArrowBackIosIcon />
          </button>
        ) : null}
        {videos.length - 1 > selectedVideoPart ? (
          <button
            type="button"
            onClick={() => {
              this.setState(
                {
                  selectedVideoPart: selectedVideoPart + 1,
                  selectedVideoId: videos[selectedVideoPart + 1].id,
                  subtitlesSource: this.filterSubSrc(this.props, selectedVideoPart + 1),
                },
                () => {
                  this.setState({ videoSources: this.filterVideoSrc(this.props), imgDscrFlag: true });
                },
              );
            }}
            className={`${classes.nextBtn} ${classes.arrowBtn}`}
          >
            <ArrowForwardIosIcon />
          </button>
        ) : null}
      </>
    ) : null;
  }

  renderBackExercise = (classes, id) => {
    if (localStorage.getItem('backExerciseData')) {
      const { exerciseName, backUrl, targetId } = JSON.parse(localStorage.getItem('backExerciseData'));
      if (id === targetId.split('.')[0]) {
        return (
          <li className={classes.actionCrumbsItem}>
            <Link to={backUrl} className={classes.backLink}>
              {exerciseName}
            </Link>
            <ArrowForwardIcon className={classes.arrowIcon} />
          </li>
        );
      }
    } else if (localStorage.getItem('backSearchData')) {
      const { backUrl } = JSON.parse(localStorage.getItem('backSearchData'));
      return (
        <li className={classes.actionCrumbsItem}>
          <Link to={backUrl} className={classes.backLink}>
            Search result
          </Link>
          <ArrowForwardIcon className={classes.arrowIcon} />
        </li>
      );
    }
    return null;
  };

  backHandler = e => {
    const backFlag = this.props.match.params?.backFlag;
    if (this.state.exerciseBackUrl) {
      return this.setState({ backToExercise: true });
    }
    if (typeof backFlag === 'boolean' && !backFlag) {
      return this.props.closePopup();
    }
    if (this.props.history) {
      return this.props.history.goBack();
    }
    e.preventDefault();
  };

  render() {
    const {
      classes,
      match: { params },
      subMuscle: {
        loading,
        loaded,
        data: { submuscle },
      },
      intl: { formatMessage },
    } = this.props;
    const {
      selectedMuscle,
      selectedAction,
      videoSources,
      selectedVideoId,
      noDataFlag,
      redirectToPricing,
      backToExercise,
      exerciseBackUrl,
    } = this.state;
    const openBtnClasses = classnames(classes.openDialogBtn, classes.btn);

    return (
      <div className={classes.wrap}>
        <Helmet>
          <title>{defineAppTitle()}</title>
          <meta name="description" content={formatMessage(messages.metaDescription)} />
        </Helmet>
        {noDataFlag && <p className={classes.noDataMsg}>{formatMessage(messages.noDataMsg)}</p>}
        {loading && <AppPreloader />}
        {loaded && (
          <>
            <Paper elevation={3} className={classes.actionsWrap}>
              {this.renderSelectMuscles()}
              {submuscle.parts[parseInt(selectedMuscle, 10)].actions.length > 0 && (
                <>
                  <span className={classes.mmDivider} />
                  {this.renderSelectAction()}
                </>
              )}
            </Paper>
            <IconButton className={classes.backButton} onClick={this.backHandler}>
              <ArrowBack />
            </IconButton>
            <div className={classes.videoWrap}>
              <Paper elevation={8} className={classes.videoBox}>
                <ul className={classes.actionCrumbsList}>
                  {this.renderBackExercise(classes, submuscle.id)}
                  <li className={classes.actionCrumbsItem}>
                    {submuscle.name}
                    <ArrowForwardIcon className={classes.arrowIcon} />
                  </li>
                  <li className={`${classes.actionCrumbsItem} ${classes.fox}`}>
                    {submuscle.parts[parseInt(selectedMuscle, 10)].name}
                    <ArrowForwardIcon className={classes.arrowIcon} />
                  </li>
                  <li className={`${classes.actionCrumbsItem} ${classes.fox}`}>
                    {submuscle.parts[parseInt(selectedMuscle, 10)].actions.length > 0 &&
                      submuscle.parts[parseInt(selectedMuscle, 10)].actions[parseInt(selectedAction, 10)].name}
                  </li>
                </ul>
                {submuscle.parts[parseInt(selectedMuscle, 10)].actions.length > 0 && (
                  <>
                    <FavoriteFolderBtns
                      id={params?.muscleId.split('?')[0]}
                      assetId={selectedVideoId || null}
                      chapter="muscular"
                    />
                    {videoSources && this.renderVideo()}
                    {this.renderArrows(
                      submuscle.parts[parseInt(selectedMuscle, 10)].actions[parseInt(selectedAction, 10)].videos,
                    )}
                    <div className={classes.bulletsWrap}>
                      <div className={classes.bulletsBox}>
                        {this.renderVideoPartsButtons(
                          submuscle.parts[parseInt(selectedMuscle, 10)].actions[parseInt(selectedAction, 10)].videos,
                          classes,
                          formatMessage,
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div className={classes.videoBtnsBox}>
                  {submuscle.origin && submuscle.origin.video_url && submuscle.origin.video_url !== '' && (
                    <button
                      className={openBtnClasses}
                      type="button"
                      onClick={() => this.openDialogHandler('insertion')}
                    >
                      {formatMessage(messages.originInsertion)}
                    </button>
                  )}
                  {(submuscle.description?.actions !== '' || submuscle.description?.structure !== '') && (
                    <button
                      className={openBtnClasses}
                      type="button"
                      onClick={() =>
                        this.setState({ openInfoDialog: true }, () => {
                          mixpanelStorage.setItem('moreInfoClicked', 'True');
                          this.setMuscleOriginViewInfoHandler();
                        })
                      }
                    >
                      {formatMessage(messages.moreInfo)}
                    </button>
                  )}
                </div>
              </Paper>
            </div>
            {this.renderSkeletalImage()}
            {this.renderInfoDialog()}
            {this.renderVideoDialog()}
            {redirectToPricing && <Redirect to={PRICING_PAGE} />}
            {backToExercise && <Redirect to={exerciseBackUrl} />}
          </>
        )}
      </div>
    );
  }
}

SubMuscle.propTypes = {
  classes: PropTypes.object,
  global: PropTypes.object,
  subMuscle: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  getMenuItemForVideo: PropTypes.func,
  getSubmuscleById: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = createStructuredSelector({
  subMuscle: makeSelectSubMuscle(),
  global: makeSelectGlobal(),
});

function mapDispatchToProps(dispatch) {
  return {
    getSubmuscleById: bindActionCreators(actions.getDetailSubmuscle, dispatch),
    getMenuItemForVideo: bindActionCreators(appActions.getMenuItemForVideo, dispatch),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'subMuscle', reducer });
const withSaga = injectSaga({ key: 'subMuscle', saga });
const withAppReducer = injectReducer({ key: 'global', reducer: appReducer });
const withAppSaga = injectSaga({ key: 'global', saga: appSaga });

const SubMuscleStyled = withStyles(styles)(injectIntl(SubMuscle));

export default compose(
  withReducer,
  withSaga,
  withAppReducer,
  withAppSaga,
  withConnect,
)(SubMuscleStyled);
