/*
 * SimpleVideoPage Messages
 *
 * This contains all the text for the SimpleVideoPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.SimpleVideoPage';

export default defineMessages({
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
  metaTitle: {
    id: `${scope}.metaTitle`,
    defaultMessage: 'Exercise',
  },
  metaDescription: {
    id: `${scope}.metaDescription`,
    defaultMessage: 'Description of Exercise',
  },
  correct: {
    id: `${scope}.correct`,
    defaultMessage: 'Exercise Analysis:',
  },
  mistake: {
    id: `${scope}.mistake`,
    defaultMessage: 'Common Mistakes:',
  },
  problems: {
    id: `${scope}.problems`,
    defaultMessage: 'Common Problems:',
  },
  preparatory: {
    id: `${scope}.preparatory`,
    defaultMessage: 'Preparatory Exercises:',
  },
  alternativeExercises: {
    id: `${scope}.alternativeExercises`,
    defaultMessage: 'Alternative Exercises:',
  },
  muscles: {
    id: `${scope}.muscles`,
    defaultMessage: 'Active Muscles:',
  },
  notice: {
    id: `${scope}.notice`,
    defaultMessage: 'Notice:',
  },
  noticeDscr: {
    id: `${scope}.noticeDscr`,
    defaultMessage:
      'You are about to step out of this chapter and open muscular anatomy chapter to see the muscle you have chosen. You can always come back by clicking on "back" button.',
  },
  noticeCheckbox: {
    id: `${scope}.noticeCheckbox`,
    defaultMessage: "Don't show this message again",
  },
  labelTarget: {
    id: `${scope}.labelTarget`,
    defaultMessage: 'Target',
  },
  labelSynergist: {
    id: `${scope}.labelSynergist`,
    defaultMessage: 'Synergist',
  },
  labelStabilizer: {
    id: `${scope}.labelStabilizer`,
    defaultMessage: 'Stabilizer',
  },
  labelLengthening: {
    id: `${scope}.labelLengthening`,
    defaultMessage: 'Lengthening',
  },
  goToFullAsana: {
    id: `${scope}.goToFullAsana`,
    defaultMessage: 'Go to full asana',
  },
  goToFullExercise: {
    id: `${scope}.goToFullExercise`,
    defaultMessage: 'Go to full exercise',
  },
  videoIsNotFound: {
    id: `${scope}.videoIsNotFound`,
    defaultMessage: 'Video is not found',
  },
  missingLangDescription: {
    id: `${scope}.missingLangDescription`,
    defaultMessage: 'translations are coming soon! We are on it...',
  },
});
