import { takeLatest, put } from 'redux-saga/effects';
import * as ActionTypes from './constants';
import axios from '../../utils/axiosConfig';

export function* fetchGetByModel() {
  try {
    const response = yield axios.get('video_with_details/body_parts');
    if (response) {
      yield put({
        type: ActionTypes.GET_BYMODEL_SUCCESS,
        data: response.data,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_BYMODEL_FAIL,
      data: e.response.data.message,
    });
  }
}

export function* fetchGetByModelMuscles() {
  try {
    const response = yield axios.get('video_with_details/muscles');
    if (response) {
      yield put({
        type: ActionTypes.GET_BYMODEL_MUSCLES_SUCCESS,
        data: response.data,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_BYMODEL_MUSCLES_FAIL,
      data: e.response.data.message,
    });
  }
}

export function* fetchGetMuscular() {
  try {
    const response = yield axios.get('muscular/assets');
    if (response) {
      yield put({
        type: ActionTypes.GET_MUSCULAR_SUCCESS,
        data: response.data,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_MUSCULAR_FAIL,
      data: e.response.data.message,
    });
  }
}

// All sagas to be loaded
export default function* byModelSaga() {
  yield takeLatest(ActionTypes.GET_BYMODEL, fetchGetByModel);
  yield takeLatest(ActionTypes.GET_BYMODEL_MUSCLES, fetchGetByModelMuscles);
  yield takeLatest(ActionTypes.GET_MUSCULAR, fetchGetMuscular);
}
