/* eslint-disable no-debugger, no-console, no-restricted-globals */
import ReactGA from 'react-ga4';
import { CURRENT_VERTICAL, defineGAMeasurmentId } from './verticalConfigs';
// eslint-disable-next-line import/no-cycle
import {
  getMode,
  getPlan,
  getSubscriber,
  getTheoryViewTime,
  getUserId,
  getTheoryViewInfo,
  removeTheoryInfoAndTime,
} from './localStorage';

const GA_MEASUREMENT_ID = defineGAMeasurmentId();
const gaWorks = {
  init: () => {
    const userId = getUserId();
    ReactGA.reset();
    ReactGA.initialize([
      {
        trackingId: GA_MEASUREMENT_ID,
        gtagOptions: userId
          ? {
              user_id: userId,
              subscriber: getSubscriber(),
              plan: getSubscriber() === 'yes' ? getMode() : undefined,
              subscription: getSubscriber() === 'yes' ? getPlan() : undefined,
            }
          : {
              subscriber: getSubscriber(),
              plan: getSubscriber() === 'yes' ? getMode() : undefined,
              subscription: getSubscriber() === 'yes' ? getPlan() : undefined,
            },
      },
    ]);
  },
  event: (method, params = {}) => {
    const userId = getUserId();
    const subscriber = getSubscriber();
    if (userId) params.mmUserId = userId;
    params.subscriber = subscriber;
    if (subscriber === 'yes') {
      params.plan = getMode();
      params.subscription = getPlan();
    }

    if (method.startsWith('1old')) {
      return console.log('LG --- old GA method:\n', method, '\n-----\n');
    }
    ReactGA.event(method, params);
  },
  makeAttributesForNews: newItem => {
    const { type, section } = newItem;
    let item_category;
    let item_id;
    if (newItem.exercise_id && newItem.exercise_id !== '') {
      item_category = 'exercise';
      item_id = newItem.exercise_id;
    }
    if (newItem.muscular_id && newItem.muscular_id !== '') {
      item_category = 'muscular';
      item_id = newItem.muscular_id;
    }
    if (newItem.mmplan_id && newItem.mmplan_id !== '') {
      item_category = 'mm_plan';
      item_id = newItem.mmplan_id;
    }
    if (newItem.skeletal_id && newItem.skeletal_id !== '') {
      item_category = 'skeletal';
      item_id = newItem.skeletal_id;
    }
    if (newItem.theory_id && newItem.theory_id !== '') {
      item_category = 'theory';
      item_id = newItem.theory_id;
    }

    return {
      item_category,
      type,
      section,
      item_id,
    };
  },
  sendTheoryViewTime: () => {
    const theoryViewInfo = getTheoryViewInfo();
    const theoryViewTime = getTheoryViewTime();
    if (!theoryViewInfo) {
      if (process.env.NODE_ENV !== 'production') {
        return console.log('Theory Video Info is missing');
      }
      removeTheoryInfoAndTime();
      return;
    }
    theoryViewTime.viewTime = theoryViewTime.timeMatrix.length;
    if (theoryViewTime.viewTime === 0) return removeTheoryInfoAndTime();

    const { timeMatrix, ...viewTimeWithoutMatrix } = theoryViewTime;
    ReactGA.event('theoryVideo_view', {
      ...theoryViewInfo,
      ...viewTimeWithoutMatrix,
      completionRate: +(viewTimeWithoutMatrix?.completionRate / 100).toFixed(2),
    });

    setTimeout(() => {
      removeTheoryInfoAndTime();
    }, 3000);
  },
};

// eslint-disable-next-line no-unused-vars
const gaEmpty = {
  init: () => {
    console.log('GA is off, local env detected, GA_MEASUREMENT_ID is missing or not allowed vertical');
  },
  event: () => {},
  makeAttributesForNews: () => {},
  sendTheoryViewTime: () => {},
};

const ga =
  sessionStorage.getItem('firebase') === 'on' ||
  (location.origin.includes('https://app.') && GA_MEASUREMENT_ID && CURRENT_VERTICAL === 'strength')
    ? gaWorks
    : gaWorks;
export default ga;
