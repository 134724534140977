// this file is untracked for changes

// export const apiUrl = 'https://app.muscleandmotion.com/api/v1/';
export const apiUrl = process.env.REACT_APP_API_URL;
// export const apiUrl = 'http://localhost:1337/api/v1/';
// export const apiUrl = 'http://0.0.0.0:8080/api/v1';

// export const blueSnapProdUrl = 'https://ws.bluesnap.com/services/2/subscriptions/';
export const blueSnapUrl = process.env.REACT_APP_BLUE_SNAP_URL;

export const appVersion = process.env.REACT_APP_VERSION;
// export const appVersion = '1.5.0.74';
