/**
 *
 * VideoWrap
 *
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from './styles';
import VidePlayer from './VideoPlayer/Loadable';

export class VideoWrap extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      autoplay: true,
      controls: true,
      // sources: [],
      // audio: {},
      heigth: 864,
      width: 1152,
    };
  }

  render() {
    const { controls, autoplay, heigth, width } = this.state;
    return (
      <div>
        <VidePlayer
          sources={[{ src: `${this.props.video.asset_url}`, type: 'video/mp4' }]}
          track={this.props.video.subtitles_url || null}
          controls={controls}
          autoplay={autoplay}
          heigth={heigth}
          width={width}
          audioData={{ src: `${this.props.video.audio_url}`, type: 'audio/mp3' }}
          audio
        />

        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      </div>
    );
  }
}

VideoWrap.propTypes = {
  video: PropTypes.object,
};

const VideoWrapStyled = withStyles(styles)(VideoWrap);

export default VideoWrapStyled;
