/**
 *
 * FavoriteFolderBtns
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators, compose } from 'redux';
import IconButton from '@material-ui/core/IconButton';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import AppPreloader from 'components/AppPreloader';
import _ from 'lodash';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { createStructuredSelector } from 'reselect';
import { injectIntl, intlShape } from 'react-intl';
import myFoldersMessages from 'containers/MyFolders/messages';
import noticePopupMessages from 'components/NoticePopup/messages';
import newPlanMessages from 'components/NewPlan/messages';
import FavoriteFolderBtnsNoticePopup from 'components/Popups/FavoriteFolderBtnsNoticePopup';
import ConfirmPopup from 'components/Popups/ConfirmPopup';
import ReactGA from 'googleAnalytics';
import AddFolderPopup from './AddFolderPopup';
import favoritesSaga from '../../containers/FavoriteList/saga';
import makeSelectFavoriteList from '../../containers/FavoriteList/selectors';
import * as favoritesActions from '../../containers/FavoriteList/actions';
import favoritesReducer from '../../containers/FavoriteList/reducer';
import * as folderActions from '../../containers/MyFolders/actions';
import foldersReducer from '../../containers/MyFolders/reducer';
import foldersSaga from '../../containers/MyFolders/saga';
import makeSelectFolderContainer from '../../containers/MyFolders/selectors';
import * as appActions from '../../containers/App/actions';
import { makeSelectGlobal } from '../../containers/App/selectors';
import styles from './styles';
import NoticePopup from '../NoticePopup/Loadable';
import messages from './messages';
import authRegMessages from '../AuthRegComponent/messages';
import { defineAppName } from '../../verticalConfigs';
import mixpanel from '../../mixpanel';
import { mixpanelStorage } from '../../localStorage';

/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/prefer-stateless-function */
export class FavoriteFolderBtns extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shareDialog: false,
      shareLockPopup: false,
      loadingShare: false,
      shareTitle: '',
      shareDescription: '',
      favoriteItem: false,
      addDeleteFlag: false,
      openFolder: false,
      folderName: '',
      openDialog: false,
      showNotice: false,
      mouseX: 0,
      mouseY: 0,
      storeName: 'FolderItems',
      linkTo: '/login',
      lockPopupDescription: this.props.intl.formatMessage(messages.lockPopupDescription),
      lockSharePopupDescription: this.props.intl.formatMessage(messages.lockSharePopupDescription),
      isCreateFolder: false,
    };
  }

  componentDidMount() {
    this.fetchCheckFavorite(this.props.favoriteList, this.props.assetId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.foldersList.added) this.handleCloseFolder();
    this.fetchCheckFavorite(nextProps.favoriteList, nextProps.assetId);
    if (nextProps.favoriteList.shareLink.data) {
      this.handleOpenMail(nextProps.favoriteList.shareLink.data);
      this.props.shareLinkClean();
    }
  }

  componentDidUpdate() {
    if (this.props.foldersList.folders.error && this.props.foldersList.added) {
      this.props.openErrorNotification(this.props.intl.formatMessage(messages.errorAddFolder));
      setTimeout(() => this.props.cleanErrorAction(), 4000);
    }
  }

  componentWillUnmount() {
    if (this.props.foldersList.folders.error) this.props.cleanErrorAction();
  }

  fetchCheckFavorite = (favoriteList, assetId) => {
    if (favoriteList.favoriteList.data.favorites) {
      const { favorites } = favoriteList.favoriteList.data;
      const res = _.find(favorites, item => item.asset_id === assetId);
      this.setState({ favoriteItem: res, assetId }, () => {
        this.setState({ addDeleteFlag: !!res });
      });
    }
  };

  handleCloseFolder = () => this.setState({ openFolder: false, showNotice: false, folderName: '' });

  handleOpenMail = deeplink => {
    const title = this.props.intl.formatMessage(messages.shareTitle);
    const body = this.props.intl.formatMessage(messages.shareDescription, {
      appName: defineAppName(),
      deeplink,
    });
    window.location.href = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(body)}`;
    let openPopupTimeout;
    const clearPopupFunc = () => {
      clearTimeout(openPopupTimeout);
      this.setState({ loadingShare: false });
      window.removeEventListener('blur', clearPopupFunc);
    };
    window.addEventListener('blur', clearPopupFunc);
    mixpanelStorage.setItem('saveBlock', 'Started');
    mixpanel.videoShare();
    mixpanel.exerciseShare();
    openPopupTimeout = setTimeout(() => {
      this.setState({
        shareDialog: true,
        loadingShare: false,
        shareTitle: title,
        shareDescription: body,
      });
    }, 1000);
  };

  handleAddNewFolder = ({ itemId, assetId }) => {
    const { addNewFolder, section, chapter } = this.props;
    addNewFolder(this.state.folderName, chapter, section, itemId, assetId);
  };

  handleCloseDialog = () => this.setState({ openDialog: false });

  handleOpenDialog = (shareLockPopup = false) => this.setState({ openDialog: true, shareLockPopup });

  handleCloseShareDialog = () => this.setState({ shareDialog: false, shareTitle: '', shareDescription: '' });

  handleOpenShareDialog = () => this.setState({ shareDialog: true });

  handlerChooseFolder = (e, folder) => {
    const { assetId, showNotice } = this.state;
    const { addToFolder, chapter, subId, id, section } = this.props;
    const isFolderExist = _.find(folder.files, o => o.asset_id === assetId);
    if (!isFolderExist) {
      addToFolder({
        id: folder.id,
        chapter,
        item_id: subId || (!section ? id : id.split('@')[0].split('?')[0]),
        asset_id: assetId || this.props.assetId,
        section,
      });
      ReactGA.event('1oldfolder', { item_id: subId || (!section ? id : id.split('@')[0].split('?')[0]) });
      if (showNotice) this.setState({ showNotice: false });
    } else {
      this.setState({ showNotice: true, mouseX: e.clientX, mouseY: e.clientY });
    }
  };

  handleCloseShowNotice = () => {
    this.setState({ showNotice: false });
  };

  handleCreateFolder = () => {
    this.setState({ openFolder: false, isCreateFolder: true });
  };

  handleCloseCreatePopup = () => this.setState({ isCreateFolder: false });

  handleCreateNewFolder = () => {
    const { subId, section, id } = this.props;
    const { assetId } = this.state;
    const addToFolderData = {
      itemId: subId || (!section ? id : id.split('@')[0].split('?')[0]),
      assetId,
    };
    this.handleAddNewFolder(addToFolderData);
    this.setState({ isCreateFolder: false });
  };

  handleChangeFolderName = e => {
    this.setState({ folderName: e.target.value });
  };

  render() {
    const {
      openMainNotification,
      classes,
      addToFavoriteList,
      deleteFromFavoriteList,
      shareLinkAction,
      chapter,
      global,
      section,
      subId,
      favoriteList,
      foldersList: {
        loading,
        folders: {
          data: { folders },
        },
      },
      intl: { formatMessage },
    } = this.props;
    const {
      shareDialog,
      shareTitle,
      shareDescription,
      favoriteItem,
      assetId,
      addDeleteFlag,
      openFolder,
      linkTo,
      loadingShare,
      openDialog,
      lockPopupDescription,
      storeName,
      showNotice,
      mouseX,
      mouseY,
      shareLockPopup,
      lockSharePopupDescription,
      isCreateFolder,
    } = this.state;
    return (
      <div className={classes.actionBtnsWrap}>
        {loadingShare && <AppPreloader />}
        <IconButton
          classes={{ root: classes.actionBtn }}
          onClick={() => {
            if (global.user.data === null) this.handleOpenDialog();
            else this.setState({ openFolder: true });
          }}
        >
          <PlaylistAddIcon style={{ fontSize: 32, marginRight: -2 }} />
        </IconButton>
        {!addDeleteFlag ? (
          <IconButton
            disabled={favoriteList.addToFavorite.loading}
            onClick={() => {
              if (global.user.data === null) {
                this.handleOpenDialog();
              } else {
                setTimeout(() => {
                  this.setState({ addDeleteFlag: true });
                }, 100);
                ReactGA.event('1oldfavorite', { item_id: this.props.id });
                addToFavoriteList(this.props.id, assetId, chapter, section || null, subId);
              }
            }}
            classes={{
              root: classes.actionBtn,
            }}
          >
            <FavoriteBorderIcon />
          </IconButton>
        ) : (
          <IconButton
            disabled={favoriteList.addToFavorite.loading}
            onClick={() => {
              if (global.user.data === null) {
                this.handleOpenDialog();
              } else {
                setTimeout(() => {
                  this.setState({ addDeleteFlag: false });
                }, 100);
                deleteFromFavoriteList(favoriteItem.id);
              }
            }}
            classes={{
              root: classes.actionBtn,
            }}
          >
            <FavoriteIcon />
          </IconButton>
        )}
        <IconButton
          disabled={favoriteList.addToFavorite.loading}
          onClick={() => {
            if (global.user.data === null) {
              this.handleOpenDialog(true);
            } else {
              this.setState({ loadingShare: true });
              shareLinkAction(this.props.id, chapter, section);
            }
          }}
          classes={{
            root: classes.actionBtn,
          }}
        >
          <ShareIcon />
        </IconButton>
        <AddFolderPopup
          open={openFolder}
          onClose={this.handleCloseFolder}
          folders={folders}
          addToFolder={this.handlerChooseFolder}
          onCreateFolder={this.handleCreateFolder}
          onCloseNotice={this.handleCloseShowNotice}
          noticeCoordinate={{ mouseY, mouseX }}
          showNotice={showNotice}
          loading={loading}
        />
        <ConfirmPopup
          open={isCreateFolder}
          onCancel={this.handleCloseCreatePopup}
          onConfirm={this.handleCreateNewFolder}
          title={formatMessage(myFoldersMessages.create_new_folder)}
          cancelName={formatMessage(noticePopupMessages.cancelBtn)}
          confirmName={formatMessage(newPlanMessages.next)}
        >
          <input
            type="text"
            autoFocus
            placeholder={formatMessage(messages.placeholder)}
            className={classes.dialogInput}
            onChange={this.handleChangeFolderName}
          />
        </ConfirmPopup>
        <FavoriteFolderBtnsNoticePopup
          open={openDialog}
          handleCloseDialog={this.handleCloseDialog}
          handleChangeLocalWebStorage={this.handleChangeLocalWebStorage}
          storeName={storeName}
          linkTo={linkTo}
          description={shareLockPopup ? lockSharePopupDescription : lockPopupDescription}
          shareLockPopup={shareLockPopup}
          linkToLabel={formatMessage(authRegMessages.startButton)}
          dontShowMsgCheckBox
        />
        <NoticePopup
          open={shareDialog}
          handleCloseDialog={this.handleCloseShareDialog}
          handleChangeLocalWebStorage={this.handleChangeLocalWebStorage}
          storeName={storeName}
          buttonsBeforeCancel={[
            {
              onClick: () => {
                navigator.clipboard.writeText(shareTitle).then(() => null);
                openMainNotification(formatMessage(messages.shareTitleCopied));
              },
              title: formatMessage(messages.shareTitleButton),
            },
            {
              onClick: () => {
                navigator.clipboard.writeText(shareDescription).then(() => null);
                openMainNotification(formatMessage(messages.shareDescriptionCopied));
              },
              title: formatMessage(messages.shareDescriptionButton),
            },
          ]}
          title={shareTitle}
          description={shareDescription}
          linkToLabel={formatMessage(authRegMessages.startButton)}
          dontShowMsgCheckBox
          dialogPaperFor3Buttons
        />
      </div>
    );
  }
}

FavoriteFolderBtns.propTypes = {
  classes: PropTypes.object,
  favoriteList: PropTypes.object,
  foldersList: PropTypes.object,
  section: PropTypes.string,
  intl: intlShape.isRequired,
  // video: PropTypes.object,
  global: PropTypes.object,
  chapter: PropTypes.string,
  subId: PropTypes.string,
  assetId: PropTypes.any,
  id: PropTypes.string,
  cleanErrorAction: PropTypes.func,
  addToFavoriteList: PropTypes.func,
  openMainNotification: PropTypes.func,
  openErrorNotification: PropTypes.func,
  addToFolder: PropTypes.func,
  addNewFolder: PropTypes.func,
  deleteFromFavoriteList: PropTypes.func,
  shareLinkAction: PropTypes.func,
  shareLinkClean: PropTypes.func,
};

const favoritesWithSaga = injectSaga({ key: 'favoriteList', saga: favoritesSaga });
const favoritesWithReducer = injectReducer({ key: 'favoriteList', reducer: favoritesReducer });
const folderWithSaga = injectSaga({ key: 'myFolders', saga: foldersSaga });
const folderWithReducer = injectReducer({ key: 'myFolders', reducer: foldersReducer });

const FavoriteFolderBtnsStyled = withStyles(styles)(FavoriteFolderBtns);

const FavoriteFolderBtnsStyledIntl = injectIntl(FavoriteFolderBtnsStyled);

export default compose(
  favoritesWithSaga,
  favoritesWithReducer,
  folderWithSaga,
  folderWithReducer,
  connect(
    () =>
      createStructuredSelector({
        favoriteList: makeSelectFavoriteList(),
        foldersList: makeSelectFolderContainer(),
        global: makeSelectGlobal(),
      }),
    dispatch => ({
      openErrorNotification: bindActionCreators(appActions.openErrorNotification, dispatch),
      openMainNotification: bindActionCreators(appActions.openMainNotification, dispatch),
      addToFavoriteList: bindActionCreators(favoritesActions.addToFavoriteList, dispatch),
      addToFolder: bindActionCreators(folderActions.addToFolder, dispatch),
      cleanErrorAction: bindActionCreators(folderActions.cleanErrorAction, dispatch),
      deleteFromFavoriteList: bindActionCreators(favoritesActions.deleteFromFavoriteList, dispatch),
      shareLinkAction: bindActionCreators(favoritesActions.shareLink, dispatch),
      shareLinkClean: bindActionCreators(favoritesActions.shareLinkClean, dispatch),
      addNewFolder: bindActionCreators(folderActions.addFolder, dispatch),
    }),
  ),
)(FavoriteFolderBtnsStyledIntl);
