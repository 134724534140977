import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the theory state domain
 */

const selectTheoryDomain = state => state.theory || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Theory
 */

const makeSelectTheory = () =>
  createSelector(
    selectTheoryDomain,
    substate => substate,
  );

export default makeSelectTheory;
export { selectTheoryDomain };
