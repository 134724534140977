import green from '@material-ui/core/colors/green';

export default theme => ({
  content: {
    display: 'block',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: 'white',
    backgroundColor: 'transparent',
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  mainContent: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 32,
    paddingRight: 20,
    backgroundColor: theme.palette.MMColors.fox,
  },
  dismissBtn: {
    marginRight: 8,
    marginLeft: 42,
    // textTransform: 'uppercase',
    padding: 0,
    border: 'none',
    background: 'transparent',
    color: theme.palette.MMColors.dark,
    fontSize: 18,
  },
  msgBox: {
    display: 'flex',
    alignItems: 'center',
  },
  checkIcon: {
    position: 'relative',
    width: 18,
    height: 20,
    marginRight: 54,
    '&:before': {
      position: 'absolute',
      top: 11,
      left: 0,
      content: "''",
      transform: 'rotate(50deg)',
      borderBottom: `4px solid ${theme.palette.MMColors.dark}`,
      borderLeft: '1px solid transparent',
      borderRight: '3px solid transparent',
      height: 0,
      width: 11,
    },
    '&:after': {
      position: 'absolute',
      top: 8,
      left: 2,
      content: "''",
      transform: 'rotate(-58deg)',
      borderBottom: `4px solid ${theme.palette.MMColors.dark}`,
      borderLeft: '3px solid transparent',
      borderRight: '1px solid transparent',
      height: 0,
      width: 18,
    },
  },
  msg: {
    margin: 0,
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.MMColors.dark,
  },
});
