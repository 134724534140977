import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the myFolders state domain
 */

const selectMyFoldersDomain = state => state.myFolders || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by MyFolders
 */

const makeSelectMyFolders = () =>
  createSelector(
    selectMyFoldersDomain,
    substate => substate,
  );

export default makeSelectMyFolders;
export { selectMyFoldersDomain };
