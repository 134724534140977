/*
 *
 * Azlist actions
 *
 */

import * as ActionTypes from './constants';

export function getAZAction(dispatch) {
  return {
    type: ActionTypes.GET_AZ,
    dispatch,
  };
}
