import { takeLatest, put } from 'redux-saga/effects';
import * as ActionTypes from './constants';
import axios from '../../utils/axiosConfig';
import { OPEN_MAIN_NOTIFICATION } from '../App/constants';
import mixpanel from '../../mixpanel';
import { mixpanelStorage } from '../../localStorage';

export function* fetchGetAllFalders() {
  try {
    const response = yield axios.get(`folders?offset=0&limit=-1`);
    if (response) {
      yield put({
        type: ActionTypes.GET_ALL_FOLDERS_SUCCESS,
        data: response.data,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_ALL_FOLDERS_FAIL,
      data: e.response.data.message || 'server-error',
    });
  }
}

export function* fetchAddNewFolder({ title, chapter, section, itemId, assetId }) {
  try {
    const response = yield axios.post(`folders`, {
      title,
      section: section || '',
      chapter,
    });
    if (response) {
      yield put({
        type: ActionTypes.ADD_NEW_FOLDER_SUCCESS,
        data: response.data,
      });
      yield put({
        type: ActionTypes.GET_ALL_FOLDERS,
      });
      if (itemId || assetId) {
        yield put({
          type: ActionTypes.ADD_TO_FOLDER,
          id: response.data.folder.id,
          chapter,
          item_id: itemId,
          asset_id: assetId,
          section: section || '',
        });
      }
    }
  } catch (e) {
    yield put({
      type: ActionTypes.ADD_NEW_FOLDER_FAIL,
      data: e.response.data.message,
    });
  }
}

export function* fetchDeleteFolder({ id }) {
  try {
    const response = yield axios.delete(`folders/${id}`);
    if (response) {
      yield put({
        type: ActionTypes.DELETE_FOLDER_SUCCESS,
        data: response.data,
      });
      yield put({
        type: ActionTypes.GET_ALL_FOLDERS,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.DELETE_FOLDER_FAIL,
      data: e.response.data.message,
    });
  }
}

// {"chapter":"theory", "item_id": "1", "asset_id": "95", "section":""}
// eslint-disable-next-line camelcase
export function* fetchAddToFolder({ id, chapter, item_id, asset_id, section }) {
  try {
    const response = yield axios.post(`folders/${id}`, {
      chapter,
      item_id,
      asset_id,
      section: !section ? '' : section,
    });
    if (response) {
      yield put({
        type: ActionTypes.ADD_TO_FOLDER_SUCCESS,
        data: response.data,
      });
      yield put({
        type: OPEN_MAIN_NOTIFICATION,
        text: response.data.message,
      });
      yield put({
        type: ActionTypes.GET_ALL_FOLDERS,
      });
      mixpanelStorage.setItem('saveBlock', 'Started');
      mixpanel.videoSave();
      mixpanel.exerciseSave();
    }
  } catch (e) {
    yield put({
      type: ActionTypes.ADD_TO_FOLDER_FAIL,
      data: e.response.data.message,
    });
    yield put({
      type: OPEN_MAIN_NOTIFICATION,
      text: e.response.data.message,
    });
  }
}

// PUT http://localhost:1337/api/v1/folders/5cc171064ded4fafc0b5a153
// {title:"to renME"}
export function* fetchRenameFolder({ id, title }) {
  try {
    const response = yield axios.put(`folders/${id}`, { title });
    if (response) {
      yield put({
        type: ActionTypes.RENAME_FOLDER_SUCCESS,
      });
      yield put({
        type: ActionTypes.GET_ALL_FOLDERS,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.RENAME_FOLDER_FAIL,
      data: e.response.data.message,
    });
  }
}
// All sagas to be loaded
export default function* myFoldersSaga() {
  yield takeLatest(ActionTypes.GET_ALL_FOLDERS, fetchGetAllFalders);
  yield takeLatest(ActionTypes.ADD_NEW_FOLDER, fetchAddNewFolder);
  yield takeLatest(ActionTypes.DELETE_FOLDER, fetchDeleteFolder);
  yield takeLatest(ActionTypes.ADD_TO_FOLDER, fetchAddToFolder);
  yield takeLatest(ActionTypes.RENAME_FOLDER, fetchRenameFolder);
}
