/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
const addLocaleData = require('react-intl').addLocaleData; //eslint-disable-line
const enLocaleData = require('react-intl/locale-data/en');
const ruLocaleData = require('react-intl/locale-data/ru');
const deLocaleData = require('react-intl/locale-data/de');
const esLocaleData = require('react-intl/locale-data/es');
const ptLocaleData = require('react-intl/locale-data/pt');
const koLocaleData = require('react-intl/locale-data/ko');

const enTranslationMessages = require('./translations/en.json');
const ruTranslationMessages = require('./translations/ru.json');
const deTranslationMessages = require('./translations/de.json');
const esTranslationMessages = require('./translations/es.json');
const ptTranslationMessages = require('./translations/pt.json');
const koTranslationMessages = require('./translations/ko.json');

addLocaleData(enLocaleData);
addLocaleData(ruLocaleData);
addLocaleData(deLocaleData);
addLocaleData(esLocaleData);
addLocaleData(ptLocaleData);
addLocaleData(koLocaleData);

export const DEFAULT_LOCALE = (typeof window !== 'undefined' && window.localStorage.getItem('language')) || 'en';

// prettier-ignore
export const appLocales = [
  'en',
  'ru',
  'de',
  'es',
  'pt',
  'ko',
];

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages) : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  ru: formatTranslationMessages('ru', ruTranslationMessages),
  de: formatTranslationMessages('de', deTranslationMessages),
  es: formatTranslationMessages('es', esTranslationMessages),
  pt: formatTranslationMessages('pt', ptTranslationMessages),
  ko: formatTranslationMessages('ko', koTranslationMessages),
};
