/*
 *
 * ByModel actions
 *
 */

import * as ActionTypes from './constants';

export function geByModelAction() {
  return {
    type: ActionTypes.GET_BYMODEL,
  };
}

export function geByModelMusclesAction() {
  return {
    type: ActionTypes.GET_BYMODEL_MUSCLES,
  };
}

export function getMuscularAction() {
  return {
    type: ActionTypes.GET_MUSCULAR,
  };
}
