export default theme => {
  const wrappingRight = theme.spacing(2.5);
  const bulletWrapHeight = 56;
  return {
    wrap: {
      // display: 'flex',
      // flexWrap: 'wrap',
      // alignItems: 'flex-start',
      padding: wrappingRight,
    },
    exerciseName: {
      margin: `0 0 ${theme.spacing(2)}px`,
      fontSize: 14,
      width: '100%',
    },
    mainContentBox: {
      padding: `${theme.spacing(1.5)}px 0 0 ${theme.spacing(3)}px`,
      '& h2': {
        margin: `0 0 ${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
        fontWeight: 500,
      },
    },
    videoBox: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      '&:hover': {
        '& $arrowBtn': {
          opacity: 1,
        },
      },
    },
    videoContainer: {
      maxWidth: 'calc(100% - 400px)',
      marginRight: wrappingRight,
      position: 'relative',
      border: '1px solid #707070',
      flex: 1,
    },
    bulletsWrap: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      minHeight: bulletWrapHeight,
      width: 'calc(100% - 400px)',
      marginBottom: theme.spacing(),
      padding: `${theme.spacing()}px ${wrappingRight}px`,
      background: theme.palette.MMColors.main,
    },
    bulletsBox: {
      marginRight: theme.spacing(6),
    },
    bullet: {
      border: '1px solid #d7e5ff',
      borderRadius: '50%',
      fontWeight: 600,
      whiteSpace: 'nowrap',
      padding: 0,
      width: 22,
      height: 22,
      marginRight: theme.spacing(0.5),
      background: 'transparent',
      transition: `all 0.2s ${theme.transitions.easing.easeInOut}`,
      '&:hover': {
        borderColor: theme.palette.MMColors.fox,
        color: '#000',
        background: theme.palette.MMColors.fox,
      },
    },
    bulletActive: {
      borderColor: theme.palette.MMColors.fox,
      color: '#000',
      background: theme.palette.MMColors.fox,
    },
    bulletsLabel: {
      display: 'inline-block',
      marginRight: theme.spacing(),
      fontSize: 18,
      fontWeight: 600,
    },
    list: {
      padding: 0,
    },
    subMusclesBox: {
      flex: 1,
      minWidth: '25%',
      borderLeft: '2px solid #525966',
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(1.5),
    },
    subMuscle: {
      display: 'block',
      padding: 0,
      border: 'none',
      background: 'transparent',
      '&:hover': {
        '& p, & a': {
          color: theme.palette.MMColors.fox,
        },
      },
      '& p, & a': {
        textAlign: 'left',
        display: 'block',
        width: '100%',
        padding: `${theme.spacing()}px 0`,
        transition: `color 0.2s ${theme.transitions.easing.easeInOut}`,
      },
    },
    lockIcon: {
      position: 'absolute',
      top: '50%',
      right: theme.spacing(),
      fontSize: 18,
      transform: 'translateY(-50%)',
    },
    lockIconWrap: {
      position: 'relative',
      display: 'block',
      width: '100%',
      '& a': {
        position: 'relative',
        display: 'block',
        zIndex: 2,
        width: '100%',
        padding: `${theme.spacing()}px ${theme.spacing(4)}px ${theme.spacing()}px 0`,
      },
    },
    muscleBox: {
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'flex-start',
      width: '100%',
      maxHeight: 0,
      transition: `all 0.5s ${theme.transitions.easing.easeInOut}`,
    },
    label: {
      padding: 0,
      fontSize: 20,
      fontWeight: 600,
      background: 'transparent',
      border: 'none',
      transition: `color 0.2s ${theme.transitions.easing.easeInOut}`,
      '&$labelActive, &:hover': {
        color: theme.palette.MMColors.fox,
        '& path': {
          color: theme.palette.MMColors.fox,
        },
      },
      '& svg': {
        transition: `transform 0.3s ${theme.transitions.easing.easeInOut}`,
      },
      '& path': {
        transition: `color 0.2s ${theme.transitions.easing.easeInOut}`,
      },
    },
    labelActive: {},
    dscr: {
      margin: 0,
      fontSize: 18,
      lineHeight: '1.3em',
      letterSpacing: '0.3px',
    },
    backButton: {
      background: 'transparent',
      border: 'none',
      marginRight: theme.spacing(2),
      padding: 0,
      textDecoration: 'underline',
      color: theme.palette.MMColors.fox,
    },
    arrowBtn: {
      opacity: 0,
      position: 'absolute',
      top: '50%',
      padding: 0,
      zIndex: 10,
      background: 'transparent',
      border: 'none',
      transform: 'translateY(-50%)',
      transition: 'opacity 0.2s ease-out',
      '&:hover': {
        '& svg': {
          fill: theme.palette.MMColors.fox,
        },
      },
      '& svg': {
        fontSize: 50,
        transition: 'fill 0.2s ease-out',
      },
    },
    nextBtn: {
      right: theme.spacing(2),
    },
    prevBtn: {
      left: theme.spacing(2),
    },
    subMuscleLabel: {
      paddingBottom: theme.spacing(0.5),
      margin: 0,
      fontWeight: 600,
    },
    squareCup: {
      marginRight: theme.spacing(),
      width: 14,
      height: 14,
      borderRadius: '50%',
      display: 'inline-block',
    },
    posterWrap: {
      width: 1152,
      background: '#000',
      '& img': {
        margin: '0 auto',
        maxWidth: '100%',
      },
    },
    goToFullAsanaBtn: {
      display: 'inline-block',
      background: 'transparent',
      color: theme.palette.MMColors.fox,
      border: `1px solid ${theme.palette.MMColors.fox}`,
      borderRadius: 5,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
      marginBottom: theme.spacing(2),
    },
    bulletsAndMissingLanguageWrapper: {
      display: 'flex',
    },
    missingLanguage: {
      display: 'flex',
      marginLeft: wrappingRight,
      background: theme.palette.MMColors.fox,
      color: '#19212f',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: bulletWrapHeight,
      marginBottom: theme.spacing(),
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#72530a',
      padding: `0 ${theme.spacing()}px`,
    },
  };
};
