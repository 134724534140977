export default theme => ({
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  comingSoonTitle: {
    fontSize: 14,
    fontWeight: 700,
    textTransform: 'uppercase',
    minWidth: 90,
    marginLeft: 20,
    color: theme.palette.MMColors.selectiveYellow,
  },
  copyName: {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    opacity: 0,
    whiteSpace: 'nowrap',
  },
  tooltip: {
    fontSize: 15,
  },
  box: {
    position: 'relative',
    width: 530,
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: theme.spacing(3),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  boxLock: {
    width: 554,
  },
});
