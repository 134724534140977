// import { CURRENT_VERTICAL } from 'verticalConfigs';

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

// const vertical = CURRENT_VERTICAL;
// export const PRICING_PAGE = vertical !== 'strength' ? '/pricing' : 'https://www.muscleandmotion.com/pricing/';
export const PRICING_PAGE = '/pricing';

export const SECTIONS_NAME = {
  strength: 'strength',
  yoga: 'yoga',
  asanas: 'asanas',
  myofascial: 'myofascial',
  therapeutic_asanas: 'therapeutic_asanas',
  posture: 'posture',
  postural: 'postural',
  therapeutics: 'therapeutics',
  postural_tests: 'postural_tests',
  anatomy: 'anatomy',
  theory: 'theory',
  exercise: 'exercise',
};

export const TITLE_SIZES = {
  big: 'big',
  small: 'small',
};

export const VERTICAL_NAMES = {
  strength: 'strength',
  anatomy: 'anatomy',
  yoga: 'yoga',
  posture: 'posture',
};

export const CHAPTER_NAMES = {
  skeletal: 'skeletal',
  theory: 'theory',
  exercise: 'exercise',
  muscular: 'muscular',
};

export const FORM_ITEM_NAMES = {
  all_favorites: 'all-favorites',
  asanas: 'asanas',
  skeletal: 'skeletal',
  myofascial_release: 'myofascial release',
  therapeutic_exercises: 'therapeutic exercises',
  postural_disorders: 'postural disorders',
  yoga_asanas: 'yoga asanas',
  general_postural_tests: 'general postural tests',
  muscular: 'muscular',
};

export const SYSTEM_OF_UNITS = {
  imperial: 'imperial',
  metric: 'metric',
};
