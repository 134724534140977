/*
 *
 * ByModel reducer
 *
 */
import produce from 'immer';
import * as ActionTypes from './constants';

export const initialState = {};

/* eslint-disable default-case, no-param-reassign */
const byModelReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.GET_BYMODEL:
        draft.loadingByModel = true;
        break;
      case ActionTypes.GET_BYMODEL_SUCCESS:
        draft.loadingByModel = false;
        draft.dataByModel = action.data;
        break;
      case ActionTypes.GET_BYMODEL_FAIL:
        draft.loadingByModel = false;
        draft.dataByModel = null;
        draft.errorByModel = action.error;
        break;
      case ActionTypes.GET_BYMODEL_MUSCLES:
        draft.loadingByModelMuscles = true;
        break;
      case ActionTypes.GET_BYMODEL_MUSCLES_SUCCESS:
        draft.loadingByModelMuscles = false;
        draft.dataByModelMuscles = action.data;
        break;
      case ActionTypes.GET_BYMODEL_MUSCLES_FAIL:
        draft.loadingByModelMuscles = false;
        draft.dataByModelMuscles = null;
        draft.errorByModelMuscles = action.error;
        break;
      case ActionTypes.GET_MUSCULAR:
        draft.loading = true;
        break;
      case ActionTypes.GET_MUSCULAR_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        break;
      case ActionTypes.GET_MUSCULAR_FAIL:
        draft.loading = false;
        draft.data = null;
        draft.error = action.error;
        break;
    }
  });

export default byModelReducer;
