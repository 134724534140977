/*
 *
 * SubMuscle reducer
 *
 */
import produce from 'immer';
import * as ActionTypes from './constants';

export const initialState = {
  loaded: false,
  loading: false,
  data: {},
  error: null,
};

/* eslint-disable default-case, no-param-reassign */
const subMuscleReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.GET_SUBMUSCLE_DETAILS:
        draft.loading = true;
        draft.loaded = false;
        break;
      case ActionTypes.GET_SUBMUSCLE_DETAILS_SUCCESS:
        draft.loading = false;
        draft.loaded = true;
        draft.data = action.data;
        break;
      case ActionTypes.GET_SUBMUSCLE_DETAILS_FAIL:
        draft.loading = false;
        draft.data = {};
        draft.error = action.error;
        break;
    }
  });

export default subMuscleReducer;
