/*
 * SubBones Messages
 *
 * This contains all the text for the SubBones container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.SubBones';

export default defineMessages({
  metaTitle: {
    id: `${scope}.metaTitle`,
    defaultMessage: 'SubBones',
  },
  metaDescription: {
    id: `${scope}.metaDescription`,
    defaultMessage: 'Description of SubBones',
  },
  chooseMuscle: {
    id: `${scope}.chooseMuscle`,
    defaultMessage: 'Choose muscle:',
  },
  choosePlane: {
    id: `${scope}.choosePlane`,
    defaultMessage: 'Choose plane:',
  },
  chooseAction: {
    id: `${scope}.chooseAction`,
    defaultMessage: 'Choose action:',
  },
  labelPhotos: {
    id: `${scope}.labelPhotos`,
    defaultMessage: 'Photos:',
  },
  labelVideos: {
    id: `${scope}.labelVideos`,
    defaultMessage: 'Videos:',
  },
  labelMuscles: {
    id: `${scope}.labelMuscles`,
    defaultMessage: 'Muscles:',
  },
});
