import { takeLatest, put } from 'redux-saga/effects';
import * as ActionTypes from './constants';
import axios from '../../utils/axiosConfig';

export function* fetchGetSkeletal() {
  try {
    const response = yield axios.get('skeletal/assets');
    if (response) {
      yield put({
        type: ActionTypes.GET_SKELETAL_SUCCESS,
        data: response.data,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_SKELETAL_FAIL,
      data: e.response.data.message,
    });
  }
}

// All sagas to be loaded
export default function* skeletalSaga() {
  yield takeLatest(ActionTypes.GET_SKELETAL, fetchGetSkeletal);
}
