import { Route, Switch } from 'react-router-dom';
import React from 'react';
import Home from 'containers/Home/Loadable';
import Azlist from 'containers/Azlist';
import Profile from 'containers/Profile/Loadable';
import AuthReg from 'containers/AuthReg/Loadable';
import Pricing from 'containers/Pricing/Loadable';
import Exercises from 'containers/Exercises/Loadable';
import PlansMm from 'containers/PlansMm/Loadable';
import PlansMy from 'containers/PlansMy/Loadable';
import PlansClient from 'containers/PlansClient/Loadable';
import ByModel from 'containers/ByModel/Loadable';
import Theory from 'containers/Theory/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import FavoriteList from 'containers/FavoriteList/Loadable';
import Skeletal from 'containers/Skeletal/Loadable';
import MyFolders from 'containers/MyFolders/Loadable';
import FolderContainer from 'containers/FolderContainer/Loadable';
import PrivateRoute from './containers/PrivateRoute';
import SimpleVideoPage from './containers/SimpleVideoPage';
import SubMuscle from './containers/SubMuscle';
import MuscleExercises from './containers/MuscleExercises/Loadable';
import TheoryVideo from './containers/TheoryVideo/Loadable';
import BoneVideo from './containers/BoneVideo/Loadable';
import SearchResult from './containers/SearchResult/Loadable';
import SubBones from './containers/SubBones/Loadable';
import Articles from './containers/Articles/Loadable';
import MmPlan from './containers/MmPlan/Loadable';
import PlanWorkout from './containers/PlanWorkout/Loadable';
import PlansCurrentClient from './containers/PlansCurrentClient/Loadable';
import PricingPlans from './containers/PricingPlans/Loadable';
import { CURRENT_VERTICAL } from './verticalConfigs';
import ErrorBoundary from './ErrorBoundary';

export default () => (
  <ErrorBoundary>
    <Switch>
      <PrivateRoute exact path="/profile" component={Profile} />
      <PrivateRoute exact path="/new-popular" component={Home} />
      <PrivateRoute exact path="/exercises/:id" component={Exercises} />
      <PrivateRoute exact path="/muscle-exercises/:muscleId" component={Exercises} />
      <PrivateRoute exact path="/theory-exercises/:theoryKey/:chapter?" component={MuscleExercises} />
      <PrivateRoute exact path="/muscular-anatomy/:muscleId" component={ByModel} />
      <PrivateRoute exact path="/kinesiology-skeletal/:muscleId" component={Skeletal} />
      <PrivateRoute exact path="/by-model" component={ByModel} />
      <PrivateRoute exact path="/theory/:id/:section?" component={Theory} />
      <PrivateRoute exact path="/my-folders" component={MyFolders} />
      <PrivateRoute exact path="/my-favorites" component={FavoriteList} />
      <PrivateRoute exact path="/folder/:folderId" component={FolderContainer} />
      <PrivateRoute exact path="/search-result" component={SearchResult} />
      <PrivateRoute exact path="/submuscle/:muscleId" component={SubMuscle} />
      <PrivateRoute exact path="/bones/video/:joint" component={SubBones} />
      <PrivateRoute exact path="/bone-video/:videoId" component={BoneVideo} />
      <PrivateRoute exact path="/exercise/:videoId" component={SimpleVideoPage} />
      <PrivateRoute exact path="/theory-video/:videoId" component={TheoryVideo} />
      <PrivateRoute exact path="/articles" component={Articles} />
      <PrivateRoute exact path="/a-z" component={Azlist} />
      <PrivateRoute exact path="/training-programs/mm-plans" component={PlansMm} />
      <PrivateRoute exact path="/training-programs/my-plans" component={PlansMy} />
      <PrivateRoute exact path="/training-programs/clients" component={PlansClient} />
      <PrivateRoute exact path="/training-programs/clients/:clientId" component={PlansCurrentClient} />
      <PrivateRoute exact path="/training-programs/mm-plan/:planId" component={MmPlan} />
      <PrivateRoute exact path="/training-programs/mm-plan/workout/:workoutId" component={PlanWorkout} />
      <Route exact path="/pricing-plans" component={PricingPlans} />
      <Route exact path="/login" component={AuthReg} />
      {CURRENT_VERTICAL === 'yoga' ? <Route exact path="/registration/dailyyoga" component={AuthReg} /> : null}
      <Route exact path="/pricing" component={Pricing} />
      <Route component={NotFoundPage} />
    </Switch>
  </ErrorBoundary>
);
