/*
 * AddFolderPopup Messages
 *
 * This contains all the text for the AddFolderPopup component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.AddFolderPopup';

export default defineMessages({
  addToFolder: {
    id: `${scope}.addToFolder`,
    defaultMessage: 'Add to folder',
  },
  chooseFolder: {
    id: `${scope}.chooseFolder`,
    defaultMessage: 'Choose an existing folder or create a new one',
  },
  youDontHaveFolder: {
    id: `${scope}.youDontHaveFolder`,
    defaultMessage: `You don't have any folders yet`,
  },
});
