import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the subMuscle state domain
 */

const selectSubMuscleDomain = state => state.subMuscle || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by SubMuscle
 */

const makeSelectSubMuscle = () =>
  createSelector(
    selectSubMuscleDomain,
    substate => substate,
  );

export default makeSelectSubMuscle;
export { selectSubMuscleDomain };
