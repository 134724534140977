export const cookies = {
  setCookie(name, value) {
    document.cookie = `${name}=${value};domain=.muscleandmotion.com`;
  },
  getCookie(name) {
    const nameOfCookie = `${name}=`;
    const regExp = new RegExp(`${nameOfCookie}(.*?)`);
    const value = document.cookie
      .split(';')
      .reduce((result, string) => (string.includes(nameOfCookie) ? string.replace(regExp, '$1') : result), '');

    return value.trim() || false;
  },
  deleteCookie(name) {
    document.cookie = `${name}=;domain=.muscleandmotion.com;Max-Age=0;`;
  },
  getDomainInfo() {
    const hostnameArray = window.location.hostname.split('.');
    const env = hostnameArray[0];
    const domain = hostnameArray[1];

    return { env, domain };
  },
  setTokenToAnotherDomain(domainToSet, token) {
    const { env, domain } = this.getDomainInfo();
    const verticalId = domainToSet;
    this.setCookie(`${env}_${verticalId}`, token);
    this.setCookie(`${env}_${verticalId}_logout`, 'false');
    this.setCookie(`${env}_${domain}_logout`, 'false');
  },
  getTokenFromThisDomain() {
    const { env, domain } = this.getDomainInfo();
    return this.getCookie(`${env}_${domain}`);
  },
  getLogoutCookieFromThisDomain() {
    const { env, domain } = this.getDomainInfo();
    return this.getCookie(`${env}_${domain}_logout`);
  },
  deleteTokenFromThisDomain() {
    const { env, domain } = this.getDomainInfo();
    return this.deleteCookie(`${env}_${domain}`);
  },
  deleteLogoutCookieFromThisDomain() {
    const { env, domain } = this.getDomainInfo();
    return this.deleteCookie(`${env}_${domain}_logout`);
  },
  deleteTokensFromAllDomains() {
    const { env, domain } = this.getDomainInfo();
    ['strength', 'posture', 'yoga', 'anatomy'].forEach(domainToSet => {
      this.deleteCookie(`${env}_${domainToSet}`);
      if (this.getCookie(`${env}_${domainToSet}_logout`) !== false) {
        this.setCookie(`${env}_${domainToSet}_logout`, 'true');
        if (domain === domainToSet) this.deleteCookie(`${env}_${domainToSet}_logout`);
      }
    });
  },
};
