import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the favoriteList state domain
 */

const selectFavoriteListDomain = state => state.favoriteList || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by FavoriteList
 */

const makeSelectFavoriteList = () =>
  createSelector(
    selectFavoriteListDomain,
    substate => substate,
  );

export default makeSelectFavoriteList;
export { selectFavoriteListDomain };
