/*
 *
 * Skeletal actions
 *
 */

import * as ActionTypes from './constants';

export function getSkeletalAction() {
  return {
    type: ActionTypes.GET_SKELETAL,
  };
}
