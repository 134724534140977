export default theme => ({
  wrap: {
    '& .video-js .vjs-text-track-display .vjs-text-track-cue': {
      fontSize: '24px !important',
      '& div': {
        background: 'rgba(0, 0, 0, 0.5) !important',
      },
    },
    '& .simplebar-content-wrapper': {
      display: 'block',
      overflow: 'hidden scroll !important',
    },
    '& .simplebar-content': {
      width: '100%',
      height: '100%',
    },
    '& .simplebar-track': {
      backgroundColor: theme.palette.MMColors.cloudBurst,
      '& .simplebar-scrollbar:before': {
        backgroundColor: theme.palette.MMColors.blackCoral,
      },
    },
    '& .simplebar-vertical': {
      width: 7,
    },
    '& .simplebar-horizontal': {
      height: 7,
    },
    '& .my-custom-caption-btn .vjs-subs-caps-button': {
      '& + div.vjs-menu': {
        display: 'none !important',
      },
      '& .vjs-icon-placeholder:before': {
        display: 'none',
      },
      '& .caption-on-icon': {
        width: 20,
      },
      '& .caption-off-icon': {
        width: 20,
        display: 'none',
      },
    },
    '& .my-custom-caption-btn.my-custom-caption-btn-disabled .vjs-subs-caps-button': {
      '& .caption-on-icon': {
        display: 'none',
      },
      '& .caption-off-icon': {
        display: 'block',
      },
    },
  },
  collapseMenuBtn: {
    border: 'none',
    background: 'none',
    display: 'none',
    position: 'absolute',
    zIndex: theme.zIndex.appBar + 2,
    left: 15,
    top: 80,
    padding: 0,
    opacity: 0,
    transition: `opacity .3s ${theme.transitions.easing.easeInOut}`,
    transform: 'rotate(270deg)',
    '&:hover': {
      '& svg path': {
        color: theme.palette.MMColors.fox,
      },
    },
  },
  hideCollapseBtn: {
    display: 'block',
    opacity: 1,
  },
  main: {
    position: 'relative',
    marginLeft: 300,
    width: 'calc(100% - 300px)',
    height: 'calc(100vh - 60px)',
    background: theme.palette.MMColors.deepMain,
    zIndex: theme.zIndex.appBar + 1,
    boxShadow: 'inset 5px -5px 20px 2px rgba(0,0,0,0.25)',
  },
  withSideBar: {
    width: 'calc(100% - 0px)',
    marginLeft: 0,
    paddingLeft: 30,
    transition: `margin .2s ${theme.transitions.easing.easeInOut}`,
  },
});
