/*
 *
 * SimpleVideoPage constants
 *
 */

export const GET_VIDEO_BY_ID = 'app/SimpleVideoPage/GET_VIDEO_BY_ID';
export const GET_VIDEO_BY_ID_SUCCESS = 'app/SimpleVideoPage/GET_VIDEO_BY_ID_SUCCESS';
export const GET_VIDEO_BY_ID_FAIL = 'app/SimpleVideoPage/GET_VIDEO_BY_ID_FAIL';
export const CLEAR_VIDEO_BY_ID = 'app/SimpleVideoPage/CLEAR_VIDEO_BY_ID';
